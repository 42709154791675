import React, { useState } from "react";
import { Dialog } from "@material-tailwind/react";
import toastSuccess from "../Toast/toastSuccess";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsFillSendFill } from "react-icons/bs";
import { ContratoCoordenador } from "../Modal/stylesContratos/styledCoordenador";
import { ContratoCoordenadorFA } from "../Modal/stylesContratos/styledFA";
import { ContratoFormador } from "../Modal/stylesContratos/styledFormador";
import { ContratoFormando } from "../Modal/stylesContratos/styledFormando";
import refreshPage from "../Refresh";
import { MdAddCircleOutline } from "react-icons/md";
import { FaCircleMinus, FaFileContract } from "react-icons/fa6";
import { FaListAlt } from "react-icons/fa";
import FichaInscricao from "../FichasDeInscricao/ficha-de-inscricao";
import { StyledFichaDeInscricao as FichaInscricaoAntiga } from "../Modal/stylesContratos/styledFichaInscricao";
import AlterarFormato from "../FormChecks/formatDates/foramatDates";
import ContratoFormandoEDigital from "../Contratos/contrato-formando-E+Digital";
import ContratoFormadorEDigital from "../Contratos/contrato-formador-E+Digital";
import ContratoFormandoGreenSkillsJobs from "../Contratos/contrato-formando-green-skills-jobs";
import ContratoFormadorGreenSkillsJobs from "../Contratos/contrato-formador-green-skills-jobs";
import ContratoFormandoPercursoComenius from "../Contratos/contrato-formando-percurso-comenius";
import ContratoFormandoUfcdMaisAdvantage from "../Contratos/contrato-formando-ufcd-mais-advantage";
import ContratoFormandoEFAComenius from "../Contratos/contrato-formando-EFA-comenius";
import ContratoCoordenadorEFA from "../Contratos/contrato-coordenador-EFA";
import ContratoFormadorCompete2030 from "../Contratos/contrato-formador-compete2030";

export default function ModalEnvioContrato({
  tipo,
  data,
  token,
  formador,
  entFormadora,
}) {
  const [open, setOpen] = useState(false);
  const [cont, setCont] = useState(0);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(0);
  const [local, setLocal] = useState(0);
  const [valorBase, setValorBase] = useState(0);
  const [valorDescritivo, setValorDescritivo] = useState(0);
  const [selectTipo, setSelectTipo] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");

  // Values for the contract (editable fields)
  const [valores, setValores] = useState({
    date: "",
    local: "",
    valorBase: 0,
    valorDescritivo: 0,
    consulturia1: [{ acao: "", nHoras: "" }],
    consulturia2: [{ nameAcao: "", nHoras: "" }],
  });

  const index =
    formador && tipo === "formador"
      ? data.dadosContrato.findIndex(
          (disciplina) => disciplina.id === formador._id
        )
      : 0;

  //Abre ou fecha modal
  const handleOpen = () => {
    setOpen(!open);
  };

  //Envia contrato coordenador
  function enviarContratoCoordenador() {
    valores.date =
      date !== undefined && date !== null && date !== 0
        ? date
        : data.dadosContrato && data.dadosContrato.date
        ? data.dadosContrato.date
        : AlterarFormato(data.dateBegin);
    valores.local =
      local !== undefined && local !== null && local !== 0
        ? local
        : data.dadosContrato && data.dadosContrato.local
        ? data.dadosContrato.local
        : data.entFormadora[0].localidade;
    valores.valorBase =
      valorBase !== 0 && valorBase
        ? valorBase
        : data.dadosContrato &&
          data.dadosContrato.valorBase &&
          data.dadosContrato.valorBase;
    valores.valorDescritivo =
      valorDescritivo !== 0 && valorDescritivo
        ? valorDescritivo
        : data.dadosContrato &&
          data.dadosContrato.valorDescritivo &&
          data.dadosContrato.valorDescritivo;

    let divErro = document.getElementById("erro");
    if (valores.valorBase && valores.valorDescritivo) {
      divErro.innerHTML = "";
      setLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/coordenadores/contrato`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
        method: "POST",
        body: JSON.stringify({
          id_percurso: data._id,
          id: data.Coordenador[0]._id,
          valores: valores,
        }),
      })
        .then((res) => res.json())
        .then((result) => {});
      handleOpen();
      toastSuccess("Contrato enviado com sucesso");
      setLoading(false);
      refreshPage();
    } else {
      divErro.innerHTML = "Preencha todos os campos editáveis!";
    }
  }
  //Contrato Formação Ação
  function enviarContratoCoordenadorFA() {
    valores.date =
      date !== undefined && date !== null && date !== 0
        ? date
        : data.dadosContrato && data.dadosContrato.date
        ? data.dadosContrato.date
        : AlterarFormato(data.dateBegin);
    valores.local =
      local !== undefined && local !== null && local !== 0
        ? local
        : data.dadosContrato && data.dadosContrato.local
        ? data.dadosContrato.local
        : data.entFormadora[0].localidade;
    valores.valorBase =
      valorBase !== 0 && valorBase
        ? valorBase
        : data.dadosContrato &&
          data.dadosContrato.valorBase &&
          data.dadosContrato.valorBase;
    valores.valorDescritivo =
      valorDescritivo !== 0 && valorDescritivo
        ? valorDescritivo
        : data.dadosContrato &&
          data.dadosContrato.valorDescritivo &&
          data.dadosContrato.valorDescritivo;
    let divErro = document.getElementById("erro");
    if (valores.valorBase && valores.valorDescritivo) {
      divErro.innerHTML = "";
      setLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/coordenadores/contrato`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
        method: "POST",
        body: JSON.stringify({
          id_percurso: data._id,
          id: data.Coordenador[0]._id,
          valores: valores,
        }),
      })
        .then((res) => res.json())
        .then((result) => {});
      handleOpen();
      toastSuccess("Contrato enviado com sucesso");
      setLoading(false);
      refreshPage();
    } else {
      divErro.innerHTML = "Preencha todos os campos editáveis!";
    }
  }

  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Opens the link in a new tab/window
    link.setAttribute("download", ""); // Optional, sets the download attribute to trigger download in some browsers
    link.click();
  }

  // when contracts are changed for an individual component do not use this function
  // when all contracts are changed for a single component remove this function
  async function enviarContratoFormadorAntigo() {
    valores.date =
      date !== undefined && date !== null && date !== 0
        ? date
        : data.dadosContrato && data.dadosContrato.date
        ? data.dadosContrato.date
        : AlterarFormato(data.dateBegin);
    valores.local =
      local !== undefined && local !== null && local !== 0
        ? local
        : data.dadosContrato && data.dadosContrato.local
        ? data.dadosContrato.local
        : data.percurso[0].entFormadora[0].localidade;
    valores.valorBase =
      valorBase !== 0 && valorBase !== undefined && valorBase !== null
        ? valorBase
        : data.dadosContrato && data.dadosContrato.valorBase
        ? data.dadosContrato.valorBase
        : data.dadosContrato[index].dadosContrato.valorBase;
    valores.valorDescritivo =
      valorDescritivo !== 0 &&
      valorDescritivo !== undefined &&
      valorBase !== null
        ? valorDescritivo
        : data.dadosContrato && data.dadosContrato.valorDescritivo
        ? data.dadosContrato.valorDescritivo
        : data.dadosContrato[index].dadosContrato.valorDescritivo;
    let divErro = document.getElementById("erro");
    if (valores.valorBase && valores.valorDescritivo) {
      await fetch(`${process.env.REACT_APP_API_URL}/formadores/contrato`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
        method: "POST",
        body: JSON.stringify({
          id: formador ? formador._id : data.formadores[0]._id,
          id_ufcd: data._id,
          valores: valores,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          setLoading(false);
          handleOpen();
          refreshPage();
        });
    } else {
      divErro.innerHTML = "Preencha todos os campos editáveis!";
    }
  }
  // when contracts are changed for an individual component do not use this function
  // when all contracts are changed for a single component remove this function
  async function enviarContratoFormadorNoEmailAntigo() {
    valores.date =
      date !== undefined && date !== null && date !== 0
        ? date
        : data.dadosContrato && data.dadosContrato.date
        ? data.dadosContrato.date
        : AlterarFormato(data.dateBegin);
    valores.local =
      local !== undefined && local !== null && local !== 0
        ? local
        : data.dadosContrato && data.dadosContrato.local
        ? data.dadosContrato.local
        : data.percurso[0].entFormadora[0].localidade;
    valores.valorBase =
      valorBase !== 0 && valorBase !== undefined && valorBase !== null
        ? valorBase
        : data.dadosContrato && data.dadosContrato.valorBase
        ? data.dadosContrato.valorBase
        : data.dadosContrato[index].dadosContrato.valorBase;
    valores.valorDescritivo =
      valorDescritivo !== 0 &&
      valorDescritivo !== undefined &&
      valorBase !== null
        ? valorDescritivo
        : data.dadosContrato && data.dadosContrato.valorDescritivo
        ? data.dadosContrato.valorDescritivo
        : data.dadosContrato[index].dadosContrato.valorDescritivo;

    await fetch(
      `${process.env.REACT_APP_API_URL}/formadores/contratoSemEmail`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
        method: "POST",
        body: JSON.stringify({
          id: formador ? formador._id : data.formadores[0]._id,
          id_ufcd: data._id,
          valores: valores,
        }),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);
        downloadFile(result);
        handleOpen();
        /*   refreshPage() */
      });
  }
  // when contracts are changed for an individual component do not use this function
  // when all contracts are changed for a single component remove this function
  async function enviarContratoFormandoNoEmailAntigo() {
    valores.date =
      date !== undefined && date !== null && date !== 0
        ? date
        : data.dadosContrato && data.dadosContrato.date
        ? data.dadosContrato.date
        : data.percurso && data.percurso[0] && data.percurso[0].dateBegin
        ? AlterarFormato(data.percurso[0].dateBegin)
        : ""; // You can adjust the fallback value here
    valores.local =
      local !== undefined && local !== null && local !== 0
        ? local
        : data.dadosContrato && data.dadosContrato.local
        ? data.dadosContrato.local
        : data.percurso[0].entFormadora[0].localidade;
    await fetch(`${process.env.REACT_APP_API_URL}/formandos/contratoSemEmail`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
      method: "POST",
      body: JSON.stringify({
        id: data._id,
        percurso: data.percurso[0]._id,
        valores: valores,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);
        downloadFile(result);
        handleOpen();
        refreshPage();
      });
  }
  // when contracts are changed for an individual component do not use this function
  // when all contracts are changed for a single component remove this function
  async function enviarContratoFormandoAntigo() {
    valores.date =
      date !== undefined && date !== null && date !== 0
        ? date
        : data.dadosContrato && data.dadosContrato.date
        ? data.dadosContrato.date
        : AlterarFormato(data.percurso[0].dateBegin);
    valores.local =
      local !== undefined && local !== null && local !== 0
        ? local
        : data.dadosContrato && data.dadosContrato.local
        ? data.dadosContrato.local
        : data.percurso[0].entFormadora[0].localidade;
    await fetch(`${process.env.REACT_APP_API_URL}/formandos/contrato`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
      method: "POST",
      body: JSON.stringify({
        id: data._id,
        percurso: data.percurso[0]._id,
        valores: valores,
      }),
    }).then((result) => {
      setLoading(false);
      handleOpen();
      refreshPage();
    });
    toastSuccess("Contrato enviado com sucesso");
  }

  return (
    <>
      {tipo !== "formador" ? (
        <button
          className={`${tipo === "formando" ? "" : "button-geral"} ${
            data.dadosContrato && tipo !== "formando"
              ? "bg-text-correct hover:bg-text-correct hover:brightness-90"
              : ""
          } ${
            data.contrato_path &&
            data.dadosContrato &&
            tipo === "formando" &&
            "text-text-correct hover:brightness-90"
          } `}
          onClick={handleOpen}
          variant="gradient"
          data-tooltip-id="my-tooltip"
          data-tooltip-content={
            data.contrato_path && data.dadosContrato
              ? "Reenviar Contrato"
              : "Enviar Contrato"
          }
          data-tooltip-place="top"
        >
          <BsFillSendFill />
        </button>
      ) : (
        <button
          className={`button-geral ${
            data.contrato_path.some((formad) => formad.id === formador._id)
              ? "bg-text-correct hover:bg-text-correct hover:brightness-90"
              : ""
          }`}
          onClick={handleOpen}
          variant="gradient"
          data-tooltip-id="my-tooltip"
          data-tooltip-content={
            data.contrato_path.some((formad) => formad.id === formador._id)
              ? "Reenviar Contrato"
              : "Enviar Contrato"
          }
          data-tooltip-place="top"
        >
          <BsFillSendFill color="white" />
        </button>
      )}
      <Dialog
        className="fixed inset-0 w-screen h-screen m-0 bg-opacity-0 font-Lato"
        open={open}
        handler={handleOpen}
      >
        <div className="flex items-center justify-center h-screen">
          <div className="absolute w-full h-full" onClick={handleOpen}></div>
          <div className="relative z-50 block p-4 sm:p-10 overflow-auto rounded-lg shadow-lg max-h-[90%] w-[90%] sm:w-[70%] bg-secundary-color shadow-gray-600">
            <h1 className="text-center subtitle">
              Pré-visualização do contrato
            </h1>
            {tipo === "Cursos de Educação e Formação de Adultos" && (
              <>
                <ContratoCoordenadorEFA
                  data={data}
                  token={token}
                  setOpen={setOpen}
                />
              </>
            )}

            {(tipo === "Formação Ação" ||
              tipo === "Formação Empresarial Conjunta e Formação Ação") && (
              /* 
                  // TODO - convert in a component
                  */
              <ContratoCoordenadorFA>
                <div className="body">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <img
                        src={
                          data.entFormadora[0] && data.entFormadora[0].logotipo
                        }
                        className="h-20"
                        alt="logo"
                        style={{ marginLeft: "20px" }}
                      />
                    </div>
                    <div>
                      <h1
                        style={{
                          fontSize: "16px",
                          paddingLeft: "15px",
                          marginTop: "-5px",
                        }}
                      >
                        Contrato de prestação de serviços com Consultor de
                        Ligação/Consultor de Formação Empresarial Conjunta e
                        Formação Ação
                      </h1>
                    </div>
                    <div>
                      {data.entFinanciadora.length > 0 &&
                        data.entFinanciadora[0].logotipo1 && (
                          <img
                            src={data.entFinanciadora[0].logotipo1}
                            className="h-20"
                            alt="logo"
                            style={{ marginLeft: "20px" }}
                          />
                        )}
                    </div>
                  </div>
                  <div>
                    <div>
                      <p>
                        Entre:
                        <br />
                        <b>
                          {" "}
                          {data.entFormadora[0] &&
                            data.entFormadora[0].name}{" "}
                        </b>
                        , pessoa coletiva nº{" "}
                        {data.entFormadora[0] && data.entFormadora[0].nif} com
                        instalações na{" "}
                        {data.entFormadora[0] && data.entFormadora[0].morada},{" "}
                        {data.entFormadora[0].postalCode}{" "}
                        {data.entFormadora[0] &&
                          data.entFormadora[0].localidade}
                        , representado por{" "}
                        {data.entFormadora[0] &&
                          data.entFormadora[0].representanteLegal}
                        , adiante designada por <b>PRIMEIRO Outorgante</b>, e{" "}
                        {data.Coordenador[0] && data.Coordenador[0].fullName},
                        adiante designado/a por SEGUNDO Outorgante,
                        <br />
                      </p>
                      <table className="dados">
                        <tr>
                          <th>
                            <b>Nome Completo</b>
                          </th>
                          <td>
                            {data.Coordenador[0] &&
                              data.Coordenador[0].fullName}
                          </td>
                        </tr>
                        <tr>
                          <th>NIF</th>
                          <td>
                            {data.Coordenador[0] && data.Coordenador[0].nif}
                          </td>
                        </tr>
                        <tr>
                          <th>Cartão de Cidadão/Passaporte</th>
                          <td>
                            {data.Coordenador[0] &&
                              data.Coordenador[0].ccNumber}
                          </td>
                        </tr>
                        <tr>
                          <th>Morada</th>
                          <td>
                            {data.Coordenador[0] && data.Coordenador[0].address}
                          </td>
                        </tr>
                        <tr>
                          <th>Código Postal</th>
                          <td>
                            {data.Coordenador[0] &&
                              data.Coordenador[0].postalCode}
                          </td>
                        </tr>
                      </table>
                      <p>
                        , é nesta data livremente Outorgado um contrato de
                        prestação de serviços no âmbito da formação/consultoria,
                        o qual se rege pelas seguintes cláusulas:{" "}
                      </p>
                    </div>
                    <div>
                      <h2>Cláusula 1ª</h2>
                      <p>
                        1. O <b>SEGUNDO OUTORGANTE</b> é contratado como
                        PRESTADOR DE SERVIÇOS para o exercício da função de
                        <b> Consultor/a de Ligação</b> e/ou{" "}
                        <b>Consultor de Formação Ação</b> e/ou{" "}
                        <b>Formador de Formação Ação</b>
                        <span style={{ fontSize: "12px" }}>
                          (rasurar o que não se aplica) na seguinte empresa:
                        </span>
                      </p>
                      <table className="dados">
                        <tr>
                          <th style={{ width: "134px" }}>NOME DA EMPRESA:</th>
                          <td style={{ width: "420px" }}>
                            {data.entFormadora[0] && data.entFormadora[0].name}
                          </td>
                          <th style={{ width: "110px" }}>NIPC:</th>
                          <td style={{ width: "40px" }}>
                            {data.entFormadora[0] && data.entFormadora[0].nif}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <b>Nº do Projeto (se necessário)</b>
                          </th>
                          <td colSpan="3">
                            {data.operacao[0] &&
                              data.operacao[0].codigoOperacao}
                          </td>
                        </tr>
                      </table>
                      <span style={{ fontSize: "10px" }}>
                        NOTA: caso não se aplique no atual contrato a atividade
                        de “Consultor de Ligação” rasurar o conteúdo das células
                        anteriores
                      </span>
                    </div>
                    <div>
                      <p>
                        2. As atividades de <b>Consultor/a de Ligação</b>,
                        quando aplicável, dizem respeito às seguintes ações de
                        consultoria formativa:
                      </p>

                      <table className="dados">
                        {data.dadosContrato
                          ? data.dadosContrato.consulturia1.map(
                              (item, index) => (
                                <tr>
                                  <th style={{ width: "20%" }}>Ação:</th>
                                  <td style={{ width: "50%" }}>
                                    <input
                                      className="w-full input-field"
                                      defaultValue={item.acao}
                                      onChange={(e) => {
                                        item.acao = e.target.value;
                                      }}
                                    />
                                  </td>
                                  <th style={{ width: "15%" }}>Nº de horas:</th>
                                  <td style={{ width: "15%" }}>
                                    <input
                                      className="w-full input-field"
                                      defaultValue={item.nHoras}
                                      onChange={(e) => {
                                        item.nHoras = e.target.value;
                                      }}
                                    />
                                  </td>
                                  {valores.consulturia1.length > 1 && (
                                    <td>
                                      <button
                                        className="flex items-center"
                                        onClick={() => {
                                          valores.consulturia1 =
                                            valores.consulturia1.splice(
                                              index,
                                              1
                                            );
                                          setCont(cont + 1);
                                        }}
                                      >
                                        <FaCircleMinus className="text-xl hover:opacity-80 text-text-error" />
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              )
                            )
                          : null}
                      </table>
                      <div className="flex justify-center w-full mb-3">
                        <button
                          className="button-geral tooltip"
                          data-tip="Clique para adicionar mais uma ação."
                        >
                          <MdAddCircleOutline
                            className="text-xl"
                            onClick={() => {
                              data.dadosContrato.consulturia1.push({
                                acao: "",
                                nHoras: "",
                              });
                              setCont(cont + 1);
                            }}
                          />
                        </button>
                      </div>
                      <p style={{ fontSize: "10px" }}>
                        (NOTA:caso não se aplique no atual contrato a atividade
                        de “Consultor de Ligação” rasurar o conteúdo das células
                        anteriores)
                      </p>
                    </div>

                    <div>
                      <p>
                        3. As atividades de{" "}
                        <strong>Consultor/a de Formação Ação</strong> dizem
                        respeito à execução das seguintes ações de consultoria:
                      </p>
                      <table className="dados">
                        {data.dadosContrato
                          ? data.dadosContrato.consulturia2.map(
                              (item, index) => (
                                <tr key={index}>
                                  <th style={{ width: "20%" }}>
                                    Nome de Ação:
                                  </th>
                                  <td style={{ width: "50%" }}>
                                    <input
                                      className="w-full input-field"
                                      defaultValue={item.nameAcao}
                                      onChange={(e) => {
                                        item.nameAcao = e.target.value;
                                      }}
                                    />
                                  </td>
                                  <th style={{ width: "15%" }}>Nº de horas:</th>
                                  <td style={{ width: "15%" }}>
                                    <input
                                      className="w-full input-field"
                                      defaultValue={item.nHoras}
                                      onChange={(e) => {
                                        item.nHoras = e.target.value;
                                      }}
                                    />
                                  </td>
                                  {valores.consulturia2.length > 1 && (
                                    <td>
                                      <button
                                        className="flex items-center"
                                        onClick={() => {
                                          valores.consulturia2 =
                                            valores.consulturia2.splice(
                                              index,
                                              1
                                            );
                                          setCont(cont + 1);
                                        }}
                                      >
                                        <FaCircleMinus className="text-xl hover:opacity-80 text-text-error" />
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              )
                            )
                          : null}
                      </table>
                      <div className="flex justify-center w-full mb-3">
                        <button
                          className="button-geral tooltip"
                          data-tip="Clique para adicionar mais uma ação."
                        >
                          <MdAddCircleOutline
                            className="text-xl"
                            onClick={() => {
                              data.dadosContrato.consulturia2.push({
                                nameAcao: "",
                                nHoras: "",
                              });
                              setCont(cont + 1);
                            }}
                          />
                        </button>
                      </div>
                      <p style={{ fontSize: "10px" }}>
                        (NOTA 1: caso não se aplique no atual contrato a
                        atividade de “Consultor/a de de Formação Ação” rasurar o
                        conteúdo das células anteriores; NOTA 2: trancar as
                        células vazias)
                      </p>
                    </div>
                    <div>
                      <p>
                        4.As atividades de{" "}
                        <strong>Formador/a de Formação Ação</strong> dizem
                        respeito à execução das seguintes ações de formação:
                      </p>
                      <table className="dados">
                        {data.ufcds.map((item, index) => (
                          <tr key={index}>
                            <th style={{ width: "20%" }}>
                              Nome e Código (se existir)
                            </th>
                            <td style={{ width: "50%" }}>
                              {item.name + " " + item.codeUfcd}
                            </td>
                            <th style={{ width: "15%" }}>Nº de horas:</th>
                            <td style={{ width: "15%" }}>
                              {item.cargaHoraria}
                            </td>
                          </tr>
                        ))}
                      </table>

                      <p style={{ fontSize: "10px" }}>
                        (NOTA 1: caso não se aplique no atual contrato a
                        atividade de “Formador/a de de Formação Ação” rasurar o
                        conteúdo das células anteriores; NOTA 2: trancar as
                        células vazias)
                      </p>
                    </div>
                    <div>
                      <p>
                        5. Quando as atividades decorrerem no formato
                        presencial, o local de realização é a morada da empresa,
                        nomeadamente a seguinte:
                      </p>
                      <table className="dados">
                        <tr>
                          <th style={{ width: "20%" }}>Local</th>
                          <td style={{ width: "50%" }}>
                            {data.entFormadora[0] &&
                              data.entFormadora[0].localidade}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "20%" }}>Morada</th>
                          <td style={{ width: "50%" }}>
                            {data.entFormadora[0] &&
                              data.entFormadora[0].morada}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "20%" }}>Código de Postal</th>
                          <td style={{ width: "50%" }}>
                            {data.entFormadora[0] &&
                              data.entFormadora[0].postalCode}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div>
                      <p>
                        6. Quando as atividades decorrerem no formato a
                        distância, serão realizadas através da seguinte
                        plataforma:
                      </p>
                      <table className="dados">
                        <tr>
                          <th>
                            <b>Plataforma LMS:</b>
                          </th>
                          <td>
                            {data.moodle && data.moodle.length > 0 ? (
                              <a href={data.moodle[0].name}>
                                {data.moodle[0].name}
                              </a>
                            ) : (
                              "NA"
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div>
                      <p>
                        7. As datas previstas para a realização das atividades
                        são as seguintes:
                      </p>
                      <table className="plataforma">
                        <tr>
                          <th>
                            <b>Data de inicio</b>
                          </th>
                          <td style={{ width: "50%" }}>
                            {AlterarFormato(data.dateBegin)}{" "}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <b>Data de fim</b>
                          </th>
                          <td style={{ width: "50%" }}>
                            {AlterarFormato(data.dateEnd)}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div>
                    <h2>Cláusula 2ª</h2>
                    <ol>
                      <li>
                        As atividades previstas na prestação de serviços,
                        mencionadas na cláusula 1ª, estão enquadradas pela
                        regulamentação do COMPETE 2030 e outra legislação
                        aplicável e contempla as seguintes tarefas:
                      </li>
                      <ol className="tipoa">
                        <li>
                          Estando em causa a atividade de Consultor/a de Ligação
                          na realização do “Diagnóstico de Necessidades de
                          Formação”, este deve auscultar as necessidades de
                          formação e consultoria técnica ou outra, da empresa,{" "}
                          <b>
                            a partir do diálogo com o empresário/gestor com as
                            chefias
                          </b>{" "}
                          e, se possível, com os colaboradores,{" "}
                          <b>
                            valorizando as necessidades que estes identificam
                          </b>{" "}
                          – seguindo para isso o Procedimento 40 do Sistema
                          baseado na ISO 9001 em vigor no PRIMEIRO OUTORGANTE,
                          que se anexa ao presente contrato;
                        </li>

                        <li>
                          Estando em causa a atividade de Consultor/a de Ligação
                          na realização do “Plano de Formação e Consultoria”,
                          deve seguir o Procedimento 40 do Sistema baseado na
                          ISO 9001 em vigor no PRIMEIRO OUTORGANTE, que se anexa
                          ao presente contrato, assim como o modelo nele
                          previsto para o “Plano de Formação e Consultoria”;
                        </li>

                        <li>
                          Estando em causa a atividade de Consultor/a de
                          Formação Ação, anexa-se a/s “Ficha/s de Consultoria”
                          relativas ao serviço requerido;
                        </li>

                        <li>
                          Estando em causa a atividade de Formador/a de Formação
                          Ação, devem ser seguidos os conteúdos previstos no
                          Catálogo Nacional de Qualificações e, caso a formação
                          não esteja nele referenciado, anexa-se a/s “Ficha/s de
                          Ação de Formação” relativas ao serviço requerido.
                        </li>

                        <li>
                          Relativamente às Ações de Formação, incluem-se nas
                          tarefas do/a Formador/a receber, preencher e entregar
                          o Dossier Técnico Pedagógico da respetiva Ação de
                          Formação.
                        </li>
                      </ol>

                      <br />
                      <li>
                        {" "}
                        O <b>SEGUNDO OUTORGANTE</b> executará os serviços a que
                        se obriga no local previsto para a prestação dos
                        serviços, salvo autorização expressa do{" "}
                        <b>PRIMEIRO OUTORGANTE</b>.
                      </li>
                      <li>
                        Sempre que necessário, em dia e hora a combinar, o{" "}
                        <b>SEGUNDO OUTORGANTE</b> reunirá com elementos do
                        PRIMEIRO OUTORGANTE a fim de dar conhecimento das
                        diligências efetuadas e dos serviços em curso.
                      </li>
                    </ol>
                  </div>
                  <div>
                    <h2>Cláusula 3ª</h2>
                    <ol>
                      <li>
                        O preço base da prestação de serviços corresponde ao
                        seguinte:
                      </li>
                      <table className="valor">
                        <tr>
                          <th>Valor unitário (€)</th>
                          <td>
                            <input
                              className="w-full input-field"
                              defaultValue={valores.valorBase}
                              onChange={(e) => {
                                valores.valorBase = e.target.value;
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Valor unitário (descritivo)</th>
                          <td>
                            <input
                              className="w-full input-field"
                              defaultValue={valores.valorDescritivo}
                              onChange={(e) => {
                                valores.valorDescritivo = e.target.value;
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>NOTA:</th>
                          <td>
                            Valores acrescidos de IVA à taxa legal em vigor, se
                            de direito.
                          </td>
                        </tr>
                      </table>
                      <li>
                        O <b>SEGUNDO OUTORGANTE</b> deverá apresentar
                        faturas-recibo nos valores respeitantes aos pagamentos a
                        efetuar mensalmente, com o descritivo correspondente ao
                        objeto previsto neste contrato.
                      </li>
                      <li>
                        O <b>PRIMEIRO OUTORGANTE</b> efetuará o pagamento das
                        respetivas importâncias após entrega/emissão de toda a
                        documentação em conformidade e exigida pelo/a Gestor/a
                        de Projeto/Operação representante do{" "}
                        <b>PRIMEIRO OUTORGANTE</b>.
                      </li>
                      <li>
                        O valor da prestação de serviço será paga até 30 dias,
                        desde que os Organismos Financiadores das intervenções
                        cumpram as obrigações previstas na lei.
                      </li>
                    </ol>
                  </div>
                  <div>
                    <h2>Cláusula 4ª</h2>
                    <ol>
                      <li>
                        O <b>SEGUNDO OUTORGANTE</b> deverá enviar/manter
                        atualizados os dados relativos ao seu ficheiro pessoal,
                        nomeadamente:
                      </li>
                      <ol className="tipoa" type="a">
                        <li>Curriculum Vitae;</li>
                        <li>
                          Informação correta dos dados constantes no Cartão de
                          Cidadão;
                        </li>
                        <li>
                          Informação correta sobre o Número de Identificação
                          Bancária;
                        </li>
                        <li>
                          Cópia dos Certificados comprovativos das Habilitações
                          Literárias;
                        </li>
                        <li>
                          Cópia do Certificado Competências Profissionais (CCP);
                        </li>
                      </ol>
                    </ol>
                  </div>
                  <div>
                    <h2>Cláusula 5ª</h2>
                    <ol>
                      <li>
                        O presente contrato não confere ao SEGUNDO OUTORGANTE a
                        qualidade de trabalhador/a do <b>PRIMEIRO OUTORGANTE</b>
                        .
                      </li>
                      <li>
                        {" "}
                        Em consequência do disposto no nº 1, o{" "}
                        <b>SEGUNDO OUTORGANTE</b> não tem direito a férias,
                        subsídio de férias ou Natal, subsídio de refeição ou
                        quaisquer outros subsídios ou prestações complementares,
                        sendo que é da sua exclusiva responsabilidade a sua
                        situação perante a Administração Fiscal e Segurança
                        Social.
                      </li>
                    </ol>
                  </div>
                  <div>
                    <h2>Cláusula 6ª</h2>
                    <ol>
                      <li>
                        . O <b>PRIMEIRO OUTORGANTE</b> fará a recolha,
                        conservação e tratamento de dados do{" "}
                        <b>SEGUNDO OUTORGANTE</b> por imposição do cumprimento
                        de obrigações legais, para cumprimento do presente
                        contrato e ainda porque o <b>SEGUNDO OUTORGANTE</b> dá o
                        seu consentimento.{" "}
                      </li>

                      <li>
                        {" "}
                        O <b>PRIMEIRO OUTORGANTE</b> será o responsável pelo
                        tratamento dos dados do <b>SEGUNDO OUTORGANTE</b> cujo
                        processamento de dados será interno ficando o{" "}
                        <b>PRIMEIRO OUTORGANTE</b> expressamente autorizada a
                        caso assim o entenda efetuar esse processamento
                        externamente.
                      </li>

                      <li>
                        O <b>PRIMEIRO OUTORGANTE</b> fará o tratamento de dados
                        com a finalidade de gestão económica e contabilística,
                        gestão fiscal, gestão administrativa, gestão de
                        faturação, gestão de clientes, gestão de cobranças e
                        pagamentos, gestão de fornecedores e histórico de
                        relações comerciais.
                      </li>

                      <li>
                        Os dados pessoais incluídos no registo são o nome,
                        morada, contactos telefónicos e endereços eletrónicos,
                        data de nascimento, género, naturalidade, nacionalidade,
                        identificação civil, identificação e enquadramento
                        fiscal e perante a segurança social, autorização de
                        residência, passaporte, currículo profissional,
                        profissão, habilitações académicas, formação
                        profissional e experiência anterior.
                      </li>
                      <li>
                        O <b>PRIMEIRO OUTORGANTE</b> vai comunicar ou transferir
                        em parte ou na sua totalidade os dados pessoais do{" "}
                        <b>SEGUNDO OUTORGANTE</b> a entidades públicas e ou
                        privadas sempre que tal decorra de obrigação legal e ou
                        seja necessário para cumprimento deste ou outros
                        contratos ficando para tal expressamente autorizada pelo{" "}
                        <b>SEGUNDO OUTORGANTE</b>.
                      </li>

                      <li>
                        O <b>PRIMEIRO OUTORGANTE</b> vai conservar os dados do{" "}
                        <b>SEGUNDO OUTORGANTE</b> pelos prazos necessários a dar
                        cumprimento a obrigações legais designadamente de 10
                        (dez) anos para cumprimento à obrigação legal de arquivo
                        de toda a documentação de escrita comercial.
                      </li>
                      <li>
                        O <b>SEGUNDO OUTORGANTE</b> poderá solicitar ao{" "}
                        <b>PRIMEIRO OUTORGANTE</b> e esta, salvo impedimento
                        legal, vai salvaguardar os direitos do{" "}
                        <b>SEGUNDO OUTORGANTE</b> de acesso aos dados pessoais
                        que lhe digam respeito, bem como a sua retificação ou o
                        seu apagamento, e a limitação do tratamento, e o direito
                        de se opor ao tratamento, bem como do direito à
                        portabilidade dos dados. E ainda o direito de retirar
                        consentimento em qualquer altura, sem comprometer a
                        licitude do tratamento efetuado com base no cumprimento
                        de obrigações legais ou com base no consentimento
                        previamente dado. E também o direito de reclamação sobre
                        o tratamento de dados junto da Comissão Nacional de
                        Proteção de Dados.
                      </li>
                      <li>
                        {" "}
                        Tendo em conta as técnicas mais avançadas, os custos de
                        aplicação e a natureza, o âmbito, o contexto e as
                        finalidades do tratamento, bem como os riscos, de
                        probabilidade e gravidade variável, para os direitos e
                        liberdades das pessoas singulares, o{" "}
                        <b>PRIMEIRO OUTORGANTE</b> aplica as medidas técnicas e
                        organizativas adequadas para assegurar um nível de
                        segurança adequado ao risco, incluindo, consoante o que
                        for adequado.
                      </li>

                      <li>
                        Em caso de violação de dados pessoais o{" "}
                        <b>PRIMEIRO OUTORGANTE</b> notifica esse facto à
                        Comissão Nacional de Proteção de Dados nos termos e
                        condições previstos na lei. Se essa violação for
                        suscetível de implicar um elevado risco para os direitos
                        e liberdades do titular comunica-lhe esse facto, nos
                        termos e condições previstos na lei.
                      </li>
                    </ol>
                  </div>
                  <div>
                    <h2>Cláusula 7ª</h2>
                    <ol>
                      <li>
                        O <b>SEGUNDO OUTORGANTE</b>, depois de concluído o
                        serviço, será sujeito a uma avaliação de desempenho com
                        base na Ficha de Avaliação (passível de consulta no
                        seguinte LINK) a qual representa uma qualificação dos
                        serviços prestados e, em si mesmo, uma orientação para a
                        melhoria do desempenho e dos serviços prestados pelo{" "}
                        <b>PRIMEIRO OUTORGANTE</b>.
                      </li>
                    </ol>
                  </div>

                  <div>
                    <h2>Cláusula 8ª</h2>
                    <p>
                      O presente contrato pode cessar, sem necessidade de aviso
                      prévio quando os <b>OUTORGANTES</b> não cumpram as
                      obrigações nele assumidas.
                    </p>
                  </div>

                  <div style={{ breakInside: "avoid" }}>
                    <h2>Cláusula 9ª</h2>
                    <p>
                      O presente contrato entra em vigor com a data das
                      assinaturas e vigorará durante o período estabelecido para
                      a execução da formação agora contratualizados e descritos
                      na Cláusula 1ª.
                    </p>
                    <br />
                    <p>
                      O presente contrato é feito em duplicado fincando cada uma
                      das partes na posse de um exemplar.
                    </p>
                    <table className="localData">
                      <tr>
                        <th>Local:</th>
                        <td>
                          <input
                            className="w-full input-field"
                            defaultValue={
                              valores.local
                                ? valores.local
                                : data.entFormadora[0].localidade
                            }
                            onChange={(e) => {
                              setLocal(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Data:</th>
                        <td>
                          <input
                            className="w-full input-field"
                            defaultValue={
                              valores.date
                                ? valores.date
                                : AlterarFormato(data.dateBegin)
                            }
                            onChange={(e) => {
                              setDate(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                    </table>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        gap: "30px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "50%",
                        }}
                      >
                        <h4 style={{ textAlign: "center" }}>
                          O PRIMEIRO OUTORGANTE
                        </h4>
                        <div
                          style={{
                            minHeight: "200px",
                            borderBottom: "1px solid black !important",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{ margin: "5px auto" }}
                            id="img"
                            className="img"
                            src={
                              data.entFormadora[0] &&
                              data.entFormadora[0].assinatura
                            }
                            alt="assinatura"
                            height="70"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "50%",
                        }}
                      >
                        <h4 style={{ textAlign: "center" }}>
                          O SEGUNDO OUTORGANTE
                        </h4>
                        <div
                          style={{
                            minHeight: "200px",
                            borderBottom: "1px solid black !important",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {data.entFinanciadora &&
                    data.entFinanciadora.length > 0 &&
                    data.entFinanciadora[0].logotipo2 && (
                      <div>
                        <img
                          src={data.entFinanciadora[0].logotipo2}
                          className="h-20"
                          alt="logo"
                          style={{ marginLeft: "20px" }}
                        />
                      </div>
                    )}
                </div>
                <div className="flex justify-center w-full">
                  <button
                    className="mx-auto mt-4 button-geral"
                    onClick={enviarContratoCoordenadorFA}
                  >
                    Enviar Contrato
                  </button>
                </div>
              </ContratoCoordenadorFA>
            )}

            {tipo === "formador" &&
              data.percurso[0].operacao[0].tipologia ===
                "Emprego + Digital" && (
                <ContratoFormadorEDigital
                  data={data}
                  formador={formador}
                  token={token}
                  tipo={tipo}
                  setOpen={setOpen}
                />
              )}
            {tipo === "formador" &&
              data.percurso[0].operacao[0].codigoOperacao
                .toLowerCase()
                .includes("COMPETE2030".toLowerCase()) && (
                <ContratoFormadorCompete2030
                  data={data}
                  formador={formador}
                  token={token}
                  tipo={tipo}
                  setOpen={setOpen}
                />
              )}
            {tipo === "formador" &&
              data.percurso[0].operacao[0].tipologia ===
                "Programa Trabalhos & Competências Verdes / Green Skills & Jobs" && (
                <ContratoFormadorGreenSkillsJobs
                  data={data}
                  formador={formador}
                  token={token}
                  tipo={tipo}
                  setOpen={setOpen}
                />
              )}
            {tipo !== "Cursos de Educação e Formação de Adultos" &&
              tipo !== "Formação Ação" &&
              tipo !== "Formação Empresarial Conjunta e Formação Ação" &&
              tipo !== "formador" &&
              tipo !== "formando" && (
                /* 
                // TODO - convert in a component
                */
                <ContratoCoordenador>
                  <div className="body">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <img
                          src={
                            data.entFormadora[0] &&
                            data.entFormadora[0] &&
                            data.entFormadora[0].logotipo
                          }
                          className="h-20"
                          alt="logo"
                          style={{ marginLeft: "20px" }}
                        />
                      </div>
                      <div>
                        <h1
                          style={{
                            fontSize: "16px",
                            paddingLeft: "15px",
                            marginTop: "-5px",
                          }}
                        >
                          Contrato de prestação de serviços de Coordenação
                          Pedagógica
                        </h1>
                      </div>
                      <div>
                        {data.entFinanciadora.length > 0 &&
                          data.entFinanciadora[0].logotipo1 && (
                            <img
                              src={data.entFinanciadora[0].logotipo1}
                              className="h-20"
                              alt="logo"
                              style={{ marginLeft: "20px" }}
                            />
                          )}
                      </div>
                    </div>
                    <div>
                      <div>
                        <p>
                          Entre:
                          <br />
                          <b>
                            {data.entFormadora[0] && data.entFormadora[0].name}
                          </b>
                          , pessoa coletiva nº{" "}
                          {data.entFormadora[0] &&
                            data.entFormadora[0].nifEntFormadora}{" "}
                          com instalações na{" "}
                          {data.entFormadora[0] && data.entFormadora[0].morada},
                          representado por{" "}
                          {data.entFormadora[0] &&
                            data.entFormadora[0].representanteLegal}
                          , adiante designada por <b>PRIMEIRO Outorgante</b>, e{" "}
                          {data.Coordenador[0] && data.Coordenador[0].fullName},
                          adiante designada por SEGUNDO Outorgante,
                          <br />
                        </p>
                        <table className="dados">
                          <tr>
                            <th>
                              <b>Nome Completo</b>
                            </th>
                            <td>
                              {data.Coordenador[0] &&
                                data.Coordenador[0].fullName}
                            </td>
                          </tr>
                          <tr>
                            <th>NIF</th>
                            <td>
                              {data.Coordenador[0] && data.Coordenador[0].nif}
                            </td>
                          </tr>
                          <tr>
                            <th>Cartão de Cidadão/Passaporte</th>
                            <td>
                              {data.Coordenador[0] &&
                                data.Coordenador[0].ccNumber}
                            </td>
                          </tr>
                          <tr>
                            <th>Morada</th>
                            <td>
                              {data.Coordenador[0] &&
                                data.Coordenador[0].address}
                            </td>
                          </tr>
                          <tr>
                            <th>Código Postal</th>
                            <td>
                              {data.Coordenador[0] &&
                                data.Coordenador[0].postalCode}
                            </td>
                          </tr>
                        </table>
                        <p>
                          , é nesta data livremente Outorgado um contrato de
                          prestação de serviços no âmbito da
                          formação/consultoria, o qual se rege pelas seguintes
                          cláusulas:{" "}
                        </p>
                      </div>
                      <div>
                        <h2>Cláusula 1ª</h2>
                        <p>
                          1. O <b>SEGUNDO OUTORGANTE</b> é contratado como
                          PRESTADOR DE SERVIÇOS para o exercício da função de{" "}
                          <b>COORDENADOR/A PEDAGÓGICO/A</b> para as Unidades de
                          Formação de Curta Duração com as seguintes
                          caraterísticas:
                        </p>
                        <table className="ufcds">
                          {data.ufcds.map((item, index) => (
                            <tr key={index}>
                              <th style={{ width: "134px;" }}>UFCD:</th>
                              <td style={{ width: "420px;" }}>
                                {" "}
                                {item.codeUfcd} - {item.name}
                              </td>
                              <th style={{ width: "110px;" }}>
                                Carga Horária:
                              </th>
                              <td style={{ width: "40px;" }}>
                                {item.cargaHoraria}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <th>Nº do Projeto:</th>
                            <td colSpan="3">
                              {data.operacao[0] &&
                                data.operacao[0].codigoOperacao}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div>
                        <p>
                          2. Quando a formação decorre no formato presencial, o
                          local de realização da formação é:
                        </p>
                        <table className="realizacaocurso">
                          <tr>
                            <th>Local:</th>
                            <td>
                              {data.type !== "Online"
                                ? data.locality
                                : "(Online)"}
                            </td>
                          </tr>
                          <tr>
                            <th>Morada:</th>
                            <td>
                              {data.type !== "Online"
                                ? data.address
                                : "(Online)"}
                            </td>
                          </tr>
                          <tr>
                            <th>Código de Postal:</th>
                            <td>
                              {data.type !== "Online"
                                ? data.postalCode
                                : "(Online)"}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <b>Data de início:</b>
                            </th>
                            <td>{AlterarFormato(data.dateBegin)}</td>
                          </tr>
                          <tr>
                            <th>
                              <b>Data de fim:</b>
                            </th>
                            <td>{AlterarFormato(data.dateEnd)}</td>
                          </tr>
                        </table>
                      </div>
                      <div>
                        <p>
                          3. Quando a formação decorre no formato a distância, a
                          formação é realizada através da seguinte plataforma:
                        </p>
                        <table className="plataforma">
                          <tr>
                            <th>
                              <b>Plataforma LMS:</b>
                            </th>
                            <td>
                              {data.moodle && data.moodle.length > 0 ? (
                                <a href={data.moodle[0].name}>
                                  {data.moodle[0].name}
                                </a>
                              ) : (
                                "NA"
                              )}
                            </td>{" "}
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div>
                      <h2>Cláusula 2ª</h2>
                      <ol>
                        <li>
                          A atividade prevista na prestação de serviços,
                          mencionada na cláusula 1ª, está enquadrada pela
                          Portaria n.o 66/2022, de 1 de fevereiro e contempla as
                          seguintes condições da prestação do serviço:
                        </li>
                        <ol className="tipoa">
                          <li>
                            Promover e acompanhar a execução de ações de
                            formação;
                          </li>
                          <li>
                            Garantir o bom funcionamento pedagógico e
                            organizativo das ações;
                          </li>
                          <li>
                            Preparar a documentação a entregar/enviar a
                            formandos e formadores com o conhecimento do/a
                            Gestor/a de Projeto;
                          </li>
                          <li>
                            Realizar aberturas e encerramentos das ações de
                            formação;
                          </li>
                          <li>
                            Garantir que constam todos os documentos necessários
                            no dossier técnico-pedagógico;
                          </li>
                          <li>
                            Recolher, analisar e garantir que ficam completos os
                            processos de inscrição dos formandos;
                          </li>
                          <li>
                            Organizar de todo o processo de formação
                            (recrutamento de formadores, seleção de formandos,
                            elaboração de cronogramas e horários, acompanhamento
                            do curso, reunião com formadores) em coordenação com
                            o/a Gestor/a de Projeto;
                          </li>
                          <li>
                            Informar formadores sobre os objetivos globais e
                            disponibilizando os meios necessários ao
                            desenvolvimento das ações;
                          </li>
                          <li>
                            Articulação com formandos, formadores e outros
                            agentes envolvidos no processo formativo;
                          </li>
                          <li>
                            Elaborar os mapas de pagamentos a formandos (quando
                            aplicável);
                          </li>
                          <li>
                            Elaborar os mapas de faltas e volumes de formação;
                          </li>
                          <li>
                            Verificar em SIGO, se o formando possui os
                            requisitos para integrar a/as UFCD;
                          </li>
                          <li>
                            Avaliar os formadores, a ação/elaborar o respetivo
                            relatório final;
                          </li>
                          <li>
                            Garantir o aluguer da/a sala/s de formação e
                            preparação de equipamentos/ materiais pedagógicos
                            para a realização das ações de formação.{" "}
                          </li>
                          <li>Entregar o DTP.</li>
                        </ol>

                        <br />
                        <li>
                          {" "}
                          O <b>SEGUNDO OUTORGANTE</b> executará os serviços a
                          que se obriga nos locais que considere mais adequados
                          em termos pedagógicos, sob autorização do{" "}
                          <b>PRIMEIRO OUTORGANTE</b>.
                        </li>
                        <li>
                          Sempre que necessário, em dia e hora a combinar, o{" "}
                          <b>SEGUNDO OUTORGANTE</b> reunirá com o/a Gestor/a de
                          Projeto a fim de dar conhecimento das diligências
                          efetuadas e dos serviços em curso.
                        </li>
                        <li>
                          Ainda segundo a Portaria n.º 66/2022, de 1 de
                          fevereiro, no seu ponto 7 do Artigo 12º, o
                          desenvolvimento de Formação Modular Certificada, pode
                          decorrer no formato à distância, devendo nesses casos
                          a coordenação pedagógica referida no ponto 1 desta
                          Cláusula 2ª ser orientada pelos preceitos
                          metodológicos e administrativos previstos pela 1ª
                          Outorgante e clarificados nos seguintes documentos
                          metodológicos do Sistema de Gestão baseado no norma
                          ISO 9001, que são dados a conhecer antes da celebração
                          do presente contrato:
                        </li>
                        <ol className="tipoa" type="a">
                          <li>
                            <b>Procedimento 33:</b> Conceber e executar formação
                            à distância;
                          </li>
                          <li>METODOLOGIA do Modelo Pedagógico COM-TEC;</li>
                          <li>
                            <b>Procedimento 35:</b> Realizar a coordenação
                            pedagógica de Ações de Formação;
                          </li>
                        </ol>
                        <li>
                          No acompanhamento destas tarefas o{" "}
                          <b>SEGUNDO OUTORGANTE</b> será sujeita a uma avaliação
                          de desempenho com base na Ficha de Avaliação (passível
                          de consulta no seguinte LINK) a qual representa uma
                          qualificação dos serviços prestados e, em si mesmo,
                          uma orientação para a melhoria e contributo da{" "}
                          <b>SEGUNDO OUTORGANTE</b>
                          para o projeto referido na Cláusula 1ª deste contrato.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <h2>Cláusula 3ª</h2>
                      <ol>
                        <li>
                          O preço base da prestação de serviços corresponde ao
                          seguinte:
                        </li>
                        <table className="valor">
                          <tr>
                            <th>Valor unitário (€)</th>
                            <td>
                              <input
                                className="w-full input-field"
                                defaultValue={valores.valorBase}
                                onChange={(e) => {
                                  valores.valorBase = e.target.value;
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>Valor unitário (descritivo)</th>
                            <td>
                              <input
                                className="w-full input-field"
                                defaultValue={valores.valorDescritivo}
                                onChange={(e) => {
                                  valores.valorDescritivo = e.target.value;
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>NOTA:</th>
                            <td>
                              Valores acrescidos de IVA à taxa legal em vigor,
                              se de direito.
                            </td>
                          </tr>
                        </table>
                        <li>
                          O <b>SEGUNDO OUTORGANTE</b> deverá apresentar
                          faturas-recibo nos valores respeitantes aos pagamentos
                          a efetuar mensalmente, com o descritivo correspondente
                          ao objeto previsto neste contrato.
                        </li>
                        <li>
                          O <b>PRIMEIRO OUTORGANTE</b> efetuará o pagamento das
                          respetivas importâncias após entrega/emissão de toda a
                          documentação em conformidade e exigida pelo/a Gestor/a
                          de Projeto.
                        </li>
                        <li>
                          O valor da prestação de serviço será paga até 60 dias,
                          desde que o POISE cumpra as obrigações previstas na
                          lei, nomeadamente a disponibilização de formulários
                          para submissão de reembolsos e a aplicação do Artº 25,
                          alínea b) do Decreto Lei 159/2014.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <h2>Cláusula 4ª</h2>
                      <ol>
                        <li>
                          O <b>SEGUNDO OUTORGANTE</b> deverá enviar/manter
                          atualizados os dados relativos ao seu ficheiro
                          pessoal, nomeadamente:
                        </li>
                        <ol className="tipoa" type="a">
                          <li>Curriculum Vitae;</li>
                          <li>
                            Informação correta dos dados constantes no Cartão de
                            Cidadão;
                          </li>
                          <li>
                            Informação correta sobre o Número de Identificação
                            Bancária;
                          </li>
                          <li>
                            Cópia dos Certificados comprovativos das
                            Habilitações Literárias;
                          </li>
                          <li>
                            Cópia do Certificado Competências Profissionais
                            (CCP);
                          </li>
                        </ol>
                      </ol>
                    </div>
                    <div>
                      <h2>Cláusula 5ª</h2>
                      <ol>
                        <li>
                          O presente contrato não confere ao{" "}
                          <b>SEGUNDO OUTORGANTE</b> a qualidade de trabalhador/a
                          do
                          <b>PRIMEIRO OUTORGANTE</b>.
                        </li>
                        <ol className="tipoa" type="a">
                          <li>Curriculum Vitae;</li>
                          <li>
                            Informação correta dos dados constantes no Cartão de
                            Cidadão;
                          </li>
                          <li>
                            Informação correta sobre o Número de Identificação
                            Bancária;
                          </li>
                          <li>
                            Cópia dos Certificados comprovativos das
                            Habilitações Literárias;
                          </li>
                          <li>
                            Cópia do Certificado Competências Profissionais
                            (CCP);
                          </li>
                        </ol>
                        <li>
                          {" "}
                          Em consequência do disposto no nº 1, o{" "}
                          <b>SEGUNDO OUTORGANTE</b> não tem direito a férias,
                          subsídio de férias ou Natal, subsídio de refeição ou
                          quaisquer outros subsídios ou prestações
                          complementares, sendo que é da sua exclusiva
                          responsabilidade a sua situação perante a
                          Administração Fiscal e Segurança Social.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <h2>Cláusula 6ª</h2>
                      <ol>
                        <li>
                          O <b>PRIMEIRO OUTORGANTE</b> fará a recolha,
                          conservação e tratamento de dados do{" "}
                          <b>SEGUNDO OUTORGANTE</b> por imposição do cumprimento
                          de obrigações legais, para cumprimento do presente
                          contrato e ainda porque o <b>SEGUNDO OUTORGANTE</b> dá
                          o seu consentimento.
                        </li>

                        <li>
                          {" "}
                          O <b>PRIMEIRO OUTORGANTE</b> fará a recolha,
                          conservação e tratamento de dados do{" "}
                          <b>SEGUNDO OUTORGANTE</b> por imposição do cumprimento
                          de obrigações legais, para cumprimento do presente
                          contrato e ainda porque o<b>SEGUNDO OUTORGANTE</b> dá
                          o seu consentimento.
                        </li>
                        <li>
                          O <b>PRIMEIRO OUTORGANTE</b> será o responsável pelo
                          tratamento dos dados do SEGUNDO OUTORGANTE cujo
                          processamento de dados será interno ficando o{" "}
                          <b>PRIMEIRO OUTORGANTE</b> expressamente autorizada a
                          caso assim o entenda efetuar esse processamento
                          externamente.
                        </li>
                        <li>
                          <b>O PRIMEIRO OUTORGANTE</b> fará o tratamento de
                          dados com a finalidade de gestão económica e
                          contabilística, gestão fiscal, gestão administrativa,
                          gestão de faturação, gestão de clientes, gestão de
                          cobranças e pagamentos, gestão de fornecedores e
                          histórico de relações comerciais.
                        </li>
                        <li>
                          Os dados pessoais incluídos no registo são o nome,
                          morada, contactos telefónicos e endereços eletrónicos,
                          data de nascimento, género, naturalidade,
                          nacionalidade, identificação civil, identificação e
                          enquadramento fiscal e perante a segurança social,
                          autorização de residência, passaporte, currículo
                          profissional, profissão, habilitações académicas,
                          formação profissional e experiência anterior.
                        </li>
                        <li>
                          O <b>PRIMEIRO OUTORGANTE</b> vai comunicar ou
                          transferir em parte ou na sua totalidade os dados
                          pessoais do
                          <b>SEGUNDO OUTORGANTE</b> a entidades públicas e ou
                          privadas sempre que tal decorra de obrigação legal e
                          ou seja necessário para cumprimento deste ou outros
                          contratos ficando para tal expressamente autorizada
                          pelo <b>SEGUNDO OUTORGANTE.</b>
                        </li>
                        <li>
                          {" "}
                          O <b>PRIMEIRO OUTORGANTE</b> vai conservar os dados do{" "}
                          <b>SEGUNDO OUTORGANTE</b> pelos prazos necessários a
                          dar cumprimento a obrigações legais designadamente de
                          10 (dez) anos para cumprimento à obrigação legal de
                          arquivo de toda a documentação de escrita comercial.
                        </li>
                        <li>
                          O <b>SEGUNDO OUTORGANTE</b> poderá solicitar ao{" "}
                          <b>PRIMEIRO OUTORGANTE</b> e esta salvo impedimento
                          legal vai salvaguardar os direitos do{" "}
                          <b>SEGUNDO OUTORGANTE</b> de acesso aos dados pessoais
                          que lhe digam respeito, bem como a sua retificação ou
                          o seu apagamento, e a limitação do tratamento, e o
                          direito de se opor ao tratamento, bem como do direito
                          à portabilidade dos dados. E ainda o direito de
                          retirar consentimento em qualquer altura, sem
                          comprometer a licitude do tratamento efetuado com base
                          no cumprimento de obrigações legais ou com base no
                          consentimento previamente dado. E também o direito de
                          reclamação sobre o tratamento de dados junto da
                          Comissão Nacional de Proteção de Dados.
                        </li>
                        <li>
                          {" "}
                          Tendo em conta as técnicas mais avançadas, os custos
                          de aplicação e a natureza, o âmbito, o contexto e as
                          finalidades do tratamento, bem como os riscos, de
                          probabilidade e gravidade variável, para os direitos e
                          liberdades das pessoas singulares, o{" "}
                          <b>PRIMEIRO OUTORGANTE</b> aplica as medidas técnicas
                          e organizativas adequadas para assegurar um nível de
                          segurança adequado ao risco, incluindo, consoante o
                          que for adequado.
                        </li>
                        <li>
                          Em caso de violação de dados pessoais o{" "}
                          <b>PRIMEIRO OUTORGANTE</b> notifica esse facto à
                          Comissão Nacional de Proteção de Dados nos termos e
                          condições previstos na lei. Se essa violação for
                          suscetível de implicar um elevado risco para os
                          direitos e liberdades do titular comunica-lhe esse
                          facto, nos termos e condições previstos na lei.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <h2>Cláusula 7ª</h2>
                      <ol>
                        <li>
                          O <b>SEGUNDO OUTORGANTE</b>, depois de concluído o
                          serviço, será sujeito a uma avaliação de desempenho
                          com base na Ficha de Avaliação (passível de consulta
                          no seguinte LINK) a qual representa uma qualificação
                          dos serviços prestados e, em si mesmo, uma orientação
                          para a melhoria do desempenho e dos serviços prestados
                          pelo <b>PRIMEIRO OUTORGANTE</b>.
                        </li>
                      </ol>
                    </div>

                    <div>
                      <h2>Cláusula 8ª</h2>
                      <p>
                        O presente contrato pode cessar, sem necessidade de
                        aviso prévio quando os <b>OUTORGANTES</b> não cumpram as
                        obrigações nele assumidas.
                      </p>
                    </div>

                    <div style={{ breakInside: "avoid" }}>
                      <h2>Cláusula 9ª</h2>
                      <p>
                        O presente contrato entra em vigor com a data das
                        assinaturas e vigorará durante o período estabelecido
                        para a execução da formação agora contratualizados e
                        descritos na Cláusula 1ª.
                      </p>
                      <br />
                      <p>
                        O presente contrato é feito em duplicado fincando cada
                        uma das partes na posse de um exemplar.
                      </p>
                      <table className="localData">
                        <tr>
                          <th>Local:</th>
                          <td>
                            <input
                              className="w-full input-field"
                              defaultValue={
                                valores.local
                                  ? valores.local
                                  : data.entFormadora[0].localidade
                              }
                              onChange={(e) => {
                                setLocal(e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Data:</th>
                          <td>
                            <input
                              className="w-full input-field"
                              defaultValue={
                                valores.date
                                  ? valores.date
                                  : AlterarFormato(data.dateBegin)
                              }
                              onChange={(e) => {
                                setDate(e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                      </table>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          gap: "30px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "50%",
                          }}
                        >
                          <h4 style={{ textAlign: "center" }}>
                            O PRIMEIRO OUTORGANTE
                          </h4>
                          <div
                            style={{
                              minHeight: "200px",
                              borderBottom: "1px solid black !important",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              alt="assinatura"
                              style={{ margin: "5px auto" }}
                              id="img"
                              className="img"
                              src={
                                data.entFormadora[0] &&
                                data.entFormadora[0].assinatura
                              }
                              height="70"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "50%",
                          }}
                        >
                          <h4 style={{ textAlign: "center" }}>
                            O SEGUNDO OUTORGANTE
                          </h4>
                          <div
                            style={{
                              minHeight: "200px",
                              borderBottom: "1px solid black !important",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    {data.entFinanciadora &&
                      data.entFinanciadora.length > 0 &&
                      data.entFinanciadora[0].logotipo2 && (
                        <div>
                          <img
                            src={data.entFinanciadora[0].logotipo2}
                            className="h-20"
                            alt="logo"
                            style={{ marginLeft: "20px" }}
                          />
                        </div>
                      )}
                  </div>
                  <div className="flex justify-center w-full">
                    <button
                      className="mx-auto mt-4 button-geral"
                      onClick={enviarContratoCoordenador}
                    >
                      Enviar Contrato
                    </button>
                  </div>
                </ContratoCoordenador>
              )}

            {tipo === "formador" &&
              data.percurso[0].operacao[0].tipologia !==
                "Programa Trabalhos & Competências Verdes / Green Skills & Jobs" &&
              !data.percurso[0].operacao[0].codigoOperacao
                .toLowerCase()
                .includes("COMPETE2030".toLowerCase()) &&
              data.percurso[0].operacao[0].tipologia !==
                "Emprego + Digital" && (
                /*
                  // TODO - Convert in a component
                   *** Old Version ***
                   */
                <ContratoFormador>
                  <div className="body">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <img
                          src={data.percurso[0].entFormadora[0].logotipo}
                          className="h-20"
                          alt="logo"
                          style={{ marginLeft: "20px" }}
                        />
                      </div>
                      <div>
                        <h1
                          style={{
                            fontSize: "16px",
                            paddingLeft: "15px",
                            marginTop: "-5px",
                          }}
                        >
                          Contrato de Prestação de Serviços de Formação
                        </h1>
                      </div>
                      <div>
                        {data.percurso[0].entFinanciadora &&
                          data.percurso[0].entFinanciadora.length > 0 &&
                          data.percurso[0].entFinanciadora[0].logotipo1 && (
                            <img
                              src={
                                data.percurso[0].entFinanciadora[0].logotipo1
                              }
                              className="h-20"
                              alt="logo"
                              style={{ marginRight: "20px" }}
                            />
                          )}
                      </div>
                    </div>
                    <div className="page1"></div>
                    <div>
                      <div>
                        <p>
                          Entre,
                          <b>{data.percurso[0].entFormadora[0].name}</b>, com
                          sede social em{" "}
                          {data.percurso[0].entFormadora[0].morada},{" "}
                          {data.percurso[0].entFormadora[0].postalCode}{" "}
                          {data.percurso[0].entFormadora[0].locality} com o
                          Contribuinte nº {data.percurso[0].entFormadora[0].nif}
                          , nesse ato representado pelo/a Gerente{" "}
                          {data.percurso[0].entFormadora[0].representanteLegal},
                          adiante designada por <b>PRIMEIRO OUTORGANTE</b>, e
                        </p>
                        <table className="dados">
                          <tbody>
                            <tr>
                              <th>Nome Completo</th>
                              <td>
                                {formador
                                  ? formador.fullName
                                  : data.formadores[0].fullName}
                              </td>
                            </tr>
                            <tr>
                              <th>NIF</th>
                              <td>
                                {formador
                                  ? formador.nif
                                  : data.formadores[0].nif}
                              </td>
                            </tr>
                            <tr>
                              <th>Cartão de Cidadão/Passaporte</th>
                              <td>
                                {formador
                                  ? formador.ccNumber
                                  : data.formadores[0].ccNumber}
                              </td>
                            </tr>
                            <tr>
                              <th>Morada</th>
                              <td>
                                {formador
                                  ? formador.address
                                  : data.formadores[0].address}
                              </td>
                            </tr>
                            <tr>
                              <th>Código Postal</th>
                              <td>
                                {formador
                                  ? formador.postalCode
                                  : data.formadores[0].postalCode}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p>
                          , adiante designada por <b>SEGUNDO OUTORGANTE</b>, é
                          celebrado o presente contrato de prestação de
                          serviços, o qual se rege pelo disposto nas seguintes
                          cláusulas:
                        </p>
                      </div>
                      <div>
                        <h2>Cláusula 1ª</h2>
                        <p>
                          1. O <b>PRIMEIRO OUTORGANTE</b> contrata o{" "}
                          <b>SEGUNDO OUTORGANTE</b> para a prestação de serviços
                          o exercício da função de <b>FORMADOR/A</b> para o
                          Curso {data.percurso[0].name} enquadrado pelas
                          seguintes especificações:
                        </p>
                        <table className="ufcds">
                          <tbody>
                            <tr>
                              <th style={{ width: "134px" }}>UFCD:</th>
                              <td style={{ width: "420px" }}>
                                {data.codeUfcd} - {data.name}
                              </td>
                              <th style={{ width: "110px" }}>Carga Horária:</th>
                              <td style={{ width: "40px" }}>
                                {data.cargaHoraria}
                              </td>
                            </tr>
                            <tr>
                              <th>Nº do Projeto:</th>
                              <td colSpan="3">
                                {data.percurso[0].operacao[0] &&
                                  data.percurso[0].operacao[0].codigoOperacao}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <p>
                          2. Quando a formação decorre no formato presencial, o
                          local de realização da formação é:
                        </p>
                        <table className="realizacaocurso">
                          <tbody>
                            <tr>
                              <th>Local:</th>
                              <td>
                                {data.percurso[0].type !== "Online"
                                  ? data.percurso[0].locality
                                  : "(Online)"}
                              </td>
                            </tr>
                            <tr>
                              <th>Morada:</th>
                              <td>
                                {data.percurso[0].type !== "Online"
                                  ? data.percurso[0].address
                                  : "(Online)"}
                              </td>
                            </tr>
                            <tr>
                              <th>Código de Postal:</th>
                              <td>
                                {data.percurso[0].type !== "Online"
                                  ? data.percurso[0].postalCode
                                  : "(Online)"}
                              </td>
                            </tr>
                            <tr>
                              <th>Data de início:</th>
                              <td>{AlterarFormato(data.dateBegin)}</td>
                            </tr>
                            <tr>
                              <th>Data de fim:</th>
                              <td>{AlterarFormato(data.dateEnd)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <p>
                          3. Quando a formação decorre no formato a distância, a
                          formação é realizada através da seguinte plataforma:
                        </p>
                        <table className="plataforma">
                          <tbody>
                            <tr>
                              <th>Plataforma LMS:</th>
                              <td>
                                {data.percurso[0].type === "Online" ? (
                                  <a href={data.percurso[0].moodle[0].name}>
                                    {data.percurso[0].moodle[0].name}
                                  </a>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div>
                      <h2>Cláusula 2ª</h2>
                      <ol>
                        <li>
                          A atividade prevista na prestação de serviços,
                          mencionada na cláusula 1ª, está enquadrada pela
                          Portaria nº 66/2022, de 1 de fevereiro e contempla as
                          seguintes condições da prestação do serviço:
                        </li>
                        <ol className="tipoa">
                          <li>
                            Respeitar a regulamentação da formação em vigor.
                          </li>
                          <li>
                            Garantir a estrita confidencialidade no tratamento
                            dos dados aos quais terá acesso, garantindo que a
                            informação não será partilhada com terceiros e será
                            utilizada apenas para os fins diretamente
                            relacionados com a formação, sendo tratados de forma
                            lícita de acordo com o Regulamento Geral de Proteção
                            de Dados e legislação aplicável.
                          </li>
                          <li>
                            Matéria a lecionar de acordo com os conteúdos
                            programáticos estabelecidos contratualmente entre o{" "}
                            <b>PRIMEIRO OUTORGANTE</b> e o{" "}
                            <b>SEGUNDO OUTORGANTE</b>.
                          </li>
                          <li>
                            Adotar as estratégias pedagógicas estabelecidas
                            contratualmente com o <b>PRIMEIRO OUTORGANTE</b>.
                          </li>
                          <li>
                            Total e correto preenchimento do Dossier
                            Técnico-Pedagógico.
                          </li>
                          <li>
                            Entregar a documentação aplicável à ação de formação
                            (manual, planos de sessão, evidencias de avaliação,
                            corrigendas, grelhas e pautas de avaliação) de
                            acordo com as datas estipuladas pelo/a Gestor/a de
                            Projeto e/ou Coordenador/a Pedagógico/a.
                          </li>
                          <li>
                            Contactar em primeira instância o{" "}
                            <b>PRIMEIRO OUTORGANTE</b> sempre que surja algum
                            imprevisto em relação ao que estava acordado,
                            preenchendo simultaneamente a FICHA DE OCORRÊNCIAS,
                            constante do Dossier Técnico-Pedagógico.
                          </li>
                          <li>
                            Entregar/enviar para as instalações do{" "}
                            <b>PRIMEIRO OUTORGANTE</b> todo o material
                            pedagógico que seja adicionalmente entregue aos
                            formandos/as.
                          </li>
                          <li>
                            Participar nas reuniões: (1) de preparação do
                            desenvolvimento/execução e (2) de acompanhamento do
                            desenvolvimento/execução.
                          </li>
                        </ol>
                        <br />
                        <li>
                          Ainda segundo a Portaria n.o 66/2022, de 1 de
                          fevereiro, no seu ponto 7 do Artigo 12o , o
                          desenvolvimento de Formação Modular Certificada, pode
                          decorrer no formato à distância, devendo nesses casos
                          a execução da formação referida no ponto 1 desta
                          Cláusula 2ª ser orientada pelos preceitos
                          metodológicos e administrativos previstos pelo{" "}
                          <b>PRIMEIRO OUTORGANTE</b> e clarificados nos
                          seguintes documentos metodológicos do Sistema de
                          Gestão baseado no norma ISO 9001, que são dados a
                          conhecer antes da celebração do presente contrato:
                        </li>
                        <ol className="tipoa">
                          <li>
                            Procedimento 33: Conceber e executar formação à
                            distância;
                          </li>
                          <li>METODOLOGIA do Modelo Pedagógico COM-TEC;</li>
                        </ol>
                        <li>
                          No acompanhamento destas tarefas o{" "}
                          <b>SEGUNDO OUTORGANTE</b> será sujeita a uma avaliação
                          de desempenho com base na Ficha de Avaliação (passível
                          de consulta no seguinte LINK) a qual representa uma
                          qualificação dos serviços prestados e, em si mesmo,
                          uma orientação para a melhoria e contributo do
                          <b>SEGUNDO OUTORGANTE</b> para o projeto referido na
                          Cláusula 1ª deste contrato.
                        </li>
                        <li>
                          O <b>SEGUNDO OUTORGANTE</b> compromete-se a prestar
                          apoio pedagógico ao <b>PRIMEIRO OUTORGANTE</b>. Este
                          apoio consiste no fornecimento, discussão e
                          esclarecimento de todos os elementos pedagógicos
                          acordados com o <b>SEGUNDO OUTORGANTE</b>,
                          nomeadamente: Objetivos pedagógicos; Conteúdos
                          programáticos; Referências bibliográficas;
                          Destinatários; Estratégia pedagógica, Datas, horários
                          e local de realização da intervenção, e Suportes
                          pedagógicos.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <h2>Cláusula 3ª</h2>
                      <ol>
                        <li>
                          O preço base da prestação de serviços corresponde ao
                          seguinte:
                        </li>
                        <table className="valor">
                          <tbody>
                            <tr>
                              <th>Valor hora (€)</th>
                              <td>
                                <input
                                  className="w-full input-field"
                                  defaultValue={
                                    valores.valorBase
                                      ? valores.valorBase
                                      : formador &&
                                        data.dadosContrato[index] &&
                                        data.dadosContrato[index].dadosContrato
                                          .valorBase
                                  }
                                  onChange={(e) => {
                                    setValorBase(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Valor hora (descritivo)</th>
                              <td>
                                <input
                                  className="w-full input-field"
                                  defaultValue={
                                    valores.valorDescritivo
                                      ? valores.valorDescritivo
                                      : formador &&
                                        data.dadosContrato[index] &&
                                        data.dadosContrato[index].dadosContrato
                                          .valorDescritivo
                                  }
                                  onChange={(e) => {
                                    setValorDescritivo(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>NOTA:</th>
                              <td>
                                Valores acrescidos de IVA à taxa legal em vigor,
                                se de direito.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <li>
                          Caso a formação seja realizada no formato a distância,
                          a remuneração da totalidade da “carga horária”
                          mencionada na Cláusula 1ª, ponto 1, só será efetivada
                          se o somatório das horas das sessões síncronas com o
                          somatório das “horas de dedicação” efetivamente
                          registadas na plataforma LMS (considerando o “tempo
                          limite entre cliques de 60 minutos”), corresponder a
                          totalidade da carga horária da UFCD.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <h2>Cláusula 4ª</h2>
                      <ol>
                        <li>
                          O <b>SEGUNDO OUTORGANTE</b> deverá apresentar
                          faturas-recibo nos valores respeitantes aos pagamentos
                          a efetuar, com o{" "}
                          <b>
                            descritivo: “Ministrar {data.cargaHoraria} horas de
                            formação na UFCD {data.codeUfcd} - {data.name} no
                            Curso {data.percurso[0].name}
                            {data.percurso[0].entFinanciador &&
                              data.percurso[0].entFinanciador.length > 0 && (
                                <>
                                  {" "}
                                  ao abrigo do Projeto Nº{" "}
                                  {data.percurso[0].operacao[0].codigoOperacao}
                                </>
                              )}
                            ”
                          </b>{" "}
                          referidos no Ponto 1 da Cláusula 1ª, após a sua
                          realização parcial ou total, o qual satisfará as leis
                          fiscais aplicáveis aos rendimentos de trabalho
                          independente.
                        </li>
                        <li>
                          O <b>SEGUNDO OUTORGANTE</b> deverá emitir a
                          fatura-recibo após a entrega de toda a documentação em
                          conformidade e exigida pela Coordenação Pedagógica.{" "}
                        </li>
                        <li>
                          O <b>PRIMEIRO OUTORGANTE</b> efetuará o pagamento das
                          respetivas importâncias até 60 dias após a emissão do
                          recibo, desde que o organismo financiador cumpra as
                          obrigações previstas na lei.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <h2>Cláusula 5ª</h2>
                      <ol>
                        <li style={{ listStyleType: "none" }}>
                          O <b>SEGUNDO OUTORGANTE</b> deverá enviar/manter
                          atualizados os dados relativos ao seu ficheiro
                          pessoal, nomeadamente:
                        </li>
                        <ol className="tipoa" type="a">
                          <li>Curriculum Vitae;</li>
                          <li>
                            Informação correta dos dados constantes no Cartão de
                            Cidadão;
                          </li>
                          <li>
                            Informação correta sobre o Número de Identificação
                            Bancária;
                          </li>
                          <li>
                            Cópia dos Certificados comprovativos das
                            Habilitações Literárias;
                          </li>
                          <li>
                            Cópia do Certificado Competências Profissionais
                            (CCP);
                          </li>
                        </ol>
                      </ol>
                    </div>
                    <div>
                      <h2>Cláusula 6ª</h2>
                      <ol>
                        <li>
                          O <b>PRIMEIRO OUTORGANTE</b> fará a recolha,
                          conservação e tratamento de dados do{" "}
                          <b>SEGUNDO OUTORGANTE</b> por imposição do cumprimento
                          de obrigações legais, para cumprimento do presente
                          contrato e ainda porque o <b>SEGUNDO OUTORGANTE</b> dá
                          o seu consentimento.
                        </li>
                        <li>
                          O <b>PRIMEIRO OUTORGANTE</b> será o responsável pelo
                          tratamento dos dados do <b>SEGUNDO OUTORGANTE</b>
                          cujo processamento de dados será interno ficando o{" "}
                          <b>PRIMEIRO OUTORGANTE</b> expressamente autorizada a
                          caso assim o entenda efetuar esse processamento
                          externamente.
                        </li>
                        <li>
                          O <b>PRIMEIRO OUTORGANTE</b> fará o tratamento de
                          dados com a finalidade de gestão económica e
                          contabilística, gestão fiscal, gestão administrativa,
                          gestão de faturação, gestão de clientes, gestão de
                          cobranças e pagamentos, gestão de fornecedores e
                          histórico de relações comerciais.
                        </li>
                        <li>
                          Os dados pessoais incluídos no registo são o nome,
                          morada, contactos telefónicos e endereços eletrónicos,
                          data de nascimento, género, naturalidade,
                          nacionalidade, identificação civil, identificação e
                          enquadramento fiscal e perante a segurança social,
                          autorização de residência, passaporte, currículo
                          profissional, profissão, habilitações académicas,
                          formação profissional e experiência anterior.
                        </li>
                        <li>
                          O <b>PRIMEIRO OUTORGANTE</b> vai comunicar ou
                          transferir em parte ou na sua totalidade os dados
                          pessoais do
                          <b>SEGUNDO OUTORGANTE</b> a entidades públicas e ou
                          privadas sempre que tal decorra de obrigação legal e
                          ou seja necessário para cumprimento deste ou outros
                          contratos ficando para tal expressamente autorizada
                          pelo
                          <b>SEGUNDO OUTORGANTE</b>.
                        </li>
                        <li>
                          O <b>PRIMEIRO OUTORGANTE</b> vai conservar os dados do{" "}
                          <b>SEGUNDO OUTORGANTE</b> pelos prazos necessários a
                          dar cumprimento a obrigações legais designadamente de
                          10 (dez) anos para cumprimento à obrigação legal de
                          arquivo de toda a documentação de escrita comercial.
                        </li>
                        <li>
                          O <b>SEGUNDO OUTORGANTE</b> poderá solicitar ao{" "}
                          <b>PRIMEIRO OUTORGANTE</b> e esta salvo impedimento
                          legal vai salvaguardar os direitos do{" "}
                          <b>SEGUNDO OUTORGANTE</b> de acesso aos dados pessoais
                          que lhe digam respeito, bem como a sua retificação ou
                          o seu apagamento, e a limitação do tratamento, e o
                          direito de se opor ao tratamento, bem como do direito
                          à portabilidade dos dados. E ainda o direito de
                          retirar consentimento em qualquer altura, sem
                          comprometer a licitude do tratamento efetuado com base
                          no cumprimento de obrigações legais ou com base no
                          consentimento previamente dado. E também o direito de
                          reclamação sobre o tratamento de dados junto da
                          Comissão Nacional de Proteção de Dados.
                        </li>
                        <li>
                          Tendo em conta as técnicas mais avançadas, os custos
                          de aplicação e a natureza, o âmbito, o contexto e as
                          finalidades do tratamento, bem como os riscos, de
                          probabilidade e gravidade variável, para os direitos e
                          liberdades das pessoas singulares, o{" "}
                          <b>PRIMEIRO OUTORGANTE</b> aplica as medidas técnicas
                          e organizativas adequadas para assegurar um nível de
                          segurança adequado ao risco, incluindo, consoante o
                          que for adequado.
                        </li>
                        <li>
                          Em caso de violação de dados pessoais o{" "}
                          <b>PRIMEIRO OUTORGANTE</b> notifica esse facto à
                          Comissão Nacional de Proteção de Dados nos termos e
                          condições previstos na lei. Se essa violação for
                          suscetível de implicar um elevado risco para os
                          direitos e liberdades do titular comunica-lhe esse
                          facto, nos termos e condições previstos na lei.
                        </li>
                        <li>
                          Especificamente nas componentes de formação a
                          distância síncrona, que se realizam no formato de
                          videoconferência, o <b>SEGUNDO OUTORGANTE</b> desde já
                          aceita disponibilizar a gravação dessas sessões na
                          plataforma LMS, para partilha e revisionamento
                          posterior, por parte dos formandos ou terceiros,
                          apenas para fins de aprendizagem ou auditoria.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <h2>Cláusula 7ª</h2>
                      <ol>
                        <li>
                          O presente contrato não confere ao{" "}
                          <b>SEGUNDO OUTORGANTE</b> a qualidade de trabalhador/a
                          do <b>PRIMEIRO OUTORGANTE</b>.
                        </li>
                        <li>
                          Em consequência do disposto no no 1, o{" "}
                          <b>SEGUNDO OUTORGANTE</b> não tem direito a férias,
                          subsídio de férias ou Natal, subsídio de refeição ou
                          quaisquer outros subsídios ou prestações
                          complementares, sendo que é da sua exclusiva
                          responsabilidade a sua situação perante a
                          Administração Fiscal e Segurança Social.
                        </li>
                      </ol>
                    </div>

                    <div>
                      <h2>Cláusula 8ª</h2>
                      <p>
                        O presente contrato pode cessar, sem necessidade de
                        aviso prévio quando os <b>OUTORGANTES</b> não cumpram as
                        obrigações nele assumidas.
                      </p>
                    </div>

                    <div style={{ breakInside: "avoid" }}>
                      <h2>Cláusula 9ª</h2>
                      <p>
                        O presente contrato entra em vigor com a data das
                        assinaturas e vigorará durante o período estabelecido
                        para a execução da formação agora contratualizados e
                        descritos na Cláusula 1ª.
                      </p>
                      <br />
                      <p>
                        O presente contrato é feito em duplicado fincando cada
                        uma das partes na posse de um exemplar.
                      </p>
                      <table className="localData">
                        <tbody>
                          <tr>
                            <th>Local:</th>
                            <td>
                              <input
                                className="w-full input-field"
                                defaultValue={
                                  valores.local
                                    ? valores.local
                                    : data.percurso[0].entFormadora[0]
                                        .localidade
                                }
                                onChange={(e) => {
                                  setLocal(e.target.value);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>Data:</th>
                            <td>
                              <input
                                className="w-full input-field"
                                defaultValue={
                                  valores.date
                                    ? valores.date
                                    : AlterarFormato(data.dateBegin)
                                }
                                onChange={(e) => {
                                  setDate(e.target.value);
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          gap: "30px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "50%",
                          }}
                        >
                          <h4 style={{ textAlign: "center" }}>
                            O PRIMEIRO OUTORGANTE
                          </h4>
                          <div
                            style={{
                              minHeight: "200px",
                              borderBottom: "1px solid black !important",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              alt="assinatura"
                              style={{ margin: "5px auto" }}
                              id="img"
                              className="img"
                              src={data.percurso[0].entFormadora[0].assinatura}
                              height="70"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "50%",
                          }}
                        >
                          <h4 style={{ textAlign: "center" }}>
                            O SEGUNDO OUTORGANTE
                          </h4>
                          <div
                            style={{
                              minHeight: "200px",
                              borderBottom: "1px solid black !important",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    {data.percurso[0].entFinanciadora &&
                      data.percurso[0].entFinanciadora.length > 0 &&
                      data.percurso[0].entFinanciadora[0].logotipo2 && (
                        <div>
                          <img
                            src={data.percurso[0].entFinanciadora[0].logotipo2}
                            className="h-20"
                            alt="logo"
                            style={{ marginLeft: "20px" }}
                          />
                        </div>
                      )}
                  </div>
                  <div className="flex justify-center w-full">
                    <button
                      className="mx-auto mt-4 button-geral"
                      onClick={() => enviarContratoFormadorAntigo()}
                    >
                      Enviar Contrato
                    </button>
                    <button
                      className="mx-auto mt-4 button-geral"
                      onClick={() => enviarContratoFormadorNoEmailAntigo()}
                    >
                      Download Contrato
                    </button>
                  </div>
                </ContratoFormador>
              )}
            {tipo === "formando" && (
              <>
                <div className="flex flex-row justify-center">
                  <button
                    onClick={() => setSelectTipo(1)}
                    className="tooltip"
                    data-tip={"Contrato"}
                  >
                    <FaFileContract
                      className={
                        selectTipo === 1
                          ? "p-2 text-third-color tooltip"
                          : "p-2 text-main-color hover:text-text-second-color "
                      }
                      size={60}
                    />
                  </button>
                  <button
                    onClick={() => setSelectTipo(2)}
                    className="tooltip"
                    data-tip={"Ficha de Inscrição"}
                  >
                    <FaListAlt
                      className={
                        selectTipo === 2
                          ? "p-2 text-third-color tooltip"
                          : "p-2 text-main-color hover:text-text-second-color "
                      }
                      size={60}
                    />
                  </button>
                </div>

                {/*   
                //
                // acrescentar verificação de tipologia de contrato
                // */}
                {selectTipo === 1 &&
                  data.percurso[0].operacao[0].tipologia ===
                    "Programa Trabalhos & Competências Verdes / Green Skills & Jobs" && (
                    <ContratoFormandoGreenSkillsJobs
                      data={data}
                      token={token}
                      setOpen={setOpen}
                    />
                  )}
                {selectTipo === 1 &&
                  data.percurso[0].operacao[0].tipologia ===
                    "Emprego + Digital" && (
                    <ContratoFormandoEDigital
                      data={data}
                      token={token}
                      setOpen={setOpen}
                    />
                  )}
                {selectTipo === 1 &&
                  data.percurso[0].operacao[0].tipologia ===
                    "Formação Modular Certificada" && (
                    <ContratoFormandoPercursoComenius
                      data={data}
                      token={token}
                      setOpen={setOpen}
                    />
                  )}
                {/*  {selectTipo === 1 &&
                  data.percurso[0].operacao[0].nomeTipologia === "Formação Modular Certificada" && (
                    <ContratoFormandoUfcdMaisAdvantage
                      data={data}
                      token={token}
                      setOpen={setOpen}
                    />
                  )} */}
                {selectTipo === 1 &&
                  data.percurso[0].operacao[0].tipologia ===
                    "Cursos de Educação e Formação de Adultos" && (
                    <ContratoFormandoEFAComenius
                      data={data}
                      token={token}
                      setOpen={setOpen}
                    />
                  )}
                {selectTipo === 1 &&
                  data.percurso[0].operacao[0].tipologia !==
                    "Formação Modular Certificada" &&
                  data.percurso[0].operacao[0].tipologia !==
                    "Cursos de Educação e Formação de Adultos" &&
                  data.percurso[0].operacao[0].tipologia !==
                    "Programa Trabalhos & Competências Verdes / Green Skills & Jobs" &&
                  data.percurso[0].operacao[0].tipologia !==
                    "Emprego + Digital" && (
                    /*
                    // TODO - convert in a component
                     *** Old Version ***
                     */
                    <ContratoFormando>
                      <div className="body">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <img
                              src={data.percurso[0].entFormadora[0].logotipo}
                              className="h-20"
                              alt="logo"
                              style={{ marginLeft: "20px" }}
                            />
                          </div>
                          <div>
                            <h1
                              style={{
                                fontSize: "20px",
                                paddingLeft: "15px",
                                marginTop: "-5px",
                              }}
                            >
                              Contrato Formando/a
                            </h1>
                          </div>
                          <div>
                            {data.percurso[0].entFinanciadora &&
                              data.percurso[0].entFinanciadora.length > 0 &&
                              data.percurso[0].entFinanciadora[0].logotipo1 && (
                                <img
                                  src={
                                    data.percurso[0].entFinanciadora[0]
                                      .logotipo1
                                  }
                                  className="h-20"
                                  alt="logo"
                                  style={{ marginLeft: "20px" }}
                                />
                              )}
                          </div>
                        </div>

                        <div className="page1">
                          <div className="content">
                            <div className="mt-4">
                              <p>
                                Entre:
                                <br />
                                <b>{data.percurso[0].entFormadora[0].name}</b>,
                                com instalações na{" "}
                                {data.percurso[0].entFormadora[0].morada},{" "}
                                {data.percurso[0].entFormadora[0].postalCode}{" "}
                                {data.percurso[0].entFormadora[0].localidade},
                                com o Contribuinte nº{" "}
                                {data.percurso[0].entFormadora[0].nif}, aqui
                                representada por{" "}
                                {
                                  data.percurso[0].entFormadora[0]
                                    .representanteLegal
                                }
                                , adiante designada por{" "}
                                <b>Primeiro Outorgante</b>, e
                              </p>
                              <table className="dados">
                                <tr>
                                  <th>Nome Completo</th>
                                  <td>{data.fullName}</td>
                                </tr>
                                <tr>
                                  <th>NIF</th>
                                  <td>{data.nif}</td>
                                </tr>
                                <tr>
                                  <th>Cartão de Cidadão/Passaporte</th>
                                  <td>{data.idNumber}</td>
                                </tr>
                                <tr>
                                  <th>Morada</th>
                                  <td>{data.address}</td>
                                </tr>
                                <tr>
                                  <th>Código Postal</th>
                                  <td>{data.postalCode}</td>
                                </tr>
                              </table>
                              <p>
                                , adiante designada por{" "}
                                <b>Segundo Outorgante</b>, é celebrado o
                                presente contrato de prestação de serviços, o
                                qual se rege pelo disposto nas seguintes
                                cláusulas:
                              </p>
                            </div>
                            <div>
                              <h2>
                                Cláusula 1ª - Objeto do Contrato, local e
                                cronograma
                              </h2>
                              <p>
                                1. O <b>Primeiro Outorgante</b> obriga-se a
                                proporcionar ao <b>Segundo Outorgante</b>, que
                                aceita, o seguinte curso {data.percurso[0].name}
                                .
                              </p>
                            </div>
                            <div>
                              <p>
                                2. O curso em causa é composto pelas seguintes
                                Unidades de Formação de Curta Duração:
                              </p>

                              <table className="ufcds">
                                <tbody>
                                  {data.ufcds.map((ufcd, index) => {
                                    if (
                                      ufcd.nInscricoes &&
                                      !ufcd.nInscricoes.find(
                                        (item) => item === data._id
                                      ) &&
                                      ufcd.equivalencias &&
                                      !ufcd.equivalencias.find(
                                        (item) => item === data._id
                                      )
                                    ) {
                                      return (
                                        <tr key={index}>
                                          <th style={{ width: "140px" }}>
                                            Nome:
                                          </th>
                                          <td style={{ width: "420px" }}>
                                            {ufcd.codeUfcd} - {ufcd.name}
                                          </td>
                                          <th style={{ width: "110px" }}>
                                            Carga Horária:
                                          </th>
                                          <td style={{ width: "30px" }}>
                                            {ufcd.cargaHoraria}
                                          </td>
                                        </tr>
                                      );
                                    }
                                    return null; // If the condition is not met, return null to avoid rendering anything.
                                  })}
                                </tbody>
                              </table>
                            </div>
                            <div>
                              <p>
                                3. O curso em causa é uma Ação de Formação
                                desenvolvida no âmbito da seguinte operação a
                                cargo do <b>Primeiro Outorgante</b>:
                              </p>
                              {data.percurso[0].entFinanciadora &&
                                data.percurso[0].entFinanciadora.length > 0 && (
                                  <table className="projeto">
                                    <tr>
                                      <th>Nº do Projeto:</th>
                                      <td>
                                        {data.percurso[0].operacao[0] &&
                                          data.percurso[0].operacao[0]
                                            .codigoOperacao}
                                      </td>
                                    </tr>
                                  </table>
                                )}
                            </div>
                            {data.percurso[0].address ? (
                              <>
                                <div>
                                  <p>
                                    4. Quando a formação decorre (total ou
                                    parcialmente) no formato presencial, o local
                                    de realização do curso é o seguinte:
                                  </p>
                                  <table className="realizacaocurso">
                                    <tr>
                                      <th>Local:</th>
                                      <td>{data.percurso[0].locality}</td>
                                    </tr>
                                    <tr>
                                      <th>Morada:</th>
                                      <td>{data.percurso[0].address}</td>
                                    </tr>
                                    <tr>
                                      <th>Código de Postal:</th>
                                      <td>{data.percurso[0].postalCode}</td>
                                    </tr>
                                  </table>
                                </div>

                                <div>
                                  <p>
                                    5. Quando a formação decorre (total ou
                                    parcialmente) no formato a distância, o
                                    curso realiza-se através da seguinte
                                    plataforma:
                                  </p>

                                  <table className="plataforma">
                                    <tr>
                                      <th>Plataforma LMS:</th>
                                      <td>
                                        {data.percurso[0].moodle &&
                                        data.percurso[0].moodle.length > 0 ? (
                                          <a
                                            href={
                                              data.percurso[0].moodle[0].name
                                            }
                                          >
                                            {data.percurso[0].moodle[0].name}
                                          </a>
                                        ) : (
                                          "NA"
                                        )}
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </>
                            ) : (
                              <table className="projeto">
                                <tr>
                                  <th>Local:</th>
                                  <td>{data.percurso[0].locality}</td>
                                </tr>
                              </table>
                            )}
                            <div>
                              <p>
                                {data.percurso[0].address ? "6." : "4."} O
                                cronograma da formação é facultado no início da
                                Ação de Formação, sendo que está compreendido
                                entre as seguintes datas:
                              </p>

                              <table className="localData">
                                <tr>
                                  <th>Data de início prevista:</th>
                                  <td>
                                    {AlterarFormato(data.percurso[0].dateBegin)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Data de fim prevista:</th>
                                  <td>
                                    {AlterarFormato(data.percurso[0].dateEnd)}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <h2>Cláusula 2ª - Direitos dos/as formandos/as</h2>
                            <p>
                              São direitos do <b>Segundo Outorgante</b>:
                            </p>
                            <ol>
                              <li>
                                Receber do <b>Primeiro Outorgante</b> os
                                ensinamentos de acordo com os objetivos de Ação
                                de Formação e respetivo Programa de Formação.
                              </li>
                              <li>
                                Garantir a proteção dos dados pessoais do/a
                                Formando/a, sendo tratados apenas para fins
                                diretamente relacionados com a ação de formação,
                                conservando-os de forma segura nos termos e
                                prazos aplicáveis.
                              </li>
                              <li>
                                Beneficiar de um seguro contra acidentes,
                                ocorridos durante e por causa da formação, na
                                modalidade de acidentes pessoais, nos casos
                                aplicáveis.
                              </li>
                              <li>
                                Receber informação e acompanhamento
                                técnico-pedagógico no decurso da ação.
                              </li>
                              <li>
                                Receber do <b>Primeiro Outorgante</b>, no final
                                de cada Ação de Formação, em caso de
                                aproveitamento, o Certificado de Formação
                                Profissional legalmente previsto.
                              </li>
                            </ol>
                          </div>
                          <h2>
                            Cláusula 3ª - Deveres gerais dos/as formandos/as
                          </h2>
                          <p>
                            São deveres do <b>Segundo Outorgante</b>:
                          </p>
                          <ol>
                            <li>
                              Tratar com urbanidade o <b>Primeiro Outorgante</b>{" "}
                              e seus representantes.
                            </li>
                            <li>
                              Frequentar com assiduidade e pontualidade a Ação
                              de Formação, de acordo com as regras constantes
                              nos regulamentos de formação em vigor,
                              nomeadamente o Regulamento da Formação à Distância
                              ({" "}
                              <a href="https://drive.google.com/drive/folders/1GBgijvoBmp04CpA6dvtPEgeCgP6KmeiB?usp=sharing">
                                https://drive.google.com/drive/folders/1GBgijvoBmp04CpA6dvtPEgeCgP6KmeiB?usp=sharing
                              </a>
                              ), quando esta decorre nesse formato.
                            </li>
                            <li>
                              Realizar as provas de avaliação de acordo com os
                              métodos e critérios constantes nos regulamentos da
                              Ação de Formação.
                            </li>
                            <li>
                              Abster-se da prática de qualquer ato que possa
                              causar prejuízo ou descrédito do{" "}
                              <b>Primeiro Outorgante</b>
                            </li>
                            <li>
                              Sempre que se aplique, zelar pela conservação e
                              boa utilização dos bens e instalações do{" "}
                              <b>Primeiro Outorgante</b> e entidades associadas
                              ao projeto, na Ação de Formação em causa.
                            </li>
                            <li>
                              Suportar os custos de substituição ou reparação
                              dos equipamentos e materiais fornecidos pelo
                              <b>Primeiro Outorgante</b>, ou seus
                              representantes, sempre que danificados por via de
                              comportamento doloso ou gravemente negligente.
                            </li>
                            <li>
                              O registo e qualquer inscrição é individual e
                              intransmissível devendo apenas ser utilizada pelo/
                              próprio/a.
                            </li>
                            <li>
                              Na sequência do ponto anterior, sempre que a
                              formação decorra no formato a distância, refere-se
                              o seguinte:
                            </li>
                            <ol className="tipoa" type="a">
                              <li>
                                O Login (“Nome de Utilizador”) e a Password
                                (“Senha de Acesso”) apenas podem ser utilizados
                                pelo formando/a.
                              </li>
                              <li>
                                Todos os materiais, vídeos, textos, imagens e
                                outros conteúdos disponibilizados ao/à formando/
                                destinam-se única e exclusivamente ao usufruto
                                do/a próprio/a e não podem ser entregues a
                                qualquer outro indivíduo ou entidade.
                              </li>
                              <li>
                                O/a formando/a reconhece que todos os seus
                                acessos e movimentos dentro da Plataforma LMS
                                (referida na Cláusula 1a) são registados e que
                                os mesmos serão usados para efeitos de
                                verificação de assiduidade.
                              </li>
                              <li>
                                O/a formando/a terá acesso aos conteúdos
                                disponibilizados na Plataforma LMS (referida na
                                Cláusula 1a) durante o tempo determinado no
                                cronograma da Ação de Formação em que se
                                inscreveu.
                              </li>
                              <li>
                                Após o término da Ação de Formação, na data
                                definida e comunicada no cronograma, o/a
                                formando/a terá o prazo de 1 mês para continuar
                                a aceder aos conteúdos disponibilizados na
                                Plataforma LMS (referida na Cláusula 1a).
                              </li>
                              <li>
                                Para que o/a formando/a possa aceder aos
                                conteúdos da Ação de Formação em causa, deve
                                garantir que tem os recursos técnicos
                                necessários, tais como computador, ligação à
                                Internet, e a aplicação ZOOM (https://zoom.us/).
                              </li>
                            </ol>
                          </ol>
                        </div>
                        <div>
                          <h2>
                            Cláusula 4ª - Assiduidade nas componentes de
                            formação presencial ou a distância, quando síncronas
                          </h2>
                          <ol>
                            <li>
                              O conceito de “Presença” ou a “Falta” a uma
                              sessão, aplica-se apenas à formação presencial ou
                              formação à distância quando estas decorrem no
                              formato síncrono, isto é, não se aplica à formação
                              à distância quando esta decorre no formato
                              assíncrono.
                            </li>
                            <li>
                              A “Presença” ou a “Falta” a uma sessão será
                              registada através da rubrica, ou não, da Folha de
                              Presenças/Sumários (ou outro método previsto pelo
                              organismo financiador).
                            </li>
                            <li>
                              Entende-se por “Falta”, para efeitos do presente
                              Contrato:
                            </li>
                            <ol className="tipoa" type="a">
                              <li>
                                A não comparência a uma sessão de formação
                                presencial ou formação a distância, quando esta
                                decorre no formato síncrona, na data e horário
                                previsto no cronograma (ou agendada pelo/a
                                formador/a).
                              </li>
                              <li>
                                A comparência do/a formando/a a uma sessão de
                                formação presencial ou formação a distância,
                                quando no formato síncrona, com atraso superior
                                a 15 minutos, é considerada “Falta”.
                              </li>
                            </ol>
                            <li>
                              Especificamente nas componentes de formação a
                              distância síncrona, que se realizam no formato de
                              videoconferência, o formado obriga-se a estar com
                              a câmara e microfone ligado, a fim de poder
                              intervir sempre que solicitado pelo/a formador/a,
                              assim como autoriza a gravação dessas sessões para
                              partilha e re-visionamento posterior, na
                              plataforma do curso, apenas para fins de
                              aprendizagem ou auditoria.
                            </li>
                            <li>
                              As faltas podem ser justificadas ou
                              injustificadas.
                            </li>
                          </ol>
                          <ol />
                          <li value="6">
                            São consideradas faltas justificadas, mediante
                            apresentação de comprovativo, as faltas da seguinte
                            natureza:
                          </li>
                          <ol className="tipoa" type="a">
                            <li>Doença ou Acidente;</li>
                            <li>Falecimento de parentes próximos ou afins;</li>
                            <li>Casamento;</li>
                            <li>Maternidade ou paternidade;</li>
                            <li>Doença ou Acidente de familiar a cargo;</li>
                            <li>
                              Qualquer dever imposto por lei, que não admita
                              adiamento;
                            </li>
                            <li>
                              Outros motivos comprovados e aceites pela Entidade
                              Formadora.
                            </li>
                          </ol>
                          <li>
                            As faltas justificadas, quando previsíveis devem ser
                            comunicadas à Coordenação Pedagógica, com a
                            antecedência mínima de dois dias.
                          </li>
                        </div>
                        <div>
                          <h2>
                            Cláusula 5ª - Assiduidade nas componentes de
                            formação a distância, quando assíncronas
                          </h2>
                          <ol>
                            <li>
                              A assiduidade da componente à distância assíncrona
                              é verificada através da “carga de trabalho” e
                              tempo de trabalho registado pela Plataforma LMS
                              (referida na Cláusula 1ª) onde decorre a formação,
                              sendo que corresponde à realização (ou não) das
                              atividades previstas no Roteiro Pedagógico de cada
                              uma das UFCD que fazem parte do Percurso de
                              Formação em causa, as quais são disponibilizadas
                              na Plataforma LMS (referida na Cláusula 1ª) e que
                              incluem:
                            </li>

                            <ol className="tipoa" type="a">
                              <li>
                                O visionamento e estudo dos materiais
                                pedagógicos, incluindo vídeo aulas, documentação
                                de suporte, atividades e outros materiais
                                disponibilizados.
                              </li>
                              <li>
                                A realização das atividades previstas na
                                plataforma e outras tarefas determinadas pelo/a
                                formador/a.
                              </li>
                            </ol>
                            <li>
                              Nas componentes da formação desenvolvida no
                              formato à distância, componente assíncrona, o/a
                              formando/a está obrigado à realização de 90% das
                              atividades pedagógicas previstas no Roteiro
                              Pedagógico de cada uma das UFCD que fazem parte do
                              Percurso de Formação, independentemente do que de
                              seguida será referido na Cláusula 6ª.
                            </li>
                            <li>
                              O Roteiro Pedagógico da UFCD tem uma carga horária
                              associada a cada conteúdo, mas a assiduidade não
                              está diretamente relacionada com o cumprimento de
                              um horário ou um tempo de trabalho dedicado à
                              atividade, mas sim à sua realização com zelo e
                              interesse de cada uma das componentes previstas no
                              Roteiro Pedagógico.
                            </li>
                            <li>
                              O controlo da realização ou não das atividades
                              previstas no Roteiro Pedagógico é efetuado de
                              forma automática por meio da Plataforma LMS
                              (referida na Cláusula 1ª).
                            </li>
                          </ol>
                        </div>
                        <div>
                          <h2>Cláusula 6ª - Aproveitamento</h2>
                          <ol>
                            <li>
                              O/a formando/a está obrigado à frequência de pelo
                              menos 90% do total das horas presencias ou
                              síncronas e/ou da carga de trabalho assíncrona
                              (quando aplicável o disposto na Cláusula 5ª), da
                              totalidade do Percurso de Formação e,
                              cumulativamente, a 50% da carga horária/carga de
                              trabalho de cada UFCD.
                            </li>
                            <li>
                              Durante o Percurso de Formação o/a formando/a será
                              submetido/a a um processo de avaliação sumativa a
                              cada uma das suas componentes, UFCD a UFCD, que
                              irá determinar o seu aproveitamento, ou não.
                            </li>
                            <li>
                              Caso o formando não cumpra o disposto no nº 1 e nº
                              2 da presente Cláusula, à luz da Portaria nº
                              66/2022, para alcançar os resultados mínimos de
                              aprendizagem, o <b>Primeiro Outorgante</b> deve
                              desenvolver os mecanismos de recuperação
                              necessários ao cumprimento dos objetivos
                              inicialmente definidos, nomeadamente,
                              disponibilizando os materiais pedagógicos em
                              formato assíncrono em momentos diferentes dos
                              inicialmente previstos no cronograma e a
                              possibilidade de realização das avaliações
                              sumativas noutros momentos para que alcance os
                              resultados de aprendizagem mínimos.
                            </li>
                            <li>
                              Desde que a Ação de Formação seja concluída “Com
                              Aproveitamento”, o <b>Segundo Outorgante</b>
                              receberá do
                              <b>Primeiro Outorgante</b>, o respetivo
                              Certificado de Formação Profissional legalmente
                              previsto.
                            </li>
                          </ol>
                        </div>
                        <div>
                          <h2>
                            Cláusula 7ª - Alteração do Percurso/Cronograma de
                            Formação
                          </h2>
                          <ol>
                            <li>
                              Quando, por razões alheias à sua vontade e a si
                              não imputáveis, o <b>Primeiro Outorgante</b> não
                              puder cumprir integralmente o Percurso/Cronograma
                              de Formação previsto, poderá proceder aos
                              necessários ajustamentos, devendo sempre comunicar
                              tal facto ao <b>Segundo Outorgante</b>.
                            </li>
                            <li>
                              A alteração do Percurso/Cronograma de Formação
                              pelos motivos referidos no número anterior, não
                              confere ao <b>Segundo Outorgante</b> direito a
                              qualquer indemnização.
                            </li>
                          </ol>
                        </div>
                        <div>
                          <h2>Cláusula 8ª - Casos Omissos</h2>
                          <ol>
                            <li>
                              Nos casos omissos, recorrer-se-á ao Regulamento de
                              Funcionamento da Formação e à Lei Geral.
                            </li>
                            <li>
                              As dúvidas suscitadas pela aplicação do presente
                              contrato serão resolvidas pelo{" "}
                              <b>Primeiro Outorgante</b>, ou seus
                              representantes.
                            </li>
                          </ol>
                        </div>
                        <div>
                          <h2>Cláusula 9ª — Duração</h2>
                          <p>
                            O presente contrato vigorará pelo período de duração
                            de formação, previsto na Cláusula 1ª.
                          </p>
                        </div>

                        <div>
                          <h2>Cláusula 10ª — Incumprimento</h2>
                          <ol>
                            <li>
                              Verificando-se o incumprimento pelo{" "}
                              <b>Segundo Outorgante</b> de qualquer das
                              obrigações assumidas no presente contrato, o{" "}
                              <b>Primeiro Outorgante</b> poderá proceder à
                              rescisão imediata do presente contrato, sem
                              direito a qualquer indemnização ou compensação
                              pecuniária.
                            </li>
                            <li>
                              Os/as formandos/as que não tenham concluído a
                              formação por motivo de faltas relacionadas com a
                              maternidade, paternidade ou assistência à família,
                              têm prioridade no acesso a Ações de Formação que
                              se iniciem imediatamente após o termo do
                              impedimento.
                            </li>
                          </ol>
                        </div>
                        <div style={{ breakInside: "avoid" }}>
                          <h2>Cláusula 11ª — Legislação</h2>
                          <p>
                            O presente Contrato de Formação regula-se nos termos
                            do disposto na Portaria no 60-A/2015, para efeitos
                            de Co-financiamento pelo Fundo Social Europeu (FSE).
                          </p>
                          <br />
                          <p>
                            O presente contrato é feito em duplicado fincando
                            cada uma das partes na posse de um exemplar.
                          </p>
                          <table className="localData">
                            <tr>
                              <th>Local:</th>
                              <td>
                                <input
                                  className="w-full input-field"
                                  defaultValue={
                                    valores.local
                                      ? valores.local
                                      : data.percurso[0].entFormadora[0]
                                          .localidade
                                  }
                                  onChange={(e) => {
                                    setLocal(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Data:</th>
                              <td>
                                <input
                                  className="w-full input-field"
                                  defaultValue={
                                    valores.date
                                      ? valores.date
                                      : AlterarFormato(
                                          data.percurso[0].dateBegin
                                        )
                                  }
                                  onChange={(e) => {
                                    setDate(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>
                          </table>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              gap: "30px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "50%",
                              }}
                            >
                              <h4 style={{ textAlign: "center" }}>
                                O PRIMEIRO OUTORGANTE
                              </h4>
                              <div
                                style={{
                                  minHeight: "200px",
                                  borderBottom: "1px solid black !important",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  alt="assinatura"
                                  style={{ margin: "5px auto" }}
                                  id="img"
                                  className="img"
                                  src={
                                    data.percurso[0].entFormadora[0].assinatura
                                  }
                                  height="70"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "50%",
                              }}
                            >
                              <h4 style={{ textAlign: "center" }}>
                                O SEGUNDO OUTORGANTE
                              </h4>
                              <div
                                style={{
                                  minHeight: "200px",
                                  borderBottom: "1px solid black !important",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        {data.percurso[0].entFinanciadora &&
                          data.percurso[0].entFinanciadora.length > 0 &&
                          data.percurso[0].entFinanciadora[0].logotipo2 && (
                            <div>
                              <img
                                src={
                                  data.percurso[0].entFinanciadora[0].logotipo2
                                }
                                className="h-20"
                                alt="logo"
                                style={{ marginLeft: "20px" }}
                              />
                            </div>
                          )}
                      </div>
                      <div className="flex justify-center w-full">
                        <button
                          className="mx-auto mt-4 button-geral"
                          onClick={() => enviarContratoFormandoAntigo()}
                        >
                          Enviar Contrato
                        </button>
                        <button
                          className="mx-auto mt-4 button-geral"
                          onClick={() => enviarContratoFormandoNoEmailAntigo()}
                        >
                          Download Contrato
                        </button>
                      </div>
                    </ContratoFormando>
                  )}
                {selectTipo === 2 && (
                  /*
                   ***New Version***
                   */
                  <FichaInscricao data={data} />
                )}

                {/* {selectTipo === 2 &&
                  data.percurso[0].operacao[0].nomeTipologia ===
                  "operaçãox" && (
                    
                    // TODO - convert in a component
                     // ***Old Version***
                    <FichaInscricaoAntiga>
                      <div className="body">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                          }}
                        >
                          <div>
                            <img
                              src={data.percurso[0].entFormadora[0].logotipo}
                              className="h-20"
                              alt="logo"
                              style={{ marginLeft: "20px" }}
                            />
                          </div>
                          <div>
                            <h1
                              style={{
                                fontSize: "20px",
                                paddingLeft: "15px",
                                marginTop: "-5px",
                              }}
                            >
                              Ficha de Inscrição
                            </h1>
                          </div>
                          <div>
                            {data.percurso[0].entFinanciadora &&
                              data.percurso[0].entFinanciadora.length > 0 &&
                              data.percurso[0].entFinanciadora[0].logotipo1 && (
                                <img
                                  src={
                                    data.percurso[0].entFinanciadora[0]
                                      .logotipo1
                                  }
                                  className="h-20"
                                  alt="logo"
                                  style={{ marginLeft: "20px" }}
                                />
                              )}
                          </div>
                        </div>
                        <div className="page w-full">
                          <div className="mb-3 container-1">
                            <div className="resposta">
                              <div
                                style={{
                                  width: "250px",
                                  border: "1px solid black",
                                  backgroundColor: "#d6d6d6",
                                  fontWeight: " bold",
                                  alignItems: "center",
                                }}
                              >
                                Curso/Módulo/UFCD
                              </div>
                              <div className="value-1">
                                {data.percurso[0].name}
                              </div>
                            </div>
                          </div>
                          <div className="mb-2 container-1 w-full">
                            <div className="value-20 w-full">
                              Identificação Pessoal
                            </div>
                            <div className="w-full">
                              <div className="resposta">
                                <div className="element-1 border-t-0">
                                  Nome Completo
                                </div>
                                <div className="value-1">{data.fullName}</div>
                              </div>
                            </div>
                            <div className="resposta flex">
                              <div className="resposta-2">
                                <div className="element-1">
                                  Data de Nascimento
                                </div>
                                <div className="value-1">{data.birthDate}</div>
                              </div>
                              <div className="resposta-3 ">
                                <div className="element-1">Género</div>
                                <div className="value-3">{data.gender}</div>
                              </div>
                            </div>
                            <div className="resposta flex">
                              <div className="resposta-2">
                                <div className="element-1">
                                  Naturalidade Concelho
                                </div>
                                <div className="value-2">
                                  {data.parish.split(/[-\\/]/)[1]}
                                </div>
                              </div>
                              <div className="resposta-3">
                                <div className="element-1">
                                  Naturalidade Distrito
                                </div>
                                <div className="value-3">
                                  {data.parish.split(/[-\\/]/)[0]}
                                </div>
                              </div>
                            </div>
                            <div className="resposta">
                              <div className="element-1">Nacionalidade</div>
                              <div className="value-1">{data.nationality}</div>
                            </div>
                            <div className="resposta">
                              <div className="element-1">Morada</div>
                              <div className="value-1">{data.address}</div>
                            </div>
                            <div className="resposta">
                              <div className="element-1">Código Postal</div>
                              <div className="value-1">{data.postalCode}</div>
                            </div>
                            <div className="resposta">
                              <div className="element-1">
                                Telefone/Telemóvel
                              </div>
                              <div className="value-1">{data.cellNumber}</div>
                            </div>
                            <div className="resposta">
                              <div className="element-1">E-mail</div>
                              <div className="value-1">{data.email}</div>
                            </div>
                            <div className="resposta flex">
                              <div className="resposta-2">
                                <div className="element-1">
                                  Tipo de Documento de Identificação
                                </div>
                                <div className="value-1">
                                  {data.documentType}
                                </div>
                              </div>
                              <div className="resposta-3">
                                <div className="element-1">
                                  Nº de Contribuinte
                                </div>
                                <div className="value-3">{data.nif}</div>
                              </div>
                            </div>
                            <div className="resposta-12 flex ">
                              <div className="resposta-2 ">
                                <div className="element-1">
                                  Nº Documento de Identificação
                                </div>
                                <div className="value-1">{data.idNumber}</div>
                              </div>
                              <div className="resposta-3 flex">
                                <div className="w-1/2 flex flex-col justify-center">
                                  <div className="element-16 h-1/2">
                                    Nº Segurança Social
                                  </div>
                                  <div className="element-16 h-1/2">
                                    Data de Validade
                                  </div>
                                </div>
                                <div className="w-1/2 flex flex-col justify-center">
                                  <div className="value-16 h-1/2">
                                    {data.niss}
                                  </div>
                                  <div className="value-16 h-1/2">
                                    {data.idExpiration}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="resposta">
                              <div className="element-1">
                                Habilitações Académicas
                              </div>
                              <div className="value-1">
                                {data.academicDegree}
                              </div>
                            </div>
                          </div>
                          <div className="container-1">
                            <div className="value-20">Entidade Empregadora</div>
                            <div className="resposta">
                              <div className="element-1">
                                Nome da Entidade Empregadora
                              </div>
                              <div className="value-1">{data.nameEmployer}</div>
                            </div>
                            <div className="resposta">
                              <div className="element-1">
                                Vínculo Contratual
                              </div>
                              <div className="value-1">
                                {data.employmentSituation}
                              </div>
                            </div>
                            <div className="resposta">
                              <div className="element-1 text-[12px]">
                                Categoria Profissional/Grupo Profissional
                              </div>
                              <div className="value-1">{data.job}</div>
                            </div>
                          </div>
                          <div className="Motivo mt-4">
                            <span className="textMotivo">
                              Sim, Autorizo o/a{" "}
                              {data.percurso[0].entFormadora[0].name} a utilizar
                              os dados do Participante para processamento
                              informático, divulgação de serviços próprios e
                              fornecer à DGERT e/ou Entidades
                              Reguladores/Financiadoras das ações de formação
                              para auscultação relativamente à qualidade dos
                              seus serviços por um período máximo de 5 anos.
                            </span>
                          </div>
                          <div className="container-table ">
                            <div className="mt-4">
                              <p style={{ fontSize: "10px" }}>
                                Os campos assinalados com asterisco (*) são
                                OBRIGATÓRIOS para validação de requisitos,
                                seleção de formandos e emissão do certificado. A
                                inscrição é ANULADA se tiver dados incompletos,
                                não legíveis ou com erros, bem como
                                comprovativos insuficientes ou desatualizados.
                                O/A {data.percurso[0].entFormadora[0].name} ,
                                garante a estrita confidencialidade no
                                tratamento dos dados presentes nesta ficha,
                                garantindo que a informação não será partilhada
                                com terceiros e será utilizada apenas para os
                                fins diretamente relacionados com a formação,
                                sendo tratados de forma lícita de acordo com o
                                Regulamento geral de proteção de dados e
                                legislação aplicável.
                              </p>
                              <p style={{ marginTop: "4px" }}>
                                Os dados solicitados são os estritamente
                                necessários para a realização da formação
                                modular certificada, considerando o
                                enquadramento legal e seu financiamento nos
                                termos previstos do Regulamento específico do
                                domínio da Inclusão Social e Emprego,
                                nomeadamente para:
                              </p>
                              <ol className="list-decimal pl-5 text-sm mx-6">
                                <li>
                                  Processo técnico-pedagógico Artigo. n.º 8 da
                                  Portaria n.º 60-A/2011 de 2 de março. e n.º 4
                                  do ponto II do Anexo 2 da Portaria. 851/2010
                                  de 6 de setembro, alterada e republicada pela
                                  Portaria nº 208/2013, de 26 de Junho;
                                </li>
                                <li>
                                  Emissão de contratos de formação Artigo n.º 8
                                  da Portaria n.º 60-A/2011 de 2 de março.,
                                  artigo. n.º 39 da Portaria n.º 283/2011 de 24
                                  de outubro. e n.º 5 do ponto II do Anexo 2 da
                                  Portaria 851/2010 de 6 de setembro, alterada e
                                  republicada pela Portaria nº 208/2013, de 26
                                  de Junho;
                                </li>
                                <li>
                                  Emissão de certificados na plataforma SIGO
                                  Artigo n.º 7 nº 5 do Decreto-Lei n.º 14/2017
                                  de 26 de janeiro. e n.º 4 do ponto II do Anexo
                                  2 da Portaria 851/2010 de 6 de setembro,
                                  alterada e republicada pela Portaria nº
                                  208/2013, de 26 de Junho;
                                </li>
                                <li>
                                  Reporte Balcão 2020, nos termos da legislação
                                  em vigor associada à formação financiada
                                  (https://balcao.portugal2020.pt);
                                </li>
                                <li>
                                  Guia do registo de Participante POCH de março
                                  de 2017 e Artigo. n.º 25 do Decreto-Lei n.º
                                  159/2014 de 27 de outubro;
                                </li>
                                <li>
                                  Verificação do cumprimento dos requisitos da
                                  certificação
                                </li>
                              </ol>
                              <div className="mt-8">
                                <span className="text-lg font-bold">
                                  Assinatura do/a formando/a:
                                  ______________________________________ Data:
                                  ___/____/______
                                </span>
                              </div>
                              <br />
                              <br />
                              <div className="mt-2">
                                <span className="text-lg font-bold">
                                  Informação Validada:
                                  ____________________________________________
                                  Data: ___/____/______
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </FichaInscricaoAntiga>
                  )} */}
              </>
            )}

            {errorMessage && (
              <div className="flex justify-center w-full">
                <p className="text-text-error">{errorMessage}</p>
              </div>
            )}

            <button className="absolute top-1.5 right-1.5" onClick={handleOpen}>
              <AiOutlineCloseCircle
                size={24}
                className="hover:text-text-error"
              />
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
