import styled from "styled-components";

export const StyleFormandoGreenSkillsJobs = styled.div`
  .body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    background-color: white;
    color: black;
    padding: 10px;
  }
  h1 {
    text-align: center;
    font-size: 16px;
  }
  h2 {
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    font-style: italic;
  }
  p {
    margin-bottom: 0%;
    margin-top: 0%;
    text-align: justify;
  }
  li {
    margin-bottom: 5px;
    margin-top: 5px;
    text-align: justify;
  }
  ol {
    padding-left: 1em;
  }
  .dados {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
  }
  .dados th {
    font-size: 10px;
    background-color: rgb(221, 221, 221);
    width: 160px;
    border: 1px solid black;
    padding: 5px;
    text-align: right;
  }
  .dados td {
    font-size: 10px;
    border: 1px solid black;
    padding: 5px;
    text-align: left;
  }
  .ufcds {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
  }
  .ufcds th {
    font-size: 10px;
    background-color: rgb(221, 221, 221);
    border: 1px solid black;
    padding: 5px;
    text-align: right;
  }
  .ufcds td {
    font-size: 10px;
    border: 1px solid black;
    padding: 5px;
    text-align: left;
  }
  .realizacaocurso {
    margin-top: 5px;
    margin-bottom: 5px;
    break-inside: avoid;
    table-layout: fixed;
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
  }
  .realizacaocurso th {
    font-size: 10px;
    background-color: rgb(221, 221, 221);
    width: 120px;
    border: 1px solid black;
    padding: 5px;
    text-align: right;
  }
  .realizacaocurso td {
    font-size: 10px;
    border: 1px solid black;
    padding: 5px;
    text-align: left;
  }
  .plataforma {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
  }
  .plataforma th {
    font-size: 10px;
    background-color: rgb(221, 221, 221);
    width: 120px;
    border: 1px solid black;
    padding: 5px;
    text-align: right;
  }
  .plataforma td {
    font-size: 10px;
    border: 1px solid black;
    padding: 5px;
    text-align: left;
  }
  .localData {
    margin-top: 5px;
    margin-bottom: 5px;
    break-inside: avoid;
    table-layout: fixed;
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
  }
  .localData th {
    font-size: 10px;
    background-color: rgb(221, 221, 221);
    width: 120px;
    border: 1px solid black;
    padding: 5px;
    text-align: right;
  }
  .localData td {
    font-size: 10px;
    border: 1px solid black;
    padding: 5px;
    text-align: left;
  }
  .valor {
    margin-top: 5px;
    margin-bottom: 5px;
    break-inside: avoid;
    table-layout: fixed;
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
  }
  .valor th {
    font-size: 10px;
    background-color: rgb(221, 221, 221);
    width: 120px;
    border: 1px solid black;
    padding: 5px;
    text-align: right;
  }
  .valor td {
    font-size: 10px;
    border: 1px solid black;
    padding: 5px;
    text-align: left;
  }
  .tipoa {
    counter-reset: list;
    padding-left: 2.5em;
  }
  .tipoa > li {
    list-style: none;
    position: relative;
  }
  .tipoa > li:before {
    counter-increment: list;
    content: counter(list, lower-alpha) ") ";
    position: absolute;
    left: -1.4em;
  }
  .img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .header {
    margin-top: -5.5px;
  }
  .header-m {
    margin-bottom: 5px;
  }
  .header-m5 {
    margin-bottom: 15px;
  }
  .container-assinatura {
    margin-top: 10px;
  }
  .alinea {
    text-indent: 2em;
  }
  .page-break-after {
    page-break-after: always;
  }
  .page-break-inside {
    page-break-inside: avoid;
  }
  .signatureLine {
    border-bottom: 1px solid black;
    width: 50%;
  }
`;
