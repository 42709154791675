import React from "react";

import { StyledFichaDeInscricao } from "../Modal/stylesContratos/styledFichaInscricao";

export default function FichaInscricao({ data }) {
  return (
    <StyledFichaDeInscricao>
      <div className="body">
        <div className="flex justify-between items-center ">
          {" "}
          <div>
            <img
              src={data.percurso[0].entFormadora[0].logotipo}
              className="h-20 w-56"
              alt="logo"
              style={{ marginLeft: "20px", marginBottom: "10px" }}
            />
          </div>
          <div>
            <h1
              style={{
                fontSize: "30px",

                marginTop: "-5px",
              }}
            >
              <b>Ficha de Inscrição</b>
            </h1>
          </div>
          <div>
            {data.percurso[0].entFinanciadora &&
              data.percurso[0].entFinanciadora.length > 0 &&
              data.percurso[0].entFinanciadora[0].logotipo1 && (
                <img
                  src={data.percurso[0].entFinanciadora[0].logotipo1}
                  className="h-20"
                  alt="logo"
                  style={{ marginRight: "20px" }}
                />
              )}
          </div>
        </div>

        <div className="page w-full">
          <div className="mb-3 container-1">
            <div className="resposta">
              <div
                style={{
                  width: "250px",
                  border: "1px solid black",
                  backgroundColor: "#d6d6d6",
                  fontWeight: " bold",
                  alignItems: "center",
                }}
              >
                Curso/Módulo/UFCD
              </div>
              <div className="value-1">{data.percurso[0].name}</div>
            </div>
          </div>
          <div className="mb-2 container-1 w-full">
            <div className="value-20 w-full">Identificação Pessoal</div>
            <div className="w-full">
              <div className="resposta">
                <div className="element-1 border-t-0">Nome Completo</div>
                <div className="value-1">{data.fullName}</div>
              </div>
            </div>
            <div className="resposta flex">
              <div className="resposta-2">
                <div className="element-1">Data de Nascimento</div>
                <div className="value-1">{data.birthDate}</div>
              </div>
              <div className="resposta-3 ">
                <div className="element-1">Género</div>
                <div className="value-3">{data.gender}</div>
              </div>
            </div>
            <div className="resposta flex">
              <div className="resposta-2">
                <div className="element-1">Naturalidade Concelho</div>
                <div className="value-2">{data.parish.split(/[-\\/]/)[1]}</div>
              </div>
              <div className="resposta-3">
                <div className="element-1">Naturalidade Distrito</div>
                <div className="value-3">{data.parish.split(/[-\\/]/)[0]}</div>
              </div>
            </div>
            <div className="resposta">
              <div className="element-1">Nacionalidade</div>
              <div className="value-1">{data.nationality}</div>
            </div>
            <div className="resposta">
              <div className="element-1">Morada</div>
              <div className="value-1">{data.address}</div>
            </div>
            <div className="resposta">
              <div className="element-1">Código Postal</div>
              <div className="value-1">{data.postalCode}</div>
            </div>
            <div className="resposta">
              <div className="element-1">Telefone/Telemóvel</div>
              <div className="value-1">{data.cellNumber}</div>
            </div>
            <div className="resposta">
              <div className="element-1">E-mail</div>
              <div className="value-1">{data.email}</div>
            </div>
            <div className="resposta flex">
              <div className="resposta-2">
                <div className="element-1">
                  Tipo de Documento de Identificação
                </div>
                <div className="value-1">{data.documentType}</div>
              </div>
              <div className="resposta-3">
                <div className="element-1">Nº de Contribuinte</div>
                <div className="value-3">{data.nif}</div>
              </div>
            </div>
            <div className="resposta-12 flex ">
              <div className="resposta-2 ">
                <div className="element-1">Nº Documento de Identificação</div>
                <div className="value-1">{data.idNumber}</div>
              </div>
              <div className="resposta-3 flex">
                <div className="w-1/2 flex flex-col justify-center">
                  <div className="element-16 h-1/2">Nº Segurança Social</div>
                  <div className="element-16 h-1/2">Data de Validade</div>
                </div>
                <div className="w-1/2 flex flex-col justify-center">
                  <div className="value-16 h-1/2">{data.niss}</div>
                  <div className="value-16 h-1/2">{data.idExpiration}</div>
                </div>
              </div>
            </div>
            <div className="resposta">
              <div className="element-1">Habilitações Académicas</div>
              <div className="value-1">{data.academicDegree}</div>
            </div>
          </div>
          <div className="container-1">
            <div className="value-20">Entidade Empregadora</div>
            <div className="resposta">
              <div className="element-1">Nome da Entidade Empregadora</div>
              <div className="value-1">{data.nameEmployer}</div>
            </div>
            <div className="resposta">
              <div className="element-1">Vínculo Contratual</div>
              <div className="value-1">{data.employmentSituation}</div>
            </div>
            <div className="resposta">
              <div className="element-1 text-[12px]">
                Categoria Profissional/Grupo Profissional
              </div>
              <div className="value-1">{data.job}</div>
            </div>
          </div>
          <div className="Motivo mt-4">
            <span className="textMotivo">
              Sim, Autorizo o/a {data.percurso[0].entFormadora[0].name} a
              utilizar os dados do Participante para processamento informático,
              divulgação de serviços próprios e fornecer à DGERT e/ou Entidades
              Reguladores/Financiadoras das ações de formação para auscultação
              relativamente à qualidade dos seus serviços por um período máximo
              de 5 anos.
            </span>
          </div>
          <div className="container-table ">
            <div className="mt-4">
              <p style={{ fontSize: "10px" }}>
                Os campos assinalados com asterisco (*) são OBRIGATÓRIOS para
                validação de requisitos, seleção de formandos e emissão do
                certificado. A inscrição é ANULADA se tiver dados incompletos,
                não legíveis ou com erros, bem como comprovativos insuficientes
                ou desatualizados. O/A {data.percurso[0].entFormadora[0].name} ,
                garante a estrita confidencialidade no tratamento dos dados
                presentes nesta ficha, garantindo que a informação não será
                partilhada com terceiros e será utilizada apenas para os fins
                diretamente relacionados com a formação, sendo tratados de forma
                lícita de acordo com o Regulamento geral de proteção de dados e
                legislação aplicável.
              </p>
              <p style={{ marginTop: "4px" }}>
                Os dados solicitados são os estritamente necessários para a
                realização da formação modular certificada, considerando o
                enquadramento legal no programa{" "}
                <b>{data?.percurso[0]?.entFinanciadora[0]?.name ?? ""}</b> e seu
                financiamento nos termos previstos do Regulamento específico do
                domínio da Inclusão Social e Emprego, nomeadamente para:
              </p>
              <ol className="list-decimal pl-5 text-sm mx-6">
                <li>
                  Emissão de certificados na plataforma SIGO Artigo n.º 7 nº 5
                  do Decreto-Lei n.º 14/2017 de 26 de janeiro. e n.º 4 do ponto
                  II do Anexo 2 da Portaria 851/2010 de 6 de setembro, alterada
                  e republicada pela Portaria nº 208/2013, de 26 de Junho;
                </li>
                <li>
                  Reporte Balcão 2030, nos termos da legislação em vigor
                  associada à formação financiada{" "}
                  <a
                    href="https://portugal2030.pt/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "blue", textDecoration: "underline" }}
                  >
                    (https://portugal2030.pt/)
                  </a>
                  , quando aplicável;
                </li>
                <li>
                  Verificação do cumprimento dos requisitos da certificação.
                </li>
              </ol>
              <div className="mt-8">
                <span className="text-lg font-bold">
                  Assinatura do/a formando/a:
                  ______________________________________ Data: ___/____/______
                </span>
              </div>
              <br />
              <br />
              <div className="mt-2">
                <span className="text-lg font-bold">
                  Informação Validada:
                  ____________________________________________ Data:
                  ___/____/______
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFichaDeInscricao>
  );
}
