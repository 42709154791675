import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BsFillPencilFill } from "react-icons/bs";
import Footer from "../../../components/Footer";
import toastSuccess from "../../../components/Toast/toastSuccess";
import toastError from "../../../components/Toast/toastError";
import { IoMdRemoveCircle } from "react-icons/io";
import ConfirmToaster from "../../../components/Toast/toastConfirmChoice";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import NavbarAdmin2 from "../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import Cookies from "js-cookie";

function Operacoes() {
    const [data, setData] = useState([]);
    const [diretExec, setDiretExec] = useState([]);
    const [gestorOpe, setGestorOpe] = useState([]);
    const [gestorFin, setGestorFin] = useState([]);
    const [diretDep, setDiretDep] = useState([]);

    const [entFinanciadoraList, setEntFinanciadoraList] = useState("");
    const [entFormadoraList, setEntFormadoraList] = useState("");

    const [admin, setAdmin] = useState("");
    const [modal, setModal] = useState(-1);
    const [cont, setCont] = useState(0);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [alterar, setAlterar] = useState(0);
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("SuperAdmin-operacoes-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`SuperAdmin-operacoes-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    const params = useParams();
    const navigate = useNavigate();

    const handleClick = (event, item) => {
        // Check if the clicked element is a <td>
        if (event.target.tagName === 'TD') {
            // Check if the <td> has a 'clickable' class
            if (!event.target.classList.contains('operacao')) {
                // Navigate to the desired location
                // Replace 'destination' with your actual navigation logic
            }
            else navigate("/menuSuperAdmin/operacoes/" + item._id)
        }
    };
    useEffect(() => {
        Cookies.set("SuperAdmin-operacoes-Pag", paginaAtual);
        Cookies.set(`SuperAdmin-operacoes-itm1`, itemsPag)
        getNome()
        getAdmins()
        getEntFormadoras()
        getEntFinanciadoras()
    }, [loading, paginaAtual, nPaginas, paginaAtual, itemsPag, cont]);

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };
    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    function getNome() {
        fetch(`${process.env.REACT_APP_API_URL}/operacao`, {
            headers: {
                "authorization": localStorage.getItem('admin2')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    paginacaoTabelas(result)
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                    setLoading(false);
                }
            );
    }

    function getEntFormadoras() {
        fetch(`${process.env.REACT_APP_API_URL}/EntFormadora`, {
            headers: {
                'authorization': localStorage.getItem("admin2")
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                setEntFormadoraList(result)
            });
    }

    function getEntFinanciadoras() {
        fetch(`${process.env.REACT_APP_API_URL}/EntFinanciadora`, {
            headers: {
                'authorization': localStorage.getItem("admin2")
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                setEntFinanciadoraList(result)
            });
    }
    function submeterNomeOp() {
        const nomeOperacao = document.getElementById("nameTipologia").value
        const codigoOperacao = document.getElementById("codigoOperacao").value
        const tipologia = document.getElementById("tipologia").value
        const gestorOperacao = document.getElementById("gestorOpe").value
        const gestorFinanceiro = document.getElementById("gestorFin").value
        const diretorDepartamento = document.getElementById("diretorDep").value
        const diretorExecutivo = document.getElementById("diretorExec").value
        const entFormadora = document.getElementById("entForm").value
        const entFinanciadora = document.getElementById("entFinanc").value
        if (!nomeOperacao || !codigoOperacao || !tipologia) {
            let missingFields = [];
        
            // Check each field and add to the missing fields array if empty
            if (!nomeOperacao) missingFields.push("Nome da operação");
            if (!codigoOperacao) missingFields.push("Código da operação");
            if (!tipologia) missingFields.push("Tipologia");
        
            // Join the missing fields into a string and show the toast error
            toastError("Dados por preencher: " + missingFields.join(", "));
        
            return;
        }
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/operacao`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem('admin2') },
                body: JSON.stringify({
                    nomeTipologia: nomeOperacao,
                    codigoOperacao: codigoOperacao,
                    tipologia: tipologia,
                    gestorOperacao: gestorOperacao,
                    gestorFinanceiro: gestorFinanceiro,
                    diretorExecutivo: diretorExecutivo,
                    diretorDepartamento: diretorDepartamento,
                    entFormadora: entFormadora,
                    entFinanciadora: entFinanciadora
                
                })
            })
            .then(res => res.json())
            .then(
                (res) => {
                    toastSuccess('Operação inserida com sucesso!')
                    setLoading(false)
                },
                (error) => {
                    console.error(error)
                    setLoading(false)
                    return
                }
            )
        getNome()
        document.getElementById("nameTipologia").value = ""
        document.getElementById("codigoOperacao").value = ""
    }


    //Elimina o nome pelo seu id
    function deleteNome(_id) {

        fetch(`${process.env.REACT_APP_API_URL}/operacao/${_id}`, {
            method: 'DELETE',
            headers: {
                'authorization': localStorage.getItem('admin2')
            }
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('Operação removida com sucesso!')
                getNome()
            })
        })
    }

    function changeOperacao(id, index) {
        const nomeOperacao = document.getElementById(`nomeOperacao${index}`) && document.getElementById(`nomeOperacao${index}`).value
        const codigoOperacao = document.getElementById(`codigoOperacao${index}`) && document.getElementById(`codigoOperacao${index}`).value
        const tipologia = document.getElementById(`tipologia${index}`) && document.getElementById(`tipologia${index}`).value
        fetch(`${process.env.REACT_APP_API_URL}/operacao/${id}`,
            {
                body: JSON.stringify({
                    nomeOperacao: nomeOperacao,
                    codigoOperacao: codigoOperacao,
                    tipologia: tipologia
                }),
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('admin2')
                }
            }).then((result) => {
                result.json().then((resp) => {
                    setAlterar(false);
                    toastSuccess('Dados alterados com sucesso!')
                    getNome();
                })
            })
    }
    function addAdmin(id, gestorOperacao, gestorFinanceiro, diretorExecutivo, diretorDepartamento, entFormadora, entFinanciadora) {
        fetch(`${process.env.REACT_APP_API_URL}/operacao/associarAdmin/${id}`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem('admin2') },
                body: JSON.stringify({
                    gestorOperacao: gestorOperacao,
                    gestorFinanceiro: gestorFinanceiro,
                    diretorExecutivo: diretorExecutivo,
                    diretorDepartamento: diretorDepartamento,
                    entFormadora: entFormadora,
                    entFinanciadora: entFinanciadora
                })
            })
            .then((res) => res.json())
            .then(
                (result) => {
                    setLoading(false);
                    setModal(-1);
                    setCont(cont + 1)
                    setAdmin("")
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);

                }
            );
    }
    function getAdmins() {
        fetch(`${process.env.REACT_APP_API_URL}/admins`, {
            headers: {
                "authorization": localStorage.getItem('admin2')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    const gestorOperacao = result.filter(admin => (admin.nomeRole === "Administrador - Gestor de Operação" || admin.nomeRole === "Entidade de Ligação" || admin.nomeRole === "Administrador - Diretor de Departamento"))
                    setGestorOpe(gestorOperacao)
                    const gestorFinanceiro = result.filter(admin => (admin.nomeRole === "Gestor Financeiro"))
                    setGestorFin(gestorFinanceiro)
                    const diretorDepartamento = result.filter(admin => (admin.nomeRole === "Administrador - Diretor de Departamento"))
                    setDiretDep(diretorDepartamento)
                    const diretorExec = result.filter(admin => (admin.nomeRole === "Administrador - Gestor de Operação" || admin.nomeRole === "Administrador - Geral" || admin.nomeRole === "Entidade de Ligação" || admin.nomeRole === "Administrador - Diretor de Departamento"))
                    setDiretExec(diretorExec)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"manageOperacoesAdmin"} />
                </div>
                <div className="flex-grow mt-8">
                    <Link to={'/menuadmin/' + params.id_candidatura} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <h1 className="title">Gerir Operações</h1>
                    <div className="flex flex-col mt-2 w-full">
                        <div className="flex flex-col items-center">
                            <h2 className="subtitle">Lista de Operações</h2>
                            {data && data.length > 0 ? <>
                                <div className="flex justify-center items-center mb-1">
                                    <label className="text-text-main-color">Dados por página: </label>
                                    <select onWheel={(e) => e.target.blur()} className="selector w-16 ml-1"
                                        value={itemsPag}
                                        onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                                <div className="table-container">
                                    <table className="my-table">

                                        <thead className="bg-main-color">
                                            <tr className="text-white h-14">
                                                <th className="p-2 min-w-[80px] border border-white">Código da operação</th>
                                                <th className="p-2 min-w-[150px] border border-white">Nome da Operação</th>
                                                <th className="p-2 min-w-[150px] border border-white">Tipologia</th>
                                                <th className="p-2 min-w-[150px] border border-white">Gestor de Operação</th>
                                                <th className="p-2 min-w-[150px] border border-white">Gestor Financeiro</th>
                                                <th className="p-2 min-w-[150px] border border-white">Diretor de Departamento</th>
                                                <th className="p-2 min-w-[150px] border border-white">Diretor Executivo</th>
                                                <th className="p-2 min-w-[100px] border border-white">Entidade Formadora</th>
                                                <th className="p-2 min-w-[100px] border border-white">Entidade Financiadora</th>
                                                <th className="p-2 min-w-[80px] border border-white">Remover</th>
                                            </tr>
                                        </thead>
                                        {data.length > 0 ? <tbody className="text-text-main-color">
                                            {dataToDisplay.map((item, index) => (
                                                <tr key={index} className="row-click" onClick={(e) => handleClick(e, item)}>
                                                    {alterar === index + 1 ? (
                                                        <td className="operacao">
                                                            <input
                                                                className="input-field"
                                                                placeholder={item.codigoOperacao}
                                                                defaultValue={item.codigoOperacao}
                                                                id={"codigoOperacao" + index}
                                                                type="text"
                                                            />
                                                        </td>
                                                    ) : (
                                                        <td className="border operacao border-white">{item.codigoOperacao}</td>
                                                    )}
                                                    {alterar === index + 1 ? (
                                                        <td className="operacao">
                                                            <input
                                                                className="input-field"
                                                                placeholder={item.nomeTipologia}
                                                                defaultValue={item.nomeTipologia}
                                                                id={"nomeOperacao" + index}
                                                                type="text"
                                                            />
                                                        </td>
                                                    ) : (
                                                        <td className="border operacao border-white">{item.nomeTipologia}</td>
                                                    )}
                                                    {alterar === index + 1 ? (
                                                        <td className="operacao">
                                                            <select className="selector" defaultValue={data.tipologia && data.tipologia} type="text" id={"tipologia" + index} >
                                                                <option value="" disabled>Selecione uma tipologia</option>
                                                                <option value="Cursos de Educação e Formação de Adultos">Cursos de Educação e Formação de Adultos</option>
                                                                <option value="Formação Modular Certificada">Formação Modular Certificada</option>
                                                                <option value="Português Língua de Acolhimento (PLA)">Português Língua de Acolhimento (PLA)</option>
                                                                <option value="Formação Não Financiada">Formação Não Financiada</option>
                                                                <option value="Cursos de Especialização Tecnológica">Cursos de Especialização Tecnológica</option>
                                                                <option value="Cursos Aprendizagem">Cursos Aprendizagem</option>
                                                                <option value="Programa Trabalhos & Competências Verdes / Green Skills & Jobs, Emprego + Digital">Programa Trabalhos & Competências Verdes / Green Skills & Jobs, Emprego + Digital</option>
                                                                <option value="Projetos Locais Promotores de Qualificações de Nível B1/B2/B3">Projetos Locais Promotores de Qualificações de Nível B1/B2/B3</option>
                                                                <option value="Formação Empresarial Conjunta e Formação-ação">Formação Empresarial Conjunta e Formação-ação</option>
                                                                <option value="Projetos Conjuntos de Formação Autónoma">Projetos Conjuntos de Formação Autónoma</option>
                                                                <option value="Centros Qualifica">Centros Qualifica</option>
                                                            </select>
                                                        </td>
                                                    ) : (
                                                        <td className="border operacao border-white">{item.tipologia && item.tipologia}</td>
                                                    )}
                                                    <td className="operacao">
                                                        {item.gestorOperacao && item.gestorOperacao.length > 0 && modal !== "gestorOperacao - " + index ? <>{item.gestorOperacao[0].fullName}
                                                            <button className="mx-2 button-geral " onClick={() => setModal("gestorOperacao - " + index)}>
                                                                <BsFillPencilFill />
                                                            </button>
                                                            <button className="mx-2 button-geral " onClick={() => addAdmin(item._id, "", null, null, null)}>
                                                                <IoMdRemoveCircle />
                                                            </button></> :
                                                            modal === "gestorOperacao - " + index ? <><select className="selector" defaultValue={admin ? admin : item.gestorOperacao.length > 0 ? item.gestorOperacao[0]._id : ""} onChange={(e) => setAdmin(e.target.value)}>
                                                                <option disabled value="">Selecione um Gestor de Operação</option>
                                                                {gestorOpe.map((adm, index) => (
                                                                    <option key={index} value={adm._id}>{adm.fullName}</option>
                                                                ))}
                                                            </select>
                                                                <button className="mx-2 button-geral " onClick={() => { addAdmin(item._id, admin, null, null, null) }}> Confimar </button> </>
                                                                : <button className="mx-2 button-geral " onClick={() => setModal("gestorOperacao - " + index)}> Adicionar </button>}
                                                    </td>
                                                    <td className="operacao">{item.gestorFinanceiro && item.gestorFinanceiro.length > 0 && modal !== "gestorFinanceiro - " + index ? <>{item.gestorFinanceiro[0].fullName}
                                                        <button className="mx-2 button-geral " onClick={() => setModal("gestorFinanceiro - " + index)}>
                                                            <BsFillPencilFill />
                                                        </button>
                                                        <button className="mx-2 button-geral " onClick={() => addAdmin(item._id, null, "", null, null)}>
                                                            <IoMdRemoveCircle />
                                                        </button></> :
                                                        modal === "gestorFinanceiro - " + index ? <><select className="selector" defaultValue={admin ? admin : item.gestorFinanceiro.length > 0 ? item.gestorFinanceiro[0]._id : ""} onChange={(e) => setAdmin(e.target.value)}>
                                                            <option disabled value="">Selecione um Gestor Financeiro</option>
                                                            {gestorFin.map((adm, index) => (
                                                                <option key={index} value={adm._id}>{adm.fullName}</option>
                                                            ))}
                                                        </select>
                                                            <button className="mx-2 button-geral " onClick={() => { addAdmin(item._id, null, admin, null, null) }}> Confimar </button> </>
                                                            : <button className="mx-2 button-geral " onClick={() => setModal("gestorFinanceiro - " + index)}> Adicionar </button>}
                                                    </td>
                                                    <td className="operacao">
                                                        {item.diretorDepartamento && item.diretorDepartamento.length > 0 && modal !== "diretorDepartamento - " + index ? <>{item.diretorDepartamento[0].fullName}
                                                            <button className="mx-2 button-geral " onClick={() => setModal("diretorDepartamento - " + index)}>
                                                                <BsFillPencilFill />
                                                            </button>
                                                            <button className="mx-2 button-geral " onClick={() => addAdmin(item._id, null, null, null, "")}>
                                                                <IoMdRemoveCircle />
                                                            </button></> :
                                                            modal === "diretorDepartamento - " + index ? <><select className="selector" defaultValue={admin ? admin : item.diretorDepartamento.length > 0 ? item.diretorDepartamento[0]._id : ""} onChange={(e) => setAdmin(e.target.value)}>
                                                                <option disabled value="">Selecione um Diretor de Departamento</option>
                                                                {diretDep.map((adm, index) => (
                                                                    <option key={index} value={adm._id}>{adm.fullName}</option>
                                                                ))}
                                                            </select>
                                                                <button className="mx-2 button-geral " onClick={() => { addAdmin(item._id, null, null, null, admin) }}> Confimar </button> </>
                                                                : <button className="mx-2 button-geral " onClick={() => setModal("diretorDepartamento - " + index)}> Adicionar </button>}
                                                    </td>
                                                    <td className="operacao">
                                                        {item.diretorExecutivo && item.diretorExecutivo.length > 0 && modal !== "diretorExecutivo - " + index ? <>{item.diretorExecutivo[0].fullName}
                                                            <button className="mx-2 button-geral " onClick={() => setModal("diretorExecutivo - " + index)}>
                                                                <BsFillPencilFill />
                                                            </button>
                                                            <button className="mx-2 button-geral " onClick={() => addAdmin(item._id, null, null, "", null)}>
                                                                <IoMdRemoveCircle />
                                                            </button></> :
                                                            modal === "diretorExecutivo - " + index ? <><select className="selector" defaultValue={admin ? admin : item.diretorExecutivo.length > 0 ? item.diretorExecutivo[0]._id : ""} onChange={(e) => setAdmin(e.target.value)}>
                                                                <option disabled value="">Selecione um Diretor Executivo</option>
                                                                {diretExec.map((adm, index) => (
                                                                    <option key={index} value={adm._id}>{adm.fullName}</option>
                                                                ))}
                                                            </select>
                                                                <button className="mx-2 button-geral " onClick={() => { addAdmin(item._id, null, null, admin, null,) }}> Confimar </button> </>
                                                                : <button className="mx-2 button-geral " onClick={() => setModal("diretorExecutivo - " + index)}> Adicionar </button>}
                                                    </td>
                                                    <td className="operacao">
                                                        {item.entFormadora && item.entFormadora.length > 0 && modal !== "entFormadora - " + index ? <>{item.entFormadora[0].name}
                                                            <button className="mx-2 button-geral " onClick={() => setModal("entFormadora - " + index)}>
                                                                <BsFillPencilFill />
                                                            </button>
                                                            <button className="mx-2 button-geral " onClick={() => addAdmin(item._id, null, null, null, null, "", null)}>
                                                                <IoMdRemoveCircle />
                                                            </button></> :
                                                            modal === "entFormadora - " + index ? <><select className="selector" defaultValue={admin ? admin : item.entFormadora.length > 0 ? item.entFormadora[0]._id : ""} onChange={(e) => setAdmin(e.target.value)}>
                                                                <option disabled value="">Selecione uma Entidade Formadora</option>
                                                                {entFormadoraList.map((adm, index) => (
                                                                    <option key={index} value={adm._id}>{adm.name}</option>
                                                                ))}
                                                            </select>
                                                                <button className="mx-2 button-geral " onClick={() => { addAdmin(item._id, null, null, null, null, admin, null) }}> Confimar </button> </>
                                                                : <button className="mx-2 button-geral " onClick={() => setModal("entFormadora - " + index)}> Adicionar </button>}
                                                    </td>
                                                    <td className="operacao">
                                                        {item.entFinanciadora && item.entFinanciadora.length > 0 && modal !== "entFinanciadora - " + index ? <>{item.entFinanciadora[0].name}
                                                            <button className="mx-2 button-geral " onClick={() => setModal("entFinanciadora - " + index)}>
                                                                <BsFillPencilFill />
                                                            </button>
                                                            <button className="mx-2 button-geral " onClick={() => addAdmin(item._id, null, null, null, null, null, "")}>
                                                                <IoMdRemoveCircle />
                                                            </button></> :
                                                            modal === "entFinanciadora - " + index ? <><select className="selector" defaultValue={admin ? admin : item.entFinanciadora.length > 0 ? item.entFinanciadora[0]._id : ""} onChange={(e) => setAdmin(e.target.value)}>
                                                                <option disabled value="">Selecione uma Entidade Financiadora</option>
                                                                {entFinanciadoraList.map((adm, index) => (
                                                                    <option key={index} value={adm._id}>{adm.name}</option>
                                                                ))}
                                                            </select>
                                                                <button className="mx-2 button-geral " onClick={() => { addAdmin(item._id, null, null, null, null, null, admin) }}> Confimar </button> </>
                                                                : <button className="mx-2 button-geral " onClick={() => setModal("entFinanciadora - " + index)}> Adicionar </button>}
                                                    </td>
                                                    {alterar === index + 1 ? (
                                                        <td className="border operacao border-white">
                                                            <div className="flex items-center">
                                                                <button className="mr-2 button-cancelar" onClick={() => setAlterar(0)}>
                                                                    Cancelar
                                                                </button>
                                                                <ConfirmToaster
                                                                    icon={"Confirmar"}
                                                                    css={"button-confirmar hover:brightness-75"}
                                                                    title={"Alterar Operação"}
                                                                    message={"Deseja realizar as alterações?"}
                                                                    confirm={() => changeOperacao(item._id, index)}
                                                                ></ConfirmToaster>
                                                            </div>
                                                        </td>
                                                    ) : (
                                                        <td className="border operacao border-white">
                                                            <div className="flex items-center justify-center">
                                                                {item.nomeTipologia === "Não Financiada" ? (
                                                                    <button className="button-disabled" disabled>
                                                                        <IoMdRemoveCircle />
                                                                    </button>
                                                                ) : (
                                                                    <ConfirmToaster
                                                                        css={"button-geral hover:brightness-75"}
                                                                        icon={<IoMdRemoveCircle />}
                                                                        title={"Eliminar"}
                                                                        message={"Deseja eliminar a operação ? (Esta ação é irreversível)"}
                                                                        confirm={() => deleteNome(item._id)}
                                                                    ></ConfirmToaster>
                                                                )}
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody> : <tbody />}
                                    </table>
                                </div>
                                <div className="flex items-center justify-center mt-4 mb-8">
                                    <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                        <AiOutlineDoubleLeft />
                                    </button>
                                    <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                        <AiOutlineLeft />
                                    </button>
                                    <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                                    <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                        <AiOutlineRight />
                                    </button>
                                    <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                        <AiOutlineDoubleRight />
                                    </button>
                                </div> </> : <p className="third-title">Não existem dados</p>}
                            <div>
                                <h2 className="subtitle">Adicionar Operação</h2>
                                <div className="grid mt-4 sm:grid-cols-2 gap-2">
                                    <div className="flex flex-col w-full">
                                        <label className="text-left label-input">Nome da Tipologia:</label>
                                        <input type="text" id="nameTipologia" name="nameTipologia" className="selector w-full" required />
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <label className="text-left label-input">Código:</label>
                                        <input type="text" id="codigoOperacao" name="codigoOperacao" className="selector w-full" required />
                                    </div>
                                    <div className="flex flex-col mt-2 w-full">
                                        <label className="text-left label-input">Tipologia:</label>
                                        <select className="selector w-full" defaultValue={""} id="tipologia">
                                            <option value="" disabled>Selecione uma tipologia</option>
                                            <option value="Cursos de Educação e Formação de Adultos">Cursos de Educação e Formação de Adultos</option>
                                            <option value="Formação Modular Certificada">Formação Modular Certificada</option>
                                            <option value="Português Língua de Acolhimento (PLA)">Português Língua de Acolhimento (PLA)</option>
                                            <option value="Formação Não Financiada">Formação Não Financiada</option>
                                            <option value="Cursos de Especialização Tecnológica">Cursos de Especialização Tecnológica</option>
                                            <option value="Cursos Aprendizagem">Cursos Aprendizagem</option>
                                            <option value="Programa Trabalhos & Competências Verdes / Green Skills & Jobs, Emprego + Digital">Programa Trabalhos & Competências Verdes / Green Skills & Jobs, Emprego + Digital</option>
                                            <option value="Projetos Locais Promotores de Qualificações de Nível B1/B2/B3">Projetos Locais Promotores de Qualificações de Nível B1/B2/B3</option>
                                            <option value="Formação Empresarial Conjunta e Formação-ação">Formação Empresarial Conjunta e Formação-ação</option>
                                            <option value="Projetos Conjuntos de Formação Autónoma">Projetos Conjuntos de Formação Autónoma</option>
                                            <option value="Centros Qualifica">Centros Qualifica</option>
                                        </select>
                                    </div>
                                    {data && <>
                                        <div className="flex flex-col mt-2">
                                            <label className="text-left label-input">Gestor de Operação:</label>
                                            <select className="selector" defaultValue={""} id="gestorOpe">
                                                <option disabled value="">Selecione um Gestor de Operação</option>
                                                {gestorOpe.map((adm, index) => (
                                                    <option key={index} value={adm._id}>{adm.fullName}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="flex flex-col mt-2 w-full">
                                            <label className="text-left label-input">Gestor Financeiro:</label>
                                            <select className="selector" defaultValue={""} id="gestorFin">
                                                <option disabled value="">Selecione um Gestor Financeiro</option>
                                                {gestorFin.map((adm, index) => (
                                                    <option key={index} value={adm._id}>{adm.fullName}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <label className="text-left label-input">Diretor de Departamento:</label>
                                            <select className="selector" defaultValue={""} id="diretorDep">
                                                <option disabled value="">Selecione um Diretor de Departamento</option>
                                                {diretDep.map((adm, index) => (
                                                    <option key={index} value={adm._id}>{adm.fullName}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="flex flex-col mt-2 w-full">
                                            <label className="text-left label-input">Diretor Executivo:</label>
                                            <select className="selector" defaultValue={""} id="diretorExec">
                                                <option disabled value="">Selecione um Diretor Executivo</option>
                                                {diretExec.map((adm, index) => (
                                                    <option key={index} value={adm._id}>{adm.fullName}</option>
                                                ))}
                                            </select></div>
                                        <div className="flex flex-col mt-2">
                                            <label className="text-left label-input">Entidade Formadora:</label>
                                            <select className="selector" defaultValue={""} id="entForm">
                                                <option disabled value="">Selecione uma Entidade Formadora</option>
                                                {entFormadoraList && entFormadoraList.length>0 &&entFormadoraList.map((adm, index) => (
                                                    <option key={index} value={adm._id}>{adm.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <label className="text-left label-input">Entidade Financiadora:</label>
                                            <select className="selector" defaultValue={""} id="entFinanc">
                                                <option disabled value="">Selecione uma Entidade Financiadora</option>
                                                {entFinanciadoraList && entFinanciadoraList.length>0 &&entFinanciadoraList.map((adm, index) => (
                                                    <option key={index} value={adm._id}>{adm.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </>}
                                </div>
                                <button className="my-4 button-geral" onClick={submeterNomeOp} type="button">Submeter</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>

            </main>
        </>
    );
}

export default Operacoes;
