import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import axios from 'axios';
import AlterarFormato from '../../FormChecks/formatDates/foramatDates';
export default async function TesteTemplate(userData, ufcd) {
    // Imagens
    // Inicio Excel
    const workbook = new ExcelJS.Workbook();

    const imageUrlEntFormador = userData.entFormadora[0].logotipo;
    const imageBufferEFor = await axios.get(imageUrlEntFormador, { responseType: 'arraybuffer' });

    // Create a function to load and add the image
    async function loadImageAndAddToWorksheet(worksheet) {
        // Create a new Image object
        const img = new Image();

        // Create a promise to resolve when the image has loaded
        return new Promise((resolve, reject) => {
            img.onload = function () {
                const originalWidth = img.width;
                const originalHeight = img.height;

                // Calculate the scaling factor based on the desired width (e.g., 150)

                const scalingFactor = originalWidth / originalHeight;

                // Calculate the scaled height
                const scaledHeight = 50;
                const desiredWidth = scalingFactor * 50;

                const imageIdEFor = workbook.addImage({
                    buffer: imageBufferEFor.data,
                    extension: 'png',
                });

                worksheet.addImage(imageIdEFor, {
                    tl: { col: 0.5, row: 0.4 },
                    ext: { width: desiredWidth, height: scaledHeight },
                });

                // Resolve the promise
                resolve();
            };

            // Set the source of the image to your image file
            img.src = imageUrlEntFormador;
        });
    }
    async function loadImage2AndAddToWorksheet(worksheet, imageBuffer, imageUrlEntFinanciadora, position) {
        // Create a new Image object
        const img = new Image();

        // Create a promise to resolve when the image has loaded
        return new Promise((resolve, reject) => {
            img.onload = function () {
                const originalWidth = img.width;
                const originalHeight = img.height;


                const scalingFactor = originalWidth / originalHeight;

                // Calculate the scaled height
                const scaledHeight = 50;
                const desiredWidth = scalingFactor * 50;


                const imageId2 = workbook.addImage({
                    buffer: imageBuffer.data,
                    extension: 'png',
                });
                worksheet.addImage(imageId2, {
                    tl: { col: 20, row: position },
                    ext: { width: desiredWidth, height: scaledHeight },
                });

                // Resolve the promise
                resolve();
            };

            // Set the source of the image to your image file
            img.src = imageUrlEntFinanciadora;
        });
    }


    // Variaveis de estilização
    const alignment = {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true,
    };
    const bold = { bold: true };
    const borderThin = {
        top: { style: 'thin' },    // Top border
        bottom: { style: 'thin' }, // Bottom border
        left: { style: 'thin' },   // Left border
        right: { style: 'thin' },  // Right border
    };
    const title = { bold: true, name: "DejaVu Sans", size: 14 };

    // Tratar das cores
    function applyFillToCell(worksheet, cells) {
        const color = cells[0];
        cells.border = borderThin;
        cells.slice(1).forEach(cell => {
            worksheet.getCell(cell).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: color },
            };
        });
    }

    //INICIO DA PÁGINA CRONOGRAMA
    async function pagina1(pag) {
        const worksheet = workbook.addWorksheet('Teste pag1');
        // Call the function to load and add the image
        await loadImageAndAddToWorksheet(worksheet);

        if (userData.entFinanciadora.length > 0) {
            if (userData.entFinanciadora[0].logotipo1) {
                const imageUrlEntFinanciadora = userData.entFinanciadora[0].logotipo1;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });
                await loadImage2AndAddToWorksheet(worksheet, imageBuffer, imageUrlEntFinanciadora, 0.4);
            }
            if (userData.entFinanciadora[0].logotipo2) {
                const imageUrlEntFinanciadora = userData.entFinanciadora[0].logotipo2;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });
                await loadImage2AndAddToWorksheet(worksheet, imageBuffer, imageUrlEntFinanciadora, 82);
            }

        }
        async function cabecalho() {

            // Celulas de texto - Cabeçalho
            const A1 = worksheet.getCell('A1');
            const A2 = worksheet.getCell('A2');
            const A3 = worksheet.getCell('A3');
            const A4 = worksheet.getCell('A4');
            const A5 = worksheet.getCell('A5');
            const A6 = worksheet.getCell('A6');
            const A7 = worksheet.getCell('A7');
            const A8 = worksheet.getCell('A8');
            const A10 = worksheet.getCell('A10');
            const A11 = worksheet.getCell('A11');
            const A12 = worksheet.getCell('A12');

            const D2 = worksheet.getCell('D2');
            const D3 = worksheet.getCell('D3');
            const D4 = worksheet.getCell('D4');
            const D5 = worksheet.getCell('D5');
            const D6 = worksheet.getCell('D6');
            const D11 = worksheet.getCell('D11');
            const D12 = worksheet.getCell('D12');

            const E7 = worksheet.getCell('E7');
            const E8 = worksheet.getCell('E8');
            const E10 = worksheet.getCell('E10');

            const L3 = worksheet.getCell('L3');
            const L4 = worksheet.getCell('L4');
            const L10 = worksheet.getCell('L10');
            const L11 = worksheet.getCell('L11');

            const I7 = worksheet.getCell('I7');
            const I8 = worksheet.getCell('I8');
            const I10 = worksheet.getCell('I10');

            const P3 = worksheet.getCell('P3');
            const P4 = worksheet.getCell('P4');

            const M7 = worksheet.getCell('M7');
            const M8 = worksheet.getCell('M8');
            const M10 = worksheet.getCell('M10');

            const Q7 = worksheet.getCell('Q7');
            const Q8 = worksheet.getCell('Q8');
            const Q10 = worksheet.getCell('Q10');

            const U7 = worksheet.getCell('U7');
            const U8 = worksheet.getCell('U8');
            const U10 = worksheet.getCell('U10');


            // Valor das Celulas
            A1.value = 'Enunciado Teste/Trabalho/Atividade';
            A2.value = 'Entidade Formadora:'; D2.value = userData.entFormadora[0].name
            A3.value = 'Tipologia de Operação:'; D3.value = userData.operacao[0].nomeTipologia; L3.value = "Modalidade de Formação:"; P3.value = userData.modalidade
            A4.value = 'Operação:'; D4.value = userData.operacao[0].codigoOperacao; L4.value = "Código e Área de Formação:"; P4.value = userData.areaCodigoFormacao
            A5.value = 'Curso/Percurso:'; D5.value = userData.name;
            A6.value = 'UFCD/Disciplina:'; D6.value = ufcd.codeUfcd + " - " + ufcd.name;
            A7.value = 'Código Administrativo (em SIGO):'; E7.value = 'Código Interno:'; I7.value = 'Nível:'; M7.value = 'Carga Horária:'; Q7.value = 'N.º Curso (em SIIFSE):'; U7.value = 'N.º Ação (em SIIFSE):';
            A8.value = userData.codeSIGO; E8.value = userData.codeInterno; I8.value = userData.nivel; M8.value = userData.duration; Q8.value = userData.cursoN; U8.value = userData.acaoN;;
            A10.value = "Data de Início:"; E10.value = AlterarFormato(userData.dateBegin); I10.value = "Data de Fim:"; M10.value = AlterarFormato(userData.dateEnd); Q10.value = 'Horário:'; U10.value = userData.horario;

            A11.value = 'Local de Formação:'; D11.value = userData.locality;
            A12.value = 'Formador/a:'; D12.value = ufcd.formadores.map((item) => item.fullName).join(', ');

            // Estutura - Cabeçalho 
            worksheet.mergeCells('A1:X1');
            worksheet.mergeCells('A2:C2'); worksheet.mergeCells('D2:X2');
            worksheet.mergeCells('A3:C3'); worksheet.mergeCells('D3:K3'); worksheet.mergeCells('L3:O3'); worksheet.mergeCells('P3:X3');
            worksheet.mergeCells('A4:C4'); worksheet.mergeCells('D4:K4'); worksheet.mergeCells('L4:O4'); worksheet.mergeCells('P4:X4');
            worksheet.mergeCells('A5:C5'); worksheet.mergeCells('D5:X5');
            worksheet.mergeCells('A6:C6'); worksheet.mergeCells('D6:X6');
            worksheet.mergeCells('A7:D7'); worksheet.mergeCells('E7:H7'); worksheet.mergeCells('I7:L7'); worksheet.mergeCells('M7:P7'); worksheet.mergeCells('Q7:T7'); worksheet.mergeCells('U7:X7');
            worksheet.mergeCells('A8:D9'); worksheet.mergeCells('E8:H9'); worksheet.mergeCells('I8:L9'); worksheet.mergeCells('M8:P9'); worksheet.mergeCells('Q8:T9'); worksheet.mergeCells('U8:X9');
            worksheet.mergeCells('A10:D10'); worksheet.mergeCells('E10:H10'); worksheet.mergeCells('I10:L10'); worksheet.mergeCells('M10:P10'); worksheet.mergeCells('Q10:T10'); worksheet.mergeCells('U10:X10');
            worksheet.mergeCells('A11:C11'); worksheet.mergeCells('D11:X11');
            worksheet.mergeCells('A12:C12'); worksheet.mergeCells('D12:X12');

            // Centrar
            A1.alignment = alignment
            A2.alignment = alignment
            A3.alignment = alignment
            A4.alignment = alignment
            A5.alignment = alignment
            A6.alignment = alignment
            A7.alignment = alignment
            A8.alignment = alignment
            A10.alignment = alignment
            A11.alignment = alignment
            A12.alignment = alignment

            D2.alignment = alignment
            D3.alignment = alignment
            D4.alignment = alignment
            D5.alignment = alignment
            D6.alignment = alignment
            D11.alignment = alignment
            D12.alignment = alignment

            E7.alignment = alignment
            E8.alignment = alignment
            E10.alignment = alignment

            L3.alignment = alignment
            L4.alignment = alignment
            L10.alignment = alignment
            L11.alignment = alignment

            I7.alignment = alignment
            I8.alignment = alignment
            I10.alignment = alignment

            P3.alignment = alignment
            P4.alignment = alignment

            M7.alignment = alignment
            M8.alignment = alignment
            M10.alignment = alignment

            Q7.alignment = alignment
            Q8.alignment = alignment
            Q10.alignment = alignment

            U7.alignment = alignment
            U8.alignment = alignment
            U10.alignment = alignment


            // Negrito
            A1.font = title
            A2.font = bold
            A3.font = bold
            A4.font = bold
            A5.font = bold
            A6.font = bold
            A7.font = bold
            A10.font = bold
            A11.font = bold
            A12.font = bold

            E7.font = bold

            L3.font = bold
            L4.font = bold
            L10.font = bold

            I7.font = bold
            I10.font = bold

            M7.font = bold

            Q7.font = bold
            Q10.font = bold

            U7.font = bold

            // Collumn Width
            worksheet.getColumn('A').width = 12
            worksheet.getColumn('B').width = 8
            for (let columnNumber = 3; columnNumber <= 39; columnNumber++) {
                worksheet.getColumn(columnNumber).width = 6.5;
            }
            // Row height
            worksheet.getRow('1').height = 50
            worksheet.getRow('6').height = 35

            // Estilização - Cabeçalho
            const startCell = worksheet.getCell('A1');
            const endCell = worksheet.getCell('X12');

            worksheet.eachRow({ from: startCell.row, to: endCell.row }, (row) => {
                row.eachCell((cell) => {
                    cell.border = borderThin
                });
            });
            const cells = ['b2b2b2', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A10', 'A11', 'A12', 'E7', 'I7', 'M7', 'Q7', 'U7', 'V3', 'V4', 'I10', 'Q10'];

            //Palete de Cores
            applyFillToCell(worksheet, cells);
        }

        async function corpo() {
            const A14 = worksheet.getCell('A14');
            const A15 = worksheet.getCell('A15');

            A14.font = bold;
            A14.alignment = alignment;
            A14.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'b2b2b2' },
            };
            worksheet.mergeCells('A14:X14');
            worksheet.mergeCells('A15:X80');

            A14.border = borderThin
            A15.border = borderThin
            A14.value = "Enunciado do Teste Sumativo";
        }

        async function footer() {
            const S82 = worksheet.getCell('S82');
            worksheet.mergeCells('S82:X82');
            S82.font = bold
            S82.alignment = alignment
            S82.value = "Página " + pag + "de 4"
        }
        await cabecalho()
        await corpo()
        await footer()
    }
    async function pagina2(pag) {
        const worksheet = workbook.addWorksheet('Teste pag2');
        // Call the function to load and add the image
        await loadImageAndAddToWorksheet(worksheet);

        if (userData.entFinanciadora.length > 0) {
            if (userData.entFinanciadora[0].logotipo1) {
                const imageUrlEntFinanciadora = userData.entFinanciadora[0].logotipo1;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });
                await loadImage2AndAddToWorksheet(worksheet, imageBuffer, imageUrlEntFinanciadora, 0.4);
            }
            if (userData.entFinanciadora[0].logotipo2) {
                const imageUrlEntFinanciadora = userData.entFinanciadora[0].logotipo2;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });
                await loadImage2AndAddToWorksheet(worksheet, imageBuffer, imageUrlEntFinanciadora, 82);
            }

        }
        async function cabecalho() {

            // Celulas de texto - Cabeçalho
            const A1 = worksheet.getCell('A1');


            // Valor das Celulas
            A1.value = 'Enunciado Teste/Trabalho/Atividade';

            // Estutura - Cabeçalho 
            worksheet.mergeCells('A1:X1');

            // Centrar
            A1.alignment = alignment

            // Negrito
            A1.font = title

            // Collumn Width
            worksheet.getColumn('A').width = 12
            worksheet.getColumn('B').width = 8
            for (let columnNumber = 3; columnNumber <= 39; columnNumber++) {
                worksheet.getColumn(columnNumber).width = 6.5;
            }
            // Row height
            worksheet.getRow('1').height = 50

            // Estilização - Cabeçalho
            A1.border = borderThin
            //Palete de Cores
        }

        async function corpo() {
            const A3 = worksheet.getCell('A3');
            const A4 = worksheet.getCell('A4');
            A3.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'b2b2b2' },
            };
            A3.font = bold;
            A3.alignment = alignment;

            worksheet.mergeCells('A3:X3');
            worksheet.mergeCells('A4:X80');

            A3.border = borderThin
            A4.border = borderThin
            A3.value = "Enunciado do Teste Sumativo (Cont)";
        }
        async function footer() {
            const S82 = worksheet.getCell('S82');
            worksheet.mergeCells('S82:X82');
            S82.font = bold
            S82.alignment = alignment
            S82.value = "Página " + pag + " de 4"
        }
        await cabecalho();
        await corpo();
        await footer();
    }
    async function pagina3(pag) {
        const worksheet = workbook.addWorksheet('Teste pag3');
        // Call the function to load and add the image
        await loadImageAndAddToWorksheet(worksheet);

        if (userData.entFinanciadora.length > 0) {
            if (userData.entFinanciadora[0].logotipo1) {
                const imageUrlEntFinanciadora = userData.entFinanciadora[0].logotipo1;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });
                await loadImage2AndAddToWorksheet(worksheet, imageBuffer, imageUrlEntFinanciadora, 0.4);
            }
            if (userData.entFinanciadora[0].logotipo2) {
                const imageUrlEntFinanciadora = userData.entFinanciadora[0].logotipo2;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });
                await loadImage2AndAddToWorksheet(worksheet, imageBuffer, imageUrlEntFinanciadora, 82);
            }
        }
        async function cabecalho() {

            // Celulas de texto - Cabeçalho
            const A1 = worksheet.getCell('A1');


            // Valor das Celulas
            A1.value = 'Enunciado Teste/Trabalho/Atividade';

            // Estutura - Cabeçalho 
            worksheet.mergeCells('A1:X1');

            // Centrar
            A1.alignment = alignment

            // Negrito
            A1.font = title

            // Collumn Width
            worksheet.getColumn('A').width = 12
            worksheet.getColumn('B').width = 8
            for (let columnNumber = 3; columnNumber <= 39; columnNumber++) {
                worksheet.getColumn(columnNumber).width = 6.5;
            }
            // Row height
            worksheet.getRow('1').height = 50

            // Estilização - Cabeçalho
            A1.border = borderThin
            //Palete de Cores
        }

        async function corpo() {
            const A3 = worksheet.getCell('A3');
            const A4 = worksheet.getCell('A4');
            A3.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'b2b2b2' },
            };
            A3.font = bold;
            A3.alignment = alignment;

            worksheet.mergeCells('A3:X3');
            worksheet.mergeCells('A4:X80');

            A3.border = borderThin
            A4.border = borderThin
            A3.value = "Enunciado do Teste Sumativo (Cont)";
        }
        async function footer() {
            const S82 = worksheet.getCell('S82');
            worksheet.mergeCells('S82:X82');
            S82.font = bold
            S82.alignment = alignment
            S82.value = "Página " + pag + " de 4"
        }
        await cabecalho();
        await corpo();
        await footer();
    }
    async function pagina4(pag) {
        const worksheet = workbook.addWorksheet('Teste pag4');
        // Call the function to load and add the image
        await loadImageAndAddToWorksheet(worksheet);

        if (userData.entFinanciadora.length > 0) {
            if (userData.entFinanciadora[0].logotipo1) {
                const imageUrlEntFinanciadora = userData.entFinanciadora[0].logotipo1;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });
                await loadImage2AndAddToWorksheet(worksheet, imageBuffer, imageUrlEntFinanciadora, 0.4);
            }
            if (userData.entFinanciadora[0].logotipo2) {
                const imageUrlEntFinanciadora = userData.entFinanciadora[0].logotipo2;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });
                await loadImage2AndAddToWorksheet(worksheet, imageBuffer, imageUrlEntFinanciadora, 82);
            }
        }
        async function cabecalho() {

            // Celulas de texto - Cabeçalho
            const A1 = worksheet.getCell('A1');


            // Valor das Celulas
            A1.value = 'Enunciado Teste/Trabalho/Atividade';

            // Estutura - Cabeçalho 
            worksheet.mergeCells('A1:X1');

            // Centrar
            A1.alignment = alignment

            // Negrito
            A1.font = title

            // Collumn Width
            worksheet.getColumn('A').width = 12
            worksheet.getColumn('B').width = 8
            for (let columnNumber = 3; columnNumber <= 39; columnNumber++) {
                worksheet.getColumn(columnNumber).width = 6.5;
            }
            // Row height
            worksheet.getRow('1').height = 50

            // Estilização - Cabeçalho
            A1.border = borderThin
            //Palete de Cores
        }

        async function corpo() {
            const A3 = worksheet.getCell('A3');
            const A4 = worksheet.getCell('A4');
            A3.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'b2b2b2' },
            };
            A3.font = bold;
            A3.alignment = alignment;

            worksheet.mergeCells('A3:X3');
            worksheet.mergeCells('A4:X80');

            A3.border = borderThin
            A4.border = borderThin
            A3.value = "Enunciado do Teste Sumativo (Cont)";
        }
        async function footer() {
            const S82 = worksheet.getCell('S82');
            worksheet.mergeCells('S82:X82');
            S82.font = bold
            S82.alignment = alignment
            S82.value = "Página " + pag + " de 4"
        }
        await cabecalho();
        await corpo();
        await footer();
    }
    await pagina1(1)
    await pagina2(2)
    await pagina3(3)
    await pagina4(4)
    // Generate the Excel file
    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Convert the buffer to a Blob
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    // Save the Blob to the user's computer
    FileSaver.saveAs(blob, 'Enunciado Teste/Trabalho/Atividade' + userData.name + '.xlsx');
}