import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Component toastError for error fetching data from the database
export default function toastErrorBD(error) {
  toast.error(error, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 5000,
    hideProgressBar: true,
    style: {
      textAlign: "center",
    },
  });
}
