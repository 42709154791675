import React, { useState } from "react";
import { ContratoFormando } from "../Modal/stylesContratos/styledFormando";
import AlterarFormato from "../FormChecks/formatDates/foramatDates";
import toastError from "../Toast/toastError";
import toastSuccess from "../Toast/toastSuccess";
import refreshPage from "../Refresh";
import ConfirmToaster from "../Toast/toastConfirmChoice";

export default function ContratoFormandoEFAComenius({ data, token, setOpen }) {
  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [valores, setValores] = useState({
    date: AlterarFormato(data?.percurso[0]?.dateBegin) ?? "",
    local: data?.percurso[0]?.locality ?? "",
  });

  // Function to send the contract by email
  async function enviarContratoFormando() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingEnvio(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formandos/contrato`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: data._id,
            percurso: data.percurso[0]._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        setOpen(false);
        setLoadingEnvio(false);
        toastError("Erro! O contrato não foi enviado");
        return;
      }

      setOpen(false);
      setLoadingEnvio(false);
      toastSuccess("Contrato enviado com sucesso");
      localStorage.setItem("contractUpdated", "true");

      setTimeout(() => {
        refreshPage();
      }, 1000);

      const result = await response.json();
    } catch (error) {
      console.error("Erro ao enviar contrato:", error);
      setOpen(false);
      toastError("Erro ao enviar contrato");
    }
  }

  // Auxiliary function to download the file
  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Opens the link in a new tab/window
    link.setAttribute("download", ""); // Optional, sets the download attribute to trigger download in some browsers
    link.click();
  }

  // Function to download the contract
  async function enviarContratoFormandoNoEmail() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingDownload(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formandos/contratoSemEmail`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: data._id,
            percurso: data.percurso[0]._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao iniciar download do contrato");
      }

      const result = await response.json();
      downloadFile(result);
      setOpen(false);
      setLoadingDownload(false);
      refreshPage();
    } catch (error) {
      console.error("Erro ao iniciar download do contrato:", error);
      setOpen(false);
      setLoadingDownload(false);
      toastError("Erro ao iniciar download do contrato");
    }
  }

  // State to handle input values
  const [inputValues, setInputValues] = React.useState({
    inputDate: valores?.date ?? "",
    inputLocal: valores?.local ?? "",
  });

  // State to handle input errors
  const [inputErrors, setInputErrors] = React.useState({
    inputDate: false,
    inputLocal: false,
  });

  // Handle input change and pass the value to the parent component by invoking the handleInputChange callback
  const handleInputChangeLocal = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
    setInputErrors({
      ...inputErrors,
      [name]: false,
    });
  };

  // Validate all inputs and return true if all of them are valid
  const validateInputs = () => {
    const errors = {};
    let isValid = true;

    for (const key in inputValues) {
      if (inputValues[key].trim() === "") {
        errors[key] = true;
        isValid = false;
      }
    }
    setInputErrors(errors);
    return isValid;
  };

  // Style on inputs is for validation purposes, mark the input with red border if it's empty and black if it's not
  // On inputs, onChange event is used to handle the input change locally and in the parent component
  return (
    <>
      {loadingEnvio ? (
        <div className="text-center text-2xl">
          A enviar contrato, por favor aguarde...
        </div>
      ) : loadingDownload ? (
        <div className="text-center text-2xl">
          A fazer download do contrato, por favor aguarde...
        </div>
      ) : (
        <>
          <ContratoFormando>
            <div className="body">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <img
                    src={data.percurso[0].entFormadora[0].logotipo}
                    className="h-20"
                    alt="logo"
                    style={{ marginLeft: "20px" }}
                  />
                </div>
                <div></div>
                <div>
                  {data.percurso[0].entFinanciadora &&
                    data.percurso[0].entFinanciadora.length > 0 &&
                    data.percurso[0].entFinanciadora[0].logotipo1 && (
                      <img
                        src={data.percurso[0].entFinanciadora[0].logotipo1}
                        className="h-20"
                        alt="logo"
                        style={{ marginLeft: "20px" }}
                      />
                    )}
                </div>
              </div>

              <div className="page1">
                <div className="content">
                  <div className="mt-4">
                    <br />
                    <h1>
                      <b>
                        Contrato de prestação de serviços de formação a
                        formandos - CURSOS EFA
                      </b>
                    </h1>
                    <br />
                    <br />
                    <p>
                      Entre:
                      <br />
                      <b>{data.percurso[0].entFormadora[0].name}</b>, com sede
                      social em {data.percurso[0].entFormadora[0].morada},{" "}
                      {data.percurso[0].entFormadora[0].postalCode}{" "}
                      {data.percurso[0].entFormadora[0].distrito}, com o
                      Contribuinte nº {data.percurso[0].entFormadora[0].nif},
                      legítimo utilizador da marca comercial "ACADEMIA
                      COMENIUS", aqui representada por{" "}
                      {data.percurso[0].entFormadora[0].representanteLegal},
                      adiante designada por <b>Primeiro Outorgante</b>, e
                    </p>
                    <table className="dados">
                      <tr>
                        <th>Nome Completo</th>
                        <td>{data.fullName}</td>
                      </tr>
                      <tr>
                        <th>NIF</th>
                        <td>{data.nif}</td>
                      </tr>
                      <tr>
                        <th>Cartão de Cidadão/Passaporte</th>
                        <td>{data.idNumber}</td>
                      </tr>
                      <tr>
                        <th>Morada</th>
                        <td>{data.address}</td>
                      </tr>
                      <tr>
                        <th>Código Postal</th>
                        <td>{data.postalCode}</td>
                      </tr>
                    </table>
                    <p>
                      , adiante designada por <b>Segundo Outorgante</b>, é
                      celebrado o presente contrato de prestação de serviços, o
                      qual se rege pelo disposto nas seguintes cláusulas:
                    </p>
                  </div>
                  <div>
                    <br />
                    <h2>
                      Cláusula 1ª - Objeto do Contrato, local e cronograma
                    </h2>
                    <br />
                    <p>
                      1. O <b>Primeiro Outorgante</b> obriga-se a proporcionar
                      ao <b>Segundo Outorgante</b>, que aceita, o seguinte curso
                      EFA:
                    </p>
                    <table className="dados">
                      <tr>
                        <th>Nome do curso EFA:</th>
                        <td>{data.percurso[0].name}</td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <p>
                      2. O curso em causa é uma Ação de Formação desenvolvida no
                      âmbito da seguinte operação a cargo do{" "}
                      <b>Primeiro Outorgante</b>:
                    </p>
                    <table className="projeto">
                      <tr>
                        <th>Nº do Projeto:</th>
                        {data.percurso[0].entFinanciadora &&
                        data.percurso[0].entFinanciadora.length > 0 ? (
                          <td>
                            {data.percurso[0].operacao[0] &&
                              data.percurso[0].operacao[0].codigoOperacao}
                          </td>
                        ) : (
                          <td>N/A</td>
                        )}
                      </tr>
                    </table>
                  </div>
                  <div>
                    <p>
                      3. Esta ação de formação desenvolve-se na modalidade de
                      CURSOS EFA - Profissional, de acordo com a legislação e
                      demais documentos normativos em vigor.
                    </p>
                  </div>
                  <div>
                    <p>
                      4. Sempre que o Curso integre uma componente de formação
                      prática em contexto de trabalho, esta é realizada numa
                      empresa, designada por entidade enquadradora da formação
                      prática em contexto de trabalho.
                    </p>
                  </div>
                  <div>
                    <p>
                      5. Quando a formação decorre (total ou parcialmente) no
                      formato presencial, o local de realização do curso é o
                      seguinte:
                    </p>
                    <table className="dados">
                      <tr>
                        <th>Local:</th>
                        <td>
                          {data.percurso[0].type !== "Online"
                            ? data.percurso[0].locality
                            : "(Online)"}
                        </td>
                      </tr>
                      <tr>
                        <th>Morada:</th>
                        <td>
                          {data.percurso[0].type !== "Online"
                            ? data.percurso[0].address
                            : "(Online)"}
                        </td>
                      </tr>
                      <tr>
                        <th>Código de Postal:</th>
                        <td>
                          {data.percurso[0].type !== "Online"
                            ? data.percurso[0].postalCode
                            : "(Online)"}
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div>
                    <p>
                      6. Quando a formação decorre (total ou parcialmente) no
                      formato á distância, o curso realiza-se através da
                      seguinte plataforma:
                    </p>

                    <table className="dados">
                      <tr>
                        <th>Plataforma LMS:</th>
                        <td>
                          {data.percurso[0].moodle &&
                          data.percurso[0].moodle.length > 0 ? (
                            <a href={data.percurso[0].moodle[0].name}>
                              {data.percurso[0].moodle[0].name}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <p>
                      7. O cronograma da formação é facultado no início da Ação
                      de Formação, sendo que está compreendido entre as
                      seguintes datas:
                    </p>
                    <table className="dados">
                      <tr>
                        <th>Data de início prevista:</th>
                        <td>{AlterarFormato(data.percurso[0].dateBegin)}</td>
                      </tr>
                      <tr>
                        <th>Data de fim prevista:</th>
                        <td>{AlterarFormato(data.percurso[0].dateEnd)}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <br />
                  <h2>Cláusula 2ª - Direitos dos/as formandos/as</h2>
                  <br />
                  <p>
                    São direitos do <b>Segundo Outorgante</b>:
                  </p>
                  <ol>
                    <li>
                      1. Receber a formação com base nos referenciais de
                      formação, nas metodologias e processos de trabalho,
                      aplicados à respetiva saída profissional no respeito pelas
                      condições de higiene e segurança no trabalho, exigidos
                      pela legislação em vigor:
                    </li>
                    <ol className="tipoa" type="a">
                      <li>
                        Bolsa de formação — 1,15€/hora de formação frequentada;
                      </li>
                      <li>
                        Reembolso das despesas de transporte até ao limite de
                        15% do IAS — 66,48 €/mês – contra apresentação de
                        comprovativos de despesa válidos;
                      </li>
                      <li>
                        Subsídio de alimentação — 4,77€/dia (quando o número de
                        horas de formação diárias for igual ou superior a 3
                        horas);
                      </li>
                      <li>
                        Reembolso de outras despesas (se aplicável e previstas
                        na regulamentação).
                      </li>
                      <li>
                        O somatório dos apoios atribuídos a título de reembolso
                        de despesa, bolsa e subsídios não pode ultrapassar o
                        valor de 75% do IAS.
                      </li>
                    </ol>
                    <li>
                      2. No caso de formandos/as ativos empregados, não serão
                      atribuídos os apoios referidos na alínea anterior pela
                      frequência de formação;
                    </li>
                    <li>
                      3. No caso de formandos/as estrangeiros com titulo de
                      residência temporária, aplica-se o disposto da alínea
                      anterior e também não serão atribuídos os apoios
                      referidos;
                    </li>
                    <li>
                      4. Em caso de desempregado, beneficiar de um seguro contra
                      acidentes ocorridos durante e por causa das atividades de
                      formação, quando estas decorram total ou parcialmente em
                      formato presencial;
                    </li>
                    <li>
                      5. Obter gratuitamente, no final da ação de formação um
                      Certificado de Qualificações e/ou um Diploma e ver
                      registadas, no Passaporte Qualifica, as respetivas
                      competências adquiridas e certificadas, nos termos da
                      legislação em vigor;
                    </li>
                    <li>
                      6. Receber informação, orientação profissional e apoio
                      social no decurso da ação de formação;
                    </li>
                    <li>
                      7. Recusar a realização de atividades que não se insiram
                      no objeto do curso.
                    </li>
                  </ol>
                </div>
                <br />
                <h2>Cláusula 3ª - Deveres gerais dos/as formandos/as</h2>
                <br />
                <p>
                  São deveres do <b>Segundo Outorgante</b>:
                </p>
                <ol>
                  <li>
                    1. Tratar com urbanidade o <b>Primeiro Outorgante</b> e seus
                    representantes.
                  </li>
                  <li>
                    2. Frequentar com assiduidade e pontualidade todas as
                    componentes do Curso EFA, de acordo com as regras constantes
                    nos regulamentos de formação em vigor, nomeadamente o
                    Regulamento da Formação à Distância ({" "}
                    <a href="https://drive.google.com/drive/folders/1GBgijvoBmp04CpA6dvtPEgeCgP6KmeiB?usp=sharing">
                      https://drive.google.com/drive/folders/1GBgijvoBmp04CpA6dvtPEgeCgP6KmeiB?usp=sharing
                    </a>
                    ), quando esta decorre nesse formato.
                  </li>
                  <li>
                    3. Realizar as provas de avaliação de acordo com os métodos
                    e critérios constantes nos regulamentos da Ação de Formação.
                  </li>
                  <li>
                    4. Abster-se da prática de qualquer ato que possa causar
                    prejuízo ou descrédito do <b>Primeiro Outorgante</b>
                  </li>
                  <li>
                    5. Sempre que se aplique, zelar pela conservação e boa
                    utilização dos bens e instalações do{" "}
                    <b>Primeiro Outorgante</b> e entidades associadas ao
                    projeto, na Ação de Formação em causa.
                  </li>
                  <li>
                    6. Suportar os custos de substituição ou reparação dos
                    equipamentos e materiais fornecidos pelo
                    <b>Primeiro Outorgante</b>, ou seus representantes, sempre
                    que danificados por via de comportamento doloso ou
                    gravemente negligente.
                  </li>
                  <li>
                    7. O registo e qualquer inscrição é individual e
                    intransmissível devendo apenas ser utilizada pelo/
                    próprio/a.
                  </li>
                  <li>
                    8. Na sequência do ponto 7, sempre que o formando esteja
                    inscrito noutro Curso EFA, com ou sem financiamentos
                    públicos, deve informar o Primeiro Outorgante, sendo que não
                    pode obter apoios sociais (previstos no ponto 2 da{" "}
                    <b>Cláusula 2ª</b>) contabilizados diariamente ou
                    horariamente em duplicado. Caso sejam identificadas
                    situações correspondentes às aqui mencionadas, em que há
                    apoios sociais recebidos em duplicado, o formando obriga-se
                    a devolver todos os apoios recebidos até essa data.
                  </li>
                  <li>
                    9. Na sequência do ponto anterior, sempre que a formação
                    decorra no formato a distância, refere-se o seguinte:
                  </li>
                  <ol className="tipoa" type="a">
                    <li>
                      O Login (“Nome de Utilizador”) e a Password (“Senha de
                      Acesso”) apenas podem ser utilizados pelo formando/a.
                    </li>
                    <li>
                      Todos os materiais, vídeos, textos, imagens e outros
                      conteúdos disponibilizados ao/à formando/ destinam-se
                      única e exclusivamente ao usufruto do/a próprio/a e não
                      podem ser entregues a qualquer outro indivíduo ou
                      entidade.
                    </li>
                    <li>
                      O/a formando/a reconhece que todos os seus acessos e
                      movimentos dentro da <b>Plataforma LMS</b>
                      (referida na Cláusula 1ª) são registados e que os mesmos
                      serão usados para efeitos de verificação de assiduidade.
                    </li>
                    <li>
                      O/a formando/a terá acesso aos conteúdos disponibilizados
                      na <b>Plataforma LMS</b> (referida na Cláusula 1ª) durante
                      o tempo determinado no cronograma da Ação de Formação em
                      que se inscreveu.
                    </li>
                    <li>
                      Após o término da Ação de Formação, na data definida e
                      comunicada no cronograma, o/a formando/a terá o prazo de 1
                      mês para continuar a aceder aos conteúdos disponibilizados
                      na <b>Plataforma LMS</b>
                      (referida na Cláusula 1a).
                    </li>
                    <li>
                      Para que o/a formando/a possa aceder aos conteúdos da Ação
                      de Formação em causa, deve garantir que tem os recursos
                      técnicos necessários, tais como computador, ligação à{" "}
                      <i>Internet</i>, e a aplicação Google ZOOM (
                      <a href="https://zoom.us">https://zoom.us/</a>).
                    </li>
                  </ol>
                </ol>
              </div>
              <br />
              <h2>Cláusula 4ª - Deveres da entidade formadora</h2>
              <br />
              <p>
                São deveres do <b>Primeiro Outorgante</b>:
              </p>
              <ol>
                <li>
                  1. Assegurar a formação programada com respeito pela
                  legislação e regulamentação em vigor, pelas condições de
                  aprovação do Curso EFA.
                </li>
                <li>
                  2. Prestar o apoio técnico-pedagógico necessário e adequado à
                  implementação da formação prática em contexto de trabalho;
                </li>
                <li>
                  3. Proceder, em concordância com a entidade enquadradora da
                  formação prática em contexto de trabalho, ao planeamento,
                  organização e controlo dessa componente do Curso EFA
                  nomeadamente através da elaboração do Plano Individual de
                  Atividades a realizar em contexto de trabalho;
                </li>
                <li>
                  4. Proceder, em consonância com a entidade enquadradora da
                  formação prática em contexto de trabalho, ao acompanhamento
                  técnico-pedagógico dos/as formandos/as durante o período em
                  que decorre esta componente de formação;
                </li>
                <li>
                  5. Facultar ao/às formandos/as o acesso aos benefícios e
                  equipamentos sociais que sejam compatíveis com esta componente
                  de formação.
                </li>
                <li>
                  6.Respeitar e fazer respeitar as condições de higiene e
                  segurança no trabalho;
                </li>
                <li>
                  7. Não exigir ao formando tarefas não compreendidas no objeto
                  do Curso EFA em causa.
                </li>
              </ol>
              <br />
              <h2>
                Cláusula 5ª - Assiduidade nas componentes de formação presencial
                ou a distância, quando síncronas
              </h2>
              <br />
              <ol>
                <li>
                  1. O conceito de “Presença” ou a “Falta” a uma sessão,
                  aplica-se apenas à formação presencial ou formação à distância
                  quando estas decorrem no formato síncrono, isto é, não se
                  aplica à formação à distância quando esta decorre no formato
                  assíncrono.
                </li>
                <li>
                  2. A “Presença” ou a “Falta” a uma sessão será registada
                  através da rubrica, ou não, da Folha de Presenças/Sumários (ou
                  outro método previsto pelo organismo financiador).
                </li>
                <li>
                  3. Entende-se por “Falta”, para efeitos do presente Contrato:
                </li>
                <ol className="tipoa" type="a">
                  <li>
                    A não comparência a uma sessão de formação presencial ou
                    formação a distância, quando esta decorre no formato
                    síncrona, na data e horário previsto no cronograma (ou
                    agendada pelo/a formador/a).
                  </li>
                  <li>
                    A comparência do/a formando/a a uma sessão de formação
                    presencial ou formação a distância, quando no formato
                    síncrona, com atraso superior a 15 minutos, é considerada
                    “Falta”.
                  </li>
                </ol>
                <li>
                  4. Especificamente nas componentes de formação a distância
                  síncrona, que se realizam no formato de videoconferência, o
                  formado obriga-se a estar com a câmara e microfone ligado, a
                  fim de poder intervir sempre que solicitado pelo/a formador/a,
                  assim como autoriza a gravação dessas sessões para partilha e
                  re-visionamento posterior, na plataforma do curso, apenas para
                  fins de aprendizagem ou auditoria.
                </li>
                <li>5. As faltas podem ser justificadas ou injustificadas.</li>
                <li>
                  6. São consideradas faltas justificadas, mediante apresentação
                  de comprovativo, as faltas da seguinte natureza:
                </li>
                <ol className="tipoa" type="a">
                  <li>Doença ou Acidente;</li>
                  <li>Falecimento de parentes próximos ou afins;</li>
                  <li>Casamento;</li>
                  <li>Maternidade ou paternidade;</li>
                  <li>Doença ou Acidente de familiar a cargo;</li>
                  <li>
                    Qualquer dever imposto por lei, que não admita adiamento;
                  </li>
                  <li>
                    Outros motivos comprovados e aceites pela Entidade
                    Formadora.
                  </li>
                </ol>
                <li>
                  7. As faltas justificadas, quando previsíveis devem ser
                  comunicadas à Coordenação Pedagógica, com a antecedência
                  mínima de dois dias.
                </li>
              </ol>
              <div>
                <br />
                <h2>
                  Cláusula 6ª - Assiduidade nas componentes de formação á
                  distância, quando assíncronas
                </h2>
                <br />
                <ol>
                  <li>
                    1. A assiduidade da componente à distância assíncrona é
                    verificada através da “carga de trabalho” e tempo de
                    trabalho registado pela <b>Plataforma LMS</b> (referida na
                    Cláusula 1ª) onde decorre a formação, sendo que corresponde
                    à realização (ou não) das atividades previstas no Roteiro
                    Pedagógico de cada uma das UFCD que fazem parte do Percurso
                    de Formação em causa, as quais são disponibilizadas na{" "}
                    <b>Plataforma LMS</b> (referida na Cláusula 1ª) e que
                    incluem:
                  </li>

                  <ol className="tipoa" type="a">
                    <li>
                      O visionamento e estudo dos materiais pedagógicos,
                      incluindo vídeo aulas, documentação de suporte, atividades
                      e outros materiais disponibilizados.
                    </li>
                    <li>
                      A realização das atividades previstas na plataforma e
                      outras tarefas determinadas pelo/a formador/a.
                    </li>
                  </ol>
                  <li>
                    2. Nas componentes da formação desenvolvida no formato à
                    distância, componente assíncrona, o/a formando/a está
                    obrigado à realização de 90% das atividades pedagógicas
                    previstas no Roteiro Pedagógico de cada uma das UFCD que
                    fazem parte do Curso EFA, independentemente do que de
                    seguida será referido na Cláusula 7ª.
                  </li>
                  <li>
                    3. O Roteiro Pedagógico da UFCD tem uma carga horária
                    associada a cada conteúdo, mas a assiduidade não está
                    diretamente relacionada com o cumprimento de um horário ou
                    um tempo de trabalho dedicado à atividade, mas sim à sua
                    realização com zelo e interesse de cada uma das componentes
                    previstas no Roteiro Pedagógico.
                  </li>
                  <li>
                    4. No entanto, para cálculo dos apoios sociais previstos no
                    ponto 2 da <b>Cláusula 2ª</b> do presente contrato, será
                    utilizada a informação sobre o tempo que efetivamente o
                    formando/a utilizou a plataforma para a realização das
                    atividades (isto face ao texto da Portaria 60-A de 2015 que
                    referem o “tempo” como medida de cálculo destes apoios).
                  </li>
                  <li>
                    5. Ainda a respeito dos apoios sociais previstos no ponto 2
                    da <b>Cláusula 2ª</b> do presente contrato, o nº de horas
                    máximo que pode ser atribuído ao formando não pode
                    ultrapassar as horas previstas para cada UFCD
                    individualmente, assim como os dias de formação previstos na
                    sua execução.
                  </li>
                  <li>
                    6. O controlo da realização ou não das atividades previstas
                    nos Roteiros Pedagógicos das UFCD do Curso EFA é efetuado de
                    forma automática por meio da <b>Plataforma LMS</b> (referida
                    na Cláusula 1ª).
                  </li>
                  <li>
                    7. A utilização de sistemas informatizados, designados bot
                    (robot informáticos) é automaticamente detetado pela
                    plataforma e gera um não registo desse tempo, podendo, em
                    causo de tentativa de utilização sistemática e abusiva,
                    gerar um processo de averiguação da conduta do Segundo
                    Outorgante, podendo essa conduta ser alvo de punição de
                    acordo com a gravidade da situação.
                  </li>
                </ol>
              </div>
              <div>
                <br />
                <h2>Cláusula 7ª - Aproveitamento</h2>
                <br />
                <ol>
                  <li>
                    1. O Segundo Outorgante está obrigado à frequência de pelo
                    menos 90% do total das horas presencias ou síncronas e/ou da
                    carga de trabalho assíncrona da totalidade do Curso EFA e,
                    cumulativamente, a 50 % da carga horária/carga de trabalho
                    de cada UFCD.
                  </li>
                  <li>
                    2. Durante o Curso EFA e no seu termo, o/a formando/a será
                    submetido/a a um processo de avaliação sumativa a cada uma
                    das suas componentes, UFCD a UFCD, que irá determinar o
                    aproveitamento, ou não, do Segundo Outorgante.
                  </li>
                  <li>
                    3. Caso o formando não cumpra o disposto no nº 1 e nº 2 da
                    presente <b>Cláusula</b>, à luz da Portaria nº 86/2022, para
                    alcançar os resultados mínimos de aprendizagem, o Primeiro
                    Outorgante deve desenvolver os mecanismos de recuperação
                    necessários ao cumprimento dos objetivos inicialmente
                    definidos, nomeadamente, disponibilizando os Materiais
                    Pedagógicos em formato assíncrono em momentos diferentes dos
                    inicialmente previstos no cronograma e a possibilidade de
                    realização das avaliações sumativas noutros momentos para
                    que alcance os resultados de aprendizagem mínimos.
                  </li>
                  <li>
                    4. Caso conclua, com aproveitamento, parte do Curso EFA,
                    correspondente a um qualquer percurso formativo (conjunto de
                    UFCD) terá direito a um Certificado de Qualificações com a
                    menção no Passaporte Qualifica do percurso formativo
                    aprovado.
                  </li>
                  <li>
                    5. Caso conclua, com aproveitamento, um Curso EFA de dupla
                    certificação, um Curso EFA de habilitação escolar (3º ciclo
                    do ensino básico ou ensino secundário) ou quando a
                    componente de formação tecnológica (por já ser detentor de
                    habilitação escolar) terá direito a um Diploma respeitante à
                    qualificação obtida.
                  </li>
                </ol>
              </div>
              <div>
                <br />
                <h2>Cláusula 8ª - Alteração do Cronograma</h2>
                <br />
                <ol>
                  <li>
                    1. Quando, por razões alheias à sua vontade e a si não
                    imputáveis, o <b>Primeiro Outorgante</b> não puder cumprir
                    integralmente o Percurso/Cronograma de Formação previsto,
                    poderá proceder aos necessários ajustamentos, devendo sempre
                    comunicar tal facto ao <b>Segundo Outorgante</b>.
                  </li>
                  <li>
                    2. A alteração do Percurso/Cronograma de Formação pelos
                    motivos referidos no número anterior, não confere ao{" "}
                    <b>Segundo Outorgante</b> direito a qualquer indemnização.
                  </li>
                </ol>
              </div>
              <div>
                <br />
                <h2>Cláusula 9ª - Cessação do contrato</h2>
                <br />
                <ol>
                  <li>
                    1. O contrato pode cessar por revogação, por rescisão de uma
                    das partes ou por caducidade.
                  </li>
                  <li>
                    2. A rescisão por justa causa, por qualquer das partes, tem
                    que ser comunicada à outra, por documento escrito ou carta
                    registada, devendo dela constar o(s) respetivo(s) motivo(s).
                  </li>
                  <li>
                    3. O contrato de formação caduca quando se verificar a
                    impossibilidade superveniente, absoluta e definitiva, do
                    Segundo Outorgante frequentar a ação de formação ou de o
                    Primeiro Outorgante lha proporcionar.
                  </li>
                </ol>
              </div>
              <div>
                <br />
                <h2>Cláusula 10ª — Incumprimento</h2>
                <br />
                <ol>
                  <li>
                    1. Verificando-se o incumprimento pelo{" "}
                    <b>Segundo Outorgante</b> de qualquer das obrigações
                    assumidas no presente contrato, o <b>Primeiro Outorgante</b>{" "}
                    poderá proceder à rescisão imediata do presente contrato,
                    sem direito a qualquer indemnização ou compensação
                    pecuniária.
                  </li>
                  <li>
                    2. Os/as formandos/as que não tenham concluído a formação
                    por motivo de faltas relacionadas com a maternidade,
                    paternidade ou assistência à família, têm prioridade no
                    acesso a Ações de Formação que se iniciem imediatamente após
                    o termo do impedimento.
                  </li>
                </ol>
              </div>
              <div style={{ breakInside: "avoid" }}>
                <br />
                <h2>Cláusula 11ª — Legislação aplicável</h2>
                <br />
                <ol>
                  <p>
                    O presente Contrato de Formação regula-se nos termos do
                    disposto na Portaria nº 60-A/2015, para efeitos de
                    Co-financiamento pelo Fundo Social Europeu (FSE).
                  </p>
                  <br />
                  <p>
                    O presente contrato é feito em duplicado fincando cada uma
                    das partes na posse de um exemplar.
                  </p>
                </ol>
                <table className="localData">
                  <tr>
                    <th>Local:</th>
                    <td>
                      <input
                        className="w-full input-field"
                        type="text"
                        name="inputLocal"
                        defaultValue={valores.local}
                        placeholder="ex: Porto"
                        onChange={(e) => {
                          setValores({ ...valores, local: e.target.value });
                          handleInputChangeLocal(e);
                        }}
                        style={{
                          border: inputErrors.inputLocal
                            ? "3px solid red"
                            : "1px solid black",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Data:</th>
                    <td>
                      <input
                        className="w-full input-field"
                        type="text"
                        name="inputDate"
                        defaultValue={valores.date}
                        placeholder="12/12/2023"
                        onChange={(e) => {
                          setValores({
                            ...valores,
                            date: AlterarFormato(e.target.value),
                          });
                          handleInputChangeLocal(e);
                        }}
                        style={{
                          border: inputErrors.inputDate
                            ? "3px solid red"
                            : "1px solid black",
                        }}
                      />
                    </td>
                  </tr>
                </table>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      O PRIMEIRO OUTORGANTE
                    </h4>
                    <div
                      style={{
                        minHeight: "200px",
                        borderBottom: "1px solid black !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        alt="assinatura"
                        style={{ margin: "5px auto" }}
                        id="img"
                        className="img"
                        src={data.percurso[0].entFormadora[0].assinatura}
                        height="70"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      O SEGUNDO OUTORGANTE
                    </h4>
                    <div
                      style={{
                        minHeight: "200px",
                        borderBottom: "1px solid black !important",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              {data.percurso[0].entFinanciadora &&
                data.percurso[0].entFinanciadora.length > 0 &&
                data.percurso[0].entFinanciadora[0].logotipo2 && (
                  <div>
                    <img
                      src={data.percurso[0].entFinanciadora[0].logotipo2}
                      className="h-20"
                      alt="logo"
                      style={{ marginLeft: "20px" }}
                    />
                  </div>
                )}
            </div>
            <div className="flex justify-center w-full">
              <ConfirmToaster
                css={"mx-auto mt-4 button-geral"}
                icon={"Enviar Contrato"}
                title={"Enviar Contrato"}
                message={"Você confirma que o número do contrato está correto?"}
                confirm={enviarContratoFormando}
              />
              <ConfirmToaster
                css={"mx-auto mt-4 button-geral"}
                icon={"Download Contrato"}
                title={"Download Contrato"}
                message={"Você confirma que o número do contrato está correto?"}
                confirm={enviarContratoFormandoNoEmail}
              />
            </div>
          </ContratoFormando>
          {errorMessage && (
            <div className="text-red-500 text-center mt-4">{errorMessage}</div>
          )}
        </>
      )}
    </>
  );
}
