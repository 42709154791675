import ExcelJS from 'exceljs';
import DownloadFile from '../../DownloadFile/downloadFile';

async function ExcelAvaliacaoFormandos(id, token) {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${id._id}/RelatorioFormandos`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
        })
        .then(data => {
            DownloadFile(data);
        })
    }

export default ExcelAvaliacaoFormandos;
