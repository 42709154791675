import React, { useState, useRef, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { useNavigate, useParams } from "react-router-dom"
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Modal from 'react-modal'
import 'react-toastify/dist/ReactToastify.css';
import toastError from "../../../components/Toast/toastError";
import containsPostalCode from "../../../components/FormChecks/containsPostalCode/containsPostalCode";
import onlyString from "../../../components/FormChecks/onlyString/onlyString";
import CountryDropdown from "../../../components/FormChecks/countryDropdown/countryDropdown";
import check_nif_number from "../../../components/FormChecks/checkNif/checkNIf";
import toastSuccess from "../../../components/Toast/toastSuccess";
import isNumber from "../../../components/isNumber/isNumber";
import { RiErrorWarningLine } from "react-icons/ri";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CapitalizarNomes from "../../../components/capitalizeNames/capitalizeNames";
Modal.setAppElement('#root');


const CandidaturaFormando = () => {
    const navigate = useNavigate()
    let Next = process.env.PUBLIC_URL + "/Next.svg"
    //Passos do formulario
    const steps = [
        "DADOS PESSOAIS",
        "EMAIL",
        "NACIONALIDADE",
        "RESIDÊNCIA",
        "IDENTIFICAÇÃO",
        "HABILITAÇÕES ACADÉMICAS",
        "SITUAÇÃO PROFISSIONAL",
        "UTILIZAÇÃO DADOS PESSOAIS",
        "CENTRO QUALIFICA",
        "DOCUMENTOS"
    ];

    const [currentStep, setCurrentStep] = useState(0);
    const [numDoc, setNumDoc] = useState([]);
    const [outrosDocs, setOutrosDocs] = useState([])
    const [checkStep, setCheckStep] = useState({ section1: false, section2: false, section3: false, section4: false, section5: false, section6: false, section7: false, section8: false, section9: false })
    const [section, setSection] = useState(1);
    const ref = useRef();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [entFormadora, setEntFormadora] = useState("")
    const [loading, setLoading] = useState(false)
    //Data atual
    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    //Filtros data
    const datemin = new Date(0, 0, 1)
    const dateMax = new Date(year - 18, month, day)
    const dateCcMax = new Date(year + 10, month, day)
    const dateToday = new Date(year, month, day)
    const params = useParams();
    //Dados guardados
    // Secção - 1 - Dados Pessoais
    const [fullName, setFullName] = useState("")
    const [gender, setGender] = useState("")
    const [birthDate, setBirthDate] = useState("")
    const [cellNumber, setCellNumber] = useState("")

    //Secção - 2 - Dados Registo
    const [email, setEmail] = useState("")

    //Secção - 3  - Dados Naturalidade
    const [parishD, setParishD] = useState("")
    const [parishN, setParishN] = useState("")
    const [nationality, setNationality] = useState("")

    //Secção - 4 -  Dados Residência
    const [county, setCounty] = useState("")
    const [district, setDistrict] = useState("")
    const [locality, setLocality] = useState("")
    const [address, setAddress] = useState("")
    const [postalCode, setPostalCode] = useState("")

    //Secção - 5 -  Dados Identificativos
    const [documentType, setDocumentType] = useState("")
    const [idNumber, setIdNumber] = useState("")
    const [idExpiration, setIdExpiration] = useState("")
    const [nif, setNif] = useState("")
    const [niss, setNiss] = useState("")

    //Secção - 6 - Dados Académicos
    const [CQEP, setCQEP] = useState("")
    const [origemInscricao, setOrigemInscricao] = useState("")
    const [originCQEP, setOriginCQEP] = useState("")
    const [academicDegree, setAcademicDegree] = useState("")
    const [employmentSituation, setEmploymentSituation] = useState("")

    //Secção - 7 - Situação Laboral
    const [job, setJob] = useState("")
    const [nifEmployer, setNifEmployer] = useState("")
    const [nameEmployer, setNameEmployer] = useState("")

    const [employmentSituationDate, setEmploymentSituationDate] = useState("")
    const [beneficiarySS, setBeneficiarySS] = useState("")

    //Secção - 8 - Autorização Dados Pessoais
    const [dataAuthorization, setDataAuthorization] = useState(false)
    const [dataAuthorization2, setDataAuthorization2] = useState(false)

    //Secção - 9 - Centro Qualifica
    const [centroQualifica, setCentroQualifica] = useState("")

    //Secção - 10 - Seleção Curso
    const percurso = params.id_curso
    const [percursoName, setPercursoName] = useState("")
    const [percursoDateEndCandidacies, setPercursoDateEndCandidacies] = useState("")
    //Verificar cc
    function check_pt_cc_number(numero) {
        // Verificar se o número fornecido é uma string com 8 dígitos
        if (typeof numero !== 'string' || numero.length !== 8 || isNaN(numero)) {
            return false;
        } else {
            return true;
        }
    }
    useEffect(() => {
        getPercurso()
    }, [section]);
    const handleNameChange = (e, index) => {
        const updatedOutrosDocs = [...outrosDocs];
        if (!updatedOutrosDocs[index]) {
            updatedOutrosDocs[index] = {
                name: '',
                file: null
            };
        }
        updatedOutrosDocs[index].name = e.target.value;
        setOutrosDocs(updatedOutrosDocs);
    };

    const handleFileChange = (e, index) => {
        const updatedOutrosDocs = [...outrosDocs];
        updatedOutrosDocs[index].file = e.target.files[0];
        setOutrosDocs(updatedOutrosDocs);
    };
    //Obtem percurso
    function getPercurso() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`)
            .then((res) => res.json())
            .then(
                (result) => {
                    setPercursoName(result.name)
                    setEntFormadora(result.entFormadora[0].name)
                    setPercursoDateEndCandidacies(result.dateEndCandidacies)
                },
                (error) => {
                    console.error("Error fetching data: ", error);

                }
            );
    }

    //Proxima secção
    function sectionNext(step) {
        setSection(step);
    }
    //Secção anterior
    function sectionBack(step) {
        setSection(step);
    }
    //Animação fade
    function fade(step) {
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeInLeft')
            document.querySelector('#main-form').classList.remove('fadeInRight')
            document.querySelector('#main-form').classList.add('fadeOutLeft')
        }, 0);
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeOutLeft')
            sectionNext(step)
            document.querySelector('#main-form').classList.add('fadeInRight')
        }, 500);
    }

    //Animação fade reverse
    function fadeInRight(step) {
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeInRight')
            document.querySelector('#main-form').classList.remove('fadeInLeft')
            document.querySelector('#main-form').classList.add('fadeOutRight')
        }, 0);
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeOutRight')
            sectionBack(step)
            document.querySelector('#main-form').classList.add('fadeInLeft')
        }, 500);
    }

    //Função para mudar a cor caso ja tenha preenchido esse step
    const MultiStepForm = ({ steps, currentStep }) => {

        const percentage = (100 * (currentStep + 1)) / steps.length

        /* Animação do react spring */
        const styles = useSpring({
            from: {
                "--value": 0,
            },
            to: {
                "--value": percentage,
            },
        });

        return (
            <>
                <div className='items-center justify-center hidden xl:flex'>
                    {steps.map((step, index) => (
                        <div key={index} onClick={() => { handleNext(index + 1) }} className='flex items-center justify-center' >
                            {index == 8 && dataAuthorization2 == true ? "" : <><p className={index === currentStep ? 'step-active' : checkStep[`section${index + 1}`] == true ? 'step-done' : 'step-inactive'}>{step}</p>
                                {index !== steps.length - 1 && <i className="m-1 text-gray-600 fas fa-caret-right"></i>}</>}
                        </div>
                    ))}
                </div>

                <div className="flex flex-col items-center justify-center sm:flex-row xl:hidden">{/* Componente abaixo é deste site https://daisyui.com/components/radial-progress/  */}
                    {/* Componente deste bibilioteca https://www.npmjs.com/package/react-spring */}
                    <animated.div className="border radial-progress bg-secundary-color border-text-main-color text-main-color" style={styles}>
                        {currentStep + 1} de {steps.length}
                    </animated.div>
                    <div className="flex flex-col items-center justify-center sm:ml-10 md:ml-32">
                        <div className="text-text-second-color">{steps[currentStep]}</div>
                        {steps[currentStep + 1] ? <div className="text-text-second-color">Próximo: {steps[currentStep + 1]}</div> : ""}
                    </div>
                </div>
            </>
        );
    };

    //Higienização e Continuação
    const handleNext = (newStep) => {
        if (section === 1) {
            if (fullName !== "" && birthDate !== "" && cellNumber !== "") {
                birthDate.replace("-", ',')
                const datenow = new Date(birthDate)
                if (datenow.getTime() > dateMax.getTime()) {
                    toastError("Data incorreta");
                } else if (datenow.getTime() < dateMax.getTime())
                    if (datenow.getTime() > datemin.getTime()) {
                        if (isNumber(cellNumber)) {
                            if (cellNumber.length > 13 || cellNumber.length < 9 || cellNumber < 0) {
                                toastError("Nº de telemóvel inválido  ou com caracteres inválidos")
                                checkStep.section1 = false
                            }
                            else {
                                checkStep.section1 = true
                                if (!newStep) {
                                    fade(section + 1)
                                } else {
                                    if (section < newStep) {
                                        if (checkStep[`section${newStep - 1}`] == true) {
                                            fade(newStep)
                                        } else toastError("Preencha todas as informações necessárias antes de avançar.")
                                    } else if (section > newStep) {
                                        fadeInRight(newStep)
                                    }
                                }
                            }
                        } else {
                            toastError("Nº de telemóvel inválido ou com caracteres inválidos")
                            checkStep.section1 = false
                        }
                    } else {
                        toastError("Data incorreta");
                        checkStep.section1 = false
                    }
                else {
                    toastError("Data incorreta");
                    checkStep.section1 = false
                }
            } else {
                checkStep.section1 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else {
                    toastError("Preencha todos os dados obrigatórios.")
                }
            }
        }
        else if (section === 2) {
            if (email !== "") {
                checkStep.section2 = true
                if (!newStep) {
                    fade(section + 1)
                } else {
                    if (section < newStep) {
                        if (checkStep[`section${newStep - 1}`] == true) {
                            fade(newStep)
                        } else toastError("Preencha todas as informações necessárias antes de avançar.")
                    } else if (section > newStep) {
                        fadeInRight(newStep)
                    }
                }
            }
            else {
                checkStep.section2 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else {
                    toastError("Preencha todos os dados obrigatórios.")
                }
            }
        }
        else if (section === 3) {
            setNationality(document.getElementById("nationality").value);
            if (parishD !== "" && document.getElementById("nationality").value !== "0") {
                if (parishN !== "" && document.getElementById("nationality").value !== "0") {
                    checkStep.section3 = true
                    if (!newStep) {
                        fade(section + 1)
                    } else {
                        if (section < newStep) {
                            if (checkStep[`section${newStep - 1}`] == true) {
                                fade(newStep)
                            } else toastError("Preencha todas as informações necessárias antes de avançar.")
                        } else if (section > newStep) {
                            fadeInRight(newStep)
                        }
                    }
                }
            }
            else {
                checkStep.section3 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else {
                    toastError("Preencha todos os dados obrigatórios.")
                }
            }
        }
        else if (section === 4) {
            if (locality !== "" && address !== "" && postalCode !== "" && county !== "" && district !== "") {
                if (onlyString(locality)) {
                    if (containsPostalCode(postalCode)) {
                        checkStep.section4 = true
                        if (!newStep) {
                            fade(section + 1)
                        } else {
                            if (section < newStep) {
                                if (checkStep[`section${newStep - 1}`] == true) {
                                    fade(newStep)
                                } else toastError("Preencha todas as informações necessárias antes de avançar.")
                            } else if (section > newStep) {
                                fadeInRight(newStep)
                            }
                        }
                    } else {
                        checkStep.section4 = false
                        if (newStep && newStep < section) {
                            fadeInRight(newStep)
                        } else {
                            toastError("O Código Postal é inválido. \nEx:1111-000")
                        }
                    }
                } else {
                    checkStep.section4 = false
                    if (newStep && newStep < section) {
                        fadeInRight(newStep)
                    } else {
                        toastError("Localidade inválida.")
                    }
                }
            } else {
                checkStep.section4 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else {
                    toastError("Preencha todos os dados obrigatórios.")
                }
            }
        }
        else if (section === 5) {
            let checkcc;
            if (documentType !== '' && idNumber !== "" && idExpiration !== "" && nif !== "" && niss !== "") {
                idExpiration.replace("-", ',')
                const datenow = new Date(idExpiration)
                if (datenow.getTime() > dateToday.getTime()) {
                    if (datenow.getTime() < dateCcMax.getTime()) {
                        if (documentType === 'Cartão de Cidadão') {
                            check_pt_cc_number(idNumber)
                        }
                        if (documentType === 'BI') {
                            if (idNumber.length === 8) {
                                checkcc = true
                            }
                        }
                        if (documentType === 'Passaporte') {
                            if (idNumber.length === 7) {
                                checkcc = true
                            }
                        }
                        if (documentType === 'BI Estrangeiro') {
                            if (idNumber.length > 4) {
                                checkcc = true
                            }
                        }
                        if (documentType === 'Outro') {
                            if (idNumber.length > 4) {
                                checkcc = true
                            }
                        }
                        var checkniss = false
                        function check_niss_number() {
                            const LEN_NISS = 11;
                            const table = [29, 23, 19, 17, 13, 11, 7, 5, 3, 2];
                            function sumLists(a, b) {
                                return a.map((n, i) => n * b[i]).reduce((acc, val) => acc + val, 0);
                            }
                            // verificar tamanho do número passado
                            if (niss.length !== LEN_NISS) {
                                return false;
                            }
                            // verificar validade do carácter inicial do NISS
                            if (!["1", "2"].includes(niss[0])) {
                                return false;
                            }
                            // converter número para lista de inteiros
                            const nissList = niss.split("").map(Number);
                            // verificar soma de controlo
                            if (nissList[LEN_NISS - 1] === 9 - sumLists(table, nissList.slice(0, -1)) % 10) {
                                checkniss = true
                            }
                        }
                        check_niss_number(niss)
                        if (checkniss === true && check_nif_number(nif) === true) {
                            checkStep.section5 = true
                            if (!newStep) {
                                fade(section + 1)
                            } else {
                                if (section < newStep) {
                                    if (checkStep[`section${newStep - 1}`] == true) {
                                        fade(newStep)
                                    } else toastError("Preencha todas as informações necessárias antes de avançar.")
                                } else if (section > newStep) {
                                    fadeInRight(newStep)
                                }
                            }
                        } else if (!check_nif_number(nif)) {
                            checkStep.section5 = false
                            if (newStep && newStep < section) {
                                fadeInRight(newStep)
                            } else {
                                toastError("Número de NIF inválido.")
                            }
                        } else if (checkniss === false) {
                            checkStep.section5 = false
                            if (newStep && newStep < section) {
                                fadeInRight(newStep)
                            } else {
                                toastError("Número de NISS inválido.")
                            }
                        }
                    } else {
                        checkStep.section5 = false
                        if (newStep && newStep < section) {
                            fadeInRight(newStep)
                        } else {
                            toastError("Data do documento de identificação caducada.")
                        }
                    }
                } else {
                    checkStep.section5 = false
                    if (newStep && newStep < section) {
                        fadeInRight(newStep)
                    } else {
                        toastError("Data do documento de identificação inválida.")
                    }
                }
            } else {
                checkStep.section5 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else {
                    toastError("Preencha todos os dados obrigatórios.")
                }
            }
        }
        else if (section === 6) {
            if (CQEP !== "" && academicDegree !== "" && employmentSituation !== "" && origemInscricao !== "") {
                if (CQEP === "Sim") {
                    if (originCQEP !== "") {
                        checkStep.section6 = true
                        if (!newStep) {
                            fade(section + 1)
                        } else {
                            if (section < newStep) {
                                if (checkStep[`section${newStep - 1}`] == true) {
                                    fade(newStep)
                                } else toastError("Preencha todas as informações necessárias antes de avançar.")
                            } else if (section > newStep) {
                                fadeInRight(newStep)
                            }
                        }
                    } else {
                        checkStep.section6 = false
                        if (newStep && newStep < section) {
                            fadeInRight(newStep)
                        } else {
                            toastError("Preencha todos os dados obrigatórios.")
                        }
                    }
                } else {
                    checkStep.section6 = true
                    if (!newStep) {
                        fade(section + 1)
                    } else {
                        if (section < newStep) {
                            if (checkStep[`section${newStep - 1}`] == true) {
                                fade(newStep)
                            } else toastError("Preencha todas as informações necessárias antes de avançar.")
                        } else if (section > newStep) {
                            fadeInRight(newStep)
                        }
                    }
                }
            }
            else {
                checkStep.section6 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else {
                    toastError("Preencha todos os dados obrigatórios.")
                }
            }
        }
        else if (section === 7 && (employmentSituation === "Desempregados à procura de novo emprego - DLD" || employmentSituation === "Desempregados à procura de novo emprego - Não DLD" || employmentSituation === "Desempregados à procura do 1º emprego" || employmentSituation === "Inativos - A frequentarem ações de educação ou formação" || employmentSituation === "Inativos - Outros")) {
            if (employmentSituationDate !== "" && beneficiarySS !== "") {
                employmentSituationDate.replace("-", ',')
                const datenow = new Date(employmentSituationDate)
                if (datenow.getTime() > dateToday.getTime()) {
                    checkStep.section7 = false
                    if (newStep && newStep < section) {
                        fadeInRight(newStep)
                    } else {
                        toastError("Data incorreta");
                    }
                } else if (datenow.getTime() < dateToday.getTime()) {
                    checkStep.section7 = true
                    if (!newStep) {
                        fade(section + 1)
                    } else {
                        if (section < newStep) {
                            if (checkStep[`section${newStep - 1}`] == true) {
                                fade(newStep)
                            } else toastError("Preencha todas as informações necessárias antes de avançar.")
                        } else if (section > newStep) {
                            fadeInRight(newStep)
                        }
                    }
                }
            }
            else {
                checkStep.section7 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else {
                    toastError("Preencha todos os dados obrigatórios.")
                }
            }
        }
        else if (section === 7 && (employmentSituation.includes("Empregados por conta de outrem") || employmentSituation.includes('Empregados por conta própria'))) {
            if (job !== "" && nifEmployer !== "" && nameEmployer !== "") {
                check_nif_number(nifEmployer)
                if (onlyString(job)) {
                    if (check_nif_number(nifEmployer)) {
                        checkStep.section7 = true
                        if (!newStep) {
                            fade(section + 1)
                        } else {
                            if (section < newStep) {
                                if (checkStep[`section${newStep - 1}`] == true) {
                                    fade(newStep)
                                } else toastError("Preencha todas as informações necessárias antes de avançar.")
                            } else if (section > newStep) {
                                fadeInRight(newStep)
                            }
                        }
                    } else {
                        checkStep.section7 = false
                        if (newStep && newStep < section) {
                            fadeInRight(newStep)
                        } else {
                            toastError("Número de NIF inválido.")
                        }
                    }
                } else {
                    checkStep.section7 = false
                    if (newStep && newStep < section) {
                        fadeInRight(newStep)
                    } else {
                        toastError("Profissão/Função inválida.")
                    }
                }
            } else {
                checkStep.section7 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else {
                    toastError("Preencha todos os dados obrigatórios.")
                }
            }
        }
        else if (section === 8) {
            if (dataAuthorization === true && dataAuthorization2 === false) {
                checkStep.section8 = true
                if (!newStep) {
                    fade(section + 1)
                } else {
                    if (section < newStep) {
                        if (checkStep[`section${newStep - 1}`] == true) {
                            fade(newStep)
                        } else toastError("Preencha todas as informações necessárias antes de avançar.")
                    } else if (section > newStep) {
                        fadeInRight(newStep)
                    }
                }
            }
            else if (dataAuthorization === true && dataAuthorization2 === true) {
                checkStep.section8 = true
                if (!newStep) {
                    fade(section + 2)
                } else {
                    if (section < newStep) {
                        if (checkStep[`section${newStep - 1}`] == true) {
                            fade(newStep)
                        } else toastError("Preencha todas as informações necessárias antes de avançar.")
                    } else if (section > newStep) {
                        fadeInRight(newStep)
                    }
                }
            }
            else {
                checkStep.section8 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else {
                    toastError("Têm que aceitar os termos para continuar.")
                }
            }
        }
        else if (section === 9) {
            if (centroQualifica !== "") {
                checkStep.section9 = true
                if (!newStep) {
                    fade(section + 1)
                } else {
                    if (section < newStep) {
                        if (checkStep[`section${newStep - 1}`] == true) {
                            fade(newStep)
                        } else toastError("Preencha todas as informações necessárias antes de avançar.")
                    } else if (section > newStep) {
                        fadeInRight(newStep)
                    }
                }
            }
            else {
                checkStep.section9 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else {
                    toastError("Selecione uma opção")
                }
            }

        }
        else if (section === 10) {
            if (newStep && newStep < section) {
                fadeInRight(newStep)
            } else {
                toastError("Selecione uma opção")
            }
        }
    }
    //Retroceder 
    const handlePrevious = () => {
        if (section === 10 && dataAuthorization2 === true) fadeInRight(section - 2);
        else fadeInRight(section - 1);
    }
    //transforma data em json
    function formDataToJson(formData) {
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        return JSON.stringify(object);
    }
    //Insere user
    function submeterUser(formDataInfo) {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/formandos`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: formDataToJson(formDataInfo),
                })
                .then(res => res.json())
                .then((result) => {
                    if (result._id) {
                        resolve(result._id)
                        toastSuccess("Candidatura criada com sucesso.")
                        navigate('/')
                    }
                    else {
                        reject(result)
                    }
                })
        })
    }

    //Submete candidatura
    async function submeterCandidatura() {

        let form = document.getElementById("main-form")

        let formDataInfo = new FormData()

        // Secção - 1
        formDataInfo.append('fullName', CapitalizarNomes(fullName))
        formDataInfo.append('gender', gender)
        formDataInfo.append('birthDate', birthDate)
        formDataInfo.append('cellNumber', cellNumber)

        // Secção - 2
        formDataInfo.append('email', email)

        // Secção - 3
        formDataInfo.append('nationality', nationality)
        formDataInfo.append('parish', `${parishD}/${parishN}`)

        //Secção - 4
        formDataInfo.append('locality', locality)
        formDataInfo.append('address', address)
        formDataInfo.append('postalCode', postalCode)
        formDataInfo.append('district', district)
        formDataInfo.append('county', county)

        // Secção - 5 
        formDataInfo.append('documentType', documentType)
        formDataInfo.append('idNumber', idNumber)
        formDataInfo.append('idExpiration', idExpiration)
        formDataInfo.append('nif', nif)
        formDataInfo.append('niss', niss)

        // Secção - 6 
        formDataInfo.append('origemInscricao', origemInscricao)
        formDataInfo.append('CQEP', CQEP)
        formDataInfo.append('originCQEP', originCQEP)
        formDataInfo.append('academicDegree', academicDegree)
        formDataInfo.append('employmentSituation', employmentSituation)

        // Secção - 7
        formDataInfo.append('employmentSituationDate', employmentSituationDate)
        formDataInfo.append('beneficiarySS', beneficiarySS)

        formDataInfo.append('job', job)
        formDataInfo.append('nifEmployer', nifEmployer)
        formDataInfo.append('nameEmployer', nameEmployer)

        // Secção - 8
        formDataInfo.append('dataAuthorization', dataAuthorization)
        formDataInfo.append('dataAuthorization2', dataAuthorization2)

        // Secção - 9
        formDataInfo.append('centroQualifica', centroQualifica)

        // Secção - 10
        formDataInfo.append('percurso', percurso)
        formDataInfo.append('percursoName', percursoName)
        formDataInfo.append('sendMail', true)


        const cc = form.cc.files[0]

        const options = {
            method: "POST",
            headers: { "Accept": "application/json" },
            credentials: "same-origin"
        }

        /*  if (employmentSituation.includes("Empregados por conta de outrem") || employmentSituation.includes('Empregados por conta própria')) {
             const rv = form.rv.files[0]
             if (rv !== undefined) {
                 let rvForm = new FormData()
                 rvForm.append('rv', rv)
                 options.body = rvForm
                 const rvResponse = await fetch(`${process.env.REACT_APP_API_URL}/rv`, options)
                 const rvResult = await rvResponse.json()
                 formDataInfo.append('rv', rvResult)
             }
 
         } else if (employmentSituation === "Desempregados à procura de novo emprego - DLD" || employmentSituation === "Desempregados à procura de novo emprego - Não DLD" || employmentSituation === "Desempregados à procura do 1º emprego" || employmentSituation === "Inativos - A frequentarem ações de educação ou formação" || employmentSituation === "Inativos - Outros") {
             const dce = form.dce.files[0]
             if (dce !== undefined) {
                 let dceForm = new FormData()
                 dceForm.append('dce', dce)
                 options.body = dceForm
                 const dceResponse = await fetch(`${process.env.REACT_APP_API_URL}/mdce`, options)
                 const dceResult = await dceResponse.json()
                 formDataInfo.append('dce', dceResult)
             }
         } */
        const ch = form.ch.files[0]
        const ibancomp = form.ibancomp.files[0]
        const csp = form.csp.files[0]
        const cm = form.cm.files[0]
        if (cc !== undefined) {
            let ccForm = new FormData()
            ccForm.append('cc', cc)
            options.body = ccForm
            const ccResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcc`, options)
            const ccResult = await ccResponse.json()
            formDataInfo.append('cc', ccResult)
        }

        if (ch !== undefined) {
            let chForm = new FormData()
            chForm.append('ch', ch)
            options.body = chForm
            const chResponse = await fetch(`${process.env.REACT_APP_API_URL}/mch`, options)
            const chResult = await chResponse.json()
            formDataInfo.append('ch', chResult)
        }

        if (ibancomp !== undefined) {
            let ibancompForm = new FormData()
            ibancompForm.append('ibancomp', ibancomp)
            options.body = ibancompForm
            const ibancompResponse = await fetch(`${process.env.REACT_APP_API_URL}/mibancomp`, options)
            const ibancompResult = await ibancompResponse.json()
            formDataInfo.append('ibancomp', ibancompResult)
        }
        if (csp !== undefined) {
            let cspForm = new FormData()
            cspForm.append('csp', csp)
            options.body = cspForm
            const cspResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcsp`, options)
            const cspResult = await cspResponse.json()
            formDataInfo.append('csp', cspResult)
        }
        if (cm !== undefined) {
            let cmForm = new FormData()
            cmForm.append('cm', cm)
            options.body = cmForm
            const cmResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcm`, options)
            const cmResult = await cmResponse.json()
            formDataInfo.append('cm', cmResult)
        }
        let arrayObject = [];
        for (let i = 0; i < numDoc; i++) {
            const outro = outrosDocs[i] && outrosDocs[i].file;
            if (outro) {
                let outroForm = new FormData();
                outroForm.append('outro', outro);
                const outroOptions = options; // Create separate options object
                outroOptions.body = outroForm;
                const outroResponse = await fetch(`${process.env.REACT_APP_API_URL}/outro`, outroOptions);
                const outroResult = await outroResponse.json();
                const object = {}
                object.name = outrosDocs[i].name;
                object.file = outroResult;
                arrayObject[i] = object;
            }
        }
        if (arrayObject.length === 1) {
            formDataInfo.append("outros", JSON.stringify(arrayObject));
        } else formDataInfo.append("outros", JSON.stringify(arrayObject));
        // Submeter dados do candidato
        submeterUser(formDataInfo)
    }

    const checkSubmit = async event => {
        event.preventDefault();
        setModalIsOpen(true);
    };
    //Função de submit
    async function handleSubmit() {
        await setModalIsOpen(false)
        await submeterCandidatura()
        await setLoading(true)
    }
    //Altera nacionalidade
    const handleNationalityChange = (selectedNationality) => {
        setNationality(selectedNationality);
    };
    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );

    return (
        <>
            <Header />
            {(percursoDateEndCandidacies && new Date(percursoDateEndCandidacies) > new Date()) || !percursoDateEndCandidacies ?
                <div className="flex flex-col items-center min-h-screen">
                    <div className="title">CANDIDATURA FORMANDO</div>
                    <div className="subtitle">{percursoName}</div>
                    <div className="subtitle">{entFormadora}</div>
                    <MultiStepForm steps={steps} currentStep={section - 1} />
                    <form className="container-form m:w-96 md:w-[900px]" id="main-form" encType="multipart/form-data"  >
                        <section>
                            {section === 1 && (<div>
                                <div className="mb-3">
                                    <label htmlFor="fullName" className="label-input">Nome Completo:*</label>
                                    <input type="text" id="fullName" name="fullName" className="w-full input-field2" value={fullName} onChange={(e) => { setFullName(e.target.value) }} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="gender" className="label-input">Género:*</label>
                                    <select onWheel={(e) => e.target.blur()} className="selector2" id="gender" name="gender" value={gender} onChange={(e) => { setGender(e.target.value) }} required>
                                        <option value="" disabled>Selecione a sua opção</option>
                                        <option value="Masculino">Masculino</option>
                                        <option value="Feminino">Feminino</option>
                                        <option value="Outro">Outro</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="birthDate" className="label-input">Data de Nascimento:*</label>
                                    <input type="text" id="birthDate" name="birthDate" className="w-full input-field2" ref={ref} onFocus={() => (ref.current.type = "date")}
                                        onBlur={() => (ref.current.type = "date")} value={birthDate} onChange={(e) => { setBirthDate(e.target.value) }} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="cellNumber" className="label-input">Nº de telemóvel:*</label>
                                    <input type="text" id="cellNumber" name="cellNumber" className="w-full input-field2" minLength={9} maxLength={13} value={cellNumber} onChange={(e) => { setCellNumber(e.target.value) }} required></input>
                                </div>
                            </div>)}
                        </section>
                        <section>
                            {section === 2 && (<div>
                                <div className="mb-5">
                                    <label htmlFor="email" className="label-input">E-mail:*</label>
                                    <input type="email" id="email" name="email" className="w-full input-field2" value={email} onChange={(e) => { setEmail(e.target.value) }} required></input>
                                </div>
                            </div>
                            )}
                        </section>
                        <section>
                            {section === 3 && (<div>
                                <div className="mb-3">
                                    <label htmlFor="parish" className="label-input" >Nacionalidade:*</label>
                                    <CountryDropdown defaultValue={nationality} onChange={handleNationalityChange} />
                                </div>
                                <div className="mb-3">
                                    <label className="label-input" htmlFor="parish">Naturalidade (Distrito de Nascença):*</label>
                                    <input className="w-full input-field2" type="text" id="parishD" name="parishD" value={parishD} onChange={(e) => { setParishD(e.target.value) }} required></input>
                                </div>
                                <div className="mb-3">
                                    <label className="label-input" htmlFor="parish">Naturalidade (Concelho de Nascença):*</label>
                                    <input className="w-full input-field2" type="text" id="parishN" name="parishN" value={parishN} onChange={(e) => { setParishN(e.target.value) }} required></input>
                                </div>
                            </div>)}
                        </section>
                        <section>
                            {section === 4 && (<div>
                                <div className="mb-3">
                                    <label htmlFor="address" className="label-input">Morada:*</label>
                                    <input type="text" id="address" name="address" className="w-full input-field2" value={address} onChange={(e) => { setAddress(e.target.value) }} required></input>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="locality" className="label-input">Localidade:*</label>
                                    <input type="text" id="locality" name="locality" className="w-full input-field2" value={locality} onChange={(e) => { setLocality(e.target.value) }} required></input>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="postalCode" className="label-input" >Código Postal:*</label>
                                    <input type="text" id="postalCode" name="postalCode" className="w-full input-field2" pattern="[0-9]{4}[\-]?[0-9]{3}" value={postalCode} onChange={(e) => { setPostalCode(e.target.value) }} required></input>
                                </div>
                                <div className="mb-3">
                                    <label className="label-input" htmlFor="county">Distrito*</label>
                                    <input className="w-full input-field2" type="text" id="district" name="district" value={district} onChange={(e) => { setDistrict(e.target.value) }} required></input>
                                </div>
                                <div className="mb-3">
                                    <label className="label-input" htmlFor="county">Concelho*</label>
                                    <input className="w-full input-field2" type="text" id="county" name="county" value={county} onChange={(e) => { setCounty(e.target.value) }} required></input>
                                </div>
                            </div>)}
                        </section>
                        <section>
                            {section === 5 && (<div>
                                <div className="mb-3">
                                    <label className="label-input">Tipo de Documento de Identificação*</label>
                                    <select onWheel={(e) => e.target.blur()} className="selector2" id="documentType" name="documentType" value={documentType} onChange={(e) => { setDocumentType(e.target.value) }} required>
                                        <option value="" disabled>Selecione a sua opção</option>
                                        <option value="BI">BI</option>
                                        <option value="Passaporte">Passaporte</option>
                                        <option value="BI Estrangeiro">BI Estrangeiro</option>
                                        <option value="Cartão de Cidadão">Cartão de Cidadão</option>
                                        <option value="Outro">Outro</option>
                                    </select>
                                </div>
                                {documentType === "BI" && <div className="mb-3">
                                    <label htmlFor="idNumber" className="label-input">Número Bilhete de Identidade:*</label>
                                    <input type="text" id="idNumber" name="idNumber" className="w-full input-field2" maxLength={8} value={idNumber} onChange={(e) => { setIdNumber(e.target.value) }} required></input>
                                </div>}
                                {documentType === "Passaporte" && <div className="mb-3">
                                    <label htmlFor="idNumber" className="label-input">Número Passaporte:*</label>
                                    <input type="text" id="idNumber" name="idNumber" className="w-full input-field2" maxLength={7} value={idNumber} onChange={(e) => { setIdNumber(e.target.value) }} required></input>
                                </div>}
                                {documentType === "BI Estrangeiro" && <div className="mb-3">
                                    <label htmlFor="idNumber" className="label-input">Número Bilhete de Identidade Estrangeiro:*</label>
                                    <input type="text" id="idNumber" name="idNumber" className="w-full input-field2" maxLength={12} value={idNumber} onChange={(e) => { setIdNumber(e.target.value) }} required></input>
                                </div>}
                                {documentType === "Cartão de Cidadão" && <div className="mb-3">
                                    <label htmlFor="idNumber" className="label-input">Número Cartão Cidadão:*</label>
                                    <input type="text" id="idNumber" name="idNumber" className="w-full input-field2" maxLength={12} value={idNumber} onChange={(e) => { setIdNumber(e.target.value) }} required></input>
                                </div>}
                                {documentType === "Outro" && <div className="mb-3">
                                    <label htmlFor="idNumber" className="label-input">Número Documento de Identificação:*</label>
                                    <input type="text" id="idNumber" name="idNumber" className="w-full input-field2" maxLength={12} value={idNumber} onChange={(e) => { setIdNumber(e.target.value) }} required></input>
                                </div>}
                                <div className="mb-3">
                                    <label htmlFor="idExpiration" className="label-input">Data de Validade Documento de Identificação:*</label>
                                    <input type="text" id="idExpiration" name="idExpiration" className="w-full input-field2" ref={ref} onFocus={() => (ref.current.type = "date")}
                                        onBlur={() => (ref.current.type = "date")} value={idExpiration} onChange={(e) => { setIdExpiration(e.target.value) }} required></input>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="nif" className="label-input">Número de Contribuinte:*</label>
                                    <input type="text" id="nif" name="nif" className="w-full input-field2" minLength={9} maxLength={9} value={nif} onChange={(e) => { setNif(e.target.value) }} required></input>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="niss" className="label-input">Número de Identificação da Segurança Social:*</label>
                                    <input type="text" id="niss" name="niss" className="w-full input-field2" minLength={11} maxLength={11} value={niss} onChange={(e) => { setNiss(e.target.value) }} required></input>
                                </div>
                            </div>
                            )}
                        </section>
                        <section>
                            {section === 6 && (
                                <div className="section">
                                    <div className="mb-3">
                                        <label htmlFor="origemInscricao" className="label-input">Está a frequentar a formação por iniciativa:*</label>
                                        <select onWheel={(e) => e.target.blur()} className="selector2" id="origemInscricao" name="origemInscricao" value={origemInscricao} onChange={(e) => { setOrigemInscricao(e.target.value) }} required>
                                            <option value="" disabled>Selecione a sua opção</option>
                                            <option value="Iniciativa Própria">Iniciativa Própria</option>
                                            <option value="Iniciativa da Entidade Empregadora">Iniciativa da Entidade Empregadora</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="CQEP" className="label-input">Provém de um Centro Qualifica:*</label>
                                        <select onWheel={(e) => e.target.blur()} className="selector2" id="CQEP" name="CQEP" value={CQEP} onChange={(e) => { setCQEP(e.target.value) }} required>
                                            <option value="" disabled>Selecione a sua opção</option>
                                            <option value="Sim">Sim</option>
                                            <option value="Não">Não</option>
                                        </select>
                                    </div>
                                    {CQEP === "Sim" &&
                                        (<div className="mb-3">
                                            <label htmlFor="originCQEP" className="label-input"> Nome do Centro Qualifica:*</label>
                                            <input type="text" id="originCQEP" name="originCQEP" className="w-full input-field2" value={originCQEP} onChange={(e) => { setOriginCQEP(e.target.value) }} required></input>
                                        </div>)}
                                    <div>
                                        <label htmlFor="academicDegree" className="label-input">Grau Académico:*</label>
                                        <select onWheel={(e) => e.target.blur()} className="selector2" id="academicDegree" name="academicDegree" value={academicDegree} onChange={(e) => { setAcademicDegree(e.target.value) }} required>
                                            <option value="" disabled>Selecione a sua opção</option>
                                            <option value="Não sabe ler nem escrever">Não sabe ler nem escrever</option>
                                            <option value="Menos de 4 anos escolaridade">Menos de 4 anos escolaridade</option>
                                            <option value="1º ciclo (4º ano)">1º ciclo (4º ano)</option>
                                            <option value="2º ciclo (6º ano)">2º ciclo (6º ano)</option>
                                            <option value="3º ciclo (9º ano)">3º ciclo (9º ano)</option>
                                            <option value="Ensino Secundário">Ensino Secundário</option>
                                            <option value="Ensino Pós-Secundário não superior (ex CET Cursos de Especialização Tecnológica)">Ensino Pós-Secundário não superior (ex CET Cursos de Especialização Tecnológica)</option>
                                            <option value="Cursos superiores de curta duração (ex TeSP Cursos Técnicos Superiores Profissionais)">Cursos superiores de curta duração (ex TeSP Cursos Técnicos Superiores Profissionais)</option>
                                            <option value="Bacharelato">Bacharelato</option>
                                            <option value="Licenciatura">Licenciatura</option>
                                            <option value="Licenciatura pré-Bolonha">Licenciatura pré-Bolonha</option>
                                            <option value="Mestrado">Mestrado</option>
                                            <option value="Mestrado Pré-Bolonha">Mestrado Pré-Bolonha</option>
                                            <option value="Doutoramento">Doutoramento</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="employmentSituation" className="label-input">Situação Laboral:*</label>
                                        <select onWheel={(e) => e.target.blur()} className="selector2" id="employmentSituation" name="employmentSituation" value={employmentSituation} onChange={(e) => { setEmploymentSituation(e.target.value) }} required>
                                            <option value="" disabled>Selecione a sua opção</option>
                                            <option value="Empregados por conta de outrem - Internos">Empregados por conta de outrem - Internos</option>
                                            <option value="Empregados por conta de outrem - Externos">Empregados por conta de outrem - Externos</option>
                                            <option value="Empregados por conta própria">Empregados por conta própria</option>
                                            <option value="Desempregados à procura de novo emprego - DLD">Desempregados à procura de novo emprego - DLD</option>
                                            <option value="Desempregados à procura de novo emprego - Não DLD">Desempregados à procura de novo emprego - Não DLD</option>
                                            <option value="Desempregados à procura do 1º emprego">Desempregados à procura do 1º emprego</option>
                                            <option value="Inativos - A frequentarem ações de educação ou formação">Inativos - A frequentarem ações de educação ou formação</option>
                                            <option value="Inativos - Outros">Inativos - Outros</option>
                                        </select>
                                    </div>
                                </div>)}
                        </section>
                        <section>
                            {section === 7 && (employmentSituation === "Desempregados à procura de novo emprego - DLD" || employmentSituation === "Desempregados à procura de novo emprego - Não DLD" || employmentSituation === "Desempregados à procura do 1º emprego" || employmentSituation === "Inativos - A frequentarem ações de educação ou formação" || employmentSituation === "Inativos - Outros") ? (
                                <div>
                                    <div className="mb-3">
                                        <label htmlFor="employmentSituationDate" className="label-input">Data de inicio da situação face ao desemprego:*</label>
                                        <input type="text" id="employmentSituationDate" name="employmentSituationDate" className="w-full input-field2" ref={ref} onFocus={() => (ref.current.type = "date")}
                                            onBlur={() => (ref.current.type = "date")} value={employmentSituationDate} onChange={(e) => { setEmploymentSituationDate(e.target.value) }} required></input>
                                    </div>
                                    <div>
                                        <label htmlFor="beneficiarySS" className="label-input">Beneficiário de Prestação Social:*</label>
                                        <div>
                                            <select onWheel={(e) => e.target.blur()} className="selector2" id="beneficiarySS" name="beneficiarySS" value={beneficiarySS} onChange={(e) => { setBeneficiarySS(e.target.value) }} required>
                                                <option value="" disabled>Selecione a sua opção</option>
                                                <option value="Sim">Sim</option>
                                                <option value="Não">Não</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            ) : section === 7 && (employmentSituation.includes("Empregados por conta de outrem") || employmentSituation.includes('Empregados por conta própria')) ? (
                                <div>
                                    <div className="mb-3">
                                        <label htmlFor="job" className="label-input">Profissão/Função:*</label>
                                        <input type="text" id="job" name="job" className="w-full input-field2" minLength={25} maxLength={25} value={job} onChange={(e) => { setJob(e.target.value) }} required></input>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="nameEmployer" className="label-input">Nome Entidade Empregadora:*</label>
                                        <input type="text" id="nameEmployer" name="nameEmployer" className="w-full input-field2" value={nameEmployer} onChange={(e) => { setNameEmployer(e.target.value) }} required></input>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="nifEmployer" className="label-input">NIF Entidade Empregadora:*</label>
                                        <input type="text" id="nifEmployer" name="nifEmployer" className="w-full input-field2" minLength={9} maxLength={9} value={nifEmployer} onChange={(e) => { setNifEmployer(e.target.value) }} required></input>
                                    </div>

                                </div>) : ''}
                        </section>
                        <section>
                            {section === 8 && (
                                <div>
                                    <div className="mb-4 text-text-second-color opacity-90">Indique-nos por favor se aceita que os seus dados pessoais, possam ser facultados às Entidades Reguladoras e/ou Financiadoras das Ações de Formação.</div>
                                    <input type="checkbox" id="dataAuthorization" name="dataAuthorization" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" value="yes" checked={dataAuthorization === true} onChange={(e) => setDataAuthorization(e.target.checked ? true : false)} />
                                    <label for="dataAuthorization" className="text-sm text-text-second-color opacity-90">* 1ª Declaro que tomei conhecimento e autorizo a Cooperativa Comenius e seus cooperantes possam utilizar os meus dados para processamento informático na gestão da formação, o que inclui fornecer os dados à DGERT e/ou às entidades cooperadoras e financiadoras dos cursos em que estou inscrito.</label>
                                    <br /><br /><input type="checkbox" id="dataAuthorization2" name="dataAuthorization2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" value="yes" checked={dataAuthorization2 === true} onChange={(e) => setDataAuthorization2(e.target.checked ? true : false)} />
                                    <label for="dataAuthorization2" className="text-sm text-text-second-color opacity-90"> 2ª Declaro que autorizo a Cooperativa e seus cooperantes a utilizar os meus dados para divulgação de serviços próprios</label>
                                </div>
                            )}
                            {section === 9 && (
                                <div>
                                    <label className="text-text-second-color opacity-90">Autorizo que a minha inscrição seja transmitida ao Centro Qualifica Comenius, por forma a receber informação pertinente sobre Qualificação Profissional</label>
                                    <label>
                                        <select onWheel={(e) => e.target.blur()} className="selector2" id="centroQualifica" name="centroQualifica" value={centroQualifica} onChange={(e) => { setCentroQualifica(e.target.value) }} required>
                                            <option value="" disabled>Selecione a sua opção</option>
                                            <option value="Sim">Sim</option>
                                            <option value="Não">Não</option>
                                        </select>
                                    </label>
                                </div>)}

                            {section === 10 && (<div>
                                <div className="mb-3">
                                    <label htmlFor="cc" className="text-text-second-color opacity-90" >Cartão de Cidadão: </label>
                                    <input type="file" id="cc" name="cc" className="text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer file:bg-main-color bg-gray-50 focus:outline-none" accept=".pdf" />
                                </div>
                                <div className="mb-3">
                                    {/* {employmentSituation.includes("Empregados por conta de outrem") || employmentSituation.includes('Empregados por conta própria')  ? <><label htmlFor="rv" className="text-text-second-color opacity-90">Comprovativo de Situação Profissional: </label>
                                    <input type="file" id="rv" name="rv" accept=".pdf" className="text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer file:bg-main-color bg-gray-50 focus:outline-none" /></> : ""}
                                {employmentSituation.includes("Desempregados") || employmentSituation.includes("Inativos") ? <><label htmlFor="dce" className="text-text-second-color opacity-90">Declaração Centro de Emprego: </label>
                                    <input type="file" id="dce" name="dce" accept=".pdf" className="text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer file:bg-main-color bg-gray-50 focus:outline-none" /></> : ""} */}
                                    <label htmlFor="certificate_doc" className="text-text-second-color opacity-90">Comprovativo de Situação Profissional: </label>
                                    <input type="file" id="csp" name="csp" accept=".pdf" className="text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer file:bg-main-color bg-gray-50 focus:outline-none" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="certificate_doc" className="text-text-second-color opacity-90">Comprovativo de Morada: </label>
                                    <input type="file" id="cm" name="cm" accept=".pdf" className="text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer file:bg-main-color bg-gray-50 focus:outline-none" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="certificate_doc" className="text-text-second-color opacity-90">Certificado de Habilitações: </label>
                                    <input type="file" id="ch" name="ch" accept=".pdf" className="text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer file:bg-main-color bg-gray-50 focus:outline-none" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="ibancomp" className="text-text-second-color opacity-90">Comprovativo de IBAN: </label>
                                    <input type="file" id="ibancomp" name="ibancomp" className="text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer file:bg-main-color bg-gray-50 focus:outline-none" accept=".pdf" />
                                </div>
                                <div className="mb-1">
                                    <h1 className="mt-8 text-center subtitle">Adicionar Ficheiros</h1>
                                    <div className="flex flex-col justify-center mb-2">
                                        <label className="text-center text-text-main-color">Quantos outros documentos deseja inserir:</label>
                                        <input className="w-1/3 mx-auto input-field" type="number" value={numDoc} onChange={(e) => { setNumDoc(e.target.value) }}></input>
                                    </div>
                                    <div className="flex flex-col items-center w-full pt-4 overflow-y-auto rounded-lg max-h-156 bg-gray-50">
                                        {Array.from({ length: numDoc }).map((_, index) => (
                                            <div key={index} className=" m-2">
                                                <h4 className="mb-1 text-left uppercase text-text-second-color opacity-90">Documento {index + 1}</h4>
                                                <div className="flex w-full text-left">
                                                    <label className="w-2/5 text-text-main-color" htmlFor={`fullName${index}`}>Nome do documento:</label>
                                                    <input className="w-3/5 input-field" type="text" id={`fullName${index}`} name={`fullName${index}`} value={outrosDocs[index]?.name} onChange={(e) => handleNameChange(e, index)} />
                                                </div>
                                                <div className="flex w-full mt-2 mb-3 text-left">
                                                    <label className="w-2/5 text-text-main-color" htmlFor={`outro${index}`}>Ficheiro:</label>
                                                    <input className="w-3/5 input-file" type="file" id={`outro${index}`} name={`outro${index}`} accept=".pdf" onChange={(e) => handleFileChange(e, index)} />
                                                </div>
                                                <hr />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <p className="text-text-second-color">Nota: Formato suportado .pdf</p>
                                <div className="flex justify-center mt-3">
                                    <button className="button-geral" onClick={checkSubmit} type="button" >Submeter</button>
                                </div>

                            </div>
                            )}
                        </section>
                        <Modal isOpen={modalIsOpen} className="fixed inset-0 flex items-center justify-center">
                            <div className="absolute w-full h-full" onClick={() => setModalIsOpen(false)}></div>
                            <div className="relative flex flex-col items-center px-10 mx-5 my-5 overflow-y-scroll shadow-2xl bg-secundary-color py-7 rounded-2xl max-h-128 sm:max-h-156">
                                <div>
                                    <h1 className="mb-2 title">Dados do Formulário</h1>
                                </div>
                                <div className="modal-content">

                                    <p className="p-1 text-text-second-color"><strong>Nome Completo:</strong> {fullName}</p>
                                    <p className="p-1 text-text-second-color"><strong>Género:</strong> {gender}</p>
                                    <p className="p-1 text-text-second-color"><strong>Data de nascimento:</strong> {birthDate}</p>
                                    <p className="p-1 text-text-second-color"><strong>Número de telemóvel:</strong> {cellNumber}</p>
                                    <p className="p-1 text-text-second-color"><strong>Email:</strong> {email}</p>
                                    <p className="p-1 text-text-second-color"><strong>Naturalidade:</strong> {`${parishD}/${parishN}`}</p>
                                    <p className="p-1 text-text-second-color"><strong>Morada:</strong> {address}</p>
                                    <p className="p-1 text-text-second-color"><strong>Localidade:</strong> {locality}</p>
                                    <p className="p-1 text-text-second-color"><strong>Distrito:</strong> {district}</p>
                                    <p className="p-1 text-text-second-color"><strong>Concelho:</strong> {county}</p>
                                    <p className="p-1 text-text-second-color"><strong>Código postal:</strong> {postalCode}</p>
                                    <p className="p-1 text-text-second-color"><strong>Tipo documento identificação:</strong> {documentType}</p>
                                    <p className="p-1 text-text-second-color"><strong>Número documento identificação:</strong> {idNumber}</p>
                                    <p className="p-1 text-text-second-color"><strong>Data validade documento identificação:</strong> {idExpiration}</p>
                                    <p className="p-1 text-text-second-color"><strong>Número Contribuinte:</strong> {nif}</p>
                                    <p className="p-1 text-text-second-color"><strong>Número Segurança Social:</strong> {niss}</p>
                                    <p className="p-1 text-text-second-color"><strong>Origem de Inscrição:</strong> {origemInscricao}</p>
                                    <p className="p-1 text-text-second-color"><strong>Provém do Centro Qualifica:</strong> {CQEP}</p>
                                    {CQEP === 'Sim' ? <p className="p-1 text-text-second-color"><strong>Origem Centro Qualifica:</strong> {originCQEP}</p> : ''}
                                    <p className="p-1 text-text-second-color"><strong>Grau Académico:</strong> {academicDegree}</p>
                                    <p className="p-1 text-text-second-color"><strong>Situação Laboral:</strong> {employmentSituation}</p>
                                    {employmentSituation.includes("Empregados por conta de outrem") && <p className="p-1 text-text-second-color"><strong>Profissão/Função:</strong> {job}</p>}
                                    {employmentSituation.includes('Empregados por conta própria') && <p className="p-1 text-text-second-color"><strong>Profissão/Função:</strong> {job}</p>}
                                    {employmentSituation.includes("Empregados por conta de outrem") && <p className="p-1 text-text-second-color"><strong>Nome Entidade Empregadora:</strong> {nameEmployer}</p>}
                                    {employmentSituation.includes('Empregados por conta própria') && <p className="p-1 text-text-second-color"><strong>Nome Entidade Empregadora:</strong> {nameEmployer}</p>}
                                    {employmentSituation.includes("Empregados por conta de outrem") && <p className="p-1 text-text-second-color"><strong>NIF Entidade Empregadora:</strong> {nifEmployer}</p>}
                                    {employmentSituation.includes('Empregados por conta própria') && <p className="p-1 text-text-second-color"><strong>NIF Entidade Empregadora:</strong> {nifEmployer}</p>}
                                    {employmentSituation === "Desempregados à procura de novo emprego - DLD" && <p className="p-1 text-text-second-color"><strong>Data de inicio da situação face ao desemprego:</strong> {employmentSituationDate}</p>}
                                    {employmentSituation === "Desempregados à procura de novo emprego - Não DLD" && <p className="p-1 text-text-second-color"><strong>Data de inicio da situação face ao desemprego:</strong> {employmentSituationDate}</p>}
                                    {employmentSituation === "Desempregados à procura do 1º emprego" && <p className="p-1 text-text-second-color"><strong>Data de inicio da situação face ao desemprego:</strong> {employmentSituationDate}</p>}
                                    {employmentSituation === "Inativos - A frequentarem ações de educação ou formação" && <p className="p-1 text-text-second-color"><strong>Data de inicio da situação face ao desemprego:</strong> {employmentSituationDate}</p>}
                                    {employmentSituation === "Inativos - Outros" && <p className="p-1 text-text-second-color"><strong>Data de inicio da situação face ao desemprego:</strong> {employmentSituationDate}</p>}
                                    {employmentSituation === "Desempregados à procura de novo emprego - DLD" && <p className="p-1 text-text-second-color"><strong>Beneficiário de Prestação Social:</strong> {beneficiarySS}</p>}
                                    {employmentSituation === "Desempregados à procura de novo emprego - Não DLD" && <p className="p-1 text-text-second-color"><strong>Beneficiário de Prestação Social:</strong> {beneficiarySS}</p>}
                                    {employmentSituation === "Desempregados à procura do 1º emprego" && <p className="p-1 text-text-second-color"><strong>Beneficiário de Prestação Social:</strong> {beneficiarySS}</p>}
                                    {employmentSituation === "Inativos - A frequentarem ações de educação ou formação" && <p className="p-1 text-text-second-color"><strong>Beneficiário de Prestação Social:</strong> {beneficiarySS}</p>}
                                    {employmentSituation === "Inativos - Outros" && <p className="p-1 text-text-second-color"><strong>Beneficiário de Prestação Social:</strong> {beneficiarySS}</p>}
                                    <p className="p-1 text-text-second-color"><strong>Utilização de dados pessoais:</strong> {dataAuthorization === true && "Autorizado"}</p>
                                    {centroQualifica && <p className="p-1 text-text-second-color"><strong>Centro Qualifica:</strong> {centroQualifica}</p>}
                                    <p className="p-1 text-text-second-color"><strong>Curso selecionado:</strong> {percursoName}</p>
                                    <div className="flex flex-col items-center justify-center mb-2 text-lg">
                                        <p className="text-2xl text-text-warning"><RiErrorWarningLine /></p>
                                        <p className="text-text-warning "><strong> Ao confirmar, verifique a sua caixa de spam</strong></p>
                                    </div>
                                    <div className="flex flex-row justify-center w-full mt-5">
                                        <button className="w-24 button-confirmar" onClick={() => handleSubmit()}>Confirmar</button>
                                    </div>


                                </div>
                                <button className="absolute top-1.5 right-1.5" onClick={() => setModalIsOpen(false)}>
                                    <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                </button>
                            </div>
                        </Modal>
                        <br />
                        <div className="ml-4">
                            <h3 className="m-1 text-main-color">Legenda</h3>
                            <p className="m-1 text-xs text-text-second-color">Os campos assinalados com asterisco (*) são OBRIGATÓRIOS para validação de requisitos, seleção de formandos e emissão do certificado. A inscrição é ANULADA se tiver dados incompletos, não legíveis ou com erros, bem como comprovativos insuficientes ou desatualizados. O/A (NOME DA ENTIDADE FORMADORA), membro cooperante da Cooperativa Comenius , garante a estrita confidencialidade no tratamento dos dados presentes nesta ficha, garantindo que a informação não será partilhada com terceiros e será utilizada apenas para os fins diretamente relacionados com a formação, sendo tratados de forma lícita de acordo com o Regulamento geral de proteção de dados e legislação aplicável.
                            Os dados solicitados são os estritamente necessários para a realização da formação, considerando o enquadramento legal e seu financiamento, se aplicável.</p>
                            {section === 7 && (employmentSituation.includes("Empregados por conta de outrem") || employmentSituation.includes('Empregados por conta própria')) ? <p className="m-1 text-xs text-text-second-color"> Para encontrar o NIF da sua empresa, você pode realizar uma pesquisa online, verificar o recibo de pagamento da sua entidade empregadora ou, caso não o encontre, perguntar diretamente à entidade empregadora.</p> : ""}
                        </div>
                        {section !== 1 && (<button onClick={handlePrevious} type="button" className="button-back-form"><img className="invert" src={Next} alt="Back" /></button>)}
                        {section !== 10 && (<button onClick={() => handleNext(null)} type="button" className="button-next-form"><img className="invert" src={Next} alt="Next" /></button>)}
                    </form>
                    <div className="w-full sm:bottom-0 mt-80 sm:mt-auto"> {/* o q tá fora dos sm é um work around para o footer n ficar estranho pra mobile */}
                        <Footer />
                    </div>
                </div> : <div className="flex flex-col items-center min-h-screen"><h1 className="mt-20 subtitle">Informamos que as inscrições para este curso se encontram encerradas</h1></div>}
        </>
    )
}
export default CandidaturaFormando