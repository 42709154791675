import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogBody,
} from "@material-tailwind/react";
import { BsFillPencilFill } from "react-icons/bs";
import excelGeneratorUfcds from "../Excel/cronogramaDisciplina";
import excelGeneratorClone from "../Excel/cronogramaGeralClone";
import toastSuccess from "../Toast/toastSuccess";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import { IoMdRemoveCircle } from "react-icons/io";
import refreshPage from "../Refresh";
import toastError from "../Toast/toastError";

export default function ModalDownloadDTPCompleto({ id_curso, topicoTitulo, topicoAPI, icon, user, tooltip }) {
    const [open, setOpen] = useState(false);
    const [alterar, setAlterar] = useState(-1);
    const [cont, setCont] = useState(0);
    const [data, setData] = useState("");
    const [mapTopico, setMapTopico] = useState("");
    const [nome, setNome] = useState("");
    const [file, setFile] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getTopico()
    }, [cont, open, loading])
    //Dar download do ficheiro
    //Download do ficheiro
    function downloadFile(url) {

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');

        if ('download' in link) {
            link.setAttribute('download', '');
        }

        document.body.appendChild(link); // Append the link to the DOM

        // Use a timeout to trigger the click after a short delay
        setTimeout(() => {
            link.click();
            document.body.removeChild(link); // Remove the link from the DOM after the click
        }, 100);
    }
    //Obtem
    function getTopico() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(user)
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)
                setMapTopico(result[topicoAPI])
            })
    }
    //Abre e fecha modal
    const handleOpen = () => {
        setOpen(!open);
        setAlterar(false)
    }
    //Formatação da data
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const hour = date.getHours().toString().padStart(2, '0');
        const min = date.getMinutes().toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year} - ${hour}:${min}`;
    };
    //Posta ficheiro no cloudinary
    async function cloudinaryFicheiro(index) {
        setLoading(true)
        let maxsize = 50 * 1024 * 1024 // 50 MB
        if (!nome) {
            console.error("Nome do documento é necessário");
            setLoading(false);
            return;
        }
        const date = new Date();
        const options = {
            method: "POST",
            headers: { "Accept": "application/json" },
            credentials: "same-origin"
        }
        if (document.getElementById("ficheiro") && document.getElementById("ficheiro").value) {
            const ficheiro = document.getElementById("ficheiro").files[0]
            if ((ficheiro.name.includes(".zip") === true) || ficheiro.name.includes(".rar") === true) {
                let fileSize = document.getElementById("ficheiro").files[0].size;
                if (fileSize < maxsize) {
                    let ficheiroForm = new FormData()
                    ficheiroForm.append('zip', ficheiro)
                    options.body = ficheiroForm
                    const ficheiroResponse = await fetch(`${process.env.REACT_APP_API_URL}/ficheiro/zip`, options)
                    const ficheiroResult = await ficheiroResponse.json()
                    await fetch(`${process.env.REACT_APP_API_URL}/percurso/${id_curso}/DTP/editar`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem(user)
                        },
                        method: 'PATCH',
                        body: JSON.stringify({
                            topicoAPI: topicoAPI,
                            nome: nome,
                            index: index,
                            file: ficheiroResult.cloudinaryFileUrl,
                            date: date,
                            idUtilizador: localStorage.getItem("id")
                        })
                    })
                        .then(res => res.json())
                        .then((result) => {
                            toastSuccess("Ficheiro Uploaded com sucesso")
                            setLoading(false)
                            setOpen(!open)
                        })
                        .finally(refreshPage())
                } else {
                    setLoading(false)
                    toastError("O tamanho do ficheiro não pode ultrapassar 50MB")
                }
            }
        }else{
            setLoading(false)
        } 
    }
    //1o nome
    function firstName(item) {
        if (item) {
            const nameUser = item;
            const words = nameUser.split(' ');
            if (words.length > 0) {
                const firstWord = words[0];

                return firstWord;

            } else {
                return words;
            }
        }
    }
    //Apagar ficheiro
    async function eliminarFicheiro(index) {
        setLoading(true)
        await fetch(`${process.env.REACT_APP_API_URL}/percurso/${id_curso}/DTP/remover`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(user)
            },
            method: 'PATCH',
            body: JSON.stringify({
                topicoAPI: topicoAPI,
                index: index
            })
        })
            .then(res => res.json())
            .then((result) => {
                toastSuccess("Ficheiro eliminado com sucesso")
                getTopico()
                setCont(cont + 1)
                setLoading(false)
            })
    }

    return (
        <>
            <button className={mapTopico &&mapTopico.length > 0? "text-xl tooltip text-green-600": "text-xl tooltip text-white"} onClick={handleOpen} variant="gradient"  data-tip={tooltip}>{icon}</button>
            <Dialog className="fixed inset-0 w-screen h-screen m-0 bg-opacity-0 font-Lato" open={open} handler={handleOpen}>
                <div className="flex items-center justify-center h-screen">
                    <div className="absolute w-full h-full" onClick={handleOpen}></div>
                    <div className="relative z-50 p-2 rounded-lg shadow-lg max-h-128 w-128 overflow-auto bg-secundary-color shadow-gray-600">
                        <div className="flex flex-col justify-center my-2">
                            <h4 className="mx-4 text-center small-title">{topicoTitulo}</h4>
                            {!loading ?
                                <DialogBody>
                                    {data && topicoAPI === "cronograma" ? <>
                                        <div className="flex w-full text-left">
                                            <div className="w-2/5 text-text-second-color" >Cronograma Original: <a className="underline cursor-pointer text-text-main-color" href={data.cronogramaOriginal}> Link</a></div>
                                        </div>
                                        <div className="flex w-full text-left">
                                            <div className="w-2/5 text-text-second-color" >Cronograma Clone: <a className="underline cursor-pointer text-text-main-color" onClick={() => excelGeneratorClone(data)}> Link</a></div>
                                        </div>
                                        <>{mapTopico && mapTopico.length > 0 ? <>
                                            {mapTopico.map((item, index) => (
                                                <div key={index}>
                                                    {alterar !== index ? <>
                                                        <div className="flex justify-between w-full mt-6 text-left">
                                                            {item && item.file &&
                                                                <div>
                                                                    <span className="mr-1 label-input">Documento:</span><a className="link " htmlFor={`fullName`} href={item.file} target="_blank">{item.name}</a><br />
                                                                    <span className="mr-1 label-input ">Editado por: <span className="normal-case link tooltip" data-tip={item.nameUser}>{firstName(item.nameUser)}</span> -{formatDate(item.date)}</span><br />
                                                                </div>}
                                                            <div className="flex">
                                                                <button className="mr-2 button-geral" onClick={() => { setAlterar(index) }}><BsFillPencilFill /></button>
                                                                <button className="mr-2 button-geral" onClick={() => { downloadFile(item.file) }}><FiDownload /></button>
                                                                <button className="button-cancelar w-fit" onClick={() => { eliminarFicheiro(index) }}><IoMdRemoveCircle /></button>
                                                            </div>
                                                        </div>
                                                    </> : <>
                                                        <div className="flex w-full text-left">
                                                            <label className="w-2/5 text-text-main-color" htmlFor={`fullName`}>Nome do documento:</label>
                                                            <input className="w-3/5 input-field" type="text" id={`fullName`} name={`fullName`} onChange={(e) => setNome(e.target.value)} />
                                                        </div>
                                                        <div className="flex w-full mt-2 mb-3 text-left">
                                                            <label className="w-2/5 text-text-main-color" htmlFor={`outro`}>Ficheiro:</label>
                                                            <input className="w-3/5 input-file" type="file" id="ficheiro" name="ficheiro" accept={".zip, .rar"} />
                                                        </div>
                                                        <div className="flex justify-center">
                                                            <button className="button-cancelar" onClick={() => setAlterar(-1)}>Cancelar</button>
                                                            <button className="ml-2 button-confirmar" onClick={() => cloudinaryFicheiro(index)}>Submeter</button>
                                                        </div>
                                                    </>}
                                                </div>
                                            ))}
                                        </> : (<div className="text-text-main-color mt-6 text-center">
                                            Sem ficheiros.
                                        </div>)}</>
                                    </>
                                        : <>{mapTopico && mapTopico.length > 0 ? <>
                                            {mapTopico.map((item, index) => (
                                                <div key={index}>
                                                    {alterar !== index ? <>
                                                        <div className="flex justify-between w-full mt-6 text-left">
                                                            {item && item.file &&
                                                                <div>
                                                                    <span className="mr-1 label-input">Documento:</span><a className="link" htmlFor={`fullName`} href={item.file} target="_blank">{item.name}</a><br />
                                                                    <span className="mr-1 label-input ">Editado por: <span className="normal-case link tooltip" data-tip={item.nameUser}>{firstName(item.nameUser)}</span> -{formatDate(item.date)}</span><br />
                                                                </div>}
                                                            <div className="flex">
                                                                <button className="mr-2 button-geral" onClick={() => { setAlterar(index) }}><BsFillPencilFill /></button>
                                                                <button className="mr-2 button-geral" onClick={() => { downloadFile(item.file) }}><FiDownload /></button>
                                                                <button className="button-cancelar w-fit" onClick={() => { eliminarFicheiro(index) }}><IoMdRemoveCircle /></button>
                                                            </div>
                                                        </div>
                                                    </> : <>
                                                        <div className="flex w-full text-left">
                                                            <label className="w-2/5 text-text-main-color" htmlFor={`fullName`}>Nome do documento:</label>
                                                            <input className="w-3/5 input-field" type="text" id={`fullName`} name={`fullName`} onChange={(e) => setNome(e.target.value)} />
                                                        </div>
                                                        <div className="flex w-full mt-2 mb-3 text-left">
                                                            <label className="w-2/5 text-text-main-color" htmlFor={`outro`}>Ficheiro:</label>
                                                            <input className="w-3/5 input-file" type="file" id="ficheiro" name="ficheiro" accept=".zip, .rar" />
                                                        </div>
                                                        <div className="flex justify-center">
                                                            <button className="button-cancelar" onClick={() => setAlterar(-1)}>Cancelar</button>
                                                            <button className="ml-2 button-confirmar" onClick={() => cloudinaryFicheiro(index)}>Submeter</button>
                                                        </div>
                                                    </>}
                                                </div>
                                            ))}
                                        </> : (<div className="text-text-main-color mt-6 text-center">
                                            Sem ficheiros.
                                        </div>)}</>}
                                </DialogBody>
                                : <DialogBody>
                                    <div className="flex items-center justify-center w-full h-full bg-secundary-color">
                                        <div className="loading"></div>
                                    </div>
                                </DialogBody>}
                            <hr />
                        </div>
                        <button className="absolute top-1.5 right-1.5" onClick={handleOpen}>
                            <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                        </button>
                    </div>
                </div>
            </Dialog >
        </>
    );
}