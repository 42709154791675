import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import ConfirmToaster from "../Toast/toastConfirmChoice";
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import NavbarCoordenador from "../Navbars/NavbarCoordenador/navbarCoordenador";
import Header from "../Header";

export default function EvaluationFormador({ token }) {
    const [valor1, setValor1] = useState("")
    const [valor2, setValor2] = useState("")
    const [valor3, setValor3] = useState("")
    const [valor4, setValor4] = useState("")
    const [valor5, setValor5] = useState("")
    const [loading, setlLoading] = useState(false)
    const [data, setData] = useState("")
    const [dataFormador, setDataFormador] = useState("")
    const navigate = useNavigate();
    const params = useParams();

    let total
    if (data && data.formato === "Presencial") {
        total = parseInt((((((parseInt(valor1)) + (parseInt(valor2)) + (parseInt(valor3)) + (parseInt(valor4))) + (parseInt(valor5))) - 5) * 100) / 10) || 0;
    }
    else total = parseInt((((parseInt(valor1) - 1) * 5 + (parseInt(valor2) - 1) * 4 + (parseInt(valor3) - 1) * 3 + (parseInt(valor4) - 1) * 2) * 100) / 28) || 0;

    function handleClick() {
        navigate(-1);
    }

    useEffect(() => {
        getUFCD()
    }, [total, loading, dataFormador]);


    function getUFCD() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcd}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                }
            }).then((result) => {
                result.json().then((resp) => {
                    setData(resp)
                    if (!dataFormador) {
                        resp.evaluation.forEach(element => {
                            if (element.formadorId === params.id_formador) setDataFormador(element)
                        });
                    }
                    setlLoading(true)
                })
            })
    }
    function avaliarFormadorUFCD() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/trainer/${params.id_candidatura}/avaliar/${params.id_ufcd}`,
            {
                method: "PATCH",
                body: JSON.stringify({
                    criterio1: valor1,
                    criterio2: valor2,
                    criterio3: valor3,
                    criterio4: valor4,
                    criterio5: data.formato === "presencial" && valor5,
                    evaluation: total,
                    formadorId: params.id_formador
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                }
            }).then((result) => {
                result.json().then((resp) => {
                })
                handleClick();
            })
    }

    return (
        <>
            <main className={token === "coord" ? "md:grid sm:grid-cols-10 min-h-screen mx-auto" : "min-h-screen"}>
                {token !== "coord" && <div className="w-full">
                    {token === "admin2" && <NavbarAdmin2 currentPage={"gerirCursos"} />}
                    {token === "admin1" && <NavbarAdmin1 currentPage={"gerirCursos"} />}
                </div>}
                {token === "coord" && <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={"cursos"} />
                </div>}
                {token === "coord" && <div className="back-button-noNavbar">
                    <button onClick={() => navigate(-1)} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </button>
                </div>}
                <div className={token === "coord" ? "sm:col-span-8 h-full" : "flex-grow mt-8"}>
                   {token === "coord" && <Header />}
                    {token !== "coord" && <button onClick={() => navigate(-1)} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </button>}
                    {data && <div className="mt-10 table-container">
                        <table className="my-table">
                            <thead className="bg-main-color">
                                <tr className="text-white h-14">
                                    <th className="p-2 min-w-[80px] border border-white">Critérios</th>
                                    <th className="p-2 min-w-[80px] border border-white">Pontos por grau de resultado</th>
                                    {data.formato !== "Presencial" && <th className="p-2 min-w-[80px] border border-white">Ponderação</th>}
                                    <th className="p-2 min-w-[80px] border border-white">Resultado</th>
                                </tr>
                            </thead>
                            {data.formato === "Presencial" ?
                                <tbody className="text-text-main-color">
                                    <tr>
                                        <td>
                                            <h4>Critério 1 - Foco nas aprendizagens e nos conteúdos</h4>
                                        </td>
                                        <td>
                                            <tr>
                                                <td>
                                                    <p>
                                                        2 PONTOS - O/a formador/a desenvolve a formação visando a qualidade das
                                                        aprendizagens e a qualidade do relacionamento pedagógico dentro da sala de formação.
                                                        O programa de formação é cumprido em toda a
                                                        sua extensão, isto é, abordou todos os assuntos previstos no programa de forma
                                                        equilibrada, e na profundidade adequada.

                                                    </p>
                                                </td>
                                            </tr>
                                            <tr><td><p>
                                                1 PONTO - Embora o/a formador/a desenvolva a formação visando a qualidade das
                                                aprendizagens e a qualidade do relacionamento pedagógico dentro da sala de formação,
                                                os conteúdos do programa, ou parte deles.
                                            </p></td></tr>
                                            <tr><td><p>
                                                0 PONTOS - OU o/a formador/a teve dificuldades ao nível do desenvolvimento de um
                                                processo de aprendizagem com qualidade OU ao nivel do relacionamento pedagógico,
                                                OU os conteúdos, ou parte deles, foram aprofundados de forma muito superficial
                                            </p></td></tr>
                                        </td>
                                        <td>{dataFormador && dataFormador.criterio1 ? <div>{parseInt(dataFormador.criterio1) - 1}</div> :
                                            <select onWheel={(e) => e.target.blur()} className="selector" id="valor1" name="valor1" onChange={(e) => { setValor1(e.target.value) }}>
                                                <option value="" disabled selected>Selecione a nota</option>
                                                <option value={1}>0</option>
                                                <option value={2}>1</option>
                                                <option value={3}>2</option>
                                            </select>}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td><h4>Critério 2 - Adequação dos materiais pedagógicos</h4></td>
                                        <td>
                                            <tr><td><p>
                                                2 PONTOS - O/a formador/a desenvolveu materiais pedagógicos originais ou
                                                identificando devidamente a bibliografia usada, COM bom aspeto gráfico, e COM a devida
                                                publicitação da MARCA da Entidade e do
                                                Organismo FINANCIADOR.
                                            </p></td></tr>
                                            <tr><td><p>
                                                1 PONTO - Embora o/a formador/a desenvolva materiais pedagógicos originais ou
                                                identificando devidamente a bibliografia usada, OU cometeu erros na publicitação da
                                                MARCA da Entidade e do Organismo FINANCIADOR.
                                            </p></td></tr>
                                            <tr><td><p>
                                                0 PONTOS - OU o/a formador/a não identifica devidamente a bibliografia usada nos
                                                materiais pedagógicos OU o aspecto gráfico destes é desleixado e pobre.

                                            </p></td></tr>
                                        </td>
                                        <td>
                                        {dataFormador && dataFormador.criterio2 ? <b>{parseInt(dataFormador.criterio2) - 1}</b> :<select onWheel={(e) => e.target.blur()} className="selector" id="valor2" name="valor2" onChange={(e) => { setValor2(e.target.value) }}>
                                                <option value="" disabled selected>Selecione a nota</option>
                                                <option value={1}>0</option>
                                                <option value={2}>1</option>
                                                <option value={3}>2</option>
                                            </select>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><h4>Critério 3 - Cumprimento de preceitos administrativos e prazos</h4></td>
                                        <td>
                                            <tr><td><p>
                                                2 PONTOS - O/a formador/a entrega o DTP com toda a documentação acordada E no
                                                prazo estabelecido com a Coordenação Pedagógica
                                            </p></td></tr>
                                            <tr><td><p>
                                                1 PONTO - Embora o/a formador/a entregue a documentação atempadamente,
                                                comete/eu falhas (não sistemáticas) e a Coordenação Pedagógica teve que pedir
                                                retificações e/ou documentos suplementares.
                                            </p></td></tr>
                                            <tr><td><p>
                                                0 PONTOS - OU o/a formador/a não cumpre prazos de entrega de documentos OU a
                                                documentação do DTP está incompleta e é necessário muito tempo até este estar
                                                fechado.
                                            </p></td></tr>
                                        </td>
                                        <td>
                                        {dataFormador && dataFormador.criterio3 ? <b>{parseInt(dataFormador.criterio3) - 1}</b> :<select onWheel={(e) => e.target.blur()} className="selector" id="valor3" name="valor3" onChange={(e) => { setValor3(e.target.value) }}>
                                                <option value="" disabled selected>Selecione a nota</option>
                                                <option value={1}>0</option>
                                                <option value={2}>1</option>
                                                <option value={3}>2</option>
                                            </select>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><h4>Critério 4 - Satisfação dos formandos com o desempenho do formador</h4></td>
                                        <td>
                                            <tr><td><p>
                                                2 PONTOS – Na “Ficha de Reação à Formação”, em todos os critérios relacionados com o “formador”, obtém uma taxa de notas “4” e “5” superior a 90%.
                                            </p></td></tr>
                                            <tr><td><p>
                                                1 PONTO - Na “Ficha de Reação à Formação”, em todos os critérios relacionados com o “formador”, obtém uma taxa de notas “4” e “5” superior a 80%.
                                            </p></td></tr>
                                            <tr><td><p>
                                                0 PONTOS - Na “Ficha de Reação à Formação”, em todos os critérios relacionados com
                                                o “formador”, NÃO obtém uma taxa de notas “4” e “5” superior a 80%.
                                            </p></td></tr>
                                        </td>
                                        <td>
                                        {dataFormador && dataFormador.criterio4 ? <b>{parseInt(dataFormador.criterio4) - 1}</b> :<select onWheel={(e) => e.target.blur()} className="selector" id="valor4" name="valor4" onChange={(e) => { setValor4(e.target.value) }}>
                                                <option value="" disabled selected>Selecione a nota</option>
                                                <option value={1}>0</option>
                                                <option value={2}>1</option>
                                                <option value={3}>2</option>
                                            </select>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><h4>Critério 5 - Interesse pela formação interna</h4></td>
                                        <td>
                                            <tr><td><p>
                                                2 PONTOS –  O/a formador/a realizou a formação interna com muito interesse.
                                            </p></td></tr>
                                            <tr><td><p>
                                                1 PONTO -  O/a formador/a realizou a formação interna com interesse.
                                            </p></td></tr>
                                            <tr><td><p>
                                                0 PONTOS - O/a formador/a realizou a formação interna com pouco interesse OU não
                                                frequentou qualquer formação interna no último ano.
                                            </p></td></tr>
                                        </td>
                                        <td>
                                        {dataFormador && dataFormador.criterio5 ? <b>{parseInt(dataFormador.criterio5) - 1}</b> : <select onWheel={(e) => e.target.blur()} className="selector" id="valor5" name="valor5" onChange={(e) => { setValor5(e.target.value) }}>
                                                <option value="" disabled selected>Selecione a nota</option>
                                                <option value={1}>0</option>
                                                <option value={2}>1</option>
                                                <option value={3}>2</option>
                                            </select>}
                                        </td>
                                    </tr>
                                </tbody> :
                                <tbody className="text-text-main-color">
                                    <tr>
                                        <td>
                                            <h4>Critério 1 Qualidade dos Materiais Pedagógicos</h4>
                                        </td>
                                        <td>
                                            <tr>
                                                <td>
                                                    <p>
                                                        2 PONTOS - O/a formador/a desenvolve materiais pedagógicos (vídeo aulas, atividades, ou outros)
                                                        que fomentam a aprendizagem e geram curiosidade pela aprendizagem nos formandos. Cumpre sempre
                                                        a regra 20 Objetos de Aprendizagem para 25 horas e 40 Objetos de Aprendizagem para 50 horas.
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr><td><p>
                                                1 PONTO - Embora o/a formador/a cumpra a regra 20 Objetos de Aprendizagem para 25 horas e
                                                40 Objetos de Aprendizagem para 50 horas, as vídeo aulas devem ser mais centradas nas aprendizagens técnicas,
                                                ou então, devem ser mais aprofundadas. A duração das vídeo-aulas é adequada e não ultrapassa os 10 minutos.
                                            </p></td></tr>
                                            <tr><td><p>
                                                0 PONTOS - OU o/a formador/a não cumpre a regra dos 20 Objetos de Aprendizagem para 25 horas e
                                                40 Objetos de Aprendizagem para 50 horas, OU tem que melhorar as vídeo aulas, mesmo no básico como:
                                                sincronizar a animação com a locução ou devem ser mais curtas ou reduzir os slides têm excesso de conteúdos.
                                            </p></td></tr>
                                        </td>
                                        <td><h1>5</h1></td>
                                        <td>{dataFormador && dataFormador.criterio1 ? <b>{parseInt(dataFormador.criterio1) - 1}</b> :
                                            <select onWheel={(e) => e.target.blur()} className="selector" id="valor1" name="valor1" onChange={(e) => { setValor1(e.target.value) }}>
                                                <option value="" disabled selected>Selecione a nota</option>
                                                <option value={1}>0</option>
                                                <option value={2}>1</option>
                                                <option value={3}>2</option>
                                            </select>}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td><h4>Critério 2  Acompanhamento aos participantes</h4></td>
                                        <td>
                                            <tr><td><p>
                                                2 PONTOS - O/a formador/a dá feedback aos participantes de forma consistente, acrescentando valor
                                                ao debate e suscitando reflexão aos formandos. Cumpre a regra de dar feedback a 100% das intervenções dos
                                                participantes no FÓRUM GERAL.
                                            </p></td></tr>
                                            <tr><td><p>
                                                1 PONTO - Embora o/a formador/a cumpra a regra de dar feedback a 100% das intervenções dos participantes
                                                FÓRUM GERAL, o tipo de feedback tem de acrescentar mais valor e suscitar aprendizagens nos formandos.
                                            </p></td></tr>
                                            <tr><td><p>
                                                0 PONTOS - OU o/a formador/a não cumpre a regra de dar feedback a 100% das intervenções dos participantes,
                                                OU, genericamente, não contribui para as aprendizagens nesses feedbacks.
                                            </p></td></tr>
                                        </td>
                                        <td><h1>4</h1></td>
                                        <td>
                                        {dataFormador && dataFormador.criterio2 ? <b>{parseInt(dataFormador.criterio2) - 1}</b> :<select onWheel={(e) => e.target.blur()} className="selector" id="valor2" name="valor2" onChange={(e) => { setValor2(e.target.value) }}>
                                                <option value="" disabled selected>Selecione a nota</option>
                                                <option value={1}>0</option>
                                                <option value={2}>1</option>
                                                <option value={3}>2</option>
                                            </select>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><h4>Critério 3 Cumprimento de preceitos administrativos e prazos</h4></td>
                                        <td>
                                            <tr><td><p>
                                                2 PONTOS - O/a formador/a entrega a PAUTA com dados corretamente transferidos da plataforma e no prazo máximo
                                                de 15 dias (consecutivos) após o último dia de formação, à Coordenação Pedagógica/Mediação.A restante
                                                documentação do DTP é entregue dentro desse prazo (inclsive o Registo de Assiduidade Assíncrona do Formador e as Folhas de Sumário da Formação Síncrona).
                                            </p></td></tr>
                                            <tr><td><p>
                                                1 PONTO - Embora o/a formador/a entregue a documentação atempadamente, comete/eu falhas (não sistemáticas) na construção da PAUTA ou na fidedignidade da informação constante nos registos de assiduidade.
                                            </p></td></tr>
                                            <tr><td><p>
                                                0 PONTOS - OU o/a formador/a não cumpre prazos de entrega de documentos OU a PAUTA e registos de assiduidade têm erros.
                                            </p></td></tr>
                                        </td>
                                        <td><h1>3</h1></td>
                                        <td>
                                        {dataFormador && dataFormador.criterio3 ? <b>{parseInt(dataFormador.criterio3) - 1}</b> : <select onWheel={(e) => e.target.blur()} className="selector" id="valor3" name="valor3" onChange={(e) => { setValor3(e.target.value) }}>
                                                <option value="" disabled selected>Selecione a nota</option>
                                                <option value={1}>0</option>
                                                <option value={2}>1</option>
                                                <option value={3}>2</option>
                                            </select>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><h4>Critério 4 Satisfação dos formandos com o desempenho do formador</h4></td>
                                        <td>
                                            <tr><td><p>
                                                2 PONTOS – Na “Ficha de Reação à Formação”, em todos os critérios relacionados com o “formador”, obtém uma taxa de notas “4” e “5” superior a 90%.
                                            </p></td></tr>
                                            <tr><td><p>
                                                1 PONTO - Na “Ficha de Reação à Formação”, em todos os critérios relacionados com o “formador”, obtém uma taxa de notas “4” e “5” superior a 80%.
                                            </p></td></tr>
                                            <tr><td><p>
                                                0 PONTOS - Na “Ficha de Reação à Formação”, em todos os critérios relacionados com o “formador”, NÃO obtém uma taxa de notas “4” e “5” superior a 80%.
                                            </p></td></tr>
                                        </td>
                                        <td><h1>2</h1></td>
                                        <td>
                                        {dataFormador && dataFormador.criterio4? <b>{parseInt(dataFormador.criterio4) - 1}</b> :<select onWheel={(e) => e.target.blur()} className="selector" id="valor4" name="valor4" onChange={(e) => { setValor4(e.target.value) }}>
                                                <option value="" disabled selected>Selecione a nota</option>
                                                <option value={1}>0</option>
                                                <option value={2}>1</option>
                                                <option value={3}>2</option>
                                            </select>}
                                        </td>
                                    </tr>
                                </tbody>}
                        </table>
                    </div>}
                    {dataFormador && dataFormador.evaluationFinal ?<div className="m-10 text-3xl"> <b>{parseInt(dataFormador.evaluationFinal)}%</b></div>:<div className="mb-10">{total === null ? <h2></h2> : <h2 className="text-main-color">TOTAL FINAL = {total}%</h2>}
                        <ConfirmToaster css={"button-confirmar mt-2 hover:brightness-75 tooltip"} icon={"Confirmar"} title={"Avaliar"} message={"Deseja avaliar o formador ? (Esta ação é irreversível)"} confirm={avaliarFormadorUFCD} tooltip={"Avaliar Formador"}></ConfirmToaster>
                    </div>}
                </div>
            </main>
            <Footer />
        </>
    )
}