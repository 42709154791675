import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Header from "../../../components/Header";
import Footer from '../../../components/Footer'
import toastError from '../../../components/Toast/toastError';
import { ToastContainer, toast } from 'react-toastify'
import { FaUserTie } from "react-icons/fa";

export default function Login() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false)
    //Mostrar pass
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const navigate = useNavigate();

    let idLogged = 0

    // dados do input
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [stayLogin, setStayLogin] = useState(false)

    // função login em for, no entanto o login não está local e apenas é um acesso e não uma autorização
    // para aceder rapidamente ao login deixo aqui um login modelo para teste  email:a   pass: 2134
    function submeterLogin(e) {
        e.preventDefault();
        getCandidaturasValidados().then((data) => {
            if (data.status === "not_found" || data.status === "not_valid") {
                toastError(data.message)
            } else {
                if (data.role === 2) {
                    navigate('/MenuSuperAdmin')
                } else if (data.role === 1) {
                    idLogged = data.id
                    navigate(`/MenuAdmin/${idLogged}`)
                }
            };
        })
    }
    
    //Obter candidaturas validas
    function getCandidaturasValidados() {
        const handleErrors = response => {
            const data = response.json()
            if (!response.ok) {
                toastError(data)
            }
            return data;
        }

        return new Promise((resolve, reject) => {

            fetch(`${process.env.REACT_APP_API_URL}/admins/login`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        email: email,
                        password: password,
                        stayLogin: stayLogin
                    })
                })
                .then(handleErrors)
                .then(
                    (result) => {
                        if (result.role === 2) {
                            localStorage.setItem('admin2', result.jwt)
                            localStorage.setItem("id", result.id)
                        }
                        else if (result.role === 1) {
                            if(result.nomeRole === "Administrador - Gestor de Operação"){
                                localStorage.setItem('TipoAdmin', "Administrador - Gestor de Operação")
                            }
                            if(result.nomeRole === "Gestor Financeiro"){
                                localStorage.setItem('TipoAdmin', "Gestor Financeiro")
                            }
                            else if(result.nomeRole === "Técnico Administrativo"){
                                localStorage.setItem('TipoAdmin', "Técnico Administrativo")
                            }
                            else if(result.nomeRole === "TORVC"){
                                localStorage.setItem('TipoAdmin', "TORVC")
                            }
                            else if(result.nomeRole === "FA"){
                                localStorage.setItem('TipoAdmin', "FA")
                            }
                            else if(result.nomeRole === "Marketing"){
                                localStorage.setItem('TipoAdmin', "Marketing")
                            }
                            else if(result.nomeRole === "Entidade de Ligação"){
                                localStorage.setItem('TipoAdmin', "Entidade de Ligação")
                            }
                            localStorage.setItem('admin1', result.jwt)
                            localStorage.setItem("id", result.id)
                        }
                        setData(result);
                        setLoading(false);
                        resolve(result)
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                        reject(error)
                    }
                );
        })

    }
    useEffect(() => {
        return () => {
            toast.dismiss();
        }
    }, []);

    return (
        <>
            <main className='relative min-h-screen'>
                <Header />
                <div className='back-button-div-noNavbar'>
                    <Link to={'/'} className="">
                        <img className='back-button-img' src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} alt="Back" />
                    </Link>
                </div>
                <div className='flex items-center flex-grow'>
                    <div className="flex flex-col px-3 py-20 mb-6 rounded-lg bg-secundary-color md:px-16 mt-10">
                        <div className='flex justify-center '>
                            <FaUserTie className="text-main-color" size={60} />
                        </div>
                        <h1 className="mt-2 title">Admin</h1>
                        <form id="login-form" onSubmit={submeterLogin}>
                            <div className='flex items-center justify-center mb-5'>
                                <span className='mr-2'>
                                    <i className="fas fa-user text-main-color"></i>
                                </span>
                                <input type="email" id="email" className='text-lg input-field w-[270px] pl-2 rounded-xl h-10' name="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="E-mail" required />
                            </div>
                            <div className='flex items-center justify-center'>
                                <span className='mr-2'>
                                    <i className="fas fa-key text-main-color"></i>
                                </span>
                                <div className='relative'>
                                    <input type={showPassword ? 'text' : 'password'} id="password" name="password" className='text-lg input-field h-10 w-[270px] pl-2 rounded-xl' onChange={(e) => { setPassword(e.target.value) }} placeholder="Password" required />
                                    <button type="button" onClick={toggleShowPassword} className="absolute top-1 right-1">
                                        <i className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                    </button>
                                </div>
                            </div>
                            <div className='mt-2 mb-5'>
                                <Link className="link" to="/forgotpassword">Esqueci-me da Palavra-passe</Link>
                            </div>
                            <div className='flex items-center justify-center my-5'>
                                <input id="manterlogin" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" type="checkbox" name="stayLogin" value="True" checked={stayLogin === true} onChange={(e) => { setStayLogin(e.target.checked ? true : false) }} />
                                <label htmlFor="manterlogin" className='ml-2 text-text-second-color'>Manter sessão de login iniciada</label>
                            </div>
                            <input className="max-w-[270px] w-full h-10 button-geral rounded-xl ml-6" type="submit" value="Login" />
                        </form>
                    </div>
                </div>
                <div className="w-full mt-24 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    )
}