export default function formatarESeparardata(date) {
  if (date) {
    // Check if the browser is Safari
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (!isSafari) {
      if (date.split("-")[0].length === 2) {
        date = date.replaceAll("-", "/"); // Use / to ensure valid date format
        date = date.split("/").reverse().join("/"); // Adjust the format to YYYY/MM/DD
        date = new Date(date);
      } else {
        let e = new Date(date);
        if (!isNaN(e.getTime())) {
          // Check if the date is valid
          date = e;
        }
      }
    } else {
      // For other browsers, replace "-" with "," and manually format the date
      date = new Date(date);
    }

    // Map of month numbers to month names
    const meses = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ];

    // Get day, month, and year
    const dia = date.getDate().toString().padStart(2, "0");
    const mes = meses[date.getMonth()];
    const ano = date.getFullYear().toString();

    return { dia, mes, ano };
  }
  return null;
}
