const CountryDropdown = ({ defaultValue,onChange,css}) => {
    const nationalities = [
        { value: 'Português', label: 'Português' },
        { value: 'Afegão', label: 'Afegão' },
        { value: 'Albanês', label: 'Albanês' },
        { value: 'Alemão', label: 'Alemão' },
        { value: 'Americano', label: 'Americano' },
        { value: 'Andorrano', label: 'Andorrano' },
        { value: 'Angolano', label: 'Angolano' },
        { value: 'Antiguano', label: 'Antiguano' },
        { value: 'Argelino', label: 'Argelino' },
        { value: 'Argentino', label: 'Argentino' },
        { value: 'Arménio', label: 'Arménio' },
        { value: 'Australiano', label: 'Australiano' },
        { value: 'Austríaco', label: 'Austríaco' },
        { value: 'Azeri', label: 'Azeri' },
        { value: 'Bahamiano', label: 'Bahamiano' },
        { value: 'Bangladechiano', label: 'Bangladechiano' },
        { value: 'Barbadense', label: 'Barbadense' },
        { value: 'Barenita', label: 'Barenita' },
        { value: 'Belga', label: 'Belga' },
        { value: 'Belizenho', label: 'Belizenho' },
        { value: 'Benineseo', label: 'Benineseo' },
        { value: 'Bielorrusso', label: 'Bielorrusso' },
        { value: 'Birmanês', label: 'Birmanês' },
        { value: 'Boliviano', label: 'Boliviano' },
        { value: 'Bósnio', label: 'Bósnio' },
        { value: 'Brasileiro', label: 'Brasileiro' },
        { value: 'Britânico', label: 'Britânico' },
        { value: 'Bruneíno', label: 'Bruneíno' },
        { value: 'Búlgaro', label: 'Búlgaro' },
        { value: 'Burquino', label: 'Burquino' },
        { value: 'Burundiano', label: 'Burundiano' },
        { value: 'Butanês', label: 'Butanês' },
        { value: 'Cabo-verdiano', label: 'Cabo-verdiano' },
        { value: 'Camaronês', label: 'Camaronês' },
        { value: 'Cambojano', label: 'Cambojano' },
        { value: 'Canadiano', label: 'Canadiano' },
        { value: 'Cazaque', label: 'Cazaque' },
        { value: 'Centro-africano', label: 'Centro-africano' },
        { value: 'Chadiano', label: 'Chadiano' },
        { value: 'Chileno', label: 'Chileno' },
        { value: 'Chinês', label: 'Chinês' },
        { value: 'Cingalês', label: 'Cingalês' },
        { value: 'Colombiano', label: 'Colombiano' },
        { value: 'Comoriano', label: 'Comoriano' },
        { value: 'Congolês', label: 'Congolês' },
        { value: 'Costarriquenho', label: 'Costarriquenho' },
        { value: 'Croata', label: 'Croata' },
        { value: 'Cubano', label: 'Cubano' },
        { value: 'Cipriota', label: 'Cipriota' },
        { value: 'Dinamarquês', label: 'Dinamarquês' },
        { value: 'Djibutiense', label: 'Djibutiense' },
        { value: 'Dominicano', label: 'Dominicano' },
        { value: 'Egípcio', label: 'Egípcio' },
        { value: 'Emirati', label: 'Emirati' },
        { value: 'Equatoriano', label: 'Equatoriano' },
        { value: 'Eritreu', label: 'Eritreu' },
        { value: 'Essuatiniano', label: 'Essuatiniano' },
        { value: 'Espanhol', label: 'Espanhol' },
        { value: 'Eslovaco', label: 'Eslovaco' },
        { value: 'Esloveno', label: 'Esloveno' },
        { value: 'Estónio', label: 'Estónio' },
        { value: 'Etíope', label: 'Etíope' },
        { value: 'Filipino', label: 'Filipino' },
        { value: 'Finlandês', label: 'Finlandês' },
        { value: 'Francês', label: 'Francês' },
        { value: 'Gabonês', label: 'Gabonês' },
        { value: 'Gambiano', label: 'Gambiano' },
        { value: 'Ganense', label: 'Ganense' },
        { value: 'Georgiano', label: 'Georgiano' },
        { value: 'Granadino', label: 'Granadino' },
        { value: 'Grego', label: 'Grego' },
        { value: 'Guatemalteco', label: 'Guatemalteco' },
        { value: 'Guianês', label: 'Guianês' },
        { value: 'Guineano', label: 'Guineano' },
        { value: 'Guineense', label: 'Guineense' },
        { value: 'Guineense Equatorial', label: 'Guineense Equatorial' },
        { value: 'Haitiano', label: 'Haitiano' },
        { value: 'Holandês', label: 'Holandês' },
        { value: 'Hondurenho', label: 'Hondurenho' },
        { value: 'Húngaro', label: 'Húngaro' },
        { value: 'Iemenita', label: 'Iemenita' },
        { value: 'Indiano', label: 'Indiano' },
        { value: 'Indonésio', label: 'Indonésio' },
        { value: 'Iraniano', label: 'Iraniano' },
        { value: 'Iraquiano', label: 'Iraquiano' },
        { value: 'Irlandês', label: 'Irlandês' },
        { value: 'Islandês', label: 'Islandês' },
        { value: 'Israelita', label: 'Israelita' },
        { value: 'Italiano', label: 'Italiano' },
        { value: 'Jamaicano', label: 'Jamaicano' },
        { value: 'Japonês', label: 'Japonês' },
        { value: 'Jordaniano', label: 'Jordaniano' },
        { value: 'Kiribatiano', label: 'Kiribatiano' },
        { value: 'Kosovar', label: 'Kosovar' },
        { value: 'Kuwaitiano', label: 'Kuwaitiano' },
        { value: 'Laosiano', label: 'Laosiano' },
        { value: 'Letão', label: 'Letão' },
        { value: 'Libanês', label: 'Libanês' },
        { value: 'Liberiano', label: 'Liberiano' },
        { value: 'Líbio', label: 'Líbio' },
        { value: 'Liechtensteiniano', label: 'Liechtensteiniano' },
        { value: 'Lituano', label: 'Lituano' },
        { value: 'Luxemburguês', label: 'Luxemburguês' },
        { value: 'Macedônio', label: 'Macedônio' },
        { value: 'Malagasy', label: 'Malagasy' },
        { value: 'Malaio', label: 'Malaio' },
        { value: 'Malauiano', label: 'Malauiano' },
        { value: 'Maldivo', label: 'Maldivo' },
        { value: 'Maliano', label: 'Maliano' },
        { value: 'Maltes', label: 'Maltês' },
        { value: 'Marroquino', label: 'Marroquino' },
        { value: 'Marshalês', label: 'Marshalês' },
        { value: 'Mauriciano', label: 'Mauriciano' },
        { value: 'Mauritano', label: 'Mauritano' },
        { value: 'Mexicano', label: 'Mexicano' },
        { value: 'Micronésio', label: 'Micronésio' },
        { value: 'Moldavo', label: 'Moldavo' },
        { value: 'Monegasco', label: 'Monegasco' },
        { value: 'Mongol', label: 'Mongol' },
        { value: 'Montenegrino', label: 'Montenegrino' },
        { value: 'Moçambicano', label: 'Moçambicano' },
        { value: 'Namibiano', label: 'Namibiano' },
        { value: 'Nauruano', label: 'Nauruano' },
        { value: 'Nepalês', label: 'Nepalês' },
        { value: 'Neozelandês', label: 'Neozelandês' },
        { value: 'Nigeriano', label: 'Nigeriano' },
        { value: 'Nigerino', label: 'Nigerino' },
        { value: 'Norte-coreano', label: 'Norte-coreano' },
        { value: 'Norueguês', label: 'Norueguês' },
        { value: 'Omanense', label: 'Omanense' },
        { value: 'Paquistanês', label: 'Paquistanês' },
        { value: 'Palauano', label: 'Palauano' },
        { value: 'Palestino', label: 'Palestino' },
        { value: 'Panamenho', label: 'Panamenho' },
        { value: 'Papuásio', label: 'Papuásio' },
        { value: 'Paraguaio', label: 'Paraguaio' },
        { value: 'Peruano', label: 'Peruano' },
        { value: 'Polaco', label: 'Polaco' },
        { value: 'Porto-riquenho', label: 'Porto-riquenho' },
        { value: 'Catari', label: 'Catari' },
        { value: 'Queniano', label: 'Queniano' },
        { value: 'Quirguiz', label: 'Quirguiz' },
        { value: 'Romeno', label: 'Romeno' },
        { value: 'Ruandês', label: 'Ruandês' },
        { value: 'Russo', label: 'Russo' },
        { value: 'Salomônense', label: 'Salomônense' },
        { value: 'Salvadorenho', label: 'Salvadorenho' },
        { value: 'Samoano', label: 'Samoano' },
        { value: 'Sanmarinense', label: 'Sanmarinense' },
        { value: 'São-cristovense', label: 'São-cristovense' },
        { value: 'São-lucense', label: 'São-lucense' },
        { value: 'São-tomense', label: 'São-tomense' },
        { value: 'São-vicentino', label: 'São-vicentino' },
        { value: 'Saudi', label: 'Saudi' },
        { value: 'Senegalês', label: 'Senegalês' },
        { value: 'Sérvio', label: 'Sérvio' },
        { value: 'Seychellense', label: 'Seychellense' },
        { value: 'Serra-leonês', label: 'Serra-leonês' },
        { value: 'Singapuriano', label: 'Singapuriano' },
        { value: 'Sírio', label: 'Sírio' },
        { value: 'Somali', label: 'Somali' },
        { value: 'Sudanês', label: 'Sudanês' },
        { value: 'Sul-africano', label: 'Sul-africano' },
        { value: 'Sul-coreano', label: 'Sul-coreano' },
        { value: 'Sul-sudanês', label: 'Sul-sudanês' },
        { value: 'Surinamês', label: 'Surinamês' },
        { value: 'Sueco', label: 'Sueco' },
        { value: 'Suíço', label: 'Suíço' },
        { value: 'Tajique', label: 'Tajique' },
        { value: 'Tailandês', label: 'Tailandês' },
        { value: 'Tanzaniano', label: 'Tanzaniano' },
        { value: 'Timorense', label: 'Timorense' },
        { value: 'Togolês', label: 'Togolês' },
        { value: 'Tonganês', label: 'Tonganês' },
        { value: 'Trinitário-tobaguense', label: 'Trinitário-tobaguense' },
        { value: 'Tunisino', label: 'Tunisino' },
        { value: 'Turco', label: 'Turco' },
        { value: 'Turcomano', label: 'Turcomano' },
        { value: 'Tuvaluano', label: 'Tuvaluano' },
        { value: 'Ucraniano', label: 'Ucraniano' },
        { value: 'Ugandense', label: 'Ugandense' },
        { value: 'Uruguaio', label: 'Uruguaio' },
        { value: 'Uzbeque', label: 'Uzbeque' },
        { value: 'Vanuatuense', label: 'Vanuatuense' },
        { value: 'Venezuelano', label: 'Venezuelano' },
        { value: 'Vietnamita', label: 'Vietnamita' },
        { value: 'Zambiano', label: 'Zambiano' },
        { value: 'Zimbabuense', label: 'Zimbabuense' }
    ];
    const handleDropdownChange = (e) => {
        const selectedValue = e.target.value;
        onChange(selectedValue); // Pass the selected value to the parent component
      };
    return (
        <select onWheel={(e) => e.target.blur()} id="nationality" className={css?css:"text-lg selector"} defaultValue={defaultValue} onChange={handleDropdownChange}>
            <option value="" disabled>Selecione a sua nacionalidade </option>
            {nationalities.map((nationality) => (
                <option key={nationality.value} value={nationality.value}>
                    {nationality.label}
                </option>
            ))}
        </select>
    );
};

export default CountryDropdown;