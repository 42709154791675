import React, { useState, useEffect } from "react";
import { StyleFormandoGreenSkillsJobs } from "../Modal/stylesContratos/styled-contrato-formando-green-skills-jobs";
import AlterarFormato from "../FormChecks/formatDates/foramatDates";
import SepararData from "../FormChecks/formatDates/splitDates";
import toastError from "../Toast/toastError";
import toastSuccess from "../Toast/toastSuccess";
import refreshPage from "../Refresh";
import ConfirmToaster from "../Toast/toastConfirmChoice";

export default function ContratoFormadorGreenSkillsJobs({
  data,
  token,
  formador,
  tipo,
  setOpen,
}) {
  // This const is for finding the "dadosContrato" object in the array of objects
  // Verify if the formador._id is equal to the id in the array of objects
  const index =
    formador && tipo === "formador"
      ? data.dadosContrato.findIndex((item) => item.id === formador._id)
      : 0;
  const contrato = data?.dadosContrato[index]?.dadosContrato;

  // const to handle the date
  const { dia, mes, ano } = SepararData(data.dateBegin);

  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [valores, setValores] = useState({
    //cargoRepresentanteLegal: contrato?.cargoRepresentanteLegal ?? "",
    //parishRepresentanteLegal:  data?.percurso[0].entFormadora[0].distritoRepresentanteLegal ?? "",
    //countyRepresentanteLegal: data?.percurso[0].entFormadora[0].concelhoRepresentanteLegal ?? "",
    //ccRepresentanteLegal: contrato?.ccRepresentanteLegal ?? "",
    //validadeCcRepresentanteLegal: contrato?.validadeCcRepresentanteLegal ?? "",
    numeroContrato: contrato?.numeroContrato ?? "",
    valorHoraFormador: contrato?.valorHoraFormador ?? "",
    valorBrutoFormador: contrato?.valorBrutoFormador ?? "",
    valorLiquidoFormador: contrato?.valorLiquidoFormador ?? "",
    valorBrutoFormadorExtenso: contrato?.valorBrutoFormadorExtenso ?? "",
    valorIVAFormador: contrato?.valorIVAFormador ?? "",
    numeroDotacaoOrcamental: contrato?.numeroDotacaoOrcamental ?? "",
    numeroCompromisso: contrato?.numeroCompromisso ?? "",
  });

  // Function to send the contract to the coordinator
  async function enviarContratoFormador() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingEnvio(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formadores/contrato`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: formador ? formador._id : data.formadores[0]._id,
            id_ufcd: data._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        setOpen(false);
        setLoadingEnvio(false);
        toastError("Erro! O contrato não foi enviado");
        return;
      }

      setOpen(false);
      setLoadingEnvio(false);
      toastSuccess("Contrato enviado com sucesso");
      localStorage.setItem("contractUpdated", "true");

      setTimeout(() => {
        refreshPage();
      }, 1000);

      const result = await response.json();
    } catch (error) {
      console.error("Erro ao enviar contrato:", error);
      setOpen(false);
      toastError("Erro ao enviar contrato");
    }
  }

  // Auxiliary function to download the file
  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Opens the link in a new tab/window
    link.setAttribute("download", ""); // Optional, sets the download attribute to trigger download in some browsers
    link.click();
  }

  async function enviarContratoFormadorNoEmail() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingDownload(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formadores/contratoSemEmail`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: formador ? formador._id : data.formadores[0]._id,
            id_ufcd: data._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao iniciar download do contrato");
      }

      const result = await response.json();
      downloadFile(result);
      setOpen(false);
      setLoadingDownload(false);
      refreshPage();
    } catch (error) {
      console.error("Erro ao iniciar download do contrato:", error);
      setOpen(false);
      setLoadingDownload(false);
      toastError("Erro ao iniciar download do contrato");
    }
  }

  // State to handle input values
  const [inputValues, setInputValues] = useState({
    //inputCargoRepresentanteLegal: valores.cargoRepresentanteLegal,
    //inputParishRepresentanteLegal: valores.parishRepresentanteLegal,
    //inputCountyRepresentanteLegal: valores.countyRepresentanteLegal,
    //inputCcRepresentanteLegal: valores.ccRepresentanteLegal,
    //inputValidadeCcRepresentanteLegal: valores.validadeCcRepresentanteLegal,
    inputNumeroContrato: valores.numeroContrato,
    inputValorHoraFormador: valores.valorHoraFormador,
    inputValorBrutoFormador: valores.valorBrutoFormador,
    inputValorLiquidoFormador: valores.valorLiquidoFormador,
    inputValorBrutoFormadorExtenso: valores.valorBrutoFormadorExtenso,
    inputValorIVAFormador: valores.valorIVAFormador,
    inputNumeroDotacaoOrcamental: valores.numeroDotacaoOrcamental,
    inputNumeroCompromisso: valores.numeroCompromisso,
  });

  // State to handle input errors
  const [inputErrors, setInputErrors] = useState({
    //inputCargoRepresentanteLegal: false,
    //inputParishRepresentanteLegal: false,
    //inputCountyRepresentanteLegal: false,
    //inputCcRepresentanteLegal: false,
    //inputValidadeCcRepresentanteLegal: false,
    inputNumeroContrato: false,
    inputValorHoraFormador: false,
    inputValorBrutoFormador: false,
    inputValorLiquidoFormador: false,
    inputValorBrutoFormadorExtenso: false,
    inputValorIVAFormador: false,
    inputNumeroDotacaoOrcamental: false,
    inputNumeroCompromisso: false,
  });

  useEffect(() => {
    valores.dia = dia;
    valores.mes = mes;
    valores.ano = ano;
  }, [valores, dia, mes, ano]);

  // Handle input change and pass the value to the parent component by invoking the handleInputChange callback
  const handleInputChangeLocal = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
    setInputErrors({
      ...inputErrors,
      [name]: false,
    });
  };

  // Validate all inputs and return true if all of them are valid
  const validateInputs = () => {
    const errors = {};
    let isValid = true;

    for (const key in inputValues) {
      if (inputValues[key].trim() === "") {
        errors[key] = true;
        isValid = false;
      }
    }
    setInputErrors(errors);
    return isValid;
  };

  // Function to adjust the width of the input field
  const adjustWidth = (e) => {
    e.target.style.width = `${e.target.value.length + 1}ch`;
  };

  // Style on inputs is for validation purposes, mark the input with red border if it's empty and black if it's not
  // On inputs, onChange event is used to handle the input change locally and in the parent component
  return (
    <>
      {loadingEnvio ? (
        <div className="text-center text-2xl">
          A enviar contrato, por favor aguarde...
        </div>
      ) : loadingDownload ? (
        <div className="text-center text-2xl">
          A fazer download do contrato, por favor aguarde...
        </div>
      ) : (
        <>
          <StyleFormandoGreenSkillsJobs>
            <div className="body">
              <p>
                <h2>
                  <b>
                    CONTRATO DE FORMAÇÃO Nº{" "}
                    <input
                      className="input-field-contract-number"
                      type="number"
                      name="inputNumeroContrato"
                      defaultValue={valores.numeroContrato}
                      placeholder="0000"
                      onChange={(e) => {
                        setValores({
                          ...valores,
                          numeroContrato: e.target.value,
                        });
                        handleInputChangeLocal(e);
                      }}
                      style={{
                        border: inputErrors.inputNumeroContrato
                          ? "3px solid red"
                          : "1px solid black",
                      }}
                    />
                    /{ano}
                  </b>
                </h2>
                <h2>
                  Contrato de Aquisição de serviços de <b>Formador/a</b>
                </h2>
              </p>
              <br />
              <br />
              <br />
              <p>
                Ao(s) {dia} dia(s) do mês de {mes} do ano de {ano} em{" "}
                {data.percurso[0].entFormadora[0].morada},
                {data.percurso[0].entFormadora[0].postalCode}{" "}
                {data.percurso[0].entFormadora[0].distrito}, estando presentes
                como Outorgantes:
              </p>
              <br />
              <p>
                <b>PRIMEIRO OUTORGANTE:</b>{" "}
                {data.percurso[0].entFormadora[0].name}, titular do cartão de
                pessoa coletiva n.º {data.percurso[0].entFormadora[0].nif} com
                sede em {data.percurso[0].entFormadora[0].morada},{" "}
                {data.percurso[0].entFormadora[0].postalCode}, sita em{" "}
                {data.percurso[0].entFormadora[0].distrito}, devidamente
                representado neste ato por{" "}
                {data.percurso[0].entFormadora[0].representanteLegal} natural da
                freguesia de{" "}
                {data.percurso[0].entFormadora[0]
                  .localidadeRepresentanteLegal ?? "N/A"}
                {/* <input
                  className="input-field-contracts"
                  type="text"
                  name="inputParishRepresentanteLegal"
                  defaultValue={valores.parishRepresentanteLegal}
                  placeholder="Freguesia"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      parishRepresentanteLegal: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    border: inputErrors.inputParishRepresentanteLegal
                      ? "3px solid red"
                      : "1px solid black",
                    width: `${
                      valores.parishRepresentanteLegal.length > 0
                        ? valores.parishRepresentanteLegal.length + 2
                        : 10
                    }ch`,
                  }}
                /> */}
                , concelho de{" "}
                {data.percurso[0].entFormadora[0].concelhoRepresentanteLegal ??
                  "N/A"}
                {/* <input
                  className="input-field-contracts"
                  type="text"
                  name="inputCountyRepresentanteLegal"
                  defaultValue={valores.countyRepresentanteLegal}
                  placeholder="Concelho"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      countyRepresentanteLegal: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    width: `${
                      valores.countyRepresentanteLegal.length > 0
                        ? valores.countyRepresentanteLegal.length + 1
                        : 10
                    }ch`,
                    border: inputErrors.inputCountyRepresentanteLegal
                      ? "3px solid red"
                      : "1px solid black",
                  }}
                /> */}
                , portador/a do documento de identificação n.º{" "}
                {data.percurso[0].entFormadora[0].ccRepresentanteLegal ?? "N/A"}
                {/* <input
                  className="input-field-contracts"
                  type="text"
                  name="inputCcRepresentanteLegal"
                  defaultValue={valores.ccRepresentanteLegal}
                  placeholder="12345678"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      ccRepresentanteLegal: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    width: `${
                      valores.ccRepresentanteLegal.length > 0
                        ? valores.ccRepresentanteLegal.length + 1
                        : 10
                    }ch`,
                    border: inputErrors.inputCcRepresentanteLegal
                      ? "3px solid red"
                      : "1px solid black",
                  }}
                /> */}
                , válido até{" "}
                {AlterarFormato(
                  data.percurso[0].entFormadora[0].validadeCcRepresentanteLegal
                ) ?? "N/A"}
                {/* <input
                  className="input-field-contracts"
                  type="text"
                  name="inputValidadeCcRepresentanteLegal"
                  defaultValue={valores.validadeCcRepresentanteLegal}
                  placeholder="validade"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      validadeCcRepresentanteLegal: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    width: `${
                      valores.validadeCcRepresentanteLegal.length > 0
                        ? valores.validadeCcRepresentanteLegal.length + 1
                        : 10
                    }ch`,
                    border: inputErrors.inputValidadeCcRepresentanteLegal
                      ? "3px solid red"
                      : "1px solid black",
                  }}
                /> */}
                , na qualidade de{" "}
                {data.percurso[0].entFormadora[0].cargoRepresentanteLegal ??
                  "N/A"}
                {/* <input
                  className="input-field-contracts"
                  type="text"
                  name="inputCargoRepresentanteLegal"
                  defaultValue={valores.cargoRepresentanteLegal}
                  placeholder="Cargo"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      cargoRepresentanteLegal: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    width: `${
                      valores.cargoRepresentanteLegal.length > 0
                        ? valores.cargoRepresentanteLegal.length + 1
                        : 10
                    }ch`,
                    border: inputErrors.inputCargoRepresentanteLegal
                      ? "3px solid red"
                      : "1px solid black",
                  }}
                /> */}{" "}
                com poderes para o ato,
              </p>
              <p>e</p>
              <p>
                <b>SEGUNDO OUTORGANTE:</b> {formador.fullName}, natural da
                freguesia de {formador.parish} concelho de {formador.county}{" "}
                portador/a do documento de identificação n.º {formador.ccNumber}
                , válido até {AlterarFormato(formador.ccExpiration)},
                contribuinte fiscal n.º {formador.nif} residente em{" "}
                {formador.address}, {formador.postalCode}, {formador.district},
                titular da habilitação académica de {formador.academicDegree} em{" "}
                {formador.courseDesignation} e certificação pedagógica
                comprovada.
              </p>
              <br />
              <br />
              <br />
              <p>
                <h2>
                  <b>Cláusula Primeira</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>(Objeto do contrato)</b>
                </h2>
              </p>
              <br />
              <p>
                Dadas as competências e a experiência profissional do Segundo
                Outorgante, o Primeiro Outorgante contrata os serviços daquele
                para, na qualidade de <b>formador/a</b> desenvolver atividades
                de formação e outras afins, de acordo com o estabelecido na
                cláusula seguinte.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula Segunda</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>(Âmbito e condições da aquisição dos serviços)</b>
                </h2>
              </p>
              <br />
              <p>
                1. Compete ao formador prestar serviços de formação, de acordo
                com as respetivas habilitações, no âmbito do programa Trabalhos
                & Competências Verdes / Green Skills & Jobs, criado através da
                Portaria n.º 21/2023, de 6 de janeiro, com as alterações
                decorrentes da Portaria n.º 7/2024, de 5 de janeiro. Este
                apresenta e cumpre com todos os requisitos estipulados no
                Regulamento Específico do programa para desenvolver a formação
                no quadro abaixo apresentado inerente à ação ou percurso de
                formação profissional definido.
              </p>
              <br />
              <table class="ufcds">
                <thead>
                  <th>
                    <b>
                      Código e denominação da UFCD ou módulo de formação
                      extra-CNQ
                    </b>
                  </th>
                  <th>
                    <b>Nível</b>
                  </th>
                  <th>
                    <b>Carga horária (h)</b>
                  </th>
                  <th>
                    <b>Regime de formação (presencial, misto ou a distância)</b>
                  </th>
                </thead>
                <tbody>
                  <td>
                    {data.codeUfcd} - {data.name}
                  </td>
                  <td>{data.percurso[0].nivel}</td>
                  <td>{data.cargaHoraria}</td>
                  <td>{data.formato}</td>
                </tbody>
              </table>
              <br />
              <p>
                2. No âmbito da sua atividade compete ao Segundo Outorgante
                ministrar formação, presencial e/ou a distância, com recurso a
                diferentes estratégias, métodos, técnicas e instrumentos de
                formação e avaliação, incluindo atividades de diagnóstico e de
                avaliação dos formandos e propor, sempre que se justifique,
                estratégias de recuperação preventivas do insucesso ou abandono
                da formação, estabelecendo uma relação pedagógica diferenciada,
                dinâmica e eficaz com múltiplos grupos e em função dos perfis
                individuais, de forma a favorecer a aquisição de conhecimentos e
                competências, bem como o desenvolvimento de atitudes e
                comportamentos adequados ao desempenho profissional.
              </p>
              <br />
              <p>3. Compete-lhe, ainda, nomeadamente:</p>
              <br />
              <span class="alinea">
                <p>
                  a. Planificar e organizar a formação, bem como participar em
                  reuniões de coordenação geral e das respetivas equipas
                  formativas;
                </p>
                <p>
                  b. Desenvolver atividades de diagnóstico e de avaliação dos
                  formandos;
                </p>
                <p>
                  c. Conceber recursos pedagógico-didáticos de apoio à formação,
                  incluindo, com caráter obrigatório, a elaboração de um manual
                  de formação ou guia de aprendizagem modular relativo às
                  UFCD/UC/unidades de formação à medida ministradas;
                </p>
                <p>
                  d. Assumir o papel de responsável pedagógico de ações de
                  formação em que intervém como formador, assim como estabelecer
                  as necessárias articulações com entidades parceiras;
                </p>
                <p>
                  e. Efetuar registos nas aplicações informáticas de gestão da
                  formação e elaborar todos os documentos de natureza
                  técnico-administrativa e pedagógica decorrentes da sua
                  prestação de serviços, nomeadamente avaliações, processos,
                  atas e folhas de atividade/honorários;
                </p>
                <p>
                  f. Articular com outros formadores e/ou técnicos de formação,
                  presencialmente ou através de comunidades de práticas online,
                  partilhando modelos, experiências, métodos, técnicas e
                  recursos técnico-pedagógicos, com vista a potenciar o seu
                  desempenho individual e em equipa;
                </p>
                <p>
                  g. Acompanhar os formandos em visitas técnicas ou outros
                  eventos considerados pedagogicamente relevantes.
                </p>
              </span>
              <br />
              <p>
                4. O Segundo Outorgante realiza a prestação de serviços,
                assegurando a sua execução com zelo, rigor, assiduidade,
                pontualidade, qualidade e boa colaboração com o Primeiro
                Outorgante e os formandos, de modo a serem atingidos os
                resultados pretendidos com o presente contrato.
              </p>
              <br />
              <p>
                5. Os serviços são prestados com autonomia técnica do Segundo
                Outorgante, sem subordinação hierárquica, proporcionando ao
                primeiro outorgante o resultado do seu trabalho.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula Terceira</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>(Local da execução dos serviços)</b>
                </h2>
              </p>
              <br />
              <p>
                A prestação dos serviços objeto do presente contrato é executada
                quando em regime presencial, nas instalações da{" "}
                {data.percurso[0].entFormadora[0].name}, sito em{" "}
                {data.percurso[0].entFormadora[0].morada} –
                {data.percurso[0].entFormadora[0].postalCode}{" "}
                {data.percurso[0].entFormadora[0].distrito} ou em local a
                designar pelo Primeiro Outorgante, atentas as atividades
                descritas na cláusula anterior.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula Quarta</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>(Duração do contrato)</b>
                </h2>
              </p>
              <br />
              <p>
                1. A execução dos serviços a que o Segundo Outorgante se vincula
                tem a duração de {data.cargaHoraria} horas, à qual poderão ser
                acrescidas as horas despendidas com as atividades previstas no
                n.º 3 da cláusula 2.ª, tendo o seu início previsto em{" "}
                {AlterarFormato(data.dataBegin)} e termo previsto em{" "}
                {AlterarFormato(data.dataEnd)}.
              </p>
              <br />
              <p>
                2. De acordo com o previsto no artigo 440.º do Decreto-Lei n.º
                18/2008, de 29 de janeiro, que aprova o Código dos Contratos
                Públicos, o contrato pode sempre ser prorrogado pelo prazo
                estritamente necessário para assegurar a conclusão das unidades
                de formação de curta duração(UFCD)/unidades de
                competência(UC)/unidades de formação à medida que se encontrem a
                ser ministradas pelo Segundo Outorgante à data do seu termo e ou
                para realizar outras obrigações acessórias de natureza
                técnico-administrativa e ou pedagógica que não possam ser
                concluídas durante a sua vigência.
              </p>
              <br />
              <p>
                3. Para os efeitos do número anterior deve sempre ser celebrado
                um aditamento ao presente contrato.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula Quinta</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>(Horário e tempo de afetação)</b>
                </h2>
              </p>
              <br />
              <p>
                1. Considerando que o horário de funcionamento da entidade
                formadora está dependente do fluxo de candidatos, as atividades
                objeto do presente contrato são prestadas, predominantemente, no
                período entre as oito e as vinte horas, sem prejuízo de algum
                ajustamento a acordar entre as partes em função de necessidades
                supervenientes.
              </p>
              <br />
              <p>
                2. No caso do Formador que intervenha como Responsável
                Pedagógico e, para assegurar o desenvolvimento das atividades
                inerentes a essa função, deve considerar-se uma carga horária de
                7 horas/ação/mês de formação.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula Sexta</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>(Preço e condições de pagamento)</b>
                </h2>
              </p>
              <br />
              <p>
                1. Pela atividade executada, o Primeiro Outorgante paga,
                mensalmente ou no final da ação ao Segundo Outorgante, o valor
                hora de acordo com o apresentado no quadro seguinte:
              </p>
              <table class="ufcds">
                <thead>
                  <th>
                    <b>Nível da UFCD/Módulo Extra-CNQ</b>
                  </th>
                  <th>
                    <b>
                      Valor hora(acrescido de IVA à taxa legal se aplicável)
                    </b>
                  </th>
                  <th>
                    <b>Total Carga horária (h)</b>
                  </th>
                </thead>
                <tbody>
                  <td>Nível {data.percurso[0].nivel}</td>
                  <td>
                    <input
                      className="input-field-contracts"
                      type="text"
                      name="inputValorHoraFormador"
                      defaultValue={valores.valorHoraFormador}
                      placeholder="10,00"
                      onChange={(e) => {
                        setValores({
                          ...valores,
                          valorHoraFormador: e.target.value,
                        });
                        handleInputChangeLocal(e);
                        adjustWidth(e);
                      }}
                      style={{
                        width: `${
                          valores.valorHoraFormador.length > 0
                            ? valores.valorHoraFormador.length + 1
                            : 10
                        }ch`,
                        border: inputErrors.inputValorHoraFormador
                          ? "3px solid red"
                          : "1px solid black",
                      }}
                    />{" "}
                    €
                  </td>
                  <td>{data.cargaHoraria}</td>
                </tbody>
              </table>
              <br />
              <p>
                2. A execução dos serviços a que o Segundo Outorgante se
                vincula, face ao presente contrato, corresponde ao montante
                estimado de €
                <input
                  className="input-field-contracts"
                  type="text"
                  name="inputValorBrutoFormador"
                  defaultValue={valores.valorBrutoFormador}
                  placeholder="1000,00"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      valorBrutoFormador: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    width: `${
                      valores.valorBrutoFormador.length > 0
                        ? valores.valorBrutoFormador.length + 1
                        : 10
                    }ch`,
                    border: inputErrors.inputValorBrutoFormador
                      ? "3px solid red"
                      : "1px solid black",
                  }}
                />{" "}
                (
                <input
                  className="input-field-contracts"
                  type="text"
                  name="inputValorBrutoFormadorExtenso"
                  defaultValue={valores.valorBrutoFormadorExtenso}
                  placeholder="Mil euros"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      valorBrutoFormadorExtenso: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    width: `${
                      valores.valorBrutoFormadorExtenso.length > 0
                        ? valores.valorBrutoFormadorExtenso.length + 1
                        : 10
                    }ch`,
                    border: inputErrors.inputValorBrutoFormadorExtenso
                      ? "3px solid red"
                      : "1px solid black",
                  }}
                />{" "}
                ), com Imposto sobre o Valor Acrescentado (IVA) incluído, sendo
                €
                <input
                  className="input-field-contracts"
                  type="text"
                  name="inputValorLiquidoFormador"
                  defaultValue={valores.valorLiquidoFormador}
                  placeholder="800,00"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      valorLiquidoFormador: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    width: `${
                      valores.valorLiquidoFormador.length > 0
                        ? valores.valorLiquidoFormador.length + 1
                        : 10
                    }ch`,
                    border: inputErrors.inputValorLiquidoFormador
                      ? "3px solid red"
                      : "1px solid black",
                  }}
                />
                correspondentes ao valor dos serviços e €
                <input
                  className="input-field-contracts"
                  type="text"
                  name="inputValorIVAFormador"
                  defaultValue={valores.valorIVAFormador}
                  placeholder="200,00"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      valorIVAFormador: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    width: `${
                      valores.valorIVAFormador.length > 0
                        ? valores.valorIVAFormador.length + 1
                        : 10
                    }ch`,
                    border: inputErrors.inputValorIVAFormador
                      ? "3px solid red"
                      : "1px solid black",
                  }}
                />{" "}
                relativos ao valor do IVA.
              </p>
              <br />
              <p>
                3. O pagamento referido no número anterior é feito mediante a
                apresentação pelo Segundo Outorgante das correspondentes faturas
                ou documentos legalmente equivalentes, considerando-se que a
                prestação se vence nos sessenta dias subsequentes à sua
                apresentação.
              </p>
              <br />
              <p>
                4. Na eventualidade do não cumprimento do prazo referido no
                número anterior, aplicam-se as normas estatuídas na Lei n.º
                3/2010, de 27 de abril, que estabelece a obrigatoriedade do
                pagamento de juros de mora, calculados à taxa legal em vigor.
              </p>
              <br />
              <p>
                5. O encargo emergente deste contrato será satisfeito pela
                dotação da classificação orçamental n.º{" "}
                <input
                  className="input-field-contracts"
                  type="text"
                  name="inputNumeroDotacaoOrcamental"
                  defaultValue={valores.numeroDotacaoOrcamental}
                  placeholder="1234567"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      numeroDotacaoOrcamental: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    width: `${
                      valores.numeroDotacaoOrcamental.length > 0
                        ? valores.numeroDotacaoOrcamental.length + 1
                        : 10
                    }ch`,
                    border: inputErrors.inputNumeroDotacaoOrcamental
                      ? "3px solid red"
                      : "1px solid black",
                  }}
                />
                , a que corresponde o compromisso n.º{" "}
                <input
                  className="input-field-contracts"
                  type="text"
                  name="inputNumeroCompromisso"
                  defaultValue={valores.numeroCompromisso}
                  placeholder="1234567"
                  onChange={(e) => {
                    setValores({
                      ...valores,
                      numeroCompromisso: e.target.value,
                    });
                    handleInputChangeLocal(e);
                    adjustWidth(e);
                  }}
                  style={{
                    width: `${
                      valores.numeroCompromisso.length > 0
                        ? valores.numeroCompromisso.length + 1
                        : 10
                    }ch`,
                    border: inputErrors.inputNumeroCompromisso
                      ? "3px solid red"
                      : "1px solid black",
                  }}
                />
                .
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula Sétima</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>(Obrigações do Segundo Outorgante)</b>
                </h2>
              </p>
              <br />
              <p>
                1. O Segundo Outorgante obriga-se a apresentar, sempre que
                solicitado pelo Primeiro Outorgante, o documento comprovativo da
                situação tributária regularizada, exarada nos termos previstos
                no n.º 1 do artigo 3.º do Decreto-Lei n.º 236/95, de 13 de
                setembro e o documento comprovativo da situação contributiva
                regularizada perante a Segurança Social, emitida pelo Centro
                Regional da Segurança Social (CRSS) e/ou Instituto de Gestão
                Financeira da Segurança Social (IGFSS).
              </p>
              <br />
              <p>
                2. O Segundo Outorgante compromete-se a manter a atividade
                aberta junto das Finanças, durante a vigência do presente
                contrato e até serem prestadas todas as quitações inerentes ao
                mesmo, devendo emitir a correspondente fatura-recibo no prazo
                máximo de 5 dias úteis, a contar da data do términus do período
                a que se refere cada pagamento.
              </p>
              <br />
              <p>
                3. A data de términus da prestação de serviços, ou do período a
                que se refere cada pagamento deverá constar na fatura-recibo,
                como data da prestação do serviço.
              </p>
              <br />
              <p>
                4. A não observância dos pontos anteriores pode implicar o não
                pagamento dos valores cuja quitação não for prestada naqueles
                termos.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula Oitava</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>(Denúncia)</b>
                </h2>
              </p>
              <br />
              <p>
                Qualquer dos Outorgantes poderá denunciar o presente contrato,
                mediante aviso prévio em carta registada com aviso de receção,
                com pelo menos 30 dias úteis de antecedência em relação ao termo
                do prazo a que respeita.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula Nona</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>(Resolução do contrato)</b>
                </h2>
              </p>
              <br />
              <p>
                1. O Primeiro Outorgante poderá proceder à resolução do presente
                contrato, a todo o tempo, desde que se verifique alguma das
                seguintes condições:
              </p>
              <br />
              <span class="alinea">
                <p>
                  a) Incumprimento de obrigações decorrentes do presente
                  contrato, nomeadamente quando não sejam asseguradas as
                  atividades objeto da aquisição de serviços, em conformidade
                  com o previsto na cláusula 2.ª.;
                </p>
                <p>
                  b) Interrupção dos serviços sem autorização prévia do Primeiro
                  Outorgante, na sequência de requerimento apresentado com uma
                  antecedência mínima de 30 dias úteis;
                </p>
                <p>
                  c) Factos fortuitos ou de força maior que inviabilizem o
                  início ou a continuidade da formação.
                </p>
                <br />
                <p>
                  2. O Segundo Outorgante poderá igualmente proceder à resolução
                  do presente contrato, com fundamento na lei devendo, para o
                  efeito, observar uma antecedência mínima de trinta dias.
                </p>
                <br />
                <p>
                  3. A resolução do presente contrato por parte do Segundo
                  Outorgante sem fundamento na lei ou o não cumprimento do prazo
                  definido no número anterior, bem como o incumprimento das
                  obrigações decorrentes do mesmo implicam o dever de indemnizar
                  o Primeiro Outorgante num valor de 10% do montante contratado.
                </p>
                <br />
                <p>
                  4. Excetuam-se do referido nos números anteriores, as
                  situações em que a inobservância das obrigações por parte do
                  Segundo Outorgante resulte de facto fortuito ou de força
                  maior.
                </p>
                <br />
                <p>
                  5. A rescisão deve ser comunicada à outra parte mediante carta
                  registada com aviso de receção.
                </p>
              </span>
              <p>
                <h2>
                  <b>Cláusula Décima</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>(Resolução de litígios)</b>
                </h2>
              </p>
              <br />
              <p>
                Para qualquer litígio emergente do presente contrato que não
                possa ser resolvido por meios graciosos, é competente o Tribunal
                Administrativo e Fiscal de{" "}
                {data.percurso[0].entFormadora[0].distrito}, com renúncia a
                qualquer outro.
              </p>
              <br />
              <p>
                <h2>
                  <b>Cláusula Décima Primeira</b>
                </h2>
              </p>
              <p>
                <h2>
                  <b>(Dados pessoais)</b>
                </h2>
              </p>
              <br />
              <p>
                1. No âmbito da formação objeto do presente contrato, e em
                cumprimento do Regulamento Geral sobre a Proteção de Dados
                (RGPD), o Primeiro Outorgante gere os dados pessoais recolhidos
                ao Segundo Outorgante, diretamente ou através de entidades
                terceiras, devidamente protocoladas, com vista à prossecução das
                suas atribuições, previstas no Decreto-Lei n.º 143/2012, de 11
                de julho, na sua redação atual, designadamente, promover a
                criação e a qualidade do emprego e o combate ao desemprego,
                através da execução de políticas ativas de emprego, nomeadamente
                de formação profissional, e demais legislação aplicável.
              </p>
              <br />
              <p>
                2. Os dados pessoais recolhidos ao Segundo Outorgante podem, nos
                casos aplicáveis, ser objeto de partilha, em suporte digital ou
                físico, com:
              </p>
              <br />
              <span class="alinea">
                <p>
                  a) Entidades protocoladas com o Primeiro Outorgante, para que
                  o Segundo Outorgante possa aceder a certificações
                  profissionais específicas, potenciadoras da sua (re)integração
                  no mercado de trabalho, como sejam a Microsoft, a Primavera
                  BSS, a Cisco, e outras com as quais o Primeiro Outorgante
                  venha a celebrar acordos de cooperação com esse objetivo no
                  âmbito desta ação de formação;
                </p>
                <p>
                  b) Estruturas oficiais responsáveis pelo cofinanciamento
                  comunitário da ação objeto deste contrato;
                </p>
                <p>
                  c) A organização do(s) Campeonato(s) das Profissões, sejam
                  eles regionais, nacionais, europeus ou mundiais, em caso de
                  participação do Segundo Outorgante;
                </p>
                <p>
                  d) Entidades com competência no tratamento de eventuais
                  reclamações exaradas pelo Segundo Outorgante e/ou por
                  terceiros;
                </p>
                <p>
                  e) Entidades terceiras no âmbito de atividades frequentadas
                  pela/o formanda/o, que decorram diretamente ou sejam
                  necessárias à conclusão com aproveitamento da ação de formação
                  objeto do presente contrato, designadamente a Formação em
                  Contexto de Trabalho (FCT).
                </p>
              </span>
              <br />
              <p>
                3. No caso da ação de formação se realizar em regime à distância
                ou misto, atendendo às particularidades de cada ação, o Primeiro
                Outorgante poderá, designadamente, proceder a:
              </p>
              <br />
              <span class="alinea">
                <p>
                  a) gravação de atividades e interações desenvolvidas nas
                  plataformas à distância, como evidência da assiduidade de cada
                  formando e de participação para efeitos de avaliação da ação
                  de formação;
                </p>
                <p>
                  b) utilização de áreas de trabalho contributivas para partilha
                  de conteúdos formativos (ex.: OneDrive, Sharepoint);
                </p>
                <p>
                  c) utilização de sistemas de videoconferência e de partilha de
                  ficheiros de conteúdos formativos (ex.: Moodle e Microsoft
                  Teams);
                </p>
                <p>
                  d) utilização de sistemas de messaging e de partilha de
                  ficheiros de conteúdos formativos (ex.: Microsoft Teams).
                </p>
              </span>
              <br />
              <p>
                4. Os procedimentos de recolha e de tratamento de dados pessoais
                do Primeiro Outorgante são validados e monitorizados,
                designadamente, por entidades externas, independentes e idóneas.
              </p>
              <br />
              <p>
                5. O Primeiro Outorgante garante a não transmissibilidade dos
                dados pessoais sensíveis, a máxima confidencialidade e a sua
                conservação pelo período de tempo necessário às finalidades de
                interesse público, nos termos previstos na Portaria n.º 182/2020
                de 4 de agosto (Portaria de Conservação Arquivística),
                assegurando que os dados pessoais recolhidos são acessíveis
                apenas a pessoas devidamente autorizadas e exclusivamente para
                cumprimento das finalidades de interesse público referidas nesta
                cláusula.
              </p>
              <br />
              <p>
                6. A Política de Privacidade do IEFP, I. P. encontra-se
                disponível em www.iefp.pt.
              </p>
              <br />
              <p>
                Neste ato foram presentes pelo Segundo Outorgante os documentos
                seguintes:
              </p>
              <span class="alinea">
                <p>• Fotocópia do documento de identificação;</p>
                <p>
                  • Fotocópia do cartão de contribuinte (caso não possua cartão
                  de cidadão);
                </p>
                <p>
                  • Número do CCP, ou comprovativo de isenção de CCP (ao abrigo
                  do n.º 2, do artigo 2.º, da Portaria n.º 214/2011, de 30 de
                  maio);
                </p>
                <p>
                  • Documento comprovativo da situação contributiva regularizada
                  para a Segurança Social;
                </p>
                <p>
                  • Documento comprovativo da situação tributária regularizada,
                  emitida nos termos previstos no n.º 1, do artigo 3.º do
                  Decreto-Lei n.º 236/95, de 13 de setembro;
                </p>
                <p>
                  • Documento comprovativo de seguro de acidentes de trabalho de
                  trabalhadores independentes em vigor, no caso de pessoas
                  singulares, nos termos do disposto no artigo 4.º do
                  Decreto-Lei n.º 159/99, de 11 de maio;
                </p>
                <p>
                  • Declaração, sob o compromisso de honra, em como não se
                  encontra em situação de aposentação/reforma, em cumprimento do
                  disposto nos artigos 78.º e 79.º do Estatuto da Aposentação,
                  aprovado pelo Decreto-Lei n.º 498/72, de 9 de dezembro, com as
                  alterações introduzidas pelo Decreto-Lei n.º 137/2010, de 28
                  de dezembro, extensível aos beneficiários de pensões de
                  reforma da segurança social, nos termos da Lei do Orçamento do
                  Estado em vigor.
                </p>
              </span>
              <br />
              <br />
              <br />
              <br />
              <p>
                Por estarem de acordo com o presente clausulado, vai o mesmo ser
                assinado em duplicado, ficando um exemplar em poder de cada uma
                das partes outorgantes.
              </p>
              <br />
              <br />
              <br />
              <p>
                {data.percurso[0].entFormadora[0].distrito},{" "}
                {AlterarFormato(data.percurso[0].dateBegin)}
              </p>
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <h4 style={{ textAlign: "center" }}>O PRIMEIRO OUTORGANTE</h4>
                  <div
                    style={{
                      minHeight: "200px",
                      borderBottom: "1px solid black !important",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ margin: "5px auto" }}
                      id="img"
                      className="img"
                      src={
                        data.percurso[0].entFormadora[0] &&
                        data.percurso[0].entFormadora[0].assinatura
                      }
                      height="70"
                      alt="Assinatura"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <h4 style={{ textAlign: "center" }}>O SEGUNDO OUTORGANTE</h4>
                  <div
                    style={{
                      minHeight: "200px",
                      borderBottom: "1px solid black !important",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="flex justify-center w-full">
              <ConfirmToaster
                css={"mx-auto mt-4 button-geral"}
                icon={"Enviar Contrato"}
                title={"Enviar Contrato"}
                message={"Você confirma que o número do contrato está correto?"}
                confirm={enviarContratoFormador}
              />
              <ConfirmToaster
                css={"mx-auto mt-4 button-geral"}
                icon={"Download Contrato"}
                title={"Download Contrato"}
                message={"Você confirma que o número do contrato está correto?"}
                confirm={enviarContratoFormadorNoEmail}
              />
            </div>
          </StyleFormandoGreenSkillsJobs>
          {errorMessage && (
            <div className="text-red-500 text-center mt-4">{errorMessage}</div>
          )}
        </>
      )}
    </>
  );
}
