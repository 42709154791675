import React, { useState } from "react";
import { StyleFormandoGreenSkillsJobs } from "../Modal/stylesContratos/styled-contrato-formando-green-skills-jobs";
import AlterarFormato from "../FormChecks/formatDates/foramatDates";
import toastError from "../Toast/toastError";
import toastSuccess from "../Toast/toastSuccess";
import refreshPage from "../Refresh";
import ConfirmToaster from "../Toast/toastConfirmChoice";


export default function ContratoFormandoGreenSkillsJobs({ data, token, setOpen }){

  const [errorMessage, setErrorMessage] = useState("");
  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [valores, setValores] = useState({
    numeroContrato: data?.dadosContrato?.numeroContrato ?? "",
  });

    // State to control the input values
    const [inputValues, setInputValues] = React.useState({
      inputNumeroContrato: valores?.numeroContrato ?? "",
    });

     // Function to send the contract by email
  async function enviarContratoFormando() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingEnvio(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formandos/contrato`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: data._id,
            percurso: data.percurso[0]._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        setOpen(false);
        setLoadingEnvio(false);
        toastError("Erro! O contrato não foi enviado");
        return;
      }

      setOpen(false);
      setLoadingEnvio(false);
      toastSuccess("Contrato enviado com sucesso");
      localStorage.setItem("contractUpdated", "true");

      setTimeout(() => {
        refreshPage();
      }, 1000);

      const result = await response.json();
    } catch (error) {
      console.error("Erro ao enviar contrato:", error);
      setOpen(false);
      toastError("Erro ao enviar contrato");
    }
  }

  // Auxiliary function to download the file
  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Opens the link in a new tab/window
    link.setAttribute("download", ""); // Optional, sets the download attribute to trigger download in some browsers
    link.click();
  }

  // Function to download the contract
  async function enviarContratoFormandoNoEmail() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingDownload(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formandos/contratoSemEmail`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: data._id,
            percurso: data.percurso[0]._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao iniciar download do contrato");
      }

      const result = await response.json();
      downloadFile(result);
      setOpen(false);
      setLoadingDownload(false);
      refreshPage();
    } catch (error) {
      console.error("Erro ao iniciar download do contrato:", error);
      setOpen(false);
      setLoadingDownload(false);
      toastError("Erro ao iniciar download do contrato");
    }
  }

    // State to control the input errors
    const [inputErrors, setInputErrors] = React.useState({
      inputNumeroContrato: false,
    });

    // Handle input change and pass the value to the parent component by invoking the handleInputChange callback
    const handleInputChangeLocal = (e) => {
      const { name, value } = e.target;
      setInputValues({
        ...inputValues,
        [name]: value,
      });
      setInputErrors({
        ...inputErrors,
        [name]: false,
      });
    };

    // Validate all inputs and return true if all of them are valid
    const validateInputs = () => {
      const errors = {};
      let isValid = true;

      for (const key in inputValues) {
        if (inputValues[key].trim() === "") {
          errors[key] = true;
          isValid = false;
        }
      }
      setInputErrors(errors);
      return isValid;
    };

    // Style on inputs is for validation purposes, mark the input with red border if it's empty and black if it's not
    // On inputs, onChange event is used to handle the input change locally and in the parent component
    return (
      <>
        {loadingEnvio ? (
          <div className="text-center text-2xl">
            A enviar contrato, por favor aguarde...
          </div>
        ) : loadingDownload ? (
          <div className="text-center text-2xl">
            A fazer download do contrato, por favor aguarde...
          </div>
        ) : (
          <>
            <StyleFormandoGreenSkillsJobs>
              <div className="body">
                <p>
                  <h2>
                    <b>
                      CONTRATO DE FORMAÇÃO Nº{" "}
                      <input
                        className="input-field-contract-number"
                        type="number"
                        name="inputNumeroContrato"
                        defaultValue={valores.numeroContrato}
                        placeholder="0000"
                        onChange={(e) => {
                          setValores({
                            ...valores,
                            numeroContrato: e.target.value,
                          });
                          handleInputChangeLocal(e);
                        }}
                        style={{
                          border: inputErrors.inputNumeroContrato
                            ? "3px solid red"
                            : "1px solid black",
                        }}
                      />
                    </b>
                  </h2>
                </p>
                <br />
                <br />
                <br />
                <p>
                  Entre {data.percurso[0].entFormadora[0].name}, com o número de
                  Pessoa Coletiva {data.percurso[0].entFormadora[0].nif},
                  legalmente representado por{" "}
                  {data.percurso[0].entFormadora[0].representanteLegal}, adiante
                  designada por entidade ou primeiro outorgante e{" "}
                  {data.fullName}, portador do documento de identificação n.º{" "}
                  {data.idNumber}, válido até{" "}
                  {AlterarFormato(data.idExpiration)}, residente em{" "}
                  {data.address}, concelho de {data.county}, distrito de{" "}
                  {data.district}, adiante designado por formando ou segundo
                  outorgante, é celebrado o presente Contrato de Formação, o
                  qual se rege pelas seguintes cláusulas:
                </p>
                <br />
                <br />
                <br />
                <p>
                  <h2>
                    <b>Cláusula 1.ª</b>
                  </h2>
                </p>
                <p>
                  <h2>
                    <b>Objeto do contrato</b>
                  </h2>
                </p>
                <br />
                <p>
                  1. O primeiro outorgante compromete-se a proporcionar ao
                  segundo outorgante, formação profissional com base no
                  percurso/ação de formação de {data.percurso[0].name} de acordo
                  com a(s) unidade(s) de formação de curta duração (UFCD) /
                  unidades de competência (UC) / unidades de formação à medida
                  identificadas em anexo.
                </p>
                <br />
                <p>
                  2. Este percurso/ação de formação desenvolve-se no âmbito do
                  Programa Trabalhos & Competências Verdes / Green Skills &
                  Jobs, de acordo com a legislação e demais documentos
                  normativos em vigor.
                </p>
                <br />
                <p>
                  3. Nos termos do n.º 3 do art.º 4.º do Decreto-Lei n.º 242/88,
                  de 7 de julho, o presente contrato não gera nem titula
                  relações de trabalho subordinado e caduca com a conclusão da
                  ação de formação para que foi celebrado.
                </p>
                <br />
                <p>
                  <h2>
                    <b>Cláusula 2.ª</b>
                  </h2>
                </p>
                <p>
                  <h2>
                    <b>Local, duração e horário</b>
                  </h2>
                </p>
                <br />
                <p>
                  1. O processo formativo é assegurado pelo primeiro outorgante,
                  decorrendo a formação, quando realizada em regime presencial,
                  nas instalações localizadas em{" "}
                  {data.percurso[0].entFormadora[0].morada}, no concelho de{" "}
                  {data.percurso[0].entFormadora[0].concelho} ou noutras por ele
                  indicadas.
                </p>
                <br />
                <p>
                  2. A formação tem a duração de {data.percurso[0].duration}{" "}
                  horas, com início em{" "}
                  {AlterarFormato(data.percurso[0].dateBegin)}, terminando em{" "}
                  {AlterarFormato(data.percurso[0].dateEnd)} e decorre de acordo
                  com os horários que vierem a ser fixados pelo primeiro
                  outorgante.
                </p>
                <br />
                <p>
                  <h2>
                    <b>Cláusula 3.ª</b>
                  </h2>
                </p>
                <p>
                  <h2>
                    <b>Direitos do formando</b>
                  </h2>
                </p>
                <br />
                <p>
                  1. O segundo outorgante terá direito a exigir do primeiro
                  outorgante o cumprimento dos deveres previstos na cláusula 5.ª
                  do presente contrato.
                </p>
                <br />
                <p>2. O segundo outorgante tem direito a:</p>
                <br />
                <span class="alinea">
                  <p>
                    a) Receber a formação com base nos percursos/ações, nas
                    metodologias e processos de trabalho, aplicados à respetiva
                    saída profissional no respeito pelas condições de higiene e
                    segurança no trabalho, exigidos pela legislação em vigor;
                  </p>
                  <p>
                    b) Beneficiar dos apoios sociais que lhe possam ser
                    concedidos, de acordo com a legislação e demais documentos
                    normativos em vigor, actualizados anualmente, sempre que se
                    verifique a revisão do indexante dos apoios sociais (IAS),
                    nos termos do documento em anexo, que é parte integrante
                    deste contrato;
                  </p>
                  <p>
                    c) Beneficiar de um seguro contra acidentes ocorridos
                    durante e por causa das atividades de formação;
                  </p>
                  <p>
                    d) Obter gratuitamente, no final da ação de formação um
                    Certificado de Qualificações/Certificado de Formação
                    Profissional e ver registadas no Passaporte Qualifica as
                    respetivas competências adquiridas e certificadas, nos
                    termos da legislação e demais documentos normativos
                    aplicáveis;
                  </p>
                  <p>
                    e) Receber informação, orientação profissional e apoio
                    social no decurso da ação de formação;
                  </p>
                  <p>
                    f) Recusar a realização de actividades que não se insiram no
                    objecto do curso.
                  </p>
                </span>
                <br />
                <p>
                  3. Para além dos direitos referidos nos números anteriores, o
                  segundo outorgante beneficia do disposto no Regulamento do
                  Formando em vigor no início da formação.
                </p>
                <br />
                <p>
                  <h2>
                    <b>Cláusula 4.ª</b>
                  </h2>
                </p>
                <p>
                  <h2>
                    <b>Deveres do formando</b>
                  </h2>
                </p>
                <br />
                <p>São deveres do segundo outorgante:</p>
                <br />
                <span class="alinea">
                  <p>
                    a) Frequentar com assiduidade e pontualidade a ação de
                    formação, visando adquirir os conhecimentos teóricos e
                    práticos que lhe forem ministrados, em respeito do
                    Regulamento Interno em vigor;
                  </p>
                  <p>
                    b) Tratar com urbanidade o primeiro outorgante, seus
                    representantes, trabalhadores e colaboradores;
                  </p>
                  <p>
                    c) Guardar lealdade ao primeiro outorgante, e seus
                    representantes, nomeadamente não transmitindo para o
                    exterior informações sobre o equipamento e processos de
                    fabrico de que tome conhecimento por ocasião da ação de
                    formação;
                  </p>
                  <p>
                    d) Utilizar com cuidado e zelar pela boa conservação dos
                    equipamentos e demais bens que lhe sejam confiados para
                    efeitos de formação;
                  </p>
                  <p>
                    e) Suportar os custos de substituição ou reparação dos
                    equipamentos e materiais que utilizar no período de
                    formação, fornecidos pelo primeiro outorgante e seus
                    representantes, sempre que os danos produzidos resultem de
                    comportamento doloso ou gravemente negligente;
                  </p>
                  <p>
                    f) Cumprir os demais deveres emergentes do contrato de
                    formação;
                  </p>
                  <p>
                    g) Conhecer e cumprir as normas e procedimentos instituídos
                    no Regulamento do Formando, em vigor à data de início da
                    formação.
                  </p>
                </span>
                <br />
                <p>
                  <h2>
                    <b>Cláusula 5.ª</b>
                  </h2>
                </p>
                <p>
                  <h2>
                    <b>Deveres da entidade</b>
                  </h2>
                </p>
                <br />
                <p>São deveres do primeiro outorgante:</p>
                <br />
                <span class="alinea">
                  <p>
                    a) Assegurar a formação programada com respeito pela
                    legislação e regulamentação em vigor, pelas condições de
                    aprovação da ação de formação e pelo regime de concessão dos
                    apoios a que o formando tenha direito;
                  </p>
                  <p>
                    b) Facultar ao formando o acesso aos benefícios e
                    equipamentos sociais que sejam compatíveis com a acção
                    frequentada;
                  </p>
                  <p>
                    c) Respeitar e fazer respeitar as condições de higiene e
                    segurança no trabalho;
                  </p>
                  <p>
                    d) Não exigir ao formando tarefas não compreendidas no
                    objecto do curso;
                  </p>
                  <p>e) Cumprir os termos do presente contrato;</p>
                  <p>
                    f) Disponibilizar o Regulamento do Formando em vigor, à data
                    de início da formação;
                  </p>
                  <p>
                    g) Celebrar um contrato de seguro de acidentes pessoais
                    ocorridos durante e por causa das actividades da formação;
                  </p>
                  <p>
                    h) Passar gratuitamente ao formando, no final do
                    percurso/ação, um Certificado de Qualificações/ Certificado
                    de Formação Profissional, nos termos da legislação e demais
                    documentos normativos aplicáveis.
                  </p>
                </span>
                <br />
                <p>
                  <h2>
                    <b>Cláusula 6.ª</b>
                  </h2>
                </p>
                <p>
                  <h2>
                    <b>Faltas</b>
                  </h2>
                </p>
                <br />
                <p>
                  Às faltas aplica-se o disposto nos normativos que regulam a
                  modalidade de formação em vigor à data de início da formação.
                </p>
                <br />
                <p>
                  <h2>
                    <b>Cláusula 7.ª</b>
                  </h2>
                </p>
                <p>
                  <h2>
                    <b>Alterações supervenientes</b>
                  </h2>
                </p>
                <br />
                <p>
                  1. Quando, por razões alheias à sua vontade e a si não
                  imputáveis, o primeiro outorgante não puder cumprir
                  integralmente o plano de formação e ou o término definido no
                  n.º 2 da cláusula 2.ª, poderá proceder aos necessários
                  ajustamentos, devendo sempre comunicar por escrito tal facto
                  ao formando.
                </p>
                <br />
                <p>
                  2. As alterações mencionadas no ponto anterior, pelos motivos
                  referidos, não conferem ao formando direito a qualquer
                  indemnização.
                </p>
                <br />
                <p>
                  <h2>
                    <b>Cláusula 8.ª</b>
                  </h2>
                </p>
                <p>
                  <h2>
                    <b>Cessação do contrato</b>
                  </h2>
                </p>
                <br />
                <p>
                  1. O contrato pode cessar por revogação, por rescisão de uma
                  das partes ou por caducidade.
                </p>
                <br />
                <p>
                  2. A rescisão por justa causa, por qualquer das partes, tem
                  que ser comunicada à outra, por documento escrito ou carta
                  registada, devendo dela constar o(s) respectivo(s) motivo(s).
                </p>
                <br />
                <p>
                  3. O contrato de formação caduca quando se verificar a
                  impossibilidade superveniente, absoluta e definitiva, do
                  segundo outorgante frequentar a ação de formação ou de o
                  primeiro outorgante lha proporcionar.
                </p>
                <br />
                <p>
                  <h2>
                    <b>Cláusula 9.ª</b>
                  </h2>
                </p>
                <p>
                  <h2>
                    <b>Financiamento</b>
                  </h2>
                </p>
                <br />
                <p>
                  Este percurso / ação de formação é passível de financiamento
                  comunitário, sendo-lhe aplicável as respetivas disposições do
                  direito comunitário e nacional.
                </p>
                <br />
                <p>
                  <h2>
                    <b>Cláusula 10.ª</b>
                  </h2>
                </p>
                <p>
                  <h2>
                    <b>Permissão expressa no âmbito do RGPD</b>
                  </h2>
                </p>
                <br />
                <p>
                  1. No âmbito da formação objeto do presente contrato, e em
                  cumprimento do Regulamento Geral sobre Proteção de Dados
                  (RGPD), aplicável desde 25 de maio de 2018, o Segundo
                  Outorgante manifesta a sua autorização expressa a que os seus
                  dados, fornecidos ao Primeiro Outorgante, sejam também
                  utilizados para partilha, nos casos aplicáveis, em suporte
                  digital e/ou físico, com:
                </p>
                <br />
                <span class="alinea">
                  <p>
                    a) Entidades protocoladas com o Primeiro Outorgante, para
                    que o Segundo Outorgante possa aceder a benefícios
                    concretos, como seja a Microsoft e o acesso gratuito ao
                    Office 365;
                  </p>
                  <p>
                    b) Entidades protocoladas com o Primeiro Outorgante, para
                    que o Segundo Outorgante possa aceder a certificações
                    profissionais específicas, potenciadoras da sua
                    (re)integração no mercado de trabalho, como sejam a
                    Microsoft, a Huawei, a Primavera BSS, a Cisco, e outras com
                    as quais o Primeiro Outorgante venha a celebrar Acordos de
                    Cooperação com este objetivo;
                  </p>
                  <p>
                    c) Estruturas oficiais responsáveis pelo cofinanciamento
                    comunitário da ação objeto deste contrato;
                  </p>
                  <p>
                    d) A organização do(s) Campeonato(s) das Profissões, sejam
                    eles regionais, nacionais, europeus ou mundiais, em caso de
                    participação do Segundo Outorgante;
                  </p>
                  <p>
                    e) Entidades com competência no tratamento de eventuais
                    reclamações exaradas pelo Segundo Outorgante e/ou por
                    terceiros.
                  </p>
                </span>
                <p>
                  2. Autoriza-se ainda, a partilha de dados com entidades
                  terceiras no âmbito de atividades frequentadas pelos
                  formandos, que decorram diretamente ou sejam necessárias à
                  conclusão com aproveitamento da ação de formação objeto do
                  presente contrato.
                </p>
                <br />
                <p>
                  <h2>
                    <b>Cláusula 11.ª</b>
                  </h2>
                </p>
                <p>
                  <h2>
                    <b>Legislação aplicável</b>
                  </h2>
                </p>
                <br />
                <p>
                  Ao presente contrato, em tudo o que for omisso, aplicar-se-á o
                  disposto no Decreto-Lei n.º 242/88, de 7 de julho e demais
                  legislação complementar.
                </p>
                <br />
                <br />
                <br />
                <br />
                <p>
                  O presente contrato é feito em duplicado e assinado por ambos
                  os outorgantes, destinando-se o original, ao primeiro
                  outorgante e a cópia ao segundo outorgante.
                </p>
                <br />
                <br />
                <br />
                <p>
                  {data.percurso[0].locality},{" "}
                  {AlterarFormato(data.percurso[0].dateBegin)}
                </p>
                <br />
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      O PRIMEIRO OUTORGANTE
                    </h4>
                    <div
                      style={{
                        minHeight: "200px",
                        borderBottom: "1px solid black !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ margin: "5px auto" }}
                        id="img"
                        className="img"
                        src={
                          data.percurso[0].entFormadora[0] &&
                          data.percurso[0].entFormadora[0].assinatura
                        }
                        height="70"
                        alt="Assinatura"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      O SEGUNDO OUTORGANTE
                    </h4>
                    <div
                      style={{
                        minHeight: "200px",
                        borderBottom: "1px solid black !important",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center w-full">
                <ConfirmToaster
                  css={"mx-auto mt-4 button-geral"}
                  icon={"Enviar Contrato"}
                  title={"Enviar Contrato"}
                  message={
                    "Você confirma que o número do contrato está correto?"
                  }
                  confirm={enviarContratoFormando}
                />
                <ConfirmToaster
                  css={"mx-auto mt-4 button-geral"}
                  icon={"Download Contrato"}
                  title={"Download Contrato"}
                  message={
                    "Você confirma que o número do contrato está correto?"
                  }
                  confirm={enviarContratoFormandoNoEmail}
                />
              </div>
            </StyleFormandoGreenSkillsJobs>
            {errorMessage && (
              <div className="text-red-500 text-center mt-4">
                {errorMessage}
              </div>
            )}
          </>
        )}
      </>
    );
  }
