import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import Header from "../../Header";
import toastSuccess from "../../Toast/toastSuccess";

export default function FormAvaliacaoSatisfacaoFormandos() {
    const [data, setData] = useState("")
    const [loading, setLoading] = useState(true)
    const [cont, setCont] = useState(0)
    const [index, setIndex] = useState(-1)
    const [valor1, setValor1] = useState("")
    const [valor2, setValor2] = useState("")
    const [valor3, setValor3] = useState("")
    const [valor4, setValor4] = useState("")
    const [valor5, setValor5] = useState("")
    const [valor6, setValor6] = useState("")
    const [valor7, setValor7] = useState("")
    const [valor8, setValor8] = useState("")
    const [valor9, setValor9] = useState("")
    const [outros, setOutros] = useState("")
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
        getUFCD()
    }, [cont,loading])

    /* 
        function handleClick() {
            navigate(-1);
        } */
       

    function avaliarformandoUFCD() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcd}/avaliacaoSatisfacaoFormando`,
            {
                method: "PATCH",
                body: JSON.stringify({
                    avaliacaoSatisfacaoFormandosResults: {
                        criterio1: valor1,
                        criterio2: valor2,
                        criterio3: valor3,
                        criterio4: valor4,
                        criterio5: valor5,
                        criterio6: valor6,
                        criterio7: valor7,
                        criterio8: valor8,
                        sugestao: valor9,
                    },
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }).then((result) => {
                result.json().then((resp) => {
                    toastSuccess("Formulário submetido com sucesso")
                })
                /*                 handleClick(); */
            })
    }

    function getUFCD() {
            fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcd}/notVerif`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }).then((res) => {
                    res.json().then(result => {
                        setData(result)
                        setLoading(false)
                    }).catch(error => {
                        console.error('Error parsing JSON:', error);
                    });
                })
        
    }

    const getOptionText = (option) => {
        switch (option) {
            case 1:
                return "Muito Insuficiente";
            case 2:
                return "Insuficiente";
            case 3:
                return "Suficiente";
            case 4:
                return "Bom";
            case 5:
                return "Muito Bom";
            default:
                return "";
        }
    };


    return (
        <>
            {data ? <main className={"min-h-screen"}>
                <div className={"flex-grow mt-8"}>
                    <Header />
                    <div className="w-[80%] m-auto text-start text-[18px]">
                        <div className="mt-10">
                            <h1 className="title">Avaliação de Satisfação</h1>
                        </div>
                        <div className="my-10 text-main-color">
                            Agradecemos que dedique alguns minutos da sua atenção no preenchimento deste questionário, clicando nos espaços correspondentes à sua situação, tomando em atenção as anotações que se encontram em cada questão.
                        </div>
                        <div className="flex flex-col gap-10">
                            <h1 className="subtitle"> I - AVALIAÇÃO DA AÇÃO DE FORMAÇÃO</h1>
                            <div className="flex flex-col gap-10">
                                <div>
                                    <h4 className="third-title font-bold">1. Adequação dos conteúdos ao que foi anunciado/divulgado</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2, 3, 4, , 5].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoSatisfacaoFormandosResults[index].criterio1 === option && !valor1) || (index === -1 && valor1 === option))}
                                                    onClick={() => { index === -1 && setValor1(option) }}
                                                />
                                                <h1>{option} - {getOptionText(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <h4 className="third-title font-bold">2. Utilidade dos conteúdos para as à suas funções/futuras funções</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2, 3, 4, , 5].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoSatisfacaoFormandosResults[index].criterio2 === option) || valor2 === option)}
                                                    onClick={() => { index === -1 && setValor2(option) }}
                                                />
                                                <h1>{option} - {getOptionText(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div>
                                    <h4 className="third-title font-bold">3. Qualidade dos equipamentos (Sala vídeo conferência / Plataforma e/ou Instalações, insonorização, luminosidade, temperatura, etc.)</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2, 3, 4, , 5].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoSatisfacaoFormandosResults[index].criterio3 === option) || valor3 === option)}
                                                    onClick={() => { index === -1 && setValor3(option) }}
                                                />
                                                <h1>{option} - {getOptionText(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <h4 className="third-title font-bold">4. Qualidade do acompanhamento pedagógico da entidade</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2, 3, 4, , 5].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoSatisfacaoFormandosResults[index].criterio4 === option) || valor4 === option)}
                                                    onClick={() => { index === -1 && setValor4(option) }}
                                                />
                                                <h1>{option} - {getOptionText(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-10">
                                <h1 className="subtitle"> II - AVALIAÇÃO DO/A FORMADOR/A</h1>
                                <div>
                                    <h4 className="third-title font-bold">1. Domínio dos assuntos pelo formador</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2, 3, 4, , 5].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoSatisfacaoFormandosResults[index].criterio5 === option) || valor5 === option)}
                                                    onClick={() => { index === -1 && setValor5(option) }}
                                                />
                                                <h1>{option} - {getOptionText(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <h4 className="third-title font-bold">2. Qualidade no relacionamento interpessoal com os participantes</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2, 3, 4, , 5].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoSatisfacaoFormandosResults[index].criterio6 === option) || valor6 === option)}
                                                    onClick={() => { index === -1 && setValor6(option) }}
                                                />
                                                <h1>{option} - {getOptionText(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <h4 className="third-title font-bold">3. Qualidade Pedagógica dos materiais apresentados (vídeo aulas, se aplicável, slides, documentos de apoio, etc)</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2, 3, 4, , 5].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoSatisfacaoFormandosResults[index].criterio7 === option) || valor7 === option)}
                                                    onClick={() => { index === -1 && setValor7(option) }}
                                                />
                                                <h1>{option} - {getOptionText(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <h4 className="third-title font-bold">4. Qualidade Pedagógica das atividades desenvolvidas</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2, 3, 4, , 5].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoSatisfacaoFormandosResults[index].criterio8 === option) || valor8 === option)}
                                                    onClick={() => { index === -1 && setValor8(option) }}
                                                />
                                                <h1>{option} - {getOptionText(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h4 className="subtitle">III - SUGESTÕES/ CRÍTICAS/ COMENTÁRIOS</h4>
                                <textarea disabled={(index === -1) ? false : true} defaultValue={(data && index !== -1 && data.avaliacaoSatisfacaoFormandosResults[index].sugestao) ? data.avaliacaoSatisfacaoFormandosResults[index].sugestao : ""}
                                    onChange={(e) => setValor9(e.target.value)} className="input-textarea h-40"></textarea>
                            </div>
                            <div className="my-5 text-main-color">
                                Obrigado pela sua colaboração
                            </div>
                            <div className="mb-10 mx-auto">
                                {index === -1 && <button disabled={(!valor1 || !valor2 || !valor3 || !valor4 || !valor5 || !valor6 || !valor7 || !valor8)} onClick={avaliarformandoUFCD} className={(!valor1 || !valor2 || !valor3 || !valor4 || !valor5 || !valor6 || !valor7 || !valor8) ?"button-disabled":"button-geral"}>Submeter</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </main> : <main className="flex h-screen">
                <div className="flex flex-col items-center justify-center h-full">
                    <h2 className="text-[250px] font-bold text-main-color tracking-widest">403</h2>
                    <p className="text-lg sm:text-2xl text-text-main-color">O formando não tem acesso ao formulário.</p>
                </div>
            </main >}
            <Footer />
        </>
    )
}