import styled from "styled-components";

export const StyledFichaDeInscricao = styled.div`
  .body {
    background-color: white;
    color: black;
    font-size: 14px;
    direction: ltr;
    unicode-bidi: isolate;
    font-family: Arial;
  }

  h1 {
    text-align: center;
    font-size: 16px;
  }

  h2 {
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    font-style: italic;
  }

  .dados {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
  }

  .dados th {
    font-size: 10px;
    background-color: rgb(221, 221, 221) !important;
    width: 160px;
    border: 1px solid black;
    padding: 5px;
    text-align: right;
  }

  .dados td {
    font-size: 10px;
    border: 1px solid black;
    padding: 5px;
    text-align: left;
  }

  .img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .header {
    margin-top: -5.5px;
  }

  .header-m {
    margin-bottom: 5px;
  }

  .header-m5 {
    margin-bottom: 15px;
  }

  .container-1 {
    margin-top: 5px;
  }

  .container-5 {
    border: 1px solid black;
    margin: 0px 0px 10px 10px;
  }

  .container-table {
    margin: 0px 10px 10px 10px;
  }

  .container-6 {
    border: 1px solid black;
    box-shadow: 10px 10px #888888;
    margin: 5px 10px 0 0px;
  }

  .container-2 {
    border: 1px solid black;
    margin: 10px 0px 0 0px;
    display: flex;
    height: 200px;
  }

  .container-3 {
    border: 1px solid black;
    box-shadow: -5px -10px #888888;
    margin: 20px 0px 0 10px;
  }

  table {
    border-spacing: 0;
  }

  thead {
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
    justify-content: center;
  }

  tr,
  td,
  th {
    margin: auto;
    border: 1px solid black;
  }

  th:first-child {
    border-right: none !important;
  }

  td:first-child {
    display: flex;
    justify-content: center;
    background-color: #d6d6d6;
  }

  .resposta {
    display: flex;
    height: 25px;
  }
  .resposta-12 {
    display: flex;
    height: 50px;
  }

  .resposta-2 {
    display: flex;
    width: 60%;
  }

  .resposta-3 {
    display: flex;
    width: 40%;
  }

  .resposta-4 {
    border: 1px solid black;
    margin-top: 2px;
    display: flex;
  }

  .resposta-6 {
    display: flex;
    width: 55%;
  }

  .resposta-7 {
    width: 45%;
    display: flex;
  }

  .resposta-8 {
    width: 60%;
    display: flex;
    flex-direction: column;
  }

  .resposta-9 {
    width: 40%;
    display: flex;
    flex-direction: column;
  }

  .resposta-10 {
    display: flex;
    height: 25%;
    font-size: 10px;
    text-justify: auto;
  }

  .resposta-10 span {
    font-size: 8px;
  }

  .resposta-11 {
    display: flex;
    height: 15%;
  }

  .resposta-14 {
    display: flex;
    width: 80%;
  }

  .resposta-15 {
    display: flex;
    width: 20%;
  }

  .Motivo {
    margin: 10px 0px 0px 0px;
    border: 1px solid black;
    padding: 10px;
  }

  .element-1 {
    display: flex;
    width: 250px;
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
    align-items: center;
  }

  .element-2 {
    width: 251px;
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
  }

  .element-3 {
    width: 210px;
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
  }

  .element-4 {
    display: flex;
    justify-content: center;
    width: 25%;
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
  }

  .element-5 {
    width: 300px;
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
  }

  .element-6 {
    width: 99px;
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
  }

  .element-7 {
    width: 40%;
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }

  .element-8 {
    height: 10%;
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }

  .element-9 {
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
    display: flex;
    justify-content: center;
    height: 20%;
  }

  .element-10 {
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
    text-align: center;
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .element-12 {
    background-color: #d6d6d6;
    font-weight: bold;
    border: 1px solid black;
  }

  .element-14 {
    width: 251px;
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
  }

  .element-15 {
    width: 50px;
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
  }

  .element-16 {
    border: 1px solid black;
    background-color: #d6d6d6;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .value-1 {
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: calc(100% - 250px);
  }

  .value-2 {
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: calc(100% - 251px);
  }

  .value-3 {
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: calc(100% - 50px);
  }

  .value-4 {
    display: flex;
    justify-content: center;
    border: 1px solid black;
    width: 35%;
    height: 30px;
  }

  .value-5 {
    border: 1px solid black;
    width: 950px;
  }

  .value-6 {
    border: 1px solid black;
    width: calc(100% - 99px);
  }

  .value-7 {
    border: 1px solid black;
    width: 60%;
  }

  .value-8 {
    border: 1px solid black;
    height: 90%;
  }

  .value-9 {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    height: 20%;
  }

  .value-10 {
    border: 1px solid black;
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
  }

  .value-14 {
    border: 1px solid black;
    width: calc(100% - 251px);
  }

  .value-15 {
    border: 1px solid black;
    width: calc(100% - 50px);
  }

  .value-16 {
    display: flex;
    align-items: center;
    border: 1px solid black;
  }

  .value-20 {
    display: flex;
    vertical-align: middle;
    align-items: center;
    border: 1px solid black;
    padding: 5px;
    font-size: 18px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
  }

  .textMotivo {
    font-size: 13px;
    font-weight: bold;
  }
`;