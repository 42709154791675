import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

async function ExcelUFCDs(token, admin) {
    const alignment = {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true,
    };

    const workbook = new ExcelJS.Workbook();

    function getColumnLetterFromIndex(index) {
        const columnLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let columnName = '';

        while (index > 0) {
            const remainder = (index - 1) % 26;
            columnName = columnLetters[remainder] + columnName;
            index = Math.floor((index - 1) / 26);
        }

        return columnName;
    }

    function setMultipleColWidth(worksheet, startCol, endCol, width) {
        for (let col = startCol; col <= endCol; col++) {
            worksheet.getColumn(getColumnLetterFromIndex(col)).width = width;
        }
    }

    const convertDateFormat2 = (originalDate) => {
        const dateArray = originalDate.split('-');
        return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    };

    function camelCaseConverter(name) {
        const sanitizedName = name.replace(/[*?:\\/[\]]/g, '');
        return sanitizedName.replace(/\s+(\w)/g, (_, letter) => letter.toUpperCase());
    }

    async function getPercursos() {
        const apiUrl = `${process.env.REACT_APP_API_URL}/percurso/getCursosEventos`;
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': localStorage.getItem(token)
        };

        const response = await fetch(apiUrl, { headers });
        const result = await response.json();
        let allresults = [];
        const entidadesFormadoras = {};
        const operacoes = {};

        if (token === "admin1" || token === "admin2") {
            if (admin) {
                result.forEach(element => {
                    if (admin.entFormadora.some(entFormadora => element.entFormadora[0]._id === entFormadora._id)) {
                        allresults.push(element);
                    }
                });
            } else {
                allresults = result;
            }

            allresults.forEach(element => {
                const entFormadora = element.entFormadora[0];
                const operacao = element.operacao[0];

                if (entFormadora && !entidadesFormadoras[entFormadora._id]) {
                    entidadesFormadoras[entFormadora._id] = {
                        entFormadora: entFormadora._id,
                        name: entFormadora.name,
                    };
                }

                if (operacao && !operacoes[operacao._id]) {
                    operacoes[operacao._id] = {
                        operacao: operacao._id,
                        codigoOperacao: operacao.codigoOperacao,
                    };
                }
            });

            dadosUfcds(allresults);
        }
    }
/*         function convertHourString(start, end) {
        const dateStart = new Date(start);
        const dateEnd = new Date(end);

        // Calculate the difference in milliseconds
        const diffInMs = dateEnd - dateStart;

        // Convert milliseconds to hours, including fractions
        const diffInHours = diffInMs / (1000 * 60 * 60);

        // Return the result as a decimal value
        return diffInHours;
    }
function getVolume(disciplina) {
        let presencas = [];
        let volume = 0
        let turmaFiltrada = 0
        if (disciplina.formato === "Presencial") {
            disciplina.eventos.forEach(element => {
                element.presencas.forEach(aula => {
                    const index = presencas.findIndex(user => user.formando === aula.formando);
                    if (index !== -1) {
                        // If found, update the presenca value for the existing formando
                        let horas = !aula.presenca ? 1 * convertHourString(element.start, element.end) : aula.presenca

                        presencas[index].presenca += horas;
                    } else {
                        // If not found, push the entire object including both formando and presenca
                        presencas.push({
                            formando: aula.formando,
                            presenca: !aula.presenca ? 1 * convertHourString(element.start, element.end) : aula.presenca
                        });
                    }
                });
            });
            presencas.forEach(element => {
                if (parseFloat(disciplina.cargaHoraria) * 0.8 <= element.presenca) {
                    volume = volume + element.presenca
                    turmaFiltrada = turmaFiltrada + 1
                }
            });
        }
        else{
            disciplina.formandos.forEach(alunos => {
                alunos.pauta.forEach(element => {
                    if(element.idUfcd.toString() === disciplina._id.toString()){
                        if (parseFloat(disciplina.cargaHoraria) * 0.8 <= element.sincrona+element.assincrona) {
                            volume = volume + element.sincrona+element.assincrona
                            turmaFiltrada = turmaFiltrada + 1
                        }
                    }
                });
            });
        }
        return ({ volume: volume, formandos: turmaFiltrada })
    }; */
    function dadosUfcds(data) {
        const worksheet = workbook.addWorksheet(camelCaseConverter("Disciplinas"));
        setMultipleColWidth(worksheet, 1, 28, 20);

        const ufcdsTotal = [];
        data.forEach(percurso => {
            const ufcds = percurso.ufcds.filter(ufcd => ufcd.name !== "PRA");
            const jsonData = ufcds.map(element => ({
                NomeDisciplina: element.name,
                CodeDisciplina: element.codeUfcd,
                DateInicio: element.dateBegin,
                DateFim: element.dateEnd,
                CargaHoraria: element.cargaHoraria,
                Formador: element.formadores,
                Formandos: element.formandos.filter(formando => 
                    formando.valid === "Validado" &&
                    (formando.desistencia.length === 0 || formando.desistencia.every(desistencia => desistencia.idUfcd !== element._id)) &&
                    (formando.equivalencia.length === 0 || formando.equivalencia.every(equivalencia => equivalencia.idUfcd !== element._id)) &&
                    (formando.nInscricao.length === 0 || formando.nInscricao.every(nInscricao => nInscricao.idUfcd !== element._id))
                ).length,
               /*  Volume: getVolume(element).volume, */
                Estado: element.fechoCoordenador ? "Concluido" : "A decorrer",
                Operacao: percurso.operacao[0].codigoOperacao,
                EntFomadora: percurso.entFormadora[0].name,
                Coordenador: percurso.Coordenador[0]?.fullName || "",
                Nivel: percurso.nivel,
                CodeSIGO: percurso.codeSIGO,
                CodeInterno: percurso.codeInterno,
                NomeCurso: percurso.name,
            }));
            ufcdsTotal.push(...jsonData);
        });

        const headers = [
            'Operação', 'Entidade Formadora', 'Nome do Curso/Percurso', 'Código Interno', 'Código SIGO',
            'Código da Disciplina', 'Nome da Disciplina', 'Data de Início', 'Data de Fim', 'Carga Horária',
            'Nível', 'Formador/s', 'Número de Formandos', /* 'Volume', */ 'Coordenador', 'Estado da Disciplina'
        ];

        headers.forEach((header, index) => {
            const cell = worksheet.getCell(`${getColumnLetterFromIndex(index + 1)}1`);
            cell.value = header;
            cell.alignment = alignment;
        });

        ufcdsTotal.forEach((element, i) => {
            const row = i + 2;
            worksheet.getCell(`A${row}`).value = element.Operacao;
            worksheet.getCell(`B${row}`).value = element.EntFomadora;
            worksheet.getCell(`C${row}`).value = element.NomeCurso;
            worksheet.getCell(`D${row}`).value = element.CodeInterno;
            worksheet.getCell(`E${row}`).value = element.CodeSIGO;
            worksheet.getCell(`F${row}`).value = element.CodeDisciplina;
            worksheet.getCell(`G${row}`).value = element.NomeDisciplina;
            worksheet.getCell(`H${row}`).value = convertDateFormat2(element.DateInicio);
            worksheet.getCell(`I${row}`).value = convertDateFormat2(element.DateFim);
            worksheet.getCell(`J${row}`).value = element.CargaHoraria;
            worksheet.getCell(`K${row}`).value = element.Nivel;
            worksheet.getCell(`L${row}`).value = element.Formador.map(f => f.fullName).join(', ');
            worksheet.getCell(`M${row}`).value = element.Formandos;
           /*  worksheet.getCell(`N${row}`).value = element.Volume; */
            worksheet.getCell(`N${row}`).value = element.Coordenador;
            worksheet.getCell(`O${row}`).value = element.Estado;
        });
    }

    await getPercursos();

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, 'UFCDs.xlsx');
}

export default ExcelUFCDs;
