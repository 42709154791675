import FileSaver from "file-saver";
import { Workbook } from "exceljs";
import axios from "axios";
import AlterarFormato from "../../FormChecks/formatDates/foramatDates";

export default async function ExcelFichaTecnicaCurso(data) {
  const workbook = new Workbook();

  // Styling for the normal title
  const titleStyle = {
    font: { bold: true },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "D9D9D9" }, // cinza claro
    },
  };
  // Styling for the title in the center
  const titleStyleCenter = {
    font: { bold: true },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "D9D9D9" }, // cinza claro
    },
    alignment: { vertical: "middle", horizontal: "center", wrapText: true },
  };

  // Styling for the normal content
  const contentStyle = {
    alignment: { vertical: "top", horizontal: "left", wrapText: true },
  };
  // Styling for the content in the center
  const contentStyleCenter = {
    alignment: { vertical: "middle", horizontal: "center", wrapText: true },
  };

  // Function to add the header and images to the worksheet
  async function cabecalho(worksheet) {
    // Defining header
    worksheet.headerFooter.oddHeader =
      '&C&"Arial,Bold"&16 Ficha Técnica Curso - ' + data.name;

    const imageUrlEntFormador = data.entFormadora[0].logotipo;
    const imageBufferEFor = await axios.get(imageUrlEntFormador, {
      responseType: "arraybuffer",
    });

    // Create a function to load and add the image
    async function loadImageAndAddToWorksheet() {
      // Create a new Image object
      const img = new Image();

      // Create a promise to resolve when the image has loaded
      return new Promise((resolve, reject) => {
        img.onload = function () {
          const originalWidth = img.width;
          const originalHeight = img.height;

          // Calculate the scaling factor based on the desired width (e.g., 150)

          const scalingFactor = originalWidth / originalHeight;

          // Calculate the scaled height
          const scaledHeight = 50;
          const desiredWidth = scalingFactor * 50;

          const imageIdEFor = workbook.addImage({
            buffer: imageBufferEFor.data,
            extension: "png",
          });

          worksheet.addImage(imageIdEFor, {
            tl: { col: 0.5, row: 0 },
            ext: { width: desiredWidth, height: scaledHeight },
          });

          // Resolve the promise
          resolve();
        };

        // Set the source of the image to your image file
        img.src = imageUrlEntFormador;
      });
    }

    // Call the function to load and add the image
    await loadImageAndAddToWorksheet();

    if (data.entFinanciadora.length > 0) {
      if (data.entFinanciadora[0].logotipo1) {
        const imageUrlEntFinanciadora = data.entFinanciadora[0].logotipo1;
        const imageBuffer = await axios.get(imageUrlEntFinanciadora, {
          responseType: "arraybuffer",
        });

        async function loadImage2AndAddToWorksheet() {
          // Create a new Image object
          const img = new Image();

          // Create a promise to resolve when the image has loaded
          return new Promise((resolve, reject) => {
            img.onload = function () {
              const originalWidth = img.width;
              const originalHeight = img.height;

              const scalingFactor = originalWidth / originalHeight;

              // Calculate the scaled height
              const scaledHeight = 50;
              const desiredWidth = scalingFactor * 50;

              const imageId2 = workbook.addImage({
                buffer: imageBuffer.data,
                extension: "png",
              });
              worksheet.addImage(imageId2, {
                tl: { col: 3.5, row: 0 },
                ext: { width: desiredWidth, height: scaledHeight },
              });

              // Resolve the promise
              resolve();
            };

            // Set the source of the image to your image file
            img.src = imageUrlEntFinanciadora;
          });
        }
        await loadImage2AndAddToWorksheet();
      }
      if (data.entFinanciadora[0].logotipo2) {
        const imageUrlEntFinanciadora = data.entFinanciadora[0].logotipo2;
        const imageBuffer = await axios.get(imageUrlEntFinanciadora, {
          responseType: "arraybuffer",
        });

        async function loadImage2AndAddToWorksheet() {
          // Create a new Image object
          const img = new Image();

          // Create a promise to resolve when the image has loaded
          return new Promise((resolve, reject) => {
            img.onload = function () {
              const originalWidth = img.width;
              const originalHeight = img.height;

              const scalingFactor = originalWidth / originalHeight;

              // Calculate the scaled height
              const scaledHeight = 50;
              const desiredWidth = scalingFactor * 50;

              const imageId2 = workbook.addImage({
                buffer: imageBuffer.data,
                extension: "png",
              });
              worksheet.addImage(imageId2, {
                tl: { col: 0, row: 44 },
                ext: { width: desiredWidth, height: scaledHeight },
              });

              // Resolve the promise
              resolve();
            };

            // Set the source of the image to your image file
            img.src = imageUrlEntFinanciadora;
          });
        }
        await loadImage2AndAddToWorksheet();
      }
    }
  }

  async function firstPage() {
    // Get the formadores names and remove duplicates
    const formadores = [
      ...new Set(data.formadores.map((formador) => formador.fullName)),
    ];

    // Create a new worksheet
    const worksheet = workbook.addWorksheet("firstPage");
    // Add the header and images to the worksheet
    await cabecalho(worksheet);

    // Defining the width of columns
    worksheet.getColumn("A").width = 30;
    worksheet.getColumn("B").width = 30;
    worksheet.getColumn("C").width = 15;
    worksheet.getColumn("D").width = 15;

    // Defining were the content will start
    const startRow = 5;

    // Nome do Curso/UFCD
    worksheet.mergeCells(`A${startRow}:D${startRow}`);
    worksheet.getCell(`A${startRow}`).value = "Nome do Curso/UFCD:";
    worksheet.getCell(`A${startRow}`).style = titleStyle;
    worksheet.mergeCells(`A${startRow + 1}:D${startRow + 1}`);
    worksheet.getCell(`A${startRow + 1}`).style = contentStyle;
    worksheet.getCell(`A${startRow + 1}`).value = data.name;

    // Área de Formação
    worksheet.mergeCells(`A${startRow + 2}:D${startRow + 2}`);
    worksheet.getCell(`A${startRow + 2}`).value = "Área de Formação:";
    worksheet.getCell(`A${startRow + 2}`).style = titleStyle;
    worksheet.mergeCells(`A${startRow + 3}:D${startRow + 3}`);
    worksheet.getCell(`A${startRow + 3}`).style = contentStyle;
    worksheet.getCell(`A${startRow + 3}`).value = data.areaCodigoFormacao;

    // Ação (código interno)
    worksheet.mergeCells(`A${startRow + 4}:D${startRow + 4}`);
    worksheet.getCell(`A${startRow + 4}`).value = "Ação (código interno):";
    worksheet.getCell(`A${startRow + 4}`).style = titleStyle;
    worksheet.mergeCells(`A${startRow + 5}:D${startRow + 5}`);
    worksheet.getCell(`A${startRow + 5}`).style = contentStyle;
    worksheet.getCell(`A${startRow + 5}`).value = data.codeInterno;

    // Horário, Duração e Modalidade de Formação
    worksheet.getCell(`A${startRow + 6}`).value = "Horário:";
    worksheet.getCell(`A${startRow + 6}`).style = titleStyleCenter;
    worksheet.getCell(`B${startRow + 6}`).value = "Duração (em horas):";
    worksheet.getCell(`B${startRow + 6}`).style = titleStyleCenter;
    worksheet.mergeCells(`C${startRow + 6}:D${startRow + 6}`);
    worksheet.getCell(`C${startRow + 6}`).value = "Modalidade de Formação:";
    worksheet.getCell(`C${startRow + 6}`).style = titleStyleCenter;
    worksheet.getCell(`A${startRow + 7}`).style = contentStyleCenter;
    worksheet.getCell(`A${startRow + 7}`).value = data.horario;
    worksheet.getCell(`B${startRow + 7}`).style = contentStyleCenter;
    worksheet.getCell(`B${startRow + 7}`).value = data.duration;
    worksheet.mergeCells(`C${startRow + 7}:D${startRow + 7}`);
    worksheet.getCell(`C${startRow + 7}`).style = contentStyleCenter;
    worksheet.getCell(`C${startRow + 7}`).value = data.modalidade;

    // Formadores, Local e Datas (Início e Fim)
    worksheet.getCell(`A${startRow + 8}`).value = "Formadores:";
    worksheet.getCell(`A${startRow + 8}`).style = titleStyleCenter;
    worksheet.getCell(`B${startRow + 8}`).value = "Local:";
    worksheet.getCell(`B${startRow + 8}`).style = titleStyleCenter;
    worksheet.mergeCells(`C${startRow + 8}:D${startRow + 8}`);
    worksheet.getCell(`C${startRow + 8}`).value = "Datas:";
    worksheet.getCell(`C${startRow + 8}`).style = titleStyleCenter;
    worksheet.mergeCells(`A${startRow + 9}:A${startRow + 16}`);
    worksheet.getCell(`A${startRow + 9}`).value = formadores.join(", ");
    worksheet.getCell(`A${startRow + 9}`).style = contentStyle;
    worksheet.mergeCells(`B${startRow + 9}:B${startRow + 16}`);
    worksheet.getCell(`B${startRow + 9}`).style = contentStyleCenter;
    worksheet.getCell(`B${startRow + 9}`).value = data.locality;
    worksheet.getCell(`C${startRow + 9}`).value = "Início:";
    worksheet.getCell(`C${startRow + 9}`).style = titleStyleCenter;
    worksheet.mergeCells(`C${startRow + 10}:C${startRow + 16}`);
    worksheet.getCell(`C${startRow + 10}`).value = AlterarFormato(
      data.dateBegin
    );
    worksheet.getCell(`C${startRow + 10}`).style = contentStyleCenter;
    worksheet.getCell(`D${startRow + 9}`).value = "Fim:";
    worksheet.getCell(`D${startRow + 9}`).style = titleStyleCenter;
    worksheet.mergeCells(`D${startRow + 10}:D${startRow + 16}`);
    worksheet.getCell(`D${startRow + 10}`).value = AlterarFormato(data.dateEnd);
    worksheet.getCell(`D${startRow + 10}`).style = contentStyleCenter;

    // Formas de Organização
    worksheet.mergeCells(`A${startRow + 17}:D${startRow + 17}`);
    worksheet.getCell(`A${startRow + 17}`).value = "Formas de Organização:";
    worksheet.getCell(`A${startRow + 17}`).style = titleStyle;
    worksheet.mergeCells(`A${startRow + 18}:D${startRow + 19}`);
    worksheet.getCell(`A${startRow + 18}`).style = contentStyle;
    worksheet.getCell(`A${startRow + 18}`).value = data.type;

    // Destinatários/Pré-requisitos
    worksheet.mergeCells(`A${startRow + 20}:D${startRow + 20}`);
    worksheet.getCell(`A${startRow + 20}`).value =
      "Destinatários/Pré-requisitos:";
    worksheet.getCell(`A${startRow + 20}`).style = titleStyle;
    worksheet.mergeCells(`A${startRow + 21}:D${startRow + 26}`);
    worksheet.getCell(`A${startRow + 21}`).style = contentStyle;
    worksheet.getCell(`A${startRow + 21}`).value = "";

    // Recursos Pedagógicos
    worksheet.mergeCells(`A${startRow + 27}:D${startRow + 27}`);
    worksheet.getCell(`A${startRow + 27}`).value =
      "Recursos Pedagógicos (Componente Presencial da Formação):";
    worksheet.getCell(`A${startRow + 27}`).style = titleStyle;
    worksheet.mergeCells(`A${startRow + 28}:D${startRow + 38}`);
    worksheet.getCell(`A${startRow + 28}`).style = contentStyle;
    worksheet.getCell(`A${startRow + 28}`).value = "";

    const lastRow = startRow + 38; // Last row of content

    // Add borders to the cells
    for (let row = startRow; row <= lastRow; row++) {
      for (let col of ["A", "B", "C", "D"]) {
        const cell = worksheet.getCell(`${col}${row}`);
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
  }

  async function secondPage() {
    // Defining were the content will start
    const startRow = 5;
    // Create a new worksheet
    const worksheet = workbook.addWorksheet("secondPage");

    // Add the header and images to the worksheet
    await cabecalho(worksheet);

    // Defining the width of columns
    worksheet.getColumn("A").width = 22.5;
    worksheet.getColumn("B").width = 22.5;
    worksheet.getColumn("C").width = 22.5;
    worksheet.getColumn("D").width = 22.5;

    // Objetivos/Perfil de Saída
    worksheet.mergeCells(`A${startRow}:D${startRow}`);
    worksheet.getCell(`A${startRow}`).value = "Objetivos/Perfil de Saída:";
    worksheet.getCell(`A${startRow}`).style = titleStyle;
    worksheet.mergeCells(`A${startRow + 1}:D${startRow + 3}`);
    worksheet.getCell(`A${startRow + 1}`).value = "";
    worksheet.getCell(`A${startRow + 1}`).style = contentStyle;

    // Conteúdos Programáticos
    worksheet.mergeCells(`A${startRow + 4}:D${startRow + 4}`);
    worksheet.getCell(`A${startRow + 4}`).value =
      "Conteúdos Programáticos (UFCD):";
    worksheet.getCell(`A${startRow + 4}`).style = titleStyle;
    worksheet.mergeCells(`A${startRow + 5}:D${startRow + 7}`);
    worksheet.getCell(`A${startRow + 5}`).value = "";
    worksheet.getCell(`A${startRow + 5}`).style = contentStyle;

    // Metodologias de Formação Presencial e Online
    worksheet.mergeCells(`A${startRow + 8}:B${startRow + 8}`);
    worksheet.getCell(`A${startRow + 8}`).value =
      "Metodologias de Formação Presencial:";
    worksheet.getCell(`A${startRow + 8}`).style = titleStyle;
    worksheet.mergeCells(`C${startRow + 8}:D${startRow + 8}`);
    worksheet.getCell(`C${startRow + 8}`).value =
      "Metodologias de Formação online:";
    worksheet.getCell(`C${startRow + 8}`).style = titleStyle;
    worksheet.mergeCells(`A${startRow + 9}:B${startRow + 17}`);
    worksheet.mergeCells(`C${startRow + 9}:D${startRow + 17}`);
    worksheet.getCell(`A${startRow + 9}`).value =
      "- Método demonstrativo – o formador realiza demonstrações diversas, sobre as competências a desenvolver pelos formandos, seguindo-se a realização de práticas supervisionadas pelo formador. - Método ativo – serão utilizados equipamentos demonstrativos, discussões a partir de vídeos e exercícios em que os formandos desenvolvem as suas competências de forma autónoma.";
    worksheet.getCell(`A${startRow + 9}`).style = contentStyle;
    worksheet.getCell(`C${startRow + 9}`).value =
      "Método COM-TEC baseado em Objetos de Aprendizagem com Vídeo Aulas e Atividades.";
    worksheet.getCell(`C${startRow + 9}`).style = contentStyle;

    // Metodologias de Avaliação Presencial e Online
    worksheet.mergeCells(`A${startRow + 18}:B${startRow + 18}`);
    worksheet.getCell(`A${startRow + 18}`).value =
      "Metodologias de Avaliação (presencial):";
    worksheet.getCell(`A${startRow + 18}`).style = titleStyle;
    worksheet.mergeCells(`C${startRow + 18}:D${startRow + 18}`);
    worksheet.getCell(`C${startRow + 18}`).value =
      "Metodologias de Avaliação (on-line):";
    worksheet.getCell(`C${startRow + 18}`).style = titleStyle;
    worksheet.mergeCells(`A${startRow + 19}:B${startRow + 27}`);
    worksheet.mergeCells(`C${startRow + 19}:D${startRow + 27}`);
    worksheet.getCell(`A${startRow + 19}`).value =
      "- AVALIAÇÃO CONTÍNUA: A realizar pelo formador ao longo da formação. - AVALIAÇÃO SUMATIVA:Ficha de cariz teórico.";
    worksheet.getCell(`A${startRow + 19}`).style = contentStyle;
    worksheet.getCell(`C${startRow + 19}`).value =
      "- AVALIAÇÃO SUMATIVA: Teste de escolha múltipla segundo o modelo Pedagógico COM-TEC.";
    worksheet.getCell(`C${startRow + 19}`).style = contentStyle;

    // Critérios de Assiduidade e Certificação
    worksheet.mergeCells(`A${startRow + 28}:D${startRow + 28}`);
    worksheet.getCell(`A${startRow + 28}`).value =
      "Critérios de Assiduidade e Certificação:";
    worksheet.getCell(`A${startRow + 28}`).style = titleStyle;
    worksheet.mergeCells(`A${startRow + 29}:D${startRow + 38}`);
    worksheet.getCell(`A${startRow + 29}`).value =
      "É necessária a participação em 90% do curso, que inclui as sessões de formação, as sessões de PRA e a Formação em Contexto de Trabalho. CERTIFICAÇÃO:  A obtenção de um Certificado de Qualificações está dependente de uma avaliação positiva (mínimo de 10 valores, numa escala de 0 a 20) em todas as UFCD, assim como na sua integração  e validação no PRA – Portfólio Reflexivo de Aprendizagem.";
    worksheet.getCell(`A${startRow + 29}`).style = contentStyle;

    const lastRow = startRow + 38; // Last row of content

    // Add borders to all cells
    for (let row = startRow; row <= lastRow; row++) {
      for (let col of ["A", "B", "C", "D"]) {
        const cell = worksheet.getCell(`${col}${row}`);
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
  }

  await firstPage();
  await secondPage();

  // Generate the Excel file
  const buffer = await workbook.xlsx.writeBuffer();

  // Convert the buffer to a Blob
  const blob = new Blob([buffer], { type: "application/octet-stream" });

  // Save the Blob to the user's computer
  FileSaver.saveAs(blob, "FichaTecnicaCurso-" + data.name + ".xlsx");
}
