import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import { ToastContainer } from "react-toastify";
import NavbarAdmin2 from "../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import {
  BsBuildingFillAdd,
  BsDatabaseFillAdd,
  BsDatabaseFill,
  BsFillBuildingFill,
  BsPersonFillAdd,
} from "react-icons/bs";
import {
  FaCube,
  FaUser,
  FaUserEdit,
  FaUserGraduate,
  FaUserTag,
} from "react-icons/fa";
import {
  PiCubeFocusLight,
  PiUserListBold,
  PiUserListFill,
} from "react-icons/pi";
import { MdLibraryAdd, MdLibraryBooks } from "react-icons/md";
import { ChalkboardTeacher } from "@phosphor-icons/react";
import { BiSolidBox } from "react-icons/bi";
import { IoWarningOutline } from "react-icons/io5";
import ExcelAvaliacaoFormandos from "../../components/Excel/Formularios/formAvaliacaoFormandos";
const MenuCoordinator = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/formadores`, {
      headers: {
        authorization: localStorage.getItem("admin2"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let array = [];
        result.forEach((element) => {
          if (element.valid === false) {
            array.push(element);
          }
        });
        setData(array.length);
        setLoading(true);
      });
  }, [loading]);

  return (
    <>
      <ToastContainer />

      <main className="min-h-screen">
        <div className="w-full">
          <NavbarAdmin2 currentPage={"home"} />
        </div>
        <div className="flex-grow mt-8 mb-3">
          <h1 className="title ">Menu do Super Administrador</h1>
          <div className="w-full">
            <div className="mx-20">
              <h2 className="m-0 text-center subtitle xl:text-left">
                Administradores
              </h2>
              <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                <Link
                  to={"/MenuSuperAdmin/Profile/" + localStorage.getItem("id")}
                  className="menu-admin-buttons"
                >
                  <FaUser className="w-6 h-6 mr-2 text-white" />O meu perfil
                </Link>
                <Link
                  to={"/MenuSuperAdmin/addAdministradores"}
                  className="menu-admin-buttons"
                >
                  <BsPersonFillAdd className="w-6 h-6 mr-2 text-white" />
                  Criar Administradores
                </Link>
                <Link
                  to={"/MenuSuperAdmin/administradores"}
                  className="menu-admin-buttons"
                >
                  <FaUserEdit className="w-6 h-6 mr-2 text-white" />
                  Gerir Administradores
                </Link>
                <Link
                  to={"/MenuSuperAdmin/operacoes"}
                  className="menu-admin-buttons"
                >
                  <BiSolidBox className="w-6 h-6 mr-2 text-white" />
                  Gerir Operações
                </Link>
              </div>

              <h2 className="m-0 text-center subtitle xl:text-left">
                Coordenadores Pedagógicos
              </h2>
              <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                <Link
                  to={"/MenuSuperAdmin/addCoordinators"}
                  className="menu-admin-buttons"
                >
                  <BsPersonFillAdd className="w-6 h-6 mr-2 text-white" />
                  Criar Coordenadores
                </Link>
                <Link
                  to={"/MenuSuperAdmin/coordinators"}
                  className="menu-admin-buttons"
                >
                  <FaUserEdit className="w-6 h-6 mr-2 text-white" />
                  Gerir Coordenadores
                </Link>
              </div>
              <h2 className="m-0 text-center subtitle xl:text-left">
                Formadores
              </h2>
              <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                <Link
                  to={"/MenuSuperAdmin/candidacies"}
                  className={
                    data === 0
                      ? "menu-admin-buttons flex justify-between"
                      : "menu-admin-buttons flex justify-between brightness-150 tooltip"
                  }
                  data-tip={`${data} candidaturas`}
                >
                  <div className="flex">
                    <PiUserListBold className="w-6 h-6 mr-2 text-white" />
                    Ver Candidaturas
                  </div>
                  {data !== 0 && (
                    <IoWarningOutline className="w-6 h-6 mr-2 text-white " />
                  )}
                </Link>
                <Link
                  to={"/MenuSuperAdmin/list"}
                  className="menu-admin-buttons"
                >
                  <PiUserListFill className="w-6 h-6 mr-2 text-white" />
                  Bolsa de Formadores
                </Link>
              </div>
              <h2 className="m-0 text-center subtitle xl:text-left">
                Entidades
              </h2>
              <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                <Link
                  to={"/MenuSuperAdmin/CriarEntFormadora"}
                  className="menu-admin-buttons"
                >
                  <img
                    src="/img/school-circle-check-solid.png"
                    className="object-contain w-3 h-4 mr-2 sm:w-5 sm:h-5 invert brightness-0"
                  />
                  Criar Entidade Formadora
                </Link>
                <Link
                  to={"/MenuSuperAdmin/entFormadora"}
                  className="menu-admin-buttons"
                >
                  <img
                    src="/img/school.svg"
                    className="object-contain w-3 h-4 mr-2 sm:w-5 sm:h-5 invert brightness-0"
                  />
                  Gerir Entidades Formadoras
                </Link>
                <Link
                  to={"/MenuSuperAdmin/CriarEntFinanciadora"}
                  className="menu-admin-buttons"
                >
                  <BsBuildingFillAdd className="w-6 h-6 mr-2 text-white" />
                  Criar Entidade Financiadora
                </Link>
                <Link
                  to={"/MenuSuperAdmin/entFinanciadora"}
                  className="menu-admin-buttons"
                >
                  <BsFillBuildingFill className="w-5 h-6 mr-1 text-white" />
                  Gerir Entidades Financiadoras
                </Link>
              </div>
              <h2 className="m-0 text-center subtitle xl:text-left">
                Cursos/Percursos
              </h2>
              <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                <Link
                  to={"/MenuSuperAdmin/cursos/addcursos"}
                  className="menu-admin-buttons"
                >
                  <MdLibraryAdd className="w-6 h-6 mr-2 text-white" />
                  Criar Cursos/Percursos
                </Link>
                <Link
                  to={"/MenuSuperAdmin/cursos"}
                  className="menu-admin-buttons"
                >
                  <MdLibraryBooks className="w-6 h-6 mr-2 text-white" />
                  Gerir Cursos/Percursos
                </Link>
                <Link
                  to={"/MenuSuperAdmin/templates"}
                  className="menu-admin-buttons"
                >
                  <FaCube className="w-4 h-6 mr-1 text-white" />
                  Criar Cursos/Percursos Modelo
                </Link>
                <Link
                  to={"/MenuSuperAdmin/UFCDsGlobais"}
                  className="menu-admin-buttons"
                >
                  <PiCubeFocusLight className="w-6 h-6 mr-2 text-white" />
                  Criar Disciplinas Modelo
                </Link>
              </div>
              <h2 className="m-0 text-center subtitle xl:text-left">
                Formandos
              </h2>
              <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                <Link
                  to={"/MenuSuperAdmin/formandos"}
                  className="menu-admin-buttons"
                >
                  <FaUserGraduate className="w-4 h-6 mr-1 text-white" />
                  Ver Formandos
                </Link>
                <Link
                  to={"/MenuSuperAdmin/turmas"}
                  className="menu-admin-buttons"
                >
                  <ChalkboardTeacher
                    weight="fill"
                    className="w-6 h-6 mr-1 text-white"
                  />
                  Gerir Turmas
                </Link>
              </div>
              <h2 className="m-0 text-center subtitle xl:text-left">Moodle</h2>
              <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                <Link
                  to={"/MenuSuperAdmin/criarMoodle"}
                  className="menu-admin-buttons"
                >
                  <BsDatabaseFillAdd className="w-6 h-6 mr-2 text-white" />
                  Criar Acessos Moodle
                </Link>
                <Link
                  to={"/MenuSuperAdmin/Moodle"}
                  className="menu-admin-buttons"
                >
                  <BsDatabaseFill className="w-6 h-6 mr-2 text-white" />
                  Gerir Acessos Moodle
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*   <button className="button-geral" onClick={()=>ExcelAvaliacaoFormandos()}>Excel</button> */}
        <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
          <Footer />
        </div>
      </main>
    </>
  );
};

export default MenuCoordinator;
