import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import { BsPencilFill } from "react-icons/bs";
import toastSuccess from "../../../components/Toast/toastSuccess";
import toastError from "../../../components/Toast/toastError";
import check_nif_number from "../../../components/FormChecks/checkNif/checkNIf";
import ConfirmToaster from "../../../components/Toast/toastConfirmChoice";
import NavbarAdmin1 from "../../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../../Navbars/NavbarAdmin2/NavbarAdmin2";
import isNumber from "../../isNumber/isNumber";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight, AiOutlineCloseCircle } from "react-icons/ai";
import Cookies from "js-cookie";
import CheckTypeNotTecAdm from "../../CheckTypeAdmin/CheckTypeNotTecAdm";
import { FaFileCircleCheck, FaFileCircleXmark } from "react-icons/fa6";
import CheckTypeNotFinanc from "../../CheckTypeAdmin/CheckTypeNotFinanc";
import CountryDropdown from "../../FormChecks/countryDropdown/countryDropdown";
import AlterarFormato from "../../FormChecks/formatDates/foramatDates";
function PerfilFormando(token, navbarRoute) {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cont, setCont] = useState(0);
    const [numDoc, setNumDoc] = useState([]);
    const [outrosDocs, setOutrosDocs] = useState([])
    const [alterar2, setAlterar2] = useState(0);
    const [alterar1, setAlterar1] = useState(false);
    const [nationality, setNationality] = useState("")
    const [CQEP, setCQEP] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [verIntegracaoSistema, setVerIntegracaoSistema] = useState(false);
    document.data = data
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-allFormandosInd-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Admin-allFormandosInd-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    const [employmentSituation, setEmploymentSituation] = useState(null);

    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        getFormando()
    }, [loading, cont, alterar1, alterar2, employmentSituation, nationality]);
    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        updatePagination(data) // n é o state por causa das tabelas
    }
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data2);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data2);
        }
    }
    const handleNameChange = (e, index) => {
        const updatedOutrosDocs = [...outrosDocs];
        if (!updatedOutrosDocs[index]) {
            updatedOutrosDocs[index] = {
                name: '',
                file: null
            };
        }
        updatedOutrosDocs[index].name = e.target.value;
        setOutrosDocs(updatedOutrosDocs);
    };
    //Altera nacionalidade
    const handleNationalityChange = (selectedNationality) => {
        setNationality(selectedNationality);
    };
    const handleFileChange = (e, index) => {
        const updatedOutrosDocs = [...outrosDocs];
        if (!updatedOutrosDocs[index]) {
            updatedOutrosDocs[index] = {
                name: '',
                file: null
            };
        }
        updatedOutrosDocs[index].file = e.target.files[0];
        setOutrosDocs(updatedOutrosDocs);
    };


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }


    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };
    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data2)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data2)
        }
    }

    async function eliminarOutroDocumento(index) {
        setLoading(true)
        await fetch(`${process.env.REACT_APP_API_URL}/formandos/${params.id_formando}/deleteOtherDoc`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            method: 'PATCH',
            body: JSON.stringify({
                index: index,
            })
        })
            .then(res => res.json())
            .then((result) => {
                setLoading(false)
                toastSuccess("Ficheiro eliminado com sucesso")
                setCont(cont + 1)
            })
    }

    function aprovarDoc(approve, doc) {
        let approveDocs = {
            cc: (data.aprovDocs && data.aprovDocs[0] && data.aprovDocs[0].cc) ? data.aprovDocs[0].cc : "",
            csp: (data.aprovDocs && data.aprovDocs[0] && data.aprovDocs[0].csp) ? data.aprovDocs[0].csp : "",
            cm: (data.aprovDocs && data.aprovDocs[0] && data.aprovDocs[0].cm) ? data.aprovDocs[0].cm : "",
            ch: (data.aprovDocs && data.aprovDocs[0] && data.aprovDocs[0].ch) ? data.aprovDocs[0].ch : "",
            iban: (data.aprovDocs && data.aprovDocs[0] && data.aprovDocs[0].iban) ? data.aprovDocs[0].iban : ""
        };
        if (doc === "cc") {
            approveDocs.cc = approve
        }
        else if (doc === "ch") {
            approveDocs.ch = approve
        }
        else if (doc === "cm") {
            approveDocs.cm = approve
        }
        else if (doc === "csp") {
            approveDocs.csp = approve
        }
        else if (doc === "iban") {
            approveDocs.iban = approve
        }
        fetch(`${process.env.REACT_APP_API_URL}/formandos/${data._id}/approveDocs`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
                method: 'PATCH',
                body: JSON.stringify({
                    aprovDocs: [approveDocs]
                })
            }).then((result) => {
                result.json().then((resp) => {
                    setCont(cont + 1)
                })
            })
    }

    function removeDocs(cc, ch, csp, ibancomp, cm) {
        fetch(`${process.env.REACT_APP_API_URL}/formandos/${data._id}/removerDocs`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
                method: 'PATCH',
                body: JSON.stringify({
                    cc: cc && cc,
                    ch: ch && ch,
                    csp: csp && csp,
                    cm: cm && cm,
                    ibancomp: ibancomp && ibancomp,
                })
            }).then((result) => {
                result.json().then((resp) => {
                    setCont(cont + 1)
                })
            })
    }
    //Recebe um formando pelo seu id
    //Obtem formando 
    function getFormando() {
        fetch(`${process.env.REACT_APP_API_URL}/formandos/${params.id_formando}`, {
            headers: {
                'authorization': localStorage.getItem(token)
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    setCQEP(result.CQEP)
                    setLoading(false)
                    fetch(`${process.env.REACT_APP_API_URL}/formandos/historico/${result.nif}`, {
                        headers: {
                            'authorization': localStorage.getItem(token)
                        }
                    })
                        .then((res) => res.json())
                        .then(
                            (result) => {
                                setData2(result[0].formandos);
                                paginacaoTabelas(result[0].formandos);
                                setLoading(false);
                            },
                            (error) => {
                                console.error("Error fetching data: ", error);
                                setError(error);
                            }
                        );
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };

    function envioEmailFormando() {
        fetch(`${process.env.REACT_APP_API_URL}/formandos/${params.id_formando}/email`, {
            headers: {
                'authorization': localStorage.getItem(token)
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    toastSuccess("Email enviado com sucesso")
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };
    //Alterar dados do perfil
    function alterarPerfil() {
        if (document.getElementById('cellNumber').value !== "" && document.getElementById('email').value !== "" && document.getElementById('locality').value !== "" && document.getElementById('nif').value !== "" && document.getElementById('gender').value !== "" && document.getElementById('birthDate').value !== "" && document.getElementById('postalCode').value !== "") {
            let cellNumber = document.getElementById('cellNumber').value;
            if (isNumber(cellNumber) && cellNumber.length < 13 && cellNumber > 0) {
                let cont = 0
                function verifyEmail() {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].email === document.getElementById('email').value) {
                            if (document.getElementById('email').defaultValue === document.getElementById('email').value);
                            else cont++;
                        }
                    }
                }
                verifyEmail()
                // Verificar se o email tem "@" e o "."
                let checkEmail = document.getElementById('email').value.includes("@" && ".")
                if (checkEmail === true && cont === 0) {
                    if (document.getElementById('locality').value) {
                        if (check_nif_number(document.getElementById('nif').value)) {
                            const formData = {
                                fullName: document.getElementById('name').value ? document.getElementById('name').value : data.fullName,
                                gender: document.getElementById('gender').value ? document.getElementById('gender').value : data.gender,
                                cellNumber: document.getElementById('cellNumber').value ? document.getElementById('cellNumber').value : data.cellNumber,
                                email: document.getElementById('email').value ? document.getElementById('email').value : data.email,
                                locality: document.getElementById('locality').value ? document.getElementById('locality').value : data.locality,
                                nif: document.getElementById('nif').value ? document.getElementById('nif').value : data.nif,
                                birthDate: document.getElementById('birthDate').value ? document.getElementById('birthDate').value : data.birthDate,
                                postalCode: document.getElementById('postalCode').value ? document.getElementById('postalCode').value : data.postalCode,
                                address: document.getElementById('address').value ? document.getElementById('address').value : data.address,
                                district: document.getElementById('district').value ? document.getElementById('district').value : data.district,
                                county: document.getElementById('county').value ? document.getElementById('county').value : data.county && data.county,
                                parish: document.getElementById('parish').value ? document.getElementById('parish').value : data.parish && data.parish,
                                nationality: nationality ? nationality : data.nationality,
                                documentType: document.getElementById('documentType').value ? document.getElementById('documentType').value : data.documentType,
                                idNumber: document.getElementById('idNumber').value ? document.getElementById('idNumber').value : data.idNumber,
                                idExpiration: document.getElementById('idExpiration').value ? document.getElementById('idExpiration').value : data.idExpiration,
                                niss: document.getElementById('niss').value ? document.getElementById('niss').value : data.niss,
                                origemInscricao :  document.getElementById('origemInscricao').value ? document.getElementById('origemInscricao').value : data.origemInscricao &&data.origemInscricao,
                                CQEP: CQEP ? CQEP : data.CQEP ? data.CQEP : "",
                                originCQEP: (document.getElementById('CQEP').value === "Sim" && document.getElementById('originCQEP').value) ? document.getElementById('originCQEP').value : data.originCQEP,
                                academicDegree: document.getElementById('academicDegree').value ? document.getElementById('academicDegree').value : data.academicDegree,
                                employmentSituation: document.getElementById('employmentSituation').value ? document.getElementById('employmentSituation').value : data.employmentSituation,
                                job: document.getElementById('employmentSituation') && document.getElementById('job') && ((document.getElementById('employmentSituation').value.includes("Empregados por conta de outrem") || (document.getElementById('employmentSituation').value.includes('Empregados por conta própria')) && document.getElementById('job').value)) ? document.getElementById('job').value : data.job,
                                nifEmployer: (document.getElementById('employmentSituation').value.includes("Empregados por conta de outrem")) && document.getElementById('nifEmployer').value ? document.getElementById('nifEmployer').value : data.nifEmployer ? data.nifEmployer : "",
                                nameEmployer: (document.getElementById('employmentSituation').value.includes("Empregados por conta de outrem")) && document.getElementById('nameEmployer').value ? document.getElementById('nameEmployer').value : data.nameEmployer ? data.nameEmployer : "",
                                employmentSituationDate: (data.employmentSituation === 'Desempregados à procura de novo emprego - DLD' ||
                                    data.employmentSituation === 'Desempregados à procura de novo emprego - Não DLD' ||
                                    data.employmentSituation === 'Desempregados à procura do 1º emprego' ||
                                    data.employmentSituation === 'Inativos - A frequentarem ações de educação ou formação' ||
                                    data.employmentSituation === 'Inativos - Outros') ? document.getElementById('employmentSituationDate').value ? document.getElementById('employmentSituationDate').value : data.employmentSituationDate : "",
                                beneficiarySS: (data.employmentSituation === 'Desempregados à procura de novo emprego - DLD' ||
                                    data.employmentSituation === 'Desempregados à procura de novo emprego - Não DLD' ||
                                    data.employmentSituation === 'Desempregados à procura do 1º emprego' ||
                                    data.employmentSituation === 'Inativos - A frequentarem ações de educação ou formação' ||
                                    data.employmentSituation === 'Inativos - Outros') ? document.getElementById('beneficiarySS').value ? document.getElementById('beneficiarySS').value : data.beneficiarySS : "",
                            };
                            fetch(`${process.env.REACT_APP_API_URL}/formandos/${params.id_formando}/alterarPerfil`,
                                {
                                    body: JSON.stringify(
                                        formData
                                    ),
                                    method: "PATCH",
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'authorization': localStorage.getItem(token)
                                    }
                                }).then((result) => {
                                    result.json().then((resp) => {
                                        if (resp === "Error Email") {
                                            toastError("Email já existente.")
                                        } else {
                                            toastSuccess('Dados alterados com sucesso!')
                                            getFormando();
                                            setAlterar1(false);
                                        }
                                    })
                                })
                        } else toastError("NIF Inválido")
                    } else toastError("Localidade inválida")
                } else toastError("Email invalido ou existente")
            } else toastError("Nº de telemóvel inválido")
        } else toastError("Dados não inseridos")
    }
    //transforma data em json
    function formDataToJson(formData) {
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        return JSON.stringify(object);
    }
    //Carregar ficheiros no site
    //Submete ficheiros
    async function submeterFicheiros() {
        setLoading(true)
        let form = document.getElementById("form")

        let formDataInfo = new FormData()

        const options = {
            method: "POST",
            headers: { "Accept": "application/json" },
            credentials: "same-origin"
        }

        const cc_path = document.getElementById("cc") && document.getElementById("cc").value ? document.getElementById("cc") : ""
        const ch_path = document.getElementById("ch") && document.getElementById("ch").value ? document.getElementById("ch") : ""
        const csp_path = document.getElementById("csp") && document.getElementById("csp").value ? document.getElementById("csp") : ""
        const cm_path = document.getElementById("cm") && document.getElementById("cm").value ? document.getElementById("cm") : ""
        const ibancomp_path = document.getElementById("ibancomp") && document.getElementById("ibancomp").value ? document.getElementById("ibancomp") : ""
        const promises = [];
        if (cc_path) {
            const cc = form.cc.files[0]
            let ccForm = new FormData()
            ccForm.append('cc', cc)
            options.body = ccForm
            const ccResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcc`, options)
            const ccResult = await ccResponse.json()
            formDataInfo.append('cc', ccResult)
            promises.push(ccResponse);
        }
        if (cm_path) {
            const cm = form.cm.files[0]
            let cmForm = new FormData()
            cmForm.append('cm', cm)
            options.body = cmForm
            const cmResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcm`, options)
            const cmResult = await cmResponse.json()
            formDataInfo.append('cm', cmResult)
            promises.push(cmResponse);

        }
        /*  if (document.getElementById("dce")) {
             if (document.getElementById("dce").value) {
                 const dce = form.dce.files[0]
                 let dceForm = new FormData()
                 dceForm.append('dce', dce)
                 options.body = dceForm
                 const dceResponse = await fetch(`${process.env.REACT_APP_API_URL}/mdce`, options)
                 const dceResult = await dceResponse.json()
                 formDataInfo.append('dce', dceResult)
             }
         } */

        if (ch_path) {
            const ch = form.ch.files[0]
            let chForm = new FormData()
            chForm.append('ch', ch)
            options.body = chForm
            const chResponse = await fetch(`${process.env.REACT_APP_API_URL}/mch`, options)
            const chResult = await chResponse.json()
            formDataInfo.append('ch', chResult)
            promises.push(chResponse);
        }

        if (csp_path) {
            const csp = form.csp.files[0]
            let cspForm = new FormData()
            cspForm.append('csp', csp)
            options.body = cspForm
            const cspResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcsp`, options)
            const cspResult = await cspResponse.json()
            formDataInfo.append('csp', cspResult)
            promises.push(cspResponse);
        }

        if (ibancomp_path) {
            const ibancomp = form.ibancomp.files[0]
            let ibancompForm = new FormData()
            ibancompForm.append('ibancomp', ibancomp)
            options.body = ibancompForm
            const ibancompResponse = await fetch(`${process.env.REACT_APP_API_URL}/mibancomp`, options)
            const ibancompResult = await ibancompResponse.json()
            formDataInfo.append('ibancomp', ibancompResult)
            promises.push(ibancompResponse);
        }


        /* if (document.getElementById("rv")) {
            if (document.getElementById("rv").value) {
                const rv = form.rv.files[0]
                let rvForm = new FormData()
                rvForm.append('rv', rv)
                options.body = rvForm
                const rvResponse = await fetch(`${process.env.REACT_APP_API_URL}/rv`, options)
                const rvResult = await rvResponse.json()
                formDataInfo.append('rv', rvResult)
            }
        } */
        let arrayObject = [];
        for (let i = 0; i < numDoc; i++) {
            const outro = outrosDocs[i].file;
            if (outro) {
                let outroForm = new FormData();
                outroForm.append('outro', outro);
                const outroOptions = options; // Create separate options object
                outroOptions.body = outroForm;
                const outroResponse = await fetch(`${process.env.REACT_APP_API_URL}/outro`, outroOptions);
                const outroResult = await outroResponse.json();
                const object = {}
                object.name = outrosDocs[i].name;
                object.file = outroResult;
                arrayObject[i] = object;
            }
        }
        if (arrayObject.length === 1) {
            formDataInfo.append("outros", JSON.stringify(arrayObject));
        } else formDataInfo.append("outros", JSON.stringify(arrayObject));

        try {
            const responses = await Promise.all(promises);

            // Check if all promises were successful
            if (responses.every(response => response.ok)) {
                const patchResponse = await fetch(`${process.env.REACT_APP_API_URL}/formandos/${data._id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    method: 'PATCH',
                    body: formDataToJson(formDataInfo)
                });

                if (patchResponse.ok) {
                    try {
                        const contentType = patchResponse.headers.get('Content-Type');

                        // Check if the response is JSON
                        if (contentType && contentType.includes('application/json')) {
                            const result = await patchResponse.json(); // Parse JSON if possible
                            toastSuccess("Ficheiros submetidos com sucesso!");
                            setAlterar2(0);
                            setAlterar1(false);
                            setCont(cont + 1);
                        } else {
                            // Handle non-JSON response
                            const textResponse = await patchResponse.text(); // Get response as text
                            console.warn("Expected JSON, but got:", textResponse);
                            toastError("Unexpected response format from the server");
                        }
                    } catch (jsonError) {
                        console.error("Failed to parse JSON:", jsonError);
                        toastError("Erro inesperado no servidor");
                    }
                } else {
                    const errorText = await patchResponse.text(); // Get the error message as text
                    console.error("Server error:", errorText);
                    toastError("Erro ao submeter os ficheiros");
                }
            }
        } catch (error) {
            console.error("An error occurred:", error);
            toastError("Erro ao submeter os ficheiros");
        } finally {
            setLoading(false);
        }

    }

    //Upload dos ficheiros
    const handleFileUpload = async (event) => {
        setLoading(true);

        const selectedFile = event.target.files[0];
        let ccForm = new FormData();
        ccForm.append('logo', selectedFile);

        const options = {
            method: "POST",
            headers: { "Accept": "application/json" },
            credentials: "same-origin",
            body: ccForm
        };

        try {
            const logoResponse = await fetch(`${process.env.REACT_APP_API_URL}/logo`, options);
            const logoResult = await logoResponse.json();

            await fetch(`${process.env.REACT_APP_API_URL}/formandos/${params.id_formando}/photo`, {
                body: JSON.stringify({
                    photo: logoResult,
                }),
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                }
            });
            toastSuccess('Foto adicionada com sucesso!');
            setCont(cont + 1)

        } catch (error) {
            console.error('Error uploading image:', error);
            setLoading(false);
        }
        await setLoading(false);
    };

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    {localStorage.getItem("admin1") && <NavbarAdmin1 currentPage={navbarRoute} />}
                    {localStorage.getItem("admin2") && <NavbarAdmin2 currentPage={navbarRoute} />}
                </div>

                <button onClick={() => navigate(-1)} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </button>
                <div className="w-full mx-10 mt-8">
                    <div className="flex flex-col items-center justify-center w-full">
                        <div className="relative transition cursor-pointer group hover:opacity-80">
                            <img alt="Profile" className="mx-auto max-h-40 rounded-3xl sm:m-0" src={data.photo === undefined || data.photo === "" ? 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg' : data.photo} height="120px" onClick={() => { document.getElementById('fileInput').click(); }} />
                            <div className="absolute -translate-x-1/2 -translate-y-1/2 opacity-0 top-1/2 left-1/2 group-hover:opacity-100">
                                <BsPencilFill className="text-black" />
                            </div>
                            <input id="fileInput" type="file" accept=".png, .jpeg, .jpg" style={{ display: 'none' }} onChange={handleFileUpload} />
                        </div>
                        <div className="flex flex-col items-center mt-2">
                            {alterar1 === true ?
                                <input id="name" name="name" className="input-field-long" placeholder={data.fullName} defaultValue={data.fullName} required /> :
                                <h1 className="text-2xl font-bold text-text-main-color">{data.fullName}</h1>
                            }
                        </div>
                    </div>
                    <div className="my-6">
                        <hr />
                    </div>
                    <div className="mb-5 sm:ml-24">
                        <h2 className="text-3xl font-bold text-center text-text-main-color sm:text-left">Dados Pessoais</h2>
                        <div className="my-1 mb-2 text-center sm:text-left">
                            {data.fullName && data.cellNumber && data.email && data.nif && data.locality && data.profileValid === true && data.cc_path && data.ch_path && data.ibancomp_path && (data.dce_path || data.rv_path) ? <div className="text-text-correct">Perfil preenchido </div> : <div className="text-text-error">Dados do perfil por preencher</div>}
                        </div>
                        <div className="grid mt-4 sm:grid-cols-2">
                            {data &&
                                <>
                                    <div>
                                        <div className="text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Número de telefone:</div>
                                            {alterar1 === true ? <input id="cellNumber" name="cellNumber" className="text-lg input-field w-[20rem]" placeholder={data.cellNumber} minLength={9} maxLength={14} defaultValue={data.cellNumber} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.cellNumber}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">E-mail:</div>
                                            {alterar1 === true ? <input id="email" name="email" className="text-lg input-field w-[20rem]" placeholder={data.email} defaultValue={data.email} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.email}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Género:</div>
                                            {alterar1 === true ? <input id="gender" name="gender" className="text-lg input-field w-[20rem]" placeholder={data.gender} defaultValue={data.gender} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.gender}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Data de nascimento:</div>
                                            {alterar1 === true ? <input id="birthDate" name="birthDate" className="text-lg input-field w-[20rem]" placeholder={data.birthDate} defaultValue={data.birthDate} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.birthDate}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Código Postal:</div>
                                            {alterar1 === true ? <input id="postalCode" name="postalCode" className="text-lg input-field w-[20rem]" placeholder={data.postalCode} defaultValue={data.postalCode} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.postalCode}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Morada:</div>
                                            {alterar1 === true ? <input id="address" name="address" className="text-lg input-field w-[20rem]" placeholder={data.address} defaultValue={data.address} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.address}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Localidade:</div>
                                            {alterar1 === true ? <input id="locality" name="locality" className="text-lg input-field w-[20rem]" placeholder={data.locality} defaultValue={data.locality} required></input> : <>{data.locality ? <div className="mt-2 text-lg text-text-main-color">{data.locality}</div> : <div className="input-error-profile" />}</>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Distrito:</div>
                                            {alterar1 === true ? <input id="district" name="district" className="text-lg input-field w-[20rem]" placeholder={data.district} defaultValue={data.district} required></input> : <>{data.district ? <div className="mt-2 text-lg text-text-main-color">{data.district}</div> : <div className="input-error-profile" />}</>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Concelho:</div>
                                            {alterar1 === true ? <input id="county" name="county" className="text-lg input-field w-[20rem]" placeholder={data.county} defaultValue={data.county} required></input> : <>{data.county ? <div className="mt-2 text-lg text-text-main-color">{data.county}</div> : <div className="input-error-profile" />}</>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Naturalidade:</div>
                                            {alterar1 === true ? <input id="parish" name="parish" className="text-lg input-field w-[20rem]" placeholder={data.parish} defaultValue={data.parish} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.parish}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Nacionalidade:</div>
                                            {alterar1 === true ? <CountryDropdown css={"text-lg input-field w-[20rem]"} defaultValue={data.nationality} onChange={handleNationalityChange} /> : <div className="mt-2 text-lg text-text-main-color">{data.nationality}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">NIF:</div>
                                            {alterar1 === true ? <input id="nif" name="nif" className="text-lg input-field w-[20rem]" placeholder={data.nif} defaultValue={data.nif} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.nif}</div>}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-center sm:text-left ">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Tipo Documento Identificação:</div>
                                            {alterar1 === true ? <select onWheel={(e) => e.target.blur()} defaultValue={data.documentType} id="documentType" className="selector text-lg max-w-[20rem]">
                                                <option value="" disabled>Selecione a sua opção</option>
                                                <option value="BI">BI</option>
                                                <option value="Passaporte">Passaporte</option>
                                                <option value="BI Estrangeiro">BI Estrangeiro</option>
                                                <option value="Cartão de Cidadão">Cartão de Cidadão</option>
                                                <option value="Outro">Outro</option>
                                                </select> : <div className="mt-2 text-lg text-text-main-color">{data.documentType}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Nº Documento Identificação:</div>
                                            {alterar1 === true ? <input id="idNumber" name="idNumber" className="text-lg input-field w-[20rem]" placeholder={data.idNumber} defaultValue={data.idNumber} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.idNumber}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Validade Documento Identificação:</div>
                                            {alterar1 === true ? <input id="idExpiration" name="idExpiration" className="text-lg input-field w-[20rem]" placeholder={data.idExpiration} defaultValue={data.idExpiration} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.idExpiration}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">NISS:</div>
                                            {alterar1 === true ? <input id="niss" name="niss" className="text-lg input-field w-[20rem]" placeholder={data.niss} defaultValue={data.niss} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.niss}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Grau Académico:</div>
                                            {alterar1 === true ? <select onWheel={(e) => e.target.blur()} defaultValue={data.academicDegree} id="academicDegree" className="selector text-lg max-w-[20rem]">
                                                <option value="" disabled>Selecione a sua opção</option>
                                                <option value="Não sabe ler nem escrever">Não sabe ler nem escrever</option>
                                                <option value="Menos de 4 anos escolaridade">Menos de 4 anos escolaridade</option>
                                                <option value="1º ciclo (4º ano)">1º ciclo (4º ano)</option>
                                                <option value="2º ciclo (6º ano)">2º ciclo (6º ano)</option>
                                                <option value="3º ciclo (9º ano)">3º ciclo (9º ano)</option>
                                                <option value="Ensino Secundário">Ensino Secundário</option>
                                                <option value="Ensino Pós-Secundário não superior (ex CET Cursos de Especialização Tecnológica)">Ensino Pós-Secundário não superior (ex CET Cursos de Especialização Tecnológica)</option>
                                                <option value="Cursos superiores de curta duração (ex TeSP Cursos Técnicos Superiores Profissionais)">Cursos superiores de curta duração (ex TeSP Cursos Técnicos Superiores Profissionais)</option>
                                                <option value="Bacharelato">Bacharelato</option>
                                                <option value="Licenciatura">Licenciatura</option>
                                                <option value="Licenciatura pré-Bolonha">Licenciatura pré-Bolonha</option>
                                                <option value="Mestrado">Mestrado</option>
                                                <option value="Mestrado Pré-Bolonha">Mestrado Pré-Bolonha</option>
                                                <option value="Doutoramento">Doutoramento</option>
                                            </select> :
                                                <div className="mt-2 text-lg text-text-main-color">{data.academicDegree}</div>}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Inciativa de Inscrição:</div>
                                            {alterar1 === true ? <select onWheel={(e) => e.target.blur()} defaultValue={data.origemInscricao ? data.origemInscricao : ""} id="origemInscricao" name="origemInscricao" className="text-lg max-w-[20rem] selector">
                                                <option value="" disabled>Selecione a sua opção</option>
                                                <option value="Iniciativa Própria">Iniciativa Própria</option>
                                                <option value="Iniciativa da Entidade Empregadora">Iniciativa da Entidade Empregadora</option>
                                            </select> :
                                                data.origemInscricao ? <div className="mt-2 text-lg text-text-main-color">{data.origemInscricao}</div> : <div className="input-error-profile" />}
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Provém do Centro Qualifica:</div>
                                            {alterar1 === true ? <select onWheel={(e) => e.target.blur()} defaultValue={CQEP ? CQEP : data.CQEP ? data.CQEP : ""} id="CQEP" name="CQEP" className="text-lg max-w-[20rem] selector" onChange={(e) => setCQEP(e.target.value)}>
                                                <option disabled value="">Selecione uma opção</option>
                                                <option value="Sim">Sim</option>
                                                <option value="Não">Não</option>
                                            </select> :
                                                data.CQEP ? <div className="mt-2 text-lg text-text-main-color">{CQEP}</div> : <div className="input-error-profile" />}
                                        </div>
                                        {(CQEP && CQEP === "Sim") || (!CQEP && data.CQEP === "Sim") ? (
                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Origem Centro Qualifica:</div>
                                                {alterar1 === true ? <input id="originCQEP" name="originCQEP" className="text-lg input-field w-[20rem]" placeholder={data.originCQEP} defaultValue={data.originCQEP} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.originCQEP}</div>}
                                            </div>
                                        ) : ""}
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Situação Laboral:</div>
                                            {alterar1 === true ? <select onWheel={(e) => e.target.blur()} onChange={(e) => setEmploymentSituation(e.target.value)} defaultValue={data.employmentSituation} id="employmentSituation" className="selector text-lg max-w-[20rem]">
                                                <option value="" disabled>Selecione a sua opção</option>
                                                <option value="Empregados por conta de outrem - Internos">Empregados por conta de outrem - Internos</option>
                                                <option value="Empregados por conta de outrem - Externos">Empregados por conta de outrem - Externos</option>
                                                <option value="Empregados por conta própria">Empregados por conta própria</option>
                                                <option value="Desempregados à procura de novo emprego - DLD">Desempregados à procura de novo emprego - DLD</option>
                                                <option value="Desempregados à procura de novo emprego - Não DLD">Desempregados à procura de novo emprego - Não DLD</option>
                                                <option value="Desempregados à procura do 1º emprego">Desempregados à procura do 1º emprego</option>
                                                <option value="Inativos - A frequentarem ações de educação ou formação">Inativos - A frequentarem ações de educação ou formação</option>
                                                <option value="Inativos - Outros">Inativos - Outros</option>
                                            </select> :
                                                <div className="mt-2 text-lg text-text-main-color">{data.employmentSituation}</div>}
                                        </div>
                                        {(data.employmentSituation.includes("Empregados por conta de outrem") || (employmentSituation && employmentSituation.includes('Empregados por conta de outrem'))) || (data.employmentSituation.includes("Empregados por conta própria") || (employmentSituation && employmentSituation.includes('Empregados por conta própria'))) ? (
                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Profissão/Função:</div>
                                                {alterar1 === true ? <input id="job" name="job" className="text-lg input-field w-[20rem]" placeholder={data.job} defaultValue={data.job} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.job}</div>}
                                            </div>
                                        ) : ""}
                                        {data.employmentSituation.includes("Empregados por conta de outrem") || (employmentSituation && employmentSituation.includes('Empregados por conta de outrem')) || (data.employmentSituation.includes("Empregados por conta própria") || (employmentSituation && employmentSituation.includes('Empregados por conta própria'))) ? (
                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Nome Entidade Empregadora:</div>
                                                {alterar1 === true ? <input id="nameEmployer" name="nameEmployer" className="text-lg input-field w-[20rem]" placeholder={data.nameEmployer} defaultValue={data.nameEmployer} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.nameEmployer}</div>}
                                            </div>
                                        ) : ""}
                                        {data.employmentSituation.includes("Empregados por conta de outrem") || (employmentSituation && employmentSituation.includes('Empregados por conta de outrem')) || (data.employmentSituation.includes("Empregados por conta própria") || (employmentSituation && employmentSituation.includes('Empregados por conta própria'))) ? (
                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">NIF Entidade Empregadora:</div>
                                                {alterar1 === true ? <input id="nifEmployer" name="nifEmployer" className="text-lg input-field w-[20rem]" placeholder={data.nifEmployer} defaultValue={data.nifEmployer} required></input> : <div className="mt-2 text-lg text-text-main-color">{data.nifEmployer}</div>}
                                            </div>
                                        ) : ""}

                                        {((!employmentSituation && (data.employmentSituation === 'Desempregados à procura de novo emprego - DLD' ||
                                            data.employmentSituation === 'Desempregados à procura de novo emprego - Não DLD' ||
                                            data.employmentSituation === 'Desempregados à procura do 1º emprego' ||
                                            data.employmentSituation === 'Inativos - A frequentarem ações de educação ou formação' ||
                                            data.employmentSituation === 'Inativos - Outros')) || (employmentSituation && (employmentSituation === 'Desempregados à procura de novo emprego - DLD' ||
                                                employmentSituation === 'Desempregados à procura de novo emprego - Não DLD' ||
                                                employmentSituation === 'Desempregados à procura do 1º emprego' ||
                                                employmentSituation === 'Inativos - A frequentarem ações de educação ou formação' ||
                                                employmentSituation === 'Inativos - Outros'))) ? (
                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Data de inicio da situação face ao desemprego:</div>
                                                {alterar1 === true ? <input id="employmentSituationDate" type="date" max={new Date().toISOString().split("T")[0]} name="employmentSituationDate" className="text-lg input-field w-[20rem]" placeholder={data.employmentSituationDate} defaultValue={data.employmentSituationDate} required></input> : <div className="mt-2 text-lg text-text-main-color"> {AlterarFormato(data.employmentSituationDate)}</div>}
                                            </div>
                                        ) : ""}

                                        {((!employmentSituation && (data.employmentSituation === 'Desempregados à procura de novo emprego - DLD' ||
                                            data.employmentSituation === 'Desempregados à procura de novo emprego - Não DLD' ||
                                            data.employmentSituation === 'Desempregados à procura do 1º emprego' ||
                                            data.employmentSituation === 'Inativos - A frequentarem ações de educação ou formação' ||
                                            data.employmentSituation === 'Inativos - Outros')) || (employmentSituation && (employmentSituation === 'Desempregados à procura de novo emprego - DLD' ||
                                                employmentSituation === 'Desempregados à procura de novo emprego - Não DLD' ||
                                                employmentSituation === 'Desempregados à procura do 1º emprego' ||
                                                employmentSituation === 'Inativos - A frequentarem ações de educação ou formação' ||
                                                employmentSituation === 'Inativos - Outros'))) ? (
                                            <div className="mt-4 text-center sm:text-left">
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Beneficiário de Prestação Social:</div>
                                                {alterar1 === true ? <select onWheel={(e) => e.target.blur()} defaultValue={data.beneficiarySS} id="beneficiarySS" className="selector text-lg max-w-[20rem]">
                                                    <option disabled value="">Selecione uma opção</option>
                                                    <option value="Sim">Não</option>
                                                    <option value="Não">Sim</option>
                                                </select> :
                                                    <div className="mt-2 text-lg text-text-main-color">{data.beneficiarySS}</div>}
                                            </div>
                                        ) : ""}

                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Estado de Inscrição:</div>
                                            <div className="mt-1 text-lg text-text-main-color">{data.valid}</div>
                                        </div>
                                    </div>
                                    {CheckTypeNotFinanc() === true && <div className="flex justify-center mt-3 sm:justify-normal">
                                        {alterar1 === true ? <button className="sm:mr-5 button-cancelar w-36" onClick={() => { setAlterar1(false); }}>Cancelar</button> : ""}
                                        {alterar1 === true ?
                                            <ConfirmToaster css={"button-confirmar mt-4 sm:mt-0 w-36 hover:brightness-75 tooltip"} icon={"Confirmar"} title={"Alterar Perfil"} message={"Deseja alterar o seu perfil ?"} confirm={alterarPerfil} tooltip={"Alterar Perfil"}></ConfirmToaster> :
                                            <button className="w-36 button-geral" onClick={() => { setAlterar1(true); }}>Alterar Dados</button>
                                        }
                                    </div>}
                                </>}
                        </div>
                        <h3 className="mt-8 text-3xl font-bold text-center text-text-main-color">Ficheiros</h3>
                        <div className="mt-3">
                            <div className="text-center subtitle">Ficheiros de Identificação</div>
                            <div className="grid w-1/2 grid-cols-1 mx-auto sm:grid-cols-2 lg:grid-cols-4">
                                {data && data.cc_path ?
                                    <div className="flex justify-center items-center flex-col">
                                        <button
                                            className={data.aprovDocs && data.aprovDocs.length > 0 ?
                                                (data.aprovDocs[0].cc === "true" ? "button-files bg-green-800 hover:bg-green-700" :
                                                    (data.aprovDocs[0].cc === "false" ? "button-files bg-red-800 hover:bg-red-700" : "button-files")) :
                                                "button-files"
                                            }
                                            onClick={() => {
                                                if (data.cc_path) {
                                                    window.open(data.cc_path, '_blank');
                                                }
                                            }}
                                        >
                                            Documento de Identificação                                        </button>
                                        {((!data.aprovDocs || data.aprovDocs.length === 0) || (data.aprovDocs.length > 0 && data.aprovDocs[0].cc === "")) ? <div className="flex justify-center gap-2">
                                            <button className="tooltip" data-tip="Validar Documento" onClick={() => aprovarDoc("true", "cc")}><FaFileCircleCheck className="text-green-600" /></button>
                                            <button className="tooltip" data-tip="Invalidar Documento" onClick={() => aprovarDoc("false", "cc")}><FaFileCircleXmark className="text-red-600" /></button>
                                            <ConfirmToaster
                                                css="text-cancel-color hover:brightness-75 tooltip"
                                                icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                title="Remover Ficheiro"
                                                message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                confirm={() => removeDocs(true, false, false, false, false)}
                                                type="remover"
                                                tooltip="Eliminar Ficheiro"
                                            />
                                        </div> : <div className="flex justify-center gap-2"> <ConfirmToaster
                                            css="text-cancel-color hover:brightness-75 tooltip"
                                            icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                            title="Remover Ficheiro"
                                            message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                            confirm={() => removeDocs(true, false, false, false, false)}
                                            type="remover"
                                            tooltip="Eliminar Ficheiro"
                                        /></div>}
                                    </div>
                                    :
                                    <div className="flex justify-center tooltip" data-tip="Sem Documento">
                                        <button className="button-files-empty">Documento de Identificação </button>

                                    </div>}

                                {data && (data.employmentSituation.includes("Empregados por conta de outrem") || data.employmentSituation.includes('Empregados por conta própria')) ?
                                    <>
                                        {data.rv_path &&
                                            <div className="flex justify-center">
                                                <a href={data.rv_path} target="_blank" rel="noreferrer" download>
                                                    <button className="button-files"> Recibo de Vencimento</button>
                                                </a>
                                            </div>
                                        }
                                    </> :
                                    ""}

                                {data && (data.employmentSituation === "Desempregados à procura de novo emprego - DLD" || data.employmentSituation === "Desempregados à procura de novo emprego - Não DLD" || data.employmentSituation === "Desempregados à procura do 1º emprego" || data.employmentSituation === "Inativos - A frequentarem ações de educação ou formação" || data.employmentSituation === "Inativos - Outros") ?
                                    <>
                                        {data && data.dce_path &&
                                            <div className="flex justify-center">
                                                <a href={data.dce_path} target="_blank" rel="noreferrer" download>
                                                    <button className="button-files"> DCE </button>
                                                </a>
                                            </div>
                                        }
                                    </> : ""}
                                {data && data.csp_path ?
                                    <div className="flex justify-center items-center flex-col">
                                        <button
                                            className={data.aprovDocs && data.aprovDocs.length > 0 ?
                                                (data.aprovDocs[0].csp === "true" ? "button-files bg-green-800 hover:bg-green-700" :
                                                    (data.aprovDocs[0].csp === "false" ? "button-files bg-red-800 hover:bg-red-700" : "button-files")) :
                                                "button-files"
                                            }
                                            onClick={() => {
                                                if (data.csp_path) {
                                                    window.open(data.csp_path, '_blank');
                                                }
                                            }}
                                        >
                                            Comprovativo de Situação Profissional
                                        </button>
                                        {((!data.aprovDocs || data.aprovDocs.length === 0) || (data.aprovDocs.length > 0 && data.aprovDocs[0].csp === "")) ? <div className="flex justify-center gap-2">
                                            <button className="tooltip" data-tip="Validar Documento" onClick={() => aprovarDoc("true", "csp")}><FaFileCircleCheck className="text-green-600" /></button>
                                            <button className="tooltip" data-tip="Invalidar Documento" onClick={() => aprovarDoc("false", "csp")}><FaFileCircleXmark className="text-red-600" /></button>
                                            <ConfirmToaster
                                                css="text-cancel-color hover:brightness-75 tooltip"
                                                icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                title="Remover Ficheiro"
                                                message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                confirm={() => removeDocs(false, false, true, false, false)}
                                                type="remover"
                                                tooltip="Eliminar Ficheiro"
                                            />
                                        </div> : <div className="flex justify-center gap-2"> <ConfirmToaster
                                            css="text-cancel-color hover:brightness-75 tooltip"
                                            icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                            title="Remover Ficheiro"
                                            message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                            confirm={() => removeDocs(false, false, true, false, false)}
                                            type="remover"
                                            tooltip="Eliminar Ficheiro"
                                        /></div>}
                                    </div>
                                    :
                                    <div className="flex justify-center tooltip" data-tip="Sem Documento">
                                        <button className="button-files-empty"> Comprovativo de Situação Profissional</button>
                                    </div>}
                                {data && data.cm_path ?
                                    <div className="flex justify-center items-center flex-col">
                                        <button
                                            className={data.aprovDocs && data.aprovDocs.length > 0 ?
                                                (data.aprovDocs[0].cm === "true" ? "button-files bg-green-800 hover:bg-green-700" :
                                                    (data.aprovDocs[0].cm === "false" ? "button-files bg-red-800 hover:bg-red-700" : "button-files")) :
                                                "button-files"
                                            }
                                            onClick={() => {
                                                if (data.cm_path) {
                                                    window.open(data.cm_path, '_blank');
                                                }
                                            }}
                                        >
                                            Comprovativo de Morada
                                        </button>
                                        {((!data.aprovDocs || data.aprovDocs.length === 0) || (data.aprovDocs.length > 0 && data.aprovDocs[0].cm === "")) ? <div className="flex justify-center gap-2">
                                            <button className="tooltip" data-tip="Validar Documento" onClick={() => aprovarDoc("true", "cm")}><FaFileCircleCheck className="text-green-600" /></button>
                                            <button className="tooltip" data-tip="Invalidar Documento" onClick={() => aprovarDoc("false", "cm")}><FaFileCircleXmark className="text-red-600" /></button>
                                            <ConfirmToaster
                                                css="text-cancel-color hover:brightness-75 tooltip"
                                                icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                title="Remover Ficheiro"
                                                message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                confirm={() => removeDocs(false, false, false, false, true)}
                                                type="remover"
                                                tooltip="Eliminar Ficheiro"
                                            />
                                        </div> : <div className="flex justify-center gap-2">
                                            <ConfirmToaster
                                                css="text-cancel-color hover:brightness-75 tooltip"
                                                icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                title="Remover Ficheiro"
                                                message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                confirm={() => removeDocs(false, false, false, false, true)}
                                                type="remover"
                                                tooltip="Eliminar Ficheiro"
                                            />
                                        </div>}
                                    </div>
                                    :
                                    <div className="flex justify-center tooltip" data-tip="Sem Documento">
                                        <button className="button-files-empty"> Comprovativo de Morada</button>
                                    </div>}
                                {data && data.ch_path ?
                                    <div className="flex justify-center items-center flex-col">
                                        <button
                                            className={data.aprovDocs && data.aprovDocs.length > 0 ?
                                                (data.aprovDocs[0].ch === "true" ? "button-files bg-green-800 hover:bg-green-700" :
                                                    (data.aprovDocs[0].ch === "false" ? "button-files bg-red-800 hover:bg-red-700" : "button-files")) :
                                                "button-files"
                                            }
                                            onClick={() => {
                                                if (data.ch_path) {
                                                    window.open(data.ch_path, '_blank');
                                                }
                                            }}
                                        >
                                            Certificado de Habilitações
                                        </button>
                                        {((!data.aprovDocs || data.aprovDocs.length === 0) || (data.aprovDocs.length > 0 && data.aprovDocs[0].ch === "")) ? <div className="flex justify-center gap-2">
                                            <button className="tooltip" data-tip="Validar Documento" onClick={() => aprovarDoc("true", "ch")}><FaFileCircleCheck className="text-green-600" /></button>
                                            <button className="tooltip" data-tip="Invalidar Documento" onClick={() => aprovarDoc("false", "ch")}><FaFileCircleXmark className="text-red-600" /></button>
                                            <ConfirmToaster
                                                css="text-cancel-color hover:brightness-75 tooltip"
                                                icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                title="Remover Ficheiro"
                                                message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                confirm={() => removeDocs(false, true, false, false, false)}
                                                type="remover"
                                                tooltip="Eliminar Ficheiro"
                                            />
                                        </div> : <div className="flex justify-center gap-2"> <ConfirmToaster
                                            css="text-cancel-color hover:brightness-75 tooltip"
                                            icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                            title="Remover Ficheiro"
                                            message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                            confirm={() => removeDocs(false, true, false, false, false)}
                                            type="remover"
                                            tooltip="Eliminar Ficheiro"
                                        /></div>}
                                    </div>
                                    :
                                    <div className="flex justify-center tooltip" data-tip="Sem Documento">
                                        <button className="button-files-empty"> Certificado de Habilitações</button>
                                    </div>}
                                {data && data.ibancomp_path ?
                                    <div className="flex justify-center items-center flex-col">
                                        <button
                                            className={data.aprovDocs && data.aprovDocs.length > 0 ?
                                                (data.aprovDocs[0].iban === "true" ? "button-files bg-green-800 hover:bg-green-700" :
                                                    (data.aprovDocs[0].iban === "false" ? "button-files bg-red-800 hover:bg-red-700" : "button-files")) :
                                                "button-files"
                                            }
                                            onClick={() => {
                                                if (data.ibancomp_path) {
                                                    window.open(data.ibancomp_path, '_blank');
                                                }
                                            }}
                                        >
                                            IBAN
                                        </button>
                                        {((!data.aprovDocs || data.aprovDocs.length === 0) || (data.aprovDocs.length > 0 && data.aprovDocs[0].iban === "")) ? <div className="flex justify-center gap-2">
                                            <button className="tooltip" data-tip="Validar Documento" onClick={() => aprovarDoc("true", "iban")}><FaFileCircleCheck className="text-green-600" /></button>
                                            <button className="tooltip" data-tip="Invalidar Documento" onClick={() => aprovarDoc("false", "iban")}><FaFileCircleXmark className="text-red-600" /></button>
                                            <ConfirmToaster
                                                css="text-cancel-color hover:brightness-75 tooltip"
                                                icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                title="Remover Ficheiro"
                                                message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                confirm={() => removeDocs(false, false, false, true, false)}
                                                type="remover"
                                                tooltip="Eliminar Ficheiro"
                                            />
                                        </div> : <div className="flex justify-center gap-2"> <ConfirmToaster
                                            css="text-cancel-color hover:brightness-75 tooltip"
                                            icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                            title="Remover Ficheiro"
                                            message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                            confirm={() => removeDocs(false, false, false, true, false)}
                                            type="remover"
                                            tooltip="Eliminar Ficheiro"
                                        /></div>}
                                    </div>
                                    :
                                    <div className="flex justify-center tooltip" data-tip="Sem Documento">
                                        <button className="button-files-empty"> IBAN </button>
                                    </div>}
                            </div>
                        </div>
                        {data && data.outros_path.length > 0 &&
                            <>
                                <div className="text-center subtitle">Outros Ficheiros</div>
                                <div className="grid w-1/2 grid-cols-1 mx-auto lg:grid-cols-2 2xl:grid-cols-4">
                                    {data.outros_path.map((value, index) => (
                                        <div key={index} className="relative flex justify-center">
                                            {value && <button className="relative flex items-center justify-between p-0 button-files">
                                                <a href={value.file} target="_blank" rel="noreferrer" download className="flex-grow p-2 px-3 sm:py-4">
                                                    {value.name}
                                                </a>
                                                <ConfirmToaster
                                                    css="absolute top-0.5 right-0.5 text-cancel-color hover:brightness-75 tooltip"
                                                    icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                    title="Remover Ficheiro"
                                                    message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                    confirm={() => eliminarOutroDocumento(index)}
                                                    id={value.index}
                                                    type="remover"
                                                    tooltip="Eliminar Ficheiro"
                                                />
                                            </button>}
                                        </div>
                                    ))}
                                </div>
                            </>
                        }

                        {CheckTypeNotFinanc() === true && <div  >
                            {alterar2 === 0 &&
                                <div className="flex justify-center my-3">
                                    <button className="m-1 button-geral" onClick={() => setAlterar2(1)}>
                                        Alterar Ficheiros de Identificação
                                    </button>
                                    <button className="m-1 button-geral sm:ml-3" onClick={() => setAlterar2(2)}>
                                        Adicionar Outros Ficheiros
                                    </button>
                                </div>}

                        </div>}
                        <div>
                            {data && (!data.cc_path || !data.ch_path || !data.cm_path || !data.ibancomp_path || !data.csp_path) && <button className="m-1 my-4 mb-8 button-geral w-72" onClick={() => envioEmailFormando()}>
                                Enviar email para envio de ficheiros
                            </button>}
                        </div>
                        {alterar2 === 1 &&
                            <div className="mb-8">
                                <form className="container" id="form" onSubmit={() => submeterFicheiros()}>
                                    <h1 className="title">Insira os seguintes ficheiros</h1>
                                    <div className="flex flex-col items-center">
                                        <div className="flex flex-col mt-4 text-center sm:text-left w-9/20">
                                            <div className="label-input">Documento de Identificação: </div>
                                            <input className="input-file" type="file" id="cc" name="cc" accept=".pdf" />
                                        </div>
                                        {/* {data.employmentSituation.includes("Empregados por conta de outrem") || data.employmentSituation.includes('Empregados por conta própria') ?
                                            <div className="flex flex-col mt-4 text-center sm:text-left w-9/20">
                                                <div className="label-input" htmlFor="RV">Recibo de vencimento: </div>
                                                <input className="input-file" type="file" id="rv" name="rv" accept=".pdf" />
                                            </div> : ""
                                        }
                                        {data.employmentSituation === "Desempregados à procura de novo emprego - DLD" || data.employmentSituation === "Desempregados à procura de novo emprego - Não DLD" || data.employmentSituation === "Desempregados à procura do 1º emprego" ?
                                            <div className="flex flex-col mt-4 text-center sm:text-left w-9/20">
                                                <div className="label-input" htmlFor="dce">Declaração Centro de Emprego:</div>
                                                <input className="input-file" type="file" id="dce" name="dce" accept=".pdf" />
                                            </div> : ""
                                        } */}
                                        <div className="flex flex-col mt-4 text-center sm:text-left w-9/20">
                                            <div className="label-input" htmlFor="csp">Comprovativo de Situação Profissional: </div>
                                            <input className="input-file" type="file" id="csp" name="csp" accept=".pdf" />
                                        </div>
                                        <div className="flex flex-col mt-4 text-center sm:text-left w-9/20">
                                            <div className="label-input" htmlFor="cm">Comprovativo de Morada: </div>
                                            <input className="input-file" type="file" id="cm" name="cm" accept=".pdf" />
                                        </div>
                                        <div className="flex flex-col mt-4 text-center sm:text-left w-9/20">
                                            <div className="label-input" htmlFor="ch">Certificado de Habilitações: </div>
                                            <input className="input-file" type="file" id="ch" name="ch" accept=".pdf" />
                                        </div>
                                        <div className="flex flex-col mt-4 text-center sm:text-left w-9/20">
                                            <div className="label-input" htmlFor="ibancomp">Comprovativo de IBAN: </div>
                                            <input className="input-file" type="file" id="ibancomp" name="ibancomp" accept=".pdf" />
                                        </div>
                                        <p className="mx-auto mt-3 text-text-main-color">*Formato suportado .pdf</p>
                                        <div className="flex mt-3">
                                            <button className="mr-2 button-cancelar" id="alterar-botao" onClick={() => setAlterar2(0)}>
                                                Cancelar
                                            </button>
                                            <input className="button-confirmar" type="submit" value={"Submeter"} />
                                        </div>
                                    </div>
                                </form>
                            </div>}
                        {alterar2 === 2 ?
                            <div>
                                <h1 className="mt-8 text-center title">Adicionar Ficheiros</h1>
                                <div className="flex flex-col justify-center mb-2">
                                    <label className="text-center text-text-main-color">Quantos outros documentos deseja inserir:</label>
                                    <input className="w-1/3 mx-auto input-field" type="number" value={numDoc} onChange={(e) => { setNumDoc(e.target.value) }}></input>
                                </div>
                                <div className="flex flex-col items-center w-full pt-4 overflow-y-auto rounded-lg max-h-156 bg-gray-50">
                                    {Array.from({ length: numDoc }).map((_, index) => (
                                        <div key={index} className="w-4/5 my-2">
                                            <h4 className="mb-1 text-left uppercase text-main-color">Documento {index + 1}</h4>
                                            <div className="flex w-full text-left">
                                                <label className="w-2/5 text-text-main-color" htmlFor={`fullName${index}`}>Nome do documento:</label>
                                                <input className="w-3/5 input-field" type="text" id={`fullName${index}`} name={`fullName${index}`} value={outrosDocs[index]?.name} onChange={(e) => handleNameChange(e, index)} />
                                            </div>
                                            <div className="flex w-full mt-2 mb-3 text-left">
                                                <label className="w-2/5 text-text-main-color" htmlFor={`outro${index}`}>Ficheiro:</label>
                                                <input className="w-3/5 input-file" type="file" id={`outro${index}`} name={`outro${index}`} accept=".pdf" onChange={(e) => handleFileChange(e, index)} />
                                            </div>
                                            <hr />
                                        </div>
                                    ))}
                                </div>
                                <p className="text-center text-text-error">*Formato suportado .pdf</p>
                                <div className="flex justify-center mt-3">
                                    <button className="mr-5 button-cancelar" onClick={() => { setAlterar2(0) }}>Cancelar</button>
                                    <ConfirmToaster css={"button-confirmar"} icon={"Confirmar"} title={"Confirmar"} message={"Deseja confirmar as alterações?"} confirm={submeterFicheiros} tooltip={"Submeter Ficheiros"}></ConfirmToaster>
                                </div>
                            </div>
                            : ""}
                        <div>
                            <button className="m-1 my-4 mb-8 button-geral w-72" onClick={() => setVerIntegracaoSistema(!verIntegracaoSistema)}>
                                Ver integração com o sistema
                            </button>
                        </div>
                        {verIntegracaoSistema === true ?
                            <div className="mt-8">
                                <h1 className="title">Integração com o Sistema</h1>

                                {data && data.percurso.length > 0 ? <>
                                    <div className="table-container flex flex-col">
                                        <div className="flex justify-center items-center mb-1">
                                            <label className="text-text-main-color">Dados por página: </label>
                                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                                value={itemsPag}
                                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={50}>50</option>
                                            </select>
                                        </div>
                                        <table className="my-table">

                                            <thead className="bg-main-color">
                                                <tr className="text-white h-14">
                                                    <th className="p-2 min-w-[150px] border border-white">Curso</th>
                                                    <th className="p-2 min-w-[150px] border border-white">Estado</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-text-main-color">
                                                {dataToDisplay.map((item, index) => (
                                                    <tr key={index}>
                                                        {item.percurso ? <td className="border border-white">{item.percurso[0].name}</td> : <td />}
                                                        {item.valid === "Desistiu" ? <td>Desistiu</td> : <>{item.valid === "Validado" ? <td >Inscrito</td> : <>{item.valid === "Por Validar" ? <td>Candidato</td> : <td>Excluído</td>}</>}</>}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="flex items-center justify-center mt-4 mb-8">
                                        <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                            <AiOutlineDoubleLeft />
                                        </button>
                                        <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                            <AiOutlineLeft />
                                        </button>
                                        <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                                        <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                            <AiOutlineRight />
                                        </button>
                                        <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                            <AiOutlineDoubleRight />
                                        </button>
                                    </div> </> : <p className="third-title">Não existem dados</p>}

                            </div> : ""}
                    </div>

                </div>

            </main>
            <Footer />
        </>
    )

}
export default PerfilFormando