import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../Footer";
import 'react-toastify/dist/ReactToastify.css';
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import toastSuccess from "../Toast/toastSuccess";
import toastError from "../Toast/toastError";
import { BsPencilFill } from "react-icons/bs";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";

function GerirEntidadeFinanciadora(token) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const image = new Image();
    const [imageHeightLogo, setimageHeightLogo] = useState(0);
    const params = useParams();
    const [cont, setCont] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        getEntFinanciadora()
    }, [cont]);

    function getEntFinanciadora() {
        fetch(`${process.env.REACT_APP_API_URL}/EntFinanciadora/` + params.id_item, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)
                setLoading(false)
            })
    }
    const handleFileChangeLogo = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                image.onload = function () {
                    setimageHeightLogo(image.height)
                };
                image.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFileUploadLogo = async (event, logo) => {
        await handleFileChangeLogo(event);

        if (imageHeightLogo <= 240) {
            setLoading(true);
            const selectedFile = event.target.files[0];
            let ccForm = new FormData();
            ccForm.append('logo', selectedFile);

            const options = {
                method: "POST",
                headers: { "Accept": "application/json" },
                credentials: "same-origin",
                body: ccForm
            };

            try {
                const logoResponse = await fetch(`${process.env.REACT_APP_API_URL}/logo`, options);
                const logoResult = await logoResponse.json();
                let logo1Result
                let logo2Result
                if (logo === "logo1") {
                    logo1Result = logoResult
                }
                if (logo === "logo2") {
                    logo2Result = logoResult
                }
                await fetch(`${process.env.REACT_APP_API_URL}/EntFinanciadora/${params.id_item}/logo`, {
                    body: JSON.stringify({
                        logotipo1: logo1Result,
                        logotipo2: logo2Result
                    }),
                    method: "PATCH",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem(token)
                    }
                });

                toastSuccess('Logotipo adicionado com sucesso');
                setCont(cont + 1)
                setLoading(false);
            } catch (error) {
                console.error('Error uploading image:', error);
                setLoading(false);
            }
        } else toastError('A altura da imagem deve ser menor ou igual a 240 píxeis!');
    };



    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    {token === "admin1" && <NavbarAdmin1 currentPage={"gerirEntidadeTraining"} />}
                    {token === "admin2" && <NavbarAdmin2 currentPage={"gerirEntidadeTraining"} />}
                </div>
                <button onClick={()=> navigate(-1)} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </button>
                <div className="flex-grow mx-5 mt-20 sm:mt-14">
                    <div>
                        <h1 className="md:mx-12 title">Entidade Financiadora - {data.name}</h1>
                        <div className={token === "admin2"?"relative mx-auto transition cursor-pointer group hover:opacity-80 w-fit":"relative mx-auto transition"} onClick={() => {token === "admin2" && document.getElementById('fileInput').click(); }} >
                            <img className="object-contain h-32 mx-auto" src={data.logotipo1} />
                            <p className="mt-1 text-sm text-text-main-color">(Logotipo Canto Superior Direito)</p>
                           {token === "admin2"&& <div className="absolute -translate-x-1/2 -translate-y-1/2 opacity-0 top-1/2 left-1/2 group-hover:opacity-100">
                                <BsPencilFill className="text-black" />
                            </div>}
                           {token === "admin2"&& <input id="fileInput" type="file" accept=".png, .jpeg, .jpg" style={{ display: 'none' }} onChange={(e) => handleFileUploadLogo(e, "logo1")} />}
                        </div>
                        <div className="flex flex-col">

                        </div>

                        {data.logotipo2 && 
                        
                        
                        <div className={token === "admin2"?"relative mx-auto transition cursor-pointer group hover:opacity-80 w-fit":"relative mx-auto transition"} onClick={() => {token === "admin2" && document.getElementById('fileInput2').click(); }}>
                            <img className="object-contain h-32 mx-auto" src={data.logotipo2} />
                            <p className="mt-1 text-sm text-text-main-color">(Logotipo centro inferior)</p>
                            {token === "admin2"&&<div className="absolute -translate-x-1/2 -translate-y-1/2 opacity-0 top-1/2 left-1/2 group-hover:opacity-100">
                                <BsPencilFill className="text-black" />
                            </div>}
                            {token === "admin2" &&<input id="fileInput2" type="file" accept=".png, .jpeg, .jpg" style={{ display: 'none' }} onChange={(e) => handleFileUploadLogo(e, "logo2")} />}
                        </div>}
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default GerirEntidadeFinanciadora