import React, { useState, useEffect } from "react";
import Header from "../../../../../../components/Header";
import { useParams, Link, useNavigate } from 'react-router-dom'
import Footer from "../../../../../../components/Footer";
import ExcelGenerator from "../../../../../../components/Excel/cronogramaDisciplina";
import { FiDownload } from "react-icons/fi";
import NavbarFormador from "../../../../../../components/Navbars/NavbarFormador/navbarFormador";
import { AiOutlineCloseCircle, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineMail, AiOutlineRight } from "react-icons/ai";
import { FaEye, FaEyeSlash, FaRegListAlt, FaUser, FaUserGraduate, FaUserTie } from "react-icons/fa";
import Cookies from "js-cookie";
import AlterarFormato from "../../../../../../components/FormChecks/formatDates/foramatDates";
import { TbReportAnalytics } from "react-icons/tb";
import { PiUserListLight } from "react-icons/pi";
import toastSuccess from "../../../../../../components/Toast/toastSuccess";
import QRCodeGenerator from "../../../../../../components/QRCode/QRCode";

export default function UFCDsListed() {
    const [data, setData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formador-coursesIndUfcd-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formador-coursesIndUfcd-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Formadores-coursesIndUfcd-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    const [ufcdSelecionadaNome, setUfcdSelecionadaNome] = useState(null);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const [modal2Option, setModal2Option] = useState("");
    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.codeUfcd.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.percurso && item.percurso.length > 0 && item.percurso[0].name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }

    const navigate = useNavigate();
    const params = useParams();

    document.data = data


    useEffect(() => {
        Cookies.set("Formador-coursesIndUfcd-Pag", paginaAtual);
        Cookies.set("Formador-coursesIndUfcd-1", searchTerm);
        Cookies.set(`Formadores-coursesIndUfcd-itm1`, itemsPag)
        getUfcds()
    }, [loading, paginaAtual, nPaginas, searchTerm, itemsPag]);


    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }
    //Envio de Mail do questionário aos formandos
    function sendEmailSatisfacaoFormandos(id) {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${id}/sendMailAvaliacaoSatisfacaoFormando`, {
            headers: {
                "authorization": localStorage.getItem('user')
            }
        })
            .then(response => response.json())
            .then(result => {
                toastSuccess("Emails enviados com sucesso")
            })
    }
    const handleClick = (event, item) => {
        // Check if the clicked element is a <td>
        if (event.target.tagName === 'TD') {
            // Check if the <td> has a 'clickable' class
            if (event.target.classList.contains('percurso')) {
                window.open(item.roteiroPedagogico, '_blank')
            }
            else navigate("/menutrainers/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + item._id + "/turma")
        }
    };

    //Recebe as ufcds associadas a um formador ativas
    function getUfcds() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_curso}/percurso`, {
            headers: {
                'authorization': localStorage.getItem('user')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    const disciplinas = []
                    result.forEach(element => {

                        element.formadores.forEach(element2 => {
                            if (element2._id === params.id_candidatura) {
                                disciplinas.push(element)
                            }
                        })
                    })
                    setData(disciplinas);

                    paginacaoTabelas(filterData(disciplinas));
                    let cont = 0
                    if (result) {
                        result.forEach(element => {
                            if (element.fechoCoordenador) {
                                cont++
                            }
                        });
                        if (cont === 0) {
                            fetch(`${process.env.REACT_APP_API_URL}/percurso/coordenador/fechoCurso/${params.id_curso}`, {
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'authorization': localStorage.getItem('user')
                                },
                                method: 'POST',
                                body: JSON.stringify({
                                    fechoCoordenador: true
                                })
                            })
                                .then(res => res.json())
                                .then((result) => {
                                })
                        }
                    }
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarFormador currentPage={"cursos"} />
                </div>

                <div className="relative h-full col-span-5 mx-4 xl:col-span-8">

                    <div className="back-button-noNavbar">
                        <button onClick={() => navigate(-1)} className="flex w-8 h-8">
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </button>
                    </div>
                    <Header sideMenu={"true"} />
                    <h1 className="mx-4 mt-8 title sm:mt-0">Lista de disciplinas</h1>
                    {data && data.length > 0 ? <> <div className="search-div">
                        <label className="search-input-label">Pesquisar: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="search" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="search-input" />
                        </div>
                    </div>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-containerSideMenu">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[80px] border border-white pl-2">Código da disciplina</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nome</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data inicio</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data final</th>
                                        <th className="p-2 min-w-[80px] border border-white">Duração</th>
                                        <th className="p-2 min-w-[80px] border border-white">Turma</th>
                                        <th className="p-2 min-w-[80px] border border-white">Pauta</th>
                                        <th className="p-2 min-w-[80px] border border-white">Avaliação de Satisfação</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nome do Curso</th>
                                        <th className="p-2 min-w-[80px] border border-white">Gerir Eventos</th>
                                        <th className="p-2 min-w-[80px] border border-white pr-2">Cronograma</th>
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay).map((item, index) => (
                                            <tr key={index}>
                                                <td className="border border-white">{item.codeUfcd}</td>
                                                <td className="border border-white">{item.name}</td>
                                                <td className="border border-white" type="date">{AlterarFormato(item.dateBegin)}</td>
                                                <td className="border border-white" type="date">{AlterarFormato(item.dateEnd)}</td>
                                                <td className="border border-white">{item.cargaHoraria}</td>
                                                <td className="border border-white cursor-pointer hover:bg-gray-300 group">
                                                    <div className="flex items-center justify-center group-hover:brightness-150" onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + item._id + "/turma")}>
                                                        <FaEye size={24} />
                                                    </div>
                                                </td>
                                                {item.formandos.length > 0 ? (
                                                    <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + item._id + "/pauta")}>
                                                        <div className="flex items-center justify-center group-hover:brightness-150">
                                                            <FaEye size={24} />
                                                        </div>
                                                    </td>
                                                ) : (
                                                    <td className="border border-white cursor-not-allowed">
                                                        <div className="flex items-center justify-center opacity-25 ">
                                                            <FaEyeSlash size={24} />
                                                        </div>
                                                    </td>
                                                )}
                                                <td className="border border-white">
                                                    <button className="button-geral" onClick={(e) => { setModalIsOpen2(true); setUfcdSelecionadaNome(item) }}>
                                                        <PiUserListLight />
                                                    </button>
                                                </td>
                                                {item.percurso[0] ? (
                                                    <td className="border border-white">{item.percurso[0].name}</td>
                                                ) : (
                                                    <td className="border border-white">Sem curso</td>
                                                )}
                                                <td className="border border-white add">
                                                    <button className="button-geral" onClick={(e) => navigate("/menutrainers/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + item._id + "/percursopedagogico")}>
                                                        Marcar Eventos
                                                    </button>
                                                </td>
                                                <td className="border border-white">
                                                    <button className="button-geral" onClick={() => ExcelGenerator(item)}><FiDownload /></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>
                        <div className="modal2" style={{ display: modalIsOpen2 ? "block" : "none" }}>
                            <div className="fixed inset-0 w-screen h-screen m-0 bg-opacity-0 font-Lato">
                                <div className="flex items-center justify-center h-screen">
                                    <div className="relative z-50 p-2 rounded-lg shadow-lg  xl:w-128 bg-secundary-color shadow-gray-600 min-w-[40%] min-h-[60%]">
                                        {modalIsOpen2 && modal2Option !== "" && <div className="flex flex-row gap-2 justify-center items-center">
                                            <button onClick={() => setModal2Option("formando")} className="tooltip" data-tip={"Avaliação de Satisfação do formando"}><FaUserGraduate className={modal2Option === "formando" ? "p-2 text-third-color m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={60} /></button>
                                            <button onClick={() => setModal2Option("formador")} className="tooltip" data-tip={"Avaliação de Satisfação do formador"}><FaUser className={modal2Option === "formador" ? "p-2 text-third-color tooltip" : "p-2 text-main-color hover:text-text-second-color "} size={60} /></button>
                                        </div>}
                                        {modalIsOpen2 && modal2Option !== "" && <hr className="bg-main-color my-2 h-1" />}
                                        {modalIsOpen2 && modal2Option === "" &&
                                            <div className="h-full overflow-auto my-auto ">
                                                <h2 className="subtitle">{ufcdSelecionadaNome.codeUfcd + " - " + ufcdSelecionadaNome.name}</h2>
                                                <div className="flex flex-col gap-10 my-auto justify-center items-center h-full">
                                                    <button onClick={() => setModal2Option("formando")} className="tooltip" data-tip={"Avaliação de Satisfação dos Formandos"}>
                                                        <FaUserGraduate className={modal2Option === "formando" ? "p-2 text-third-color m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                                        <div>Avaliação de Satisfação dos Formandos</div>
                                                    </button>
                                                    <button onClick={() => setModal2Option("formador")} className="tooltip" data-tip={"Avaliação de Satisfação dos Formadores"}>
                                                        <FaUser className={modal2Option === "formador" ? "p-2 text-third-color m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                                        <div>Avaliação de Satisfação dos Formadores</div>
                                                    </button>

                                                </div>
                                            </div>
                                        }

                                        {modal2Option === "formando" && <div >
                                            <div className="flex flex-col justify-center my-2">
                                                <h2 className="subtitle">{ufcdSelecionadaNome.codeUfcd + " - " + ufcdSelecionadaNome.name}</h2>
                                                <div className="flex flex-col min-h-[35em] gap-10 my-auto justify-center items-center">
                                                    <div>
                                                        <h3 className="third-title">Formulário de avaliação</h3>
                                                        <div className="flex gap-10 justify-center">
                                                            <QRCodeGenerator link={"https://magna.comenius.pt/#/FormAvaliacaoSatisfacao/" + ufcdSelecionadaNome._id}>
                                                            </QRCodeGenerator>
                                                            <button onClick={() => window.open(process.env.PUBLIC_URL + "/#/" + "FormAvaliacaoSatisfacao/" + ufcdSelecionadaNome._id, '_blank')} className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormando && ufcdSelecionadaNome.avaliacaoSatisfacaoFormando.length > 0) ? "cursor-not-allowed tooltip" : "tooltip"} data-tip={"Formulário de Satisfação"}>
                                                                <FaRegListAlt className={"p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                                            </button>
                                                            <button onClick={() => sendEmailSatisfacaoFormandos(ufcdSelecionadaNome._id)} className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormando && ufcdSelecionadaNome.avaliacaoSatisfacaoFormando.length > 0) ? "cursor-not-allowed tooltip" : "tooltip"} data-tip={"Enviar mail de preenchimento do Formulário de Satisfação"}>
                                                                <AiOutlineMail className={"p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>}

                                        {modal2Option === "formador" && <div >
                                            <div className="flex flex-col justify-center my-2">
                                                <h2 className="subtitle">{ufcdSelecionadaNome.codeUfcd + " - " + ufcdSelecionadaNome.name}</h2>
                                                <div className="flex flex-col gap-5">
                                                    <h3 className="third-title">Formulário de avaliação</h3>
                                                    <button onClick={() => navigate(`/menutrainers/${params.id_candidatura}/cursos/${params.id_curso}/Ufcds/${ufcdSelecionadaNome._id}/FormAvaliacao/formador/${params.id_candidatura}`)} className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormador && ufcdSelecionadaNome.avaliacaoSatisfacaoFormador.length > 0) ? "cursor-not-allowed tooltip" : "tooltip"} data-tip={"Formulário de Avaliação"}>
                                                        <FaRegListAlt className={!(ufcdSelecionadaNome.avaliacaoSatisfacaoFormador && ufcdSelecionadaNome.avaliacaoSatisfacaoFormador.length > 0) ? "p-2 text-main-color m-auto" : "p-2 text-green-600 hover:text-text-second-color m-auto"} size={120} />
                                                    </button>
                                                </div>

                                            </div>
                                        </div>}


                                        <button className="absolute top-1.5 right-1.5" onClick={(e) => setModalIsOpen2(false)}>
                                            <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                    <Link to={"/menutrainers/" + params.id_candidatura + "/cursos/" + params.id_curso} className="back-button-link">
                        <img className='back-button' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                </div>
            </main>
            <Footer />
        </>
    );
}