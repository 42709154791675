import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import axios from 'axios';


const excelGeneratorPautaPresencial = async (userData, alunos, totalAulasSinc, autorization) => {
    //Data de Inicio e Data final
    const formandos = alunos
    const color1 = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFA500' },
    };

    const color2 = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFADD8E6' },
    };
    const colorH = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF' + "#efecbd".substring(1) }
    }
    const color3 = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF7FFFD4' }
    };
    const color4 = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFE0' }
    };

    // Variaveis de estilização
    const alignment = {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true,
    };
    const alignmentVertivalTop = {
        vertical: 'top',
        wrapText: true,
    };
    const bold = { bold: true };
    const bold2 = { bold: true, size: 10 };
    const title = { bold: true, name: "DejaVu Sans", size: 14 };

    const borderThin = {
        top: { style: 'thin' },    // Top border
        bottom: { style: 'thin' }, // Bottom border
        left: { style: 'thin' },   // Left border
        right: { style: 'thin' },  // Right border
    };

    const borderThinNoBottom = {
        top: { style: 'thin' },    // Top border
        left: { style: 'thin' },   // Left border
        right: { style: 'thin' },  // Right border
    };
    const borderThinNoTop = {
        bottom: { style: 'thin' },    // Top border
        left: { style: 'thin' },   // Left border
        right: { style: 'thin' },  // Right border
    };
    const borderThinNoTopNotBottom = {
        left: { style: 'thin' },   // Left border
        right: { style: 'thin' },  // Right border
    };
    const borderBottom = {
        bottom: { style: 'thin' },   // Left border
    };

    // FUNÇÕES

    //calcular a diferença dos meses
    //calcular a diferença dos meses
    function DateDifference(datea, dateb) {
        const year1 = new Date(datea).getFullYear()
        const month1 = new Date(datea).getMonth() + 1
        const year2 = new Date(dateb).getFullYear()
        const month2 = new Date(dateb).getMonth() + 1

        const yearDiff = year2 - year1;
        const monthDiff = month2 - month1;

        const totalMonthsDiff = yearDiff * 12 + monthDiff;

        return totalMonthsDiff;
    }

    // Receber a letra equivalente á posição
    function getColumnLetterFromIndex(index) {
        const columnLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let columnName = '';

        while (index > 0) {
            const remainder = (index - 1) % 26;
            columnName = columnLetters[remainder] + columnName;
            index = Math.floor((index - 1) / 26);
        }

        return columnName;
    }

    // Tratar das cores
    function applyFillToCell(worksheet, cells) {
        const color = cells[0];
        cells.border = borderThin;
        cells.slice(1).forEach(cell => {
            worksheet.getCell(cell).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: color },
            };
        });

        for (let col = 1; col <= 39; col++) {
            const cell = worksheet.getCell(13, col);
            cell.border = borderThin
            cell.font = bold
        }
    }

    // Estilizar a altura das linhas
    function setMultipleRowHeight(worksheet, startRow, endRow, height) {
        for (let row = startRow; row <= endRow; row++) {
            worksheet.getRow(row).height = height;
        }
    }

    // Inicio Excel
    const workbook = new ExcelJS.Workbook();

    //INICIO DA PÁGINA CRONOGRAMA
    const worksheet = workbook.addWorksheet('Pauta');

    // Função Cronograma
    async function Pauta() {
        // Imagens
        const imageUrlEntFormador = userData.percurso[0].entFormadora[0].logotipo;
        const imageBufferEFor = await axios.get(imageUrlEntFormador, { responseType: 'arraybuffer' });


        // Create a function to load and add the image
        async function loadImageAndAddToWorksheet() {
            // Create a new Image object
            const img = new Image();

            // Create a promise to resolve when the image has loaded
            return new Promise((resolve, reject) => {
                img.onload = function () {
                    const originalWidth = img.width;
                    const originalHeight = img.height;

                    // Calculate the scaling factor based on the desired width (e.g., 150)

                    const scalingFactor = originalWidth / originalHeight;

                    // Calculate the scaled height
                    const scaledHeight = 50;
                    const desiredWidth = scalingFactor * 50;

                    const imageIdEFor = workbook.addImage({
                        buffer: imageBufferEFor.data,
                        extension: 'png',
                    });

                    worksheet.addImage(imageIdEFor, {
                        tl: { col: 0.5, row: 0.4 },
                        ext: { width: desiredWidth, height: scaledHeight },
                    });

                    // Resolve the promise
                    resolve();
                };

                // Set the source of the image to your image file
                img.src = imageUrlEntFormador;
            });
        }

        // Call the function to load and add the image
        await loadImageAndAddToWorksheet();

        if (userData.percurso[0].entFinanciadora.length > 0 && userData.percurso[0].entFinanciadora[0].logotipo1) {
            const imageUrlEntFinanciadora = userData.percurso[0].entFinanciadora[0].logotipo2;
            const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });

            async function loadImage2AndAddToWorksheet() {
                // Create a new Image object
                const img = new Image();

                // Create a promise to resolve when the image has loaded
                return new Promise((resolve, reject) => {
                    img.onload = function () {
                        const originalWidth = img.width;
                        const originalHeight = img.height;


                        const scalingFactor = originalWidth / originalHeight;

                        // Calculate the scaled height
                        const scaledHeight = 50;
                        const desiredWidth = scalingFactor * 50;


                        const imageId2 = workbook.addImage({
                            buffer: imageBuffer.data,
                            extension: 'png',
                        });
                        worksheet.addImage(imageId2, {
                            tl: { col: 37, row: 56 },
                            ext: { width: desiredWidth, height: scaledHeight },
                        });

                        // Resolve the promise
                        resolve();
                    };

                    // Set the source of the image to your image file
                    img.src = imageUrlEntFinanciadora;
                });
            }
            await loadImage2AndAddToWorksheet();
            if (userData.percurso[0].entFinanciadora.length > 0 && userData.percurso[0].entFinanciadora[0].logotipo2) {
                const imageUrlEntFinanciadora = userData.percurso[0].entFinanciadora[0].logotipo1;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });

                async function loadImage3AndAddToWorksheet() {
                    // Create a new Image object
                    const img = new Image();

                    // Create a promise to resolve when the image has loaded
                    return new Promise((resolve, reject) => {
                        img.onload = function () {
                            const originalWidth = img.width;
                            const originalHeight = img.height;


                            const scalingFactor = originalWidth / originalHeight;

                            // Calculate the scaled height
                            const scaledHeight = 50;
                            const desiredWidth = scalingFactor * 50;


                            const imageId2 = workbook.addImage({
                                buffer: imageBuffer.data,
                                extension: 'png',
                            });
                            worksheet.addImage(imageId2, {
                                tl: { col: 37, row: 0.4 },
                                ext: { width: desiredWidth, height: scaledHeight },
                            });

                            // Resolve the promise
                            resolve();
                        };

                        // Set the source of the image to your image file
                        img.src = imageUrlEntFinanciadora;
                    });
                }
                await loadImage3AndAddToWorksheet();
            }
        }
        // Celulas de texto - Cabeçalho
        const A1 = worksheet.getCell('A1');
        const A2 = worksheet.getCell('A2');
        const A3 = worksheet.getCell('A3');
        const A4 = worksheet.getCell('A4');
        const A5 = worksheet.getCell('A5');
        const A6 = worksheet.getCell('A6');
        const A7 = worksheet.getCell('A7');
        const A8 = worksheet.getCell('A8');
        const A10 = worksheet.getCell('A10');
        const A11 = worksheet.getCell('A11');


        const D2 = worksheet.getCell('D2');
        const D3 = worksheet.getCell('D3');
        const D4 = worksheet.getCell('D4');
        const D5 = worksheet.getCell('D5');
        const D6 = worksheet.getCell('D6');
        const D11 = worksheet.getCell('D11');

        const E7 = worksheet.getCell('E7');
        const E8 = worksheet.getCell('E8');
        const E10 = worksheet.getCell('E10');

        const L7 = worksheet.getCell('L7');
        const L8 = worksheet.getCell('L8');
        const L10 = worksheet.getCell('L10');

        const V3 = worksheet.getCell('V3');
        const V4 = worksheet.getCell('V4');

        const S7 = worksheet.getCell('S7');
        const S8 = worksheet.getCell('S8');
        const S10 = worksheet.getCell('S10');

        const Z7 = worksheet.getCell('Z7');
        const Z8 = worksheet.getCell('Z8');
        const Z10 = worksheet.getCell('Z10');


        const AD3 = worksheet.getCell('AD3');
        const AD4 = worksheet.getCell('AD4');

        const AG7 = worksheet.getCell('AG7');
        const AG8 = worksheet.getCell('AG8');
        const AG10 = worksheet.getCell('AG10');

        // Valor das Celulas
        A1.value = 'Pauta';
        A2.value = 'Entidade Formadora:'; D2.value = userData.percurso[0].entFormadora[0].name
        A3.value = 'Tipologia de Operação:'; D3.value = userData.percurso[0].operacao[0].nomeTipologia; V3.value = "Modalidade de Formação:"; AD3.value = userData.percurso[0].modalidade
        A4.value = 'Operação:'; D4.value = userData.percurso[0].operacao[0].codigoOperacao; V4.value = "Código e Área de Formação:"; AD4.value = userData.percurso[0].areaCodigoFormacao
        A5.value = 'Curso/Percurso:'; D5.value = userData.percurso[0].name;
        A6.value = 'Curso/Unidade:'; D6.value = userData.codeUfcd + " - " + userData.name;
        A7.value = 'Código Administrativo (em SIGO):'; E7.value = 'Código Interno:'; L7.value = 'Nível:'; S7.value = 'Carga Horária:'; Z7.value = 'N.º Curso (em SIIFSE):'; AG7.value = 'N.º Ação (em SIIFSE):';
        A8.value = userData.percurso[0].codeSIGO; E8.value = userData.percurso[0].codeInterno; L8.value = userData.percurso[0].nivel; S8.value = userData.percurso[0].duration; Z8.value = ""; AG8.value = "";
        A10.value = "Data de Início:"; E10.value = userData.dateBegin; L10.value = "Data de Fim:"; S10.value = userData.dateEnd; Z10.value = 'Horário:'; AG10.value = userData.percurso[0].horario;

        A11.value = 'Local de Formação:'; D11.value = userData.percurso[0].locality

        // Centrar
        A1.alignment = alignment
        A2.alignment = alignment
        A3.alignment = alignment
        A4.alignment = alignment
        A5.alignment = alignment
        A6.alignment = alignment
        A7.alignment = alignment
        A8.alignment = alignment
        A10.alignment = alignment
        A11.alignment = alignment

        E7.alignment = alignment
        E8.alignment = alignment
        E10.alignment = alignment

        L7.alignment = alignment
        L8.alignment = alignment
        L10.alignment = alignment

        V3.alignment = alignment
        V4.alignment = alignment

        S7.alignment = alignment
        S8.alignment = alignment
        S10.alignment = alignment

        Z7.alignment = alignment
        Z8.alignment = alignment
        Z10.alignment = alignment

        AG7.alignment = alignment
        AG8.alignment = alignment
        AG10.alignment = alignment

        // Negrito
        A1.font = title
        A2.font = bold
        A3.font = bold
        A4.font = bold
        A5.font = bold
        A6.font = bold
        A7.font = bold
        A10.font = bold
        A11.font = bold

        E7.font = bold

        L7.font = bold
        L10.font = bold

        V3.font = bold
        V4.font = bold

        S7.font = bold

        Z7.font = bold
        Z10.font = bold

        AG7.font = bold

        // Collumn Width
        worksheet.getColumn('A').width = 4
        worksheet.getColumn('B').width = 20
        for (let columnNumber = 3; columnNumber <= 39; columnNumber++) {
            worksheet.getColumn(columnNumber).width = 6.5;
        }
        // Row height
        worksheet.getRow('1').height = 50

        // Estutura - Cabeçalho 
        worksheet.mergeCells('A1:AP1');
        worksheet.mergeCells('A2:C2'); worksheet.mergeCells('D2:AP2');
        worksheet.mergeCells('A3:C3'); worksheet.mergeCells('D3:U3'); worksheet.mergeCells('V3:AC3'); worksheet.mergeCells('AD3:AP3');
        worksheet.mergeCells('A4:C4'); worksheet.mergeCells('D4:U4'); worksheet.mergeCells('V4:AC4'); worksheet.mergeCells('AD4:AP4');
        worksheet.mergeCells('A5:C5'); worksheet.mergeCells('D5:AP5');
        worksheet.mergeCells('A6:C6'); worksheet.mergeCells('D6:AP6');
        worksheet.mergeCells('A7:D7'); worksheet.mergeCells('E7:K7'); worksheet.mergeCells('L7:R7'); worksheet.mergeCells('S7:Y7'); worksheet.mergeCells('Z7:AF7'); worksheet.mergeCells('AG7:AP7');
        worksheet.mergeCells('A8:D9'); worksheet.mergeCells('E8:K9'); worksheet.mergeCells('L8:R9'); worksheet.mergeCells('S8:Y9'); worksheet.mergeCells('Z8:AF9'); worksheet.mergeCells('AG8:AP9');
        worksheet.mergeCells('A10:D10'); worksheet.mergeCells('E10:K10'); worksheet.mergeCells('L10:R10'); worksheet.mergeCells('S10:Y10'); worksheet.mergeCells('Z10:AF10'); worksheet.mergeCells('AG10:AP10');
        worksheet.mergeCells('A11:C11'); worksheet.mergeCells('D11:AP11');

        // Estilização - Cabeçalho
        const startCell = worksheet.getCell('A1');
        const endCell = worksheet.getCell('AD6');

        worksheet.eachRow({ from: startCell.row, to: endCell.row }, (row) => {
            row.eachCell((cell) => {
                cell.border = borderThin
            });
        });
        const cells = ['efefbd', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A10', 'A11', 'E7', 'L7', 'S7', 'Z7', 'AG7', 'V3', 'V4', 'L10', 'Z10', 'A13', 'B13', 'C13',
            'D13', 'E13', 'F13', 'G13', 'H13', 'I13', 'J13', 'K13', 'L13', 'M13', 'N13', 'O13', 'P13', 'Q13', 'R13', 'S13', 'T13', 'U13', 'V13', 'W13', 'X13', 'Y13',
            'Z13', 'AA13', 'AB13', 'AC13', 'AD13', 'AE13', 'AF13', 'AG13', 'AH13', 'AI13', 'AJ13', 'AK13', 'AL13', 'AM13'];
        //Palete de Cores
        applyFillToCell(worksheet, cells);

        // Cabeçalho da tabela
        const Nome = worksheet.getCell('A13');
        const Criterios = worksheet.getCell('K13');
        const Media = worksheet.getCell('T14');
        const AtivDone = worksheet.getCell('U14');
        const Penalizacao = worksheet.getCell('X14');
        const Assiduidade = worksheet.getCell('Z14');
        const CargaSincrona = worksheet.getCell('AC14');
        const HorasAssincronas = worksheet.getCell('AF14');
        const HorasTotais = worksheet.getCell('AI14');
        const Dedicacao = worksheet.getCell('AL14');
        const NotaFinal = worksheet.getCell('AO13');

        let finalTurma = 45
        if (formandos.length > 30) {
            finalTurma = formandos.length + 45
        }

        const Observacoes = worksheet.getCell(`A${finalTurma}`);
        worksheet.mergeCells(`A${finalTurma}:AP${finalTurma + 3}`);

        const Escalas = worksheet.getCell(`A${finalTurma + 4}`);
        const Escalas1 = worksheet.getCell(`A${finalTurma + 5}`);
        const Escalas2 = worksheet.getCell(`A${finalTurma + 6}`);
        const Escalas3 = worksheet.getCell(`A${finalTurma + 7}`);
        const Escalas4 = worksheet.getCell(`A${finalTurma + 8}`);
        const Assinatura1 = worksheet.getCell(`A${finalTurma + 9}`);
        const Assinatura2 = worksheet.getCell(`E${finalTurma + 9}`);

        worksheet.mergeCells(`A${finalTurma + 4}:AP${finalTurma + 4}`);
        worksheet.mergeCells(`A${finalTurma + 5}:AP${finalTurma + 5}`);
        worksheet.mergeCells(`A${finalTurma + 6}:AP${finalTurma + 6}`);
        worksheet.mergeCells(`A${finalTurma + 7}:AP${finalTurma + 7}`);
        worksheet.mergeCells(`A${finalTurma + 8}:AP${finalTurma + 8}`);
        worksheet.mergeCells(`A${finalTurma + 9}:D${finalTurma + 10}`);
        worksheet.mergeCells(`E${finalTurma + 9}:O${finalTurma + 10}`);

        Escalas.border = borderThinNoBottom;
        Escalas1.border = borderThinNoTopNotBottom;
        Escalas2.border = borderThinNoTopNotBottom;
        Escalas3.border = borderThinNoTopNotBottom;
        Escalas4.border = borderThinNoTop;

        Escalas.font = bold;
        Assinatura1.font = bold;
        Assinatura2.font = bold;

        Escalas.value = "Escalas:";
        Escalas1.value = "Avaliação quantitativa de 0-20, com correspondência a escala qualitativa de Muito Insuficiente a Muito Bom:"
        Escalas2.value = "0-4 Muito Insuficiente; 5-9 Insuficiente; 10-13 Suficiente; 14-17 - Bom; 18-20 Muito Bom";
        Escalas3.value = "Avaliação quantitativa de 0-100, com correspondência a escala qualitativa de Muito Insuficiente a Muito Bom:";
        Escalas4.value = "0-19 Muito Insuficiente; 20-49 Insuficiente; 50-74 Suficiente; 75-89 Bom; 90-100 Muito Bom";

        Assinatura1.value = "O/A Formador/a:";
        Assinatura2.border = borderBottom;

        const EscalasContainer = ['FFFFFFFF', `A${finalTurma + 4}`, `A${finalTurma + 5}`, `A${finalTurma + 6}`, `A${finalTurma + 7}`, `A${finalTurma + 8}`];
        applyFillToCell(worksheet, EscalasContainer);

        for (let i = 11; i < (userData.testes.length + 11); i++) {
            const element = userData.testes[i - 11]
            worksheet.getColumn(`${getColumnLetterFromIndex(i)}`).width = 18

            const teste = worksheet.getCell(`${getColumnLetterFromIndex(i)}14`);
            teste.value = element.name ? element.name : `Teste ${i - 10}`;
            teste.alignment = alignment;
            teste.border = borderThin;
            teste.font = bold;
        }
        worksheet.mergeCells('A13:J14');
        worksheet.mergeCells('K13:AN13');
        worksheet.mergeCells(`K14:AH14`);
        worksheet.mergeCells('AI14:AN14');
        worksheet.mergeCells('AO13:AP14');


        Nome.alignment = alignment;
        Criterios.alignment = alignment;
        NotaFinal.alignment = alignment;
        Media.alignment = alignment;
        AtivDone.alignment = alignment;
        Penalizacao.alignment = alignment;
        Assiduidade.alignment = alignment;
        CargaSincrona.alignment = alignment;
        HorasAssincronas.alignment = alignment;
        HorasTotais.alignment = alignment;
        Dedicacao.alignment = alignment;
        Observacoes.alignment = alignmentVertivalTop;

        Nome.font = bold;
        Criterios.font = bold;
        NotaFinal.font = bold;
        Media.font = bold;
        AtivDone.font = bold;
        Penalizacao.font = bold;
        Assiduidade.font = bold;
        CargaSincrona.font = bold;
        HorasAssincronas.font = bold;
        HorasTotais.font = bold;
        Dedicacao.font = bold;
        Observacoes.font = bold;

        Nome.value = 'Nome dos/as Formandos/as - PROVAS';
        Criterios.value = 'Critérios de Avaliação';
        Media.value = "Testes/Trabalhos/Atividades";
        NotaFinal.value = 'Nota Final';
        HorasTotais.value = "Horas Totais";
        Observacoes.value = "Observações:";

        Nome.border = borderThin;
        Criterios.border = borderThin;
        NotaFinal.border = borderThin;
        Media.border = borderThin;
        AtivDone.border = borderThin;
        Penalizacao.border = borderThin;
        Assiduidade.border = borderThin;
        CargaSincrona.border = borderThin;
        HorasAssincronas.border = borderThin;
        HorasTotais.border = borderThin;
        Dedicacao.border = borderThin;
        Observacoes.border = borderThin;

        let media = 0
        for (let i = 15; i < formandos.length + 15; i++) {
            const index = formandos[i - 15].pauta.findIndex(formando => formando && formando.idUfcd === userData._id);
            const cellNumber = worksheet.getCell(`A${i}`);
            cellNumber.value = i - 14;
            cellNumber.border = borderThin;
            const cellName = worksheet.getCell(`B${i}`);
            worksheet.mergeCells(`B${i}:J${i}`);
            cellName.value = formandos[i - 15].fullName;
            cellName.border = borderThin;
            if (formandos[i - 15].valid === "Desistiu" || formandos[i - 15].nInscricao.some(item => item.idUfcd === userData._id) || formandos[i - 15].equivalencia.some(item => item.idUfcd === userData._id)) {
                for (let index = 1; index < 43; index++) {
                    const aluno =  worksheet.getCell(`${getColumnLetterFromIndex(index)}${i}`);
                    aluno.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: "D3D3D3" },
                    };   
                }
                
            }
            const cellNota = worksheet.getCell(`AO${i}`);
            worksheet.mergeCells(`AO${i}:AP${i}`);
            cellNota.border = borderThin;

            const AtivDone = worksheet.getCell(`U${i}`);
            const HorasTotais = worksheet.getCell(`AI${i}`);
            
            worksheet.mergeCells(`K${i}:AH${i}`);
            worksheet.mergeCells(`AI${i}:AN${i}`);

            AtivDone.alignment = alignment;
            Penalizacao.alignment = alignment;
            Assiduidade.alignment = alignment;
            CargaSincrona.alignment = alignment;
            HorasAssincronas.alignment = alignment;
            HorasTotais.alignment = alignment;

            Nome.border = borderThin;
            Criterios.border = borderThin;
            NotaFinal.border = borderThin;
            Media.border = borderThin;
            AtivDone.border = borderThin;
            Penalizacao.border = borderThin;
            Assiduidade.border = borderThin;
            CargaSincrona.border = borderThin;
            HorasAssincronas.border = borderThin;
            HorasTotais.border = borderThin;
            HorasTotais.value =formandos[i - 15].pauta[index] &&(formandos[i - 15].pauta[index].sincrona || formandos[i - 15].pauta[index].sincrona === 0) ?formandos[i - 15].pauta[index].sincrona  + "h" : "";

            cellNota.alignment = alignment
            cellNota.value = formandos[i - 15].pauta[index] && formandos[i - 15].pauta[index].notaFinal ? formandos[i - 15].pauta[index].notaFinal: 0
            if(formandos[i - 15].valid === "Desistiu"){
                cellNota.value = "Desistiu"
            }
            else if(formandos[i - 15].nInscricao.some(item => item.idUfcd === userData._id) && formandos[i - 15].valid !== "Desistiu")cellNota.value = "Não Inscrito"
            else if(formandos[i - 15].equivalencia.some(item => item.idUfcd === userData._id )&& formandos[i - 15].valid !== "Desistiu")cellNota.value = "Disciplina Realizada"
            else{
                 if (cellNota.value >= 9.5 && ((100* parseInt(formandos[i - 15].pauta[index].sincrona))/parseInt(userData.cargaHoraria))>=90 ){
                cellNota.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: "ffa4ffa4" },
                };
            }
            else {
                cellNota.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: "ffff0000" },
                };
            }
            }
           
        }


        for (let i = (15 + formandos.length); i <= 44; i++) {

           const  cellNumber = worksheet.getCell(`A${i}`);
            cellNumber.value = i - 14;
            cellNumber.border = borderThin;

            const cellName = worksheet.getCell(`B${i}`);
            worksheet.mergeCells(`B${i}:J${i}`);
            cellName.value = "";
            cellName.border = borderThin;
            

            const cellNota = worksheet.getCell(`AO${i}`);
            worksheet.mergeCells(`AO${i}:AP${i}`);
            cellNota.value = "";
            cellNota.border = borderThin;
         
            const AtivDone = worksheet.getCell(`U${i}`);
            const Penalizacao = worksheet.getCell(`X${i}`);
            const Assiduidade = worksheet.getCell(`Z${i}`);
            const CargaSincrona = worksheet.getCell(`AC${i}`);
            const HorasAssincronas = worksheet.getCell(`AF${i}`);
            const HorasTotais = worksheet.getCell(`AI${i}`);
            const dedicacao = worksheet.getCell(`AL${i}`);

            worksheet.mergeCells(`K${i}:AH${i}`);
            worksheet.mergeCells(`AI${i}:AN${i}`);

            AtivDone.alignment = alignment;
            Penalizacao.alignment = alignment;
            Assiduidade.alignment = alignment;
            CargaSincrona.alignment = alignment;
            HorasAssincronas.alignment = alignment;
            HorasTotais.alignment = alignment;
            dedicacao.alignment = alignment

            Nome.border = borderThin;
            Criterios.border = borderThin;
            NotaFinal.border = borderThin;
            Media.border = borderThin;
            AtivDone.border = borderThin;
            Penalizacao.border = borderThin;
            Assiduidade.border = borderThin;
            CargaSincrona.border = borderThin;
            HorasAssincronas.border = borderThin;
            HorasTotais.border = borderThin;
            dedicacao.border = borderThin;
        }

    }

    await Pauta()
    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    // Convert the buffer to a Blob
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const formData = new FormData();
    formData.append('file', blob, `pautaDisciplina${userData.name}.xlsx`); // Explicitly set the filename with .xlsx extension
    // Save the Blob to the user's computer
    if (!autorization) FileSaver.saveAs(blob, `Pauta.xlsx`);
    fetch(`${process.env.REACT_APP_API_URL}/pauta/Disciplina/${userData._id}`, {
        method: "POST",
        credentials: "same-origin",
        body: formData
    })
        .then((res) => res.json())
        .then(
            (result) => {
                // Handle the response from the server
            },
            (error) => {
                // Handle any errors
            }
        );

};


export default excelGeneratorPautaPresencial;