import React, { useEffect, useState, } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import toastSuccess from "../../../../components/Toast/toastSuccess";
import { Link } from "react-router-dom"

function AddFiles() {

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;

    useEffect(() => {
        getFormando();
    }, [loading]);

    //Obtem formando 
    function getFormando() {
        fetch(`${process.env.REACT_APP_API_URL}/formandos/${id}`)
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    setLoading(false)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    //transforma data em json
    function formDataToJson(formData) {
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        return JSON.stringify(object);
    }

    
    //Submete ficheiros
    async function submeterFicheiros(event) {
        setLoading(true)
        event.preventDefault();

        let form = document.getElementById("form")
        const cm = form.cm.files[0]
        const cc = form.cc.files[0]
        const ch = form.ch.files[0]
        const ibancomp = form.ibancomp.files[0]
        const csp = form.csp.files[0]
        let formDataInfo = new FormData()
        getFormando()

        const options = {
            method: "POST",
            headers: { "Accept": "application/json" },
            credentials: "same-origin"
        }
/* 
        if ((data.employmentSituation.includes("Empregados por conta de outrem") || data.employmentSituation.includes('Empregados por conta própria')) && document.getElementById("rv")) {
            const rv = form.rv.files[0]
            if (rv !== undefined) {
                let rvForm = new FormData()
                rvForm.append('rv', rv)
                options.body = rvForm
                const rvResponse = await fetch(`${process.env.REACT_APP_API_URL}/rv`, options)
                const rvResult = await rvResponse.json()
                formDataInfo.append('rv', rvResult)
            }

        } else if ((data.employmentSituation === "Desempregados à procura de novo emprego - DLD" || data.employmentSituation === "Desempregados à procura de novo emprego - Não DLD" || data.employmentSituation === "Desempregados à procura do 1º emprego" || data.employmentSituation === "Inativos - A frequentarem ações de educação ou formação" || data.employmentSituation === "Inativos - Outros") && document.getElementById("dce")) {
            const dce = form.dce.files[0]
            if (dce !== undefined) {
                let dceForm = new FormData()
                dceForm.append('dce', dce)
                options.body = dceForm
                const dceResponse = await fetch(`${process.env.REACT_APP_API_URL}/mdce`, options)
                const dceResult = await dceResponse.json()
                formDataInfo.append('dce', dceResult)
            }
        } */


        if (cc !== undefined && document.getElementById("cc")) {
            let ccForm = new FormData()
            ccForm.append('cc', cc)
            options.body = ccForm
            const ccResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcc`, options)
            const ccResult = await ccResponse.json()
            formDataInfo.append('cc', ccResult)
        }
        if (cm !== undefined && document.getElementById("cm")) {
            let cmForm = new FormData()
            cmForm.append('cm', cm)
            options.body = cmForm
            const cmResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcm`, options)
            const cmResult = await cmResponse.json()
            formDataInfo.append('cm', cmResult)
        }

        if (ch !== undefined && document.getElementById("ch")) {
            let chForm = new FormData()
            chForm.append('ch', ch)
            options.body = chForm
            const chResponse = await fetch(`${process.env.REACT_APP_API_URL}/mch`, options)
            const chResult = await chResponse.json()
            formDataInfo.append('ch', chResult)
        }

        if (ibancomp !== undefined && document.getElementById("ibancomp")) {
            let ibancompForm = new FormData()
            ibancompForm.append('ibancomp', ibancomp)
            options.body = ibancompForm
            const ibancompResponse = await fetch(`${process.env.REACT_APP_API_URL}/mibancomp`, options)
            const ibancompResult = await ibancompResponse.json()
            formDataInfo.append('ibancomp', ibancompResult)
        }
        if (csp !== undefined && document.getElementById("csp")) {
            let cspForm = new FormData()
            cspForm.append('csp', csp)
            options.body = cspForm
            const cspResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcsp`, options)
            const cspResult = await cspResponse.json()
            formDataInfo.append('csp', cspResult)
        }
        fetch(`${process.env.REACT_APP_API_URL}/formandos/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
                body: formDataToJson(formDataInfo)
            }).then((result) => {
                result.json().then((resp) => {
                    setLoading(false)
                    toastSuccess("Ficheiros submetidos com sucesso.")
                    navigate(`/`)
                })
            })
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <Header />
                {data && data.cc_path && data.csp_path  && data.cm_path  && data.ch_path && data.ibancomp_path ?
                    <div className="flex flex-col justify-center h-full my-auto">
                        <h1 className="mt-4 mb-3 title">Os ficheiros já foram submetidos!</h1>
                        <Link to="/" className="mt-2 mb-10 button-geral">Retornar à página inicial</Link>
                    </div> :
                    <div className="flex-grow">
                        <form id="form" onSubmit={submeterFicheiros}>

                            <h1 className="mt-4 mb-3 title">Insira os seguintes ficheiros</h1>
                            <div className="text-lg text-text-main-color">Para efetuar a sua inscrição, é necessário providenciar os seguintes documentos:</div>
                            <div className="flex flex-col items-center justify-center mt-4">
                                {!data.cc_path && <div className="flex flex-col">
                                    <label className="text-left label-input" htmlFor="cc">Cartão de Cidadão: </label>
                                    <input className="mt-1 w-80 sm:w-96 input-file" type="file" id="cc" name="cc" accept=".pdf" required></input>
                                </div>}
                                <br />
                                {!data.csp_path && <div className="flex flex-col">
                                <label htmlFor="certificate_doc" className="text-left label-input">Comprovativo de Situação Profissional: </label>
                                <input type="file" id="csp" name="csp" accept=".pdf" className="mt-1 w-80 sm:w-96 input-file" required/>
                                </div>}
                                <br />
                                {!data.cm_path && <div className="flex flex-col">
                                    <label className="text-left label-input" htmlFor="cm">Comprovativo de Morada: </label>
                                    <input className="mt-1 w-80 sm:w-96 input-file" type="file" id="cm" name="cm" accept=".pdf" required></input>
                                </div>}
                                <br />
                                {!data.ch_path && <div className="flex flex-col">
                                    <label className="text-left label-input" htmlFor="ch">Certificado de Habilitações: </label>
                                    <input className="mt-1 w-80 sm:w-96 input-file" type="file" id="ch" name="ch" accept=".pdf" required></input>
                                </div>}
                                <br />
                                {!data.ibancomp_path && <div className="flex flex-col">
                                    <label className="text-left label-input" htmlFor="ibancomp">Comprovativo de IBAN: </label>
                                    <input className="mt-1 w-80 sm:w-96 input-file" type="file" id="ibancomp" name="ibancomp" accept=".pdf" required></input>
                                </div>}
                                
                                <p className="mt-5 text-text-error">Formato suportado .pdf</p>
                                <input className="mt-2 mb-10 button-geral" type="submit" />
                            </div>
                        </form>
                    </div>
                }
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default AddFiles