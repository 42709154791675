import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from 'react-router-dom';
import Footer from "../Footer";
import Modal from 'react-modal'
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import { AiOutlineCloseCircle, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ExcelFormadores from "../Excel/excelBolsaFormadores/excelBolsaFormadores";
import toastError from "../Toast/toastError";
import Cookies from "js-cookie";
import AlterarFormato from "../FormChecks/formatDates/foramatDates";
import toastSuccess from "../Toast/toastSuccess";
import Header from "../Header";
import NavbarCoordenador from "../Navbars/NavbarCoordenador/navbarCoordenador";

function BolsaFormadoresList(token) {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [source, setSource] = useState("down");
    const [loading, setLoading] = useState(true);
    const [cont, setCont] = useState(0);
    const [error, setError] = useState(null);
    const [totalPages, setTotalPages] = useState();
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-listTrainers-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-listTrainers-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState([]); // Recebe os dados para apresentar no modal
    const [checkedTrainers, setCheckedTrainers] = useState([]);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Admin-listTrainers-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    const params = useParams();

    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    const dateNow = new Date(year, month, day)
    function addOneYear(date) {
        date.setFullYear(date.getFullYear() + 1);
        return date;
    }
    useEffect(() => {
        Cookies.set("Admin-listTrainers-pag", paginaAtual);
        Cookies.set("Admin-listTrainers-1", searchTerm);
        Cookies.set(`Admin-listTrainers-itm1`, itemsPag)
        handleSearch()
        // eslint-disable-next-line
    }, [loading, cont, paginaAtual, nPaginas, searchTerm, itemsPag]);

    const link = token === "admin1" ? `/menuadmin/${params.id_candidatura}` : token === "coord" ? `/menuCoordenador/${params.id_candidatura}` : "/menuSuperAdmin"
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

    };

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedTrainers(data.map((item) => item));
        } else {
            setCheckedTrainers([]);
        }
    };

    const associarFormadores = (e, item) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedTrainers((prevChecked) => [...prevChecked, item]);
        } else {
            setCheckedTrainers((prevChecked) => prevChecked.filter((element) => element._id !== item._id));
        }
    };


    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
        }
    }

    //Altera o estado do formador para ativo
    function alterarEstadoAtivo(id) {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${id}/estados/ativo`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                }
            }).then((result) => {
                result.json().then((resp) => {
                })
            })
    }

    //Altera o estado do formador para inativo
    function alterarEstadoInativo(id) {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${id}/estados/inativo`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                }
            }).then((result) => {
                result.json().then((resp) => {

                })
            })
    }

    //Função automatizada do estado
    function alterarEstado(date, id) {
        AlterarFormato(date)
        let dateLastUfcd = new Date(date)
        let dateLastUfcdMax = addOneYear(dateLastUfcd)
        if (dateLastUfcdMax > dateNow) {
            alterarEstadoAtivo(id)
        }
        else {
            alterarEstadoInativo(id)
        }
    }

    const handleSearch = async () => {
        await getFormadores(
            paginaAtual,
            itemsPag,
            searchTerm,
        );
    };
    //Recebe todos os formadores
    async function getFormadores(currentPage, itemsPerPage, searchTerm) {
        try {
            // Constructing the query string
            const query = new URLSearchParams({
                paginaAtual: currentPage,
                itemsPag: itemsPerPage,
                searchTerm: searchTerm,
            });

            // Fetching data from the API with pagination
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/admins/validadosWmedia?${query}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        authorization: localStorage.getItem(token),
                    },
                    method: "GET",
                }
            );

            // Error handling for failed requests
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Parsing the API response
            const result = await response.json();
            const totalItems = result.totalItems;
            const totalPages = Math.ceil(totalItems / result.itemsPerPage);

            // Update pagination logic
            if (paginaAtual > totalPages) {
                setPaginaAtual(totalPages > 0 ? totalPages : 1);
            } else {
                setNumPaginas(totalPages);
            }

            // Process each trainer's data
            const processedData = await Promise.all(
                result.data.map(async (trainer) => {
                    try {
                        // Fetching sorted ufcd data for each trainer
                        const ufcdResponse = await fetch(
                            `${process.env.REACT_APP_API_URL}/ufcds/trainer/sorted/ufcd/${trainer._id}`,
                            {
                                headers: {
                                    authorization: localStorage.getItem(token),
                                },
                            }
                        );
                        const ufcdResult = await ufcdResponse.json();

                        if (ufcdResult.length > 0) {
                            alterarEstado(ufcdResult[0].dateEnd, trainer._id);
                        } else {
                            alterarEstadoInativo(trainer._id);
                        }

                        // Calculate media (average) based on evaluations
                        let totalEvaluation = 0;
                        let count = 0;
                        const validUfcds = ufcdResult.filter((ufcd) => ufcd.evaluation !== null);

                        // Take at most 5 ufcds with evaluations
                        const filteredUfcds = validUfcds.slice(0, 5);

                        filteredUfcds.forEach((ufcd) => {
                            totalEvaluation += parseInt(ufcd.evaluation);
                            count++;
                        });

                        // Calculate average or set to -1 if no evaluations
                        trainer.media = count > 0 ? Math.round(totalEvaluation / count) : -1;

                    } catch (ufcdError) {
                        console.error("Error fetching UFCD data: ", ufcdError);
                    }

                    return trainer;
                })
            );
            // Sort data by trainer's full name
            const sortedData = processedData.slice().sort((a, b) => a.fullName.localeCompare(b.fullName));
            setData(sortedData);
            setLoading(false);

        } catch (error) {
            setError(error);
            setLoading(false);
            toastError("Erro ao obter os dados, por favor tente mais tarde.");
        }
    }


    //Envia mail ao formador para inscreve
    function sendEmail() {
        const email = document.getElementById("emailTo").value;
        if (email) {
            fetch(`${process.env.REACT_APP_API_URL}/formadores/mailInscricao`, {
                method: "POST",
                body: JSON.stringify({
                    email: email
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                }
            }).then((result) => {
                toastSuccess("Email enviado com sucesso");
                document.getElementById("emailTo").value = "";
            }).catch((error) => {
                console.error('Error:', error);
                toastError("Erro ao enviar o email");
            });
        } else {
            toastError("Email inválido");
        }
    }
    //F
    //Filtra por ordem de média
    function getFormadoresOrder() {
        let numAscending = [...data].sort((a, b) => a.media - b.media);
        if (source === "down") {
            numAscending = [...data].sort((a, b) => a.media - b.media);
            setSource("up")
        } else {
            numAscending = [...data].sort((a, b) => b.media - a.media);
            setSource("down")
        }
        setData(numAscending);
    }


    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";


    //Abre o modal e atribui ao state modal data os dados para apresentar no modal
    function openModal(item) {
        setModalData(item);
        setModalIsOpen(true);
    }
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
        }
    }

    return (
        <>
            <main className={token !== "coord" ? "min-h-screen" : "min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10"}>
                {token === "admin1" ? <div className="w-full">
                    <NavbarAdmin1 currentPage={"ListaFormador"} />
                </div> : token === "admin2" ? <div className="w-full">
                    <NavbarAdmin2 currentPage={"ListaFormador"} />
                </div> : <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={"ListaFormador"} />
                </div>
                }

              
                <div className={token !== "coord" ? "flex-grow mt-8" : "h-full col-span-5 xl:col-span-8"}>
                    {token === "coord" && <Header sideMenu={"true"} />}
                    {token === "admin1" ? <Link to={link} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link> : token === "admin2" && <Link to={link} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>}
                    <h1 className="mt-3 sm:mt-0 title">Bolsa de Formadores</h1>
                    <div className="search-div">
                            <label className="search-input-label">Pesquisar: </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="text" className="search-input" placeholder="ex: Nome, Email..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                    {data && data.length > 0 ? <> <div className="flex flex-col">
                      
                    </div>
                        <div className="flex justify-center select-all-checkbox">
                            <input
                                type="checkbox"
                                onChange={handleSelectAllChange}
                                checked={checkedTrainers.length === dataToDisplay.length}
                            />
                            <label className="pl-1 text-main-color">Selecionar Tudo</label>
                        </div>
                        <div className="flex items-center justify-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="ml-1 selector w-14"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="min-w-[80px] border border-white">Selecionar</th>
                                        <th className="min-w-[80px] border border-white">Nome Completo</th>
                                        <th className="min-w-[80px] border border-white">Email</th>
                                        <th className="min-w-[80px] border border-white">Estado</th>
                                        <th className="p-2 min-w-[80px] border border-white">Habilitações</th>
                                        <th className="p-2 min-w-[80px] border border-white">Área de Educação Formação</th>
                                        <th className="p-2 min-w-[80px] border border-white">Área de Formação Académica</th>
                                        <th className="p-2 min-w-[120px] border border-white">
                                            <div className="flex items-center justify-around ">
                                                Classificação {source === "down" ? <img alt="arrow" className="arrow" id="arrow" onClick={getFormadoresOrder} src={process.env.PUBLIC_URL + "/img/down.png"} /> : <img className="arrow" alt="arrow" id="arrow" onClick={getFormadoresOrder} src={process.env.PUBLIC_URL + "/img/up.png"} />}
                                            </div>
                                        </th>
                                        <th className="min-w-[80px] border border-white formadores">Perfil</th>
                                        <th className="min-w-[80px] border border-white formadores">Histórico</th>
                                        <th className="min-w-[80px] border border-white formadores">Avaliação</th>
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {data.map((item, index) => (
                                            <tr key={index}>
                                                <td className="min-w-[80px] border border-white">
                                                    <input type="checkbox"
                                                        onChange={(e) => associarFormadores(e, item)}
                                                        checked={checkedTrainers.some(trainer => trainer._id === item._id)} />
                                                </td>
                                                <td className="min-w-[80px] border border-white">{item.fullName}</td>
                                                <td className="min-w-[80px] border border-white">{item.email}</td>
                                                <td className="min-w-[80px] border border-white">{item.status === "true" ? "Ativo" : "Inativo"}</td>
                                                <td className="border border-white">{item.academicDegree}</td>
                                                <td className="border border-white">{item.courseDesignation}</td>
                                                {
                                                    item.area.length > 0 ?
                                                        <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => openModal(item.area)}>
                                                            <div className="flex items-center justify-center group-hover:brightness-150">
                                                                <FaEye size={24} />
                                                            </div>
                                                        </td>
                                                        :
                                                        <td className="border border-white cursor-not-allowed">
                                                            <div className="flex items-center justify-center opacity-25 ">
                                                                <FaEyeSlash size={24} />
                                                            </div>
                                                        </td>
                                                }
                                                <td className="min-w-[80px] border border-white">
                                                    {item.media !== -1 ? (
                                                        <>
                                                            {item.media ? <div>{item.media}%</div> : "Sem disciplinas"}
                                                        </>
                                                    ) : "Em atividade"}
                                                </td>
                                                <td className="min-w-[80px] border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate(link + "/list/" + item._id)}>
                                                    <div className="flex tooltip items-center justify-center group-hover:brightness-150" data-tip={!item.profileValid ? "Perfil Incompleto" : "Perfil Completo"}>
                                                        <FaEye className={item.profileValid ? "" : "text-red-800"} size={24} />
                                                    </div>
                                                </td>
                                                <td className="min-w-[80px] border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate(link + "/list/Full-ufcds/" + item._id)}>
                                                    <div className="flex items-center justify-center group-hover:brightness-150">
                                                        <FaEye size={24} />
                                                    </div>
                                                </td>
                                                <td className="min-w-[80px] border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate(link + "/list/ufcds/" + item._id)}>
                                                    <div className="flex items-center justify-center group-hover:brightness-150">
                                                        <FaEye size={24} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>
                        <div className="mt-4">
                            <button onClick={() => { checkedTrainers.length > 0 ? ExcelFormadores(checkedTrainers) : toastError("Sem formadores selecionados.") }} className="button-geral tooltip" data-tip={"Exportar Formadores Selecionados em Excel"}>Exportar em Excel</button>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div></> : <p className="third-title">Não existem dados</p>}

                    <div className="mt-10 mb-28">
                        <div className="subtitle">Envio de Email de Inscrição</div>
                        <div className="third-title"> Digite o mail:</div>
                        <input className="input-field-long" type="text" name="emailTo" id="emailTo" />
                        <button className="button-geral" onClick={sendEmail}>Submeter</button>
                    </div>
                </div>
                {/* Modal das Áreas de formação de um formador */}
                <Modal isOpen={modalIsOpen} className="fixed inset-0 flex items-center justify-center">
                    <div className="absolute w-full h-full" onClick={() => setModalIsOpen(false)}></div>
                    <div className="relative flex flex-col items-center px-10 mx-4 shadow-2xl bg-secundary-color py-7 rounded-2xl">
                        <h1 className="title">Áreas de Ação-Formação</h1>
                        {modalData.map((item, itemIndex) => (
                            <div className="flex flex-col items-start w-full" key={itemIndex}>
                                <p className="text-left text-text-main-color">{item}</p>
                            </div>
                        ))}
                        <button className="absolute top-1.5 right-1.5" onClick={() => setModalIsOpen(false)}>
                            <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                        </button>
                    </div>
                </Modal>
                {token !== "coord" && <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>}
            </main>
            {token === "coord" && <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>}
        </>
    );
}
export default BolsaFormadoresList
