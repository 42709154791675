import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../../components/Footer";
import NavbarAdmin2 from "../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import {
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import Cookies from "js-cookie";
import AlterarFormato from "../../../components/FormChecks/formatDates/foramatDates";
import toast from "../../../components/Toast/toastErrorBD";

function Candidaturas() {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [trainingEntityList, setTrainingEntityList] = useState([]);
  const [operationsList, setOperationsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [savedSearchTerm, setSavedSearchterm] = useState(
    Cookies.get("searchTerm")
  );
  const [searchTerm, setSearchTerm] = useState(
    savedSearchTerm ? savedSearchTerm : ""
  );
  const [savedTrainingEntity, setSavedTrainingEntity] = useState(
    Cookies.get("trainingEntity")
  );
  const [trainingEntity, setTrainingEntity] = useState(
    savedTrainingEntity ? savedTrainingEntity : ""
  );
  const [savedOperation, setSavedOperation] = useState(
    Cookies.get("operation")
  );
  const [operation, setOperation] = useState(
    savedOperation ? savedOperation : ""
  );
  const [savedStatusOfCourses, setSavedStatusOfCourses] = useState(
    Cookies.get("statusOfCourses")
  );
  const [statusOfCourses, setStatusOfCourses] = useState(
    savedStatusOfCourses ? savedStatusOfCourses : ""
  );
  const [savedItemsPerPage, setSavedItemsPerPage] = useState(
    Cookies.get("itemsPerPage")
  );
  const [itemsPerPage, setItemsPerPage] = useState(
    savedItemsPerPage ? savedItemsPerPage : 5
  );
  const [savedCurrentPage, setSavedCurrentPage] = useState(
    Cookies.get("currentPage")
  );
  const [currentPage, setCurrentPage] = useState(
    savedCurrentPage ? parseInt(savedCurrentPage) : 1
  );

  useEffect(() => {
    Cookies.set("SuperAdmin-turmas-Pag", currentPage);
    Cookies.set("SuperAdmin-turmas-1", searchTerm);
    Cookies.set(`SuperAdmin-turmas-itm1`, itemsPerPage);
    handleSearch();
    getEntFormadora();
    getOperacao();
  }, [
    currentPage,
    totalPages,
    searchTerm,
    itemsPerPage,
    operation,
    trainingEntity,
    statusOfCourses,
  ]);

  async function getCourses(
    currentPage,
    itemsPerPage,
    searchTerm,
    statusOfCourses,
    trainingEntity,
    operation
  ) {
    const queryParams = new URLSearchParams({
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
      searchTerm: searchTerm,
      statusOfCourses: statusOfCourses,
      trainingEntity: trainingEntity,
      operation: operation,
    }).toString();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/percurso/ativos?${queryParams}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem("admin2"),
          },
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      // Verifica se result.data é um array, se não for, transforma em um array
      const dataArray = Array.isArray(result.data)
        ? result.data
        : [result.data];

      // Busca as candidaturas para cada elemento e adiciona nCandidaturas
      const enrichedData = await Promise.all(
        dataArray.map((element) => fetchCandidaturas(element))
      );

      if (currentPage > totalPages) {
        setCurrentPage(totalPages > 0 ? totalPages : 1);
      } else {
        setTotalPages(totalPages);
      }

      // Atualiza o estado com os dados enriquecidos
      setData(enrichedData);
      setTotalPages(result.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast("Erro ao obter os dados, por favor tente mais tarde.");
    }
  }

  const fetchCandidaturas = async (element) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/percurso/${element._id}/candidatos`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem("admin2"),
        },
        method: "GET",
      }
    );
    const result = await response.json();
    return { ...element, nCandidaturas: result };
  };

  const determineCourseStatus = (dateBegin, dateEnd) => {
    const now = new Date().toISOString().split("T")[0];

    if (dateBegin <= now && dateEnd >= now) {
      return "A decorrer";
    }
    if (dateEnd < now) {
      return "Terminado";
    }
    if (dateBegin > now) {
      return "Planeado";
    }
  };

  // Get training entity from the API
  async function getEntFormadora() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/entFormadora/`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem("admin2"),
          },
        }
      );
      const result = await response.json();
      setTrainingEntityList(result);
    } catch (error) {
      toast("Erro ao obter os dados, por favor tente mais tarde.");
    }
  }

  //Get operations from the API
  async function getOperacao() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/operacao/`,
        {
          headers: {
            Accept: "application/json",
            ContentType: "application/json",
            authorization: localStorage.getItem("admin2"),
          },
        }
      );
      const result = await response.json();
      setOperationsList(result);
    } catch (error) {
      toast("Erro ao obter os dados, por favor tente mais tarde.");
    }
  }

  const handleSearch = async () => {
    await getCourses(
      currentPage,
      itemsPerPage,
      searchTerm,
      statusOfCourses,
      trainingEntity,
      operation
    );
  };

  function increasePagination() {
    if (currentPage < totalPages) {
      setCurrentPage((prevIndex) => prevIndex + 1);
    }
  }

  function decreasePagination() {
    if (currentPage > 1) {
      setCurrentPage((prevIndex) => prevIndex - 1);
    }
  }

  if (loading)
    return (
      <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
        <div className="loading"></div>
      </div>
    );
  if (error)
    return toast("Erro ao obter os dados, por favor tente mais tarde.");

  function goToLastPage() {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages);
    }
  }

  function goToFirstPage() {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }

  return (
    <>
      <main className="min-h-screen">
        <div className="w-full">
          <NavbarAdmin2 currentPage={"gerirTurmasFormandos"} />
        </div>
        <div className="flex-grow mt-8">
          <Link to={"/MenuSuperAdmin"} className="back-button-div-noNavbar">
            <img
              className="back-button-img"
              alt="Back"
              src={process.env.PUBLIC_URL + "/img/back_arrow.svg"}
            />
          </Link>
          <h1 className="title">Lista de Cursos/Percursos</h1>
          <div className="search-div">
            <label className="search-input-label">Pesquisar: </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  className="w-4 h-4 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                defaultValue={searchTerm}
                type="text"
                className="search-input"
                placeholder="ex: Nome, Local ..."
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <label className="search-input-label">Filtrar por:</label>
          <div className="flex flex-col justify-center mb-2 sm:flex-row">
            <div className="flex flex-col sm:mr-5">
              <label className="text-text-second-color" htmlFor="templateCheck">
                Entidades Formadoras{" "}
              </label>
              {trainingEntityList.length > 0 && (
                <select
                  onWheel={(e) => e.target.blur()}
                  defaultValue={trainingEntity}
                  className="mx-auto w-52 search-selector"
                  name="sel"
                  id="seletorEnt"
                  onChange={(e) => {
                    setTrainingEntity(e.target.value);
                  }}
                >
                  <option value="">Todas</option>
                  {trainingEntityList.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="mr-5 flex flex-col">
              <label className="text-text-second-color" htmlFor="templateCheck">
                Operações{" "}
              </label>
              {operationsList.length > 0 && (
                <select
                  defaultValue={operation}
                  onWheel={(e) => e.target.blur()}
                  className="mx-auto w-52 search-selector"
                  name="sel"
                  id="seletorOp"
                  onChange={(e) => {
                    setOperation(e.target.value);
                  }}
                >
                  <option value="">Todas</option>
                  {operationsList.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.codigoOperacao}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="mr-5 flex flex-col">
              <label className="text-text-second-color" htmlFor="templateCheck">
                Estado do Percurso{" "}
              </label>
              <select
                defaultValue={statusOfCourses}
                onWheel={(e) => e.target.blur()}
                className="mx-auto w-52 search-selector"
                name="sel"
                id="seletorOp"
                onChange={(e) => {
                  setStatusOfCourses(e.target.value);
                }}
              >
                <option value={""}>Todos</option>
                <option value={"A decorrer"}>A decorrer</option>
                <option value={"Terminado"}>Terminado</option>
                <option value={"Planeado"}>Planeado</option>
              </select>
            </div>
          </div>
          {data && data.length > 0 ? (
            <>
              <div className="flex justify-center items-center mb-1">
                <label className="text-text-main-color">
                  Dados por página:{" "}
                </label>
                <select
                  onWheel={(e) => e.target.blur()}
                  className="selector w-14 ml-1"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
              <div className="table-container">
                <table className="my-table">
                  <thead className="bg-main-color">
                    <tr className="text-white h-14">
                      <th className="p-2 min-w-[80px] border border-white">
                        Operação
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Entidade Formadora
                      </th>
                      <th className="p-2 min-w-[150px] border border-white">
                        Nome
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Forma de Organização
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Modalidades
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Local
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Nº de Candidatos
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Nº de Inscritos
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Nº de Disciplinas
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Data de Início
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Data de Fim
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Duração
                      </th>
                      <th className="p-2 min-w-[150px] border border-white">
                        Coordenador Pedagógico
                      </th>
                      <th className="p-2 min-w-[100px] border border-white">
                        Estado
                      </th>
                    </tr>
                  </thead>
                  {data.length > 0 ? (
                    <tbody className="text-text-main-color">
                      {data
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((item, index) => (
                          <tr
                            key={index}
                            className="row-click"
                            onClick={() =>
                              navigate(
                                "/MenuSuperAdmin/turmas/turma/" + item._id
                              )
                            }
                          >
                            <td className="border border-white">
                              {item.operacao[0].codigoOperacao}
                            </td>
                            <td className="border border-white item">
                              {item.entFormadora[0] ? (
                                <>{item.entFormadora[0].name} </>
                              ) : (
                                "N/A"
                              )}
                            </td>
                            <td className="border border-white">{item.name}</td>
                            <td className="border border-white">{item.type}</td>
                            <td className="border border-white">
                              {item.modalidade}
                            </td>
                            <td className="border border-white">
                              {item.locality}
                            </td>
                            <td className="border border-white">
                              {item.nCandidaturas.length}
                            </td>
                            {item.formandos ? (
                              <td className="border border-white">
                                {item.formandos ? item.formandos.length : 0}
                              </td>
                            ) : (
                              <td className="border border-white">0</td>
                            )}
                            <td className="border border-white">
                              {item.ufcds.length}
                            </td>
                            <td className="border border-white">
                              {AlterarFormato(item.dateBegin)}
                            </td>
                            <td className="border border-white">
                              {AlterarFormato(item.dateEnd)}
                            </td>
                            <td className="border border-white">
                              {item.duration}
                            </td>
                            <td className="border border-white">
                              {item.Coordenador && item.Coordenador[0]
                                ? item.Coordenador[0].fullName
                                : "Sem Coordenador"}
                            </td>
                            <td className="border border-white item">
                              {determineCourseStatus(
                                item.dateBegin,
                                item.dateEnd
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  ) : (
                    <tbody />
                  )}
                </table>
              </div>
              <div className="flex items-center justify-center mt-4 mb-8">
                <button
                  className={
                    currentPage > 1
                      ? "button-geral mr-1"
                      : "button-disabled mr-1"
                  }
                  onClick={() => goToFirstPage()}
                >
                  <AiOutlineDoubleLeft />
                </button>
                <button
                  className={
                    currentPage > 1
                      ? "button-geral mr-1"
                      : "button-disabled mr-1"
                  }
                  onClick={() => decreasePagination()}
                >
                  <AiOutlineLeft />
                </button>
                <p className="text-main-color">
                  Página {currentPage} - {totalPages}
                </p>
                <button
                  className={
                    currentPage < totalPages
                      ? "button-geral ml-1"
                      : "button-disabled ml-1"
                  }
                  onClick={() => increasePagination()}
                >
                  <AiOutlineRight />
                </button>
                <button
                  className={
                    currentPage < totalPages
                      ? "button-geral ml-1"
                      : "button-disabled ml-1"
                  }
                  onClick={() => goToLastPage()}
                >
                  <AiOutlineDoubleRight />
                </button>
              </div>
            </>
          ) : (
            <p className="third-title">Não existem dados</p>
          )}
        </div>
        <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
          <Footer />
        </div>
      </main>
    </>
  );
}

export default Candidaturas;
